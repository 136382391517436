const FAQItem = ({ question, answer, isOpen, onClick }: any) => (
  
    <div className="border-b border-gray-200 py-4">
      <button
        onClick={onClick}
        className="focus  flex w-full items-center justify-between font-bold text-left text-gray-700"
      >
        <span className="font-semibold font-ibm-plex text-base">{question}</span>
        <svg
          width="24"
          height="26"
          viewBox="0 0 24 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={
              isOpen
                ? "M17 14H9M13 24C18.5228 24 23 19.5228 23 14C23 8.47715 18.5228 4 13 4C7.47715 4 3 8.47715 3 14C3 19.5228 7.47715 24 13 24Z"
                : "M13 10V18M17 14H9M13 24C18.5228 24 23 19.5228 23 14C23 8.47715 18.5228 4 13 4C7.47715 4 3 8.47715 3 14C3 19.5228 7.47715 24 13 24Z"
            }
            stroke="#98A2B3"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {isOpen && <div className="mt-4 text-gray-600 font-normal font-ibm-plex">{answer}</div>}
    </div>
  );

  export default FAQItem