import { FC, useState } from "react";
import OtpInput from "react-otp-input";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { trpc } from "../../lib/trpc";
import { useAuth } from "../Common/isAuth";

const LoginOtp: FC = () => {
  const { login } = useAuth();

  const [otp, setOtp] = useState("");
  const [isOtpFocused] = useState(false);
  const [formErrors, setFormErrors] = useState("");

  const params = useParams<{ email: string }>() as { email: string };
  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    navigate(route); // Replace '/new-route' with your desired route
  };

  const { mutate: verifyOtp, isLoading: isVerify } =
    trpc.user.verifyOTP.useMutation({
      onSuccess: (res) => {
        // console.log(res, "resss2");
        if (res) {
          const token = res.token;
          login(token);
          if (res.isAccountActivated) {
            handleNavigate(`/dashboard/home`);
          } else {
            handleNavigate(`/dashboard`);
          }
          toast.success("OTP verified, successfully!");
        }
      },
      onError: (err) => {
        console.log(err, "errrrrr2");
        setFormErrors(err.message);
      },
    });

  const handleChange = (value: string) => {
    setOtp(value);
    if (formErrors) {
      setFormErrors(""); // Clear errors when the user starts typing
    }
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      verifyOtp({ useCase: "LOGIN", otp });
    } catch (error) {
      console.error("Error submitting OTP:", error);
    }
  };

  const { mutate: resendOtp, isLoading: isResend } =
    trpc.user.resendSignUpOTP.useMutation({
      onSuccess: (res) => {
        console.log(res, "resss2");
        toast.success(res);
      },
      onError: (err) => {
        console.log(err, "errr");
        toast.error(err.message);
      },
    });

  const onResend = async (event: any) => {
    event.preventDefault();

    try {
      resendOtp({ useCase: "LOGIN", email: params?.email });
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  if (!params?.email && params.email === undefined) {
    return null;
  }

  return (
    <div className="login relative h-screen w-screen bg-white">
      <div className="login-content flex h-full w-full flex-col items-start overflow-hidden">
        <section className="content-top flex w-full items-center justify-between px-16 py-10">
          <div className="w-[120px]">
            <Link to="/login">
              <img
                className="h-full w-full"
                src="/logo.jpeg"
                alt="Shiga Logo"
              />
            </Link>
          </div>
        </section>

        <section className="content-right mt-[8px] justify-center self-center justify-self-center rounded-lg bg-white px-[30px] pb-[25px] pt-[5px]">
          <div className="right-top mt-[35px] w-[560px]">
            <div className="font-ibm-plex w-full font-normal text-htext-main antialiased">
              <h1 className=" text-left text-[29px] font-semibold tracking-[-0.72px]">
                Enter your verification code
              </h1>
              <p className="font-ibm-plex  mt-2 text-[13px] text-htext-subdued ">
                To log in, kindly enter the code sent to your email address
              </p>
            </div>
          </div>
          <form onSubmit={onSubmit}>
            <fieldset className="mt-[30px] w-[560px] rounded-[14px] bg-hgray-50 px-4 py-8">
              <div className="">
                <p className="text-center text-[15px] font-normal text-htext-subdued">
                  Enter the code sent to{" "}
                  <span className="dotted-noti ml-1 rounded-lg border border-solid border-hbrand-200 bg-hbrand-50 py-1 pl-5 pr-2.5 text-[13px] font-medium text-hbrand-700">
                    {" "}
                    {params?.email}
                  </span>
                </p>
              </div>
              <div
                className={`${
                  formErrors ? "otp-input-error" : "otp-input-container"
                } mt-6 flex items-center justify-center`}
              >
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  inputType="tel"
                  placeholder="------"
                  inputStyle={{
                    width: "3.5rem",
                    color: formErrors ? "#B42318" : "#175CD3",
                    height: "3.5rem",
                    margin: "0 0.5rem",
                    fontSize: "1.8rem",
                    borderRadius: 8,
                    border: `${
                      otp && !formErrors
                        ? "1px solid #175CD3"
                        : (otp && formErrors) || (!otp && formErrors)
                          ? "2px solid #B42318"
                          : "1px solid #D0D5DD"
                    }`,
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                    ...(isOtpFocused && {
                      borderColor: formErrors ? "#B42318" : "#175CD3",
                      borderWidth: "2px",
                      boxShadow: formErrors
                        ? "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(180, 35, 24, 0.24)"
                        : "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(21, 112, 239, 0.24)",
                    }), // Example focus border color
                  }}
                  shouldAutoFocus
                  renderInput={(props) => <input {...props} />}
                />
              </div>
              {formErrors && (
                <p className="font-ibm-plex mt-4 text-center text-[14px] font-medium text-red-600">
                  {!otp && formErrors
                    ? "The 6-digit code sent to your email is required"
                    : formErrors === "Invalid OTP"
                      ? "Code is invalid or has expired"
                      : ""}
                </p>
              )}
            </fieldset>
            <div className="font-ibm-plex mt-[5px] w-[560px] font-normal antialiased">
              <button
                type="submit"
                disabled={isVerify}
                className="mb-[16px] mt-[50px] w-full rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[11px] text-[15px] font-bold text-white hover:bg-hbrand-700"
              >
                {isVerify ? (
                  <span className="loading loading-spinner loading-sm"></span>
                ) : (
                  "Verify"
                )}
              </button>

              <p className="mt-2 text-center text-[14px] font-medium text-htext-main">
                Didn’t get the code?{" "}
                <b
                  className={`font-semibold text-hbrand-700 ${
                    isResend ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                  onClick={onResend}
                >
                  {isResend ? "Resending..." : "Resend"}
                </b>
              </p>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default LoginOtp;
