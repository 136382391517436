import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { trpc } from "../../../../lib/trpc";
import { capitalizeText } from "../../../../utils/capitalise";
import RadioButton from "../../../Common/buttons/radioBtn";
import Inputs from "../../../Common/inputs";
import Select from "../../../Common/inputs/select";
import {
  arrowRight,
  TinyBriefSVG,
  warnToolTipSVG,
} from "../../../Common/svg/index";
import { uboschema, Uboschema } from "./schema";

type OwnerTypes = "YES" | "NO";

const CompleteUBOInfo: FC = () => {
  const navigate = useNavigate();
  const { mutate: updateInvitedCorUBO, isLoading: updatingInvitedCorUBO } =
    trpc.user.updateInvitedCorporateUBO.useMutation({
      onSuccess: () => {
        navigate("/dashboard/get-started");
      },
    });
  const { data, isLoading } = trpc.user.getInvitingOrgDetails.useQuery({});
  const { data: invitationResponse } =
    trpc.user.checkForUboInvitation.useQuery({});

  const methods = useForm({
    resolver: zodResolver(uboschema),
    defaultValues: {
      jobTitle: "",
      percentageOfOwnership: "",
      isPep: false,
      isOwner: false,
    },
  });

  const {
    setValue,
    register,
    formState: { errors },
  } = methods;

  const sof = ["DIRECTOR", "CEO", "CFO", "COO", "OTHERS"];
  const [selectedPEP, setSelectedPEP] = useState<OwnerTypes>();

  const handleRadioChangePEP = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedPEP(value as OwnerTypes);
    setValue("isPep", value == "YES");
  };

  const onCorpUBOSaved = (data: Uboschema) => {
    updateInvitedCorUBO(data);
  };

  useEffect(() => {
    setValue(
      "percentageOfOwnership",
      invitationResponse?.company?.percentageOfOwnership as string
    );
    setValue("jobTitle", invitationResponse?.company?.jobTitle as string);
    setSelectedPEP(invitationResponse?.company?.isPep ?"YES":"NO");
  }, [invitationResponse]);

  return (
    <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onCorpUBOSaved)}>
    <div className="pform h-screen w-screen bg-white pb-2 pt-4">
      <div className="pform-content h-full w-full overflow-hidden">
        <div className="content-nav mt-5 flex items-center px-40">
          <p className="text-[13px] font-medium text-htext-label">
            Onboarding checklist
          </p>
          <span
            className="mx-4"
            dangerouslySetInnerHTML={{ __html: arrowRight }}
          />
          <p className="text-[13px] font-semibold text-hbrand-700">
            Complete UBO Information
          </p>
        </div>

        <div className="content-nav mx-auto h-full w-full pb-40">
          <div className="content-header mx-auto mt-12 w-[815px]">
            <h2 className="text-[28px] font-semibold text-htext-main">
              Complete UBO Information
            </h2>
          </div>
  
          <div className="setupAccounts hide-scrollbar mt-6 h-full w-full overflow-y-scroll">
            <div className="accounts-top flex h-full flex-col items-center justify-between pt-3">
              <div className="w-[815px] rounded-2xl border border-hgray-100 p-7">
                {isLoading ? (
                  <span className="loading loading-spinner loading-sm"></span>
                ) : (
                  <div className="top-banner flex w-full items-center justify-between rounded-t-2xl bg-hgray-50 p-6 shadow-s-light">
                    <div className="w-[53.5%]">
                      <span className="text-center text-[11px] font-normal text-htext-main">
                        YOUR BUSINESS
                      </span>
                      <div className="mt-4 flex items-start">
                        <span
                          className="mt-1"
                          dangerouslySetInnerHTML={{ __html: TinyBriefSVG }}
                        />
                        <div className="ml-4">
                          <h3 className="text-[18.5px] font-semibold text-htext-label">
                            {capitalizeText(data?.legalName)}
                          </h3>
                          <p className="text-[15px] font-normal text-htext-main">
                            {capitalizeText(data?.type.replaceAll("_", " "))}
                          </p>
                          <p className="mt-3 text-[13px] font-normal text-htext-main">
                            (13 silverlane, 101283, Lagos, Nigeria)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                  <div className="form-content mt-4 bg-white">
                    <fieldset>
                      <div className="">
                        <p className="text-[13.5px] font-normal text-htext-subdued">
                          As an ultimate beneficiary owner of this business, be
                          sure to enter your details as it is in the business
                          documents
                        </p>
                        <div
                          className={`flex w-full items-start justify-between ${
                            errors.jobTitle || errors.percentageOfOwnership
                              ? "mt-[.80rem] h-24"
                              : "mt-[1.30rem]"
                          }`}
                        >
                          <label
                            htmlFor="jobTitle"
                            className="relative h-full w-[48.5%]"
                          >
                            <div className="mb-1.5 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>
                              <p className="text-htext-label">Job title</p>
                            </div>
                            <Select
                              id="jobTitle"
                              register={register("jobTitle")}
                              optionsItem={sof}
                              inputClass={true}
                              error={errors?.jobTitle}
                            />
                            {errors.jobTitle && (
                              <p className="font-ibm-plex absolute mt-1.5 text-[13px] font-medium text-red-600">
                                {errors.jobTitle.message}!
                              </p>
                            )}
                          </label>

                          <label
                            htmlFor="percentageOfOwnership"
                            className="relative h-full w-[48.5%]"
                          >
                            <div className="mb-1.5 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>
                              <p className="text-htext-label">
                                Percentage of ownership
                              </p>
                            </div>
                            <Inputs
                              type="text"
                              placeholder="Enter here"
                              register={register("percentageOfOwnership")}
                              addedIcon={true}
                              inputClass={true}
                              error={errors?.percentageOfOwnership}
                            />
                            {errors.percentageOfOwnership && (
                              <p className="font-ibm-plex absolute mt-1.5 text-[13px] font-medium text-red-600">
                                {errors.percentageOfOwnership.message}!
                              </p>
                            )}
                          </label>
                        </div>

                        <div className="mt-8 w-[22rem]">
                          <div className="flex items-center">
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>
                            <p className="flex items-center  text-[13px] font-medium text-htext-label">
                              Are you a political exposed personnel (PEP)?{" "}
                              <span
                                className="ml-1 cursor-pointer"
                                dangerouslySetInnerHTML={{
                                  __html: warnToolTipSVG,
                                }}
                              />
                            </p>
                          </div>
                          <div className="radio-btns mt-2 flex w-full items-center justify-between">
                            <div className="btn-1 flex h-[2.5rem] w-[48.2%] items-center rounded-lg border border-hgray-200 bg-hgray-25 px-4 py-2">
                              <RadioButton
                                name="pep"
                                value="YES"
                                checked={selectedPEP == "YES"}
                                onChange={handleRadioChangePEP}
                                className="squared w-auto"
                              />
                              <p className="ml-2 text-[13px] font-medium text-hgray-700">
                                Yes
                              </p>
                            </div>

                            <div className="btn-2 flex h-[2.5rem] w-[48.2%] items-center rounded-lg border border-hgray-200 bg-hgray-25 px-4 py-2">
                              <RadioButton
                                name="pep"
                                value="NO"
                                checked={selectedPEP == "NO"}
                                onChange={handleRadioChangePEP}
                                className="squared w-auto"
                              />
                              <p className="ml-2 text-[13px]  font-medium text-hgray-700">
                                No
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
              </div>

              <div
                className={`flex h-fit w-full items-center justify-between border-t border-t-hgray-200 px-14 pb-1 pt-6`}
              >
                <button
                  className="w-[150px] rounded-lg border  border-solid border-hgray-300 bg-white px-[10px] py-[13px] text-[15px] font-semibold text-hgray-700 shadow-s-light hover:bg-hgray-50"
                  type="button"
                  onClick={() =>
                    navigate(
                      "/get-started/onboarding-checklist/wallets-setup/how-it-works"
                    )
                  }
                >
                  Go Back
                </button>

                <button
                type="submit"
                  className="w-[250px] rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[13px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
                >
                  {updatingInvitedCorUBO ? (
                    <span className="loading loading-spinner loading-sm"></span>
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    </form>
    </FormProvider>
  );
};

export default CompleteUBOInfo;
