import PortalInBankAccount from "../../../../components/Dashboard/portal-accounts/portal-in/portal-in-bank-details";

function PortalInBankAccounts() {
    return (
            <div>
                <PortalInBankAccount />
            </div>
    )
}

export default PortalInBankAccounts;