import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BackArrowSVG } from "../../Common/svg";


const ResetEmailSent: FC = () => {
  const params = useParams<{ email: string }>() as { email: string };

  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    navigate(route); // Replace '/new-route' with your desired route
  };

  // const handleGoBack = () => {
  //   navigate(-1);
  // };

  return (
    <div className="login relative h-screen w-screen bg-white">
      <div className="login-content flex h-full w-full flex-col items-start overflow-hidden">
        <section className="content-top flex w-full items-center justify-between px-16 py-10">
          <div className="w-[120px]">
            <img className="h-full w-full" src="/logo.jpeg" alt="Shiga Logo" />
          </div>

          <div className="navi-text font-ibm-plex flex items-center justify-end space-x-3 text-sm">
            <p
              className="font-normal text-htext-main hover:text-gray-700"
            >
              Don’t have an account yet?
            </p>
            <button
              className="w-[110px] cursor-pointer rounded-lg border border-solid border-gray-300 px-[12px] py-[7px] font-semibold text-gray-700 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] transition duration-300 ease-in-out hover:bg-gray-300/[0.2]"
              type="button"
              onClick={() => handleNavigate("/signup")}
            >
              Sign up
            </button>
          </div>
        </section>

        <section className="content-right mt-[8px] justify-center self-center justify-self-center rounded-lg bg-white px-[30px] pb-[25px] pt-[5px]">
          <div className="font-ibm-plex w-[560px] border-b-[1.5px] border-solid border-b-hgray-200 py-3 font-semibold text-gray-600 antialiased">
            <div
              className="flex w-[135px] cursor-pointer items-center justify-start rounded border-solid border-gray-200 px-1.5 py-1.5 hover:border"
              onClick={() => handleNavigate("/login")}
            >
              <span
                className="mr-2"
                dangerouslySetInnerHTML={{ __html: BackArrowSVG }}
              />
              <p className="text-[15px]">Back to log in</p>
            </div>
          </div>
              <div className="right-top mt-[35px] w-[560px]">
                <div className="font-ibm-plex w-full font-normal text-htext-main antialiased">
                  <h1 className=" text-left text-[26px] font-semibold tracking-[-0.72px]">
                    Reset link sent
                  </h1>
                  <p className="mt-3 text-[15px] text-htext-subdued">
                    We sent an email to{" "}
                    <span className="dotted-noti rounded-lg border border-solid border-hbrand-200 bg-hbrand-50 py-1 pl-5 pr-2.5 text-[13px] font-medium text-hbrand-700">
                      {" "}
                      {params?.email}
                    </span>
                  </p>
                  <p className="mt-1.5 text-[15px] text-htext-subdued">
                    Please check for instructions to reset your password
                  </p>
                </div>
              </div>
              <div className="mt-[30px] flex w-[560px] items-start rounded-[14px] bg-hgray-50 px-6 py-6">
                <div className="mr-2 mt-1 h-4 w-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      opacity="0.4"
                      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z"
                      fill="#475467"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 5.25C10.4142 5.25 10.75 5.58579 10.75 6V7C10.75 7.41421 10.4142 7.75 10 7.75C9.58579 7.75 9.25 7.41421 9.25 7V6C9.25 5.58579 9.58579 5.25 10 5.25ZM10 8.75C10.4142 8.75 10.75 9.08579 10.75 9.5V14C10.75 14.4142 10.4142 14.75 10 14.75C9.58579 14.75 9.25 14.4142 9.25 14V9.5C9.25 9.08579 9.58579 8.75 10 8.75Z"
                      fill="#475467"
                    />
                  </svg>
                </div>
                <p className="font-ibm-plex text-[15px] font-normal text-htext-subdued antialiased">
                  We can only send password reset instructions to registered
                  emails with an existing account. If no email is received in
                  your inbox or spam folder, please confirm the email you
                  entered.
                </p>
              </div>
        </section>
      </div>
    </div>
  );
};

export default ResetEmailSent;
