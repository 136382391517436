import React from 'react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handlePageClick = (page: number) => {
    onPageChange(page);
  };

  return (
    <div className="flex justify-between items-center space-x-2 w-full py-4 px-6">
      <button
        className="px-4 py-[7px] text-hgray-700 font-[Inter] font-semibold text-[14px] bg-white border border-hgray-300 rounded-lg hover:bg-hgray-50 disabled:opacity-50 shadow-s-light"
        onClick={() => handlePageClick(currentPage - 1)}
        disabled={currentPage === 1}
      >
        &larr;  Previous
      </button>
      <div className='flex items-center gap-x-2'>
      {pageNumbers.map((pageNumber) => (
        <button
          key={pageNumber}
          className={`px-4 py-2.5 rounded-lg font-[Inter] font-medium text-[14px] text-center ${
            pageNumber === currentPage
              ? 'bg-gray-50 text-hgray-800 '
              : 'text-hgray-500 bg-white hover:bg-hgray-100'
          }`}
          onClick={() => handlePageClick(pageNumber)}
        >
          {pageNumber}
        </button>
      ))}
      </div>
      <button
        className="px-4 py-[7px] text-hgray-700 font-[Inter] font-semibold text-[14px] bg-white border border-hgray-300 rounded-lg hover:bg-hgray-50 disabled:opacity-50 shadow-s-light"
        onClick={() => handlePageClick(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next &rarr;
      </button>
    </div>
  );
};

export default Pagination;
