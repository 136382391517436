import MainLoader from '../../components/Common/loader/mainLoader';
import { useAuth } from '../../components/Common/isAuth';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated } = useAuth();
  
  if (isAuthenticated === null) {
    return <MainLoader />; // Show a loading indicator while the state is being determined
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};


export const UnprotectedRoute = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated } = useAuth();
  
  if (isAuthenticated === null) {
    return <MainLoader />; // Show a loading indicator while the state is being determined
  }

  if (isAuthenticated) {
    return <Navigate to="/dashboard" replace />; // Redirect authenticated users to the dashboard or another protected page
  }

  return children;
};
