import { useState } from "react";

export interface allInputsProps {
  id?: string;
  type: string;
  register: any;
  placeholder: string;
  error?: any;
  icon?: string; // SVG icon passed as a string
  showIcon?: string; // SVG icon for showing password
  hideIcon?: string; // SVG icon for hiding password
  inputClass?: boolean;
  inputClassMax?: boolean;
  addedIcon?: boolean;
  disabled?:boolean;
  countryDetails?: Array<string | { phonecode: string; name: string; flag: string }>; // Country code value
  onCountryCodeChange?: (value: string) => void;
}

const Inputs = ({
  id,
  type,
  placeholder,
  icon,
  showIcon,
  hideIcon,
  register,
  error,
  inputClass,
  addedIcon,
  disabled,
}: allInputsProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  return (
    <div
      className={`mt-[4px] flex items-center rounded-[8px] border border-solid ${disabled ? "bg-slate-50" : "bg-white"} ${addedIcon ? "pl-0 pr-[16px]" : "px-[16px]"}  shadow-s-light focus:outline-double focus:outline-2 ${
        error
          ? "border-red-500 shadow-s-light-red ring-r-width ring-r-color-red focus:outline-red-400"
          : isFocused
            ? "border-gray-300 ring-r-width ring-r-color focus:outline-hbrand-300"
            : "border-gray-300"
      }`}
    >
      {type === "email" && (
        <span
          className="mr-1.5"
          dangerouslySetInnerHTML={{ __html: icon || "" }}
        />
      )}
      {addedIcon && (
        <span
          className="border-r border-r-hgray-300 h-full p-[0.57rem] text-hgray-500 mr-3">%</span>
      )}
      <input
        id={id}
        type={isPasswordVisible && type === "password" ? "text" : type}
        className={`${inputClass ? "h-[42px]" : "h-[36px]"} ${
          type === "file" ? "cursor-pointer" : ""
        }  w-full rounded-[8px] border-0 py-[4px] text-sm font-normal focus:outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none`}
        placeholder={placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        accept={type === "file" ? "pdf" : ""}
        disabled={disabled}
        {...register}
      />

      {type === "password" && (
        <span
          className="ml-1.5 cursor-pointer"
          onClick={togglePasswordVisibility}
          dangerouslySetInnerHTML={{
            __html: isPasswordVisible ? hideIcon || "" : showIcon || "",
          }}
        />
      )}
    </div>
  );
};

export default Inputs;
