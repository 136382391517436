import { useState, useRef, useEffect, FC } from "react";

interface DropdownProps {
  dropdownItems: string[];
  defaultSelectedItem?: string;
  svgIcon: string;
  dropClass?: string;
  disabledClass?: string;
  onSelect?: (item: string) => void;
}

const CommonDropdown: FC<DropdownProps> = ({
  dropdownItems,
  defaultSelectedItem,
  svgIcon,
  dropClass,
  disabledClass,
  onSelect,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>(defaultSelectedItem || ""); // Default value
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Sync selectedValue with defaultSelectedItem when it changes
    setSelectedValue(defaultSelectedItem || "");
  }, [defaultSelectedItem]);

  const handleItemClick = (item: string): void => {
    setSelectedValue(item);
    setIsOpen(false); // Close the dropdown when an item is selected
    if (onSelect) {
      onSelect(item); // Invoke onSelect if provided
    }
  };

  const handleToggleDropdown = (): void => {
    setIsOpen(!isOpen);
  };

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className={`inline-flex h-full w-full items-center justify-between font-instrument-sans text-[13px] font-normal text-htext-placeholder focus:outline-none`}
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={handleToggleDropdown}
        >
          {!selectedValue ? disabledClass : selectedValue === "cryptoToFiat" ? "Portal Out" : selectedValue === "fiatToCrypto" ? "Portal In" : selectedValue}
          <span
            className={`${
               dropClass === "currency" && selectedValue ? "ml-[56px]"
                 : dropClass === "currency" ? "ml-[30px]"
                : dropClass === "portalType"
                  ? "ml-[35px]"
                  : dropClass === "date"
                    ? "ml-[55px]"
                      : ""
            }`}
            dangerouslySetInnerHTML={{ __html: svgIcon }}
          />
        </button>
      </div>

      {isOpen && (
        <div
          className={`absolute mt-5 origin-top-right ${
            dropClass === "currency"
              ? "ml-[-14.5px] w-[125px]"
              : dropClass === "portalType"
                ? "ml-[-14.5px] w-[145px]"
                : dropClass === "date"
                  ? "ml-[-14.5px] w-[130px]"
                    : ""
          }  z-10 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
        >
          <div className="py-2.5 border border-solid border-hgray-300 rounded-[5px] shadow-s-light" role="none">
            {dropdownItems.map((item, index) => (
              <button
                key={index}
                onClick={() => handleItemClick(item)}
                className={`block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-light-blue`}
                role="menuitem"
                tabIndex={-1}
              >
                {item === "cryptoToFiat" ? "Portal Out" : item === "fiatToCrypto" ? "Portal In" : item}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CommonDropdown;
