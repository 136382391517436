import PortalOutDetails from "../../../../components/Dashboard/portal-accounts/portal-out/portal-out-details";

function PortalOutDetailsCont() {
    return (
            <div>
                <PortalOutDetails />
            </div>
    )
}

export default PortalOutDetailsCont;