import SetupWallets from "../../../../../components/Dashboard/get-started/onboarding-checklist/walletsSetup/SetupWallets";


  
function WalletsSetupWallets() {
    return (
            <div>
                <SetupWallets />
            </div>
    )
}

export default WalletsSetupWallets;