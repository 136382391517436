import ConfirmSetupDetails from "../../../../../components/Dashboard/get-started/onboarding-checklist/walletsSetup/ConfirmSetupDetails";


  
function WalletsConfimrSetupDetails() {
    return (
            <div>
                <ConfirmSetupDetails />
            </div>
    )
}

export default WalletsConfimrSetupDetails;