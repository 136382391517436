import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { arrowRight, closeBtnSmallSVG } from "../../../Common/svg";
import Stepper from "../../../Common/stepper/stepper";

interface PortalInChildren {
  children: any;
  currentStep: number;
  complete: boolean;
  // onStepChange:(e:any)=>vid
}

const PortalInSetup: FC<PortalInChildren> = ({ children, currentStep, complete }) => {
    const navigate = useNavigate();

  const steps = [
    "Portal type",
    "Settlement stablecoin wallet",
    "Portal bank account",
  ];

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  return (
    <div className="portal-in-stable  h-screen w-screen bg-white pb-2 pt-4">
    <div className="portal-in-stable-content h-full w-full overflow-hidden">
      <div className="content-nav mt-5 flex items-center px-64">
        <p
          className="flex cursor-pointer items-center text-[13px] font-medium text-htext-label hover:opacity-80"
          onClick={() => handleNavigate("/dashboard/portal-accounts")}
        >
          <span
            className="mr-2.5"
            dangerouslySetInnerHTML={{ __html: closeBtnSmallSVG }}
          />
          Close
        </p>
        <span
          className="mx-4"
          dangerouslySetInnerHTML={{ __html: arrowRight }}
        />
        <p className="text-[13px] font-semibold text-hbrand-700">
          Swap fiat to stablecoin
        </p>
      </div>

      <div className="content-nav mx-auto h-full w-full pb-52">
          <Stepper steps={steps} currentStep={currentStep} complete={complete}/>
          {children}
        </div>
    </div>
  </div>
  );
};

export default PortalInSetup;
