import { zodResolver } from "@hookform/resolvers/zod";
import { CircleMinus, CirclePlus, Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useModal } from "../../../../context/modalProvider";
import { trpc } from "../../../../lib/trpc";
import RadioButton from "../../../Common/buttons/radioBtn";
import Inputs from "../../../Common/inputs";
import SidePopup from "../../../Common/popup/sidepopup";
import {
  closeBtnSVG,
  dollarsSVG,
  eurosSVG,
  poundsSVG,
  warnToolTipBlueSVG,
} from "../../../Common/svg";
import {
  addDestinationBankSchema,
  AddDestinationBankSchema,
} from "../../get-started/onboarding-checklist/schema";
import { CurrencyTypes } from "../../get-started/onboarding-checklist/walletsSetup/SetupAccounts";
import { currencyIcons } from "../../portal-accounts/portal-in/portal-in-details";

export default function BankAccounts() {
  const [selectedValue, setSelectedValue] = useState<CurrencyTypes>();
  const [showEditAccPopup, setShowEditAccPopup] = useState(false);
  const [showAccPopup, setShowAccPopup] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const [loadingBankId, setLoadingBankId] = useState<number | null>(null);
  const { showModal } = useModal();
  const utils = trpc.useUtils();

  // TRPC CALLS
  const { data: userAccount, isLoading: isLoadingUserAcct } =
    trpc.user.getBankAccount.useQuery({});

  const { mutateAsync: getSession, isLoading: isLoadingGettingSession } =
    trpc.user.isSessionIdExpired.useMutation({
      onError: (e) => {
        toast.error(e.message);
      },
    });

  const { mutateAsync: triggerOtp } = trpc.user.triggerOtp.useMutation({
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const { mutate: setCustomerSetBankDefault } =
    trpc.user.customerSetBankDefault.useMutation({
      onError: (e) => {
        toast.error(e.message);
        utils.user.getBankAccount.invalidate();
      },
      onSuccess: () => {
        utils.user.getBankAccount.refetch();
      },
      onSettled: () => {
        setLoadingBankId(null);
      },
    });

  const { mutate: updateUpBank, isLoading: isUpdateUpBank } =
    trpc.user.updateUpBank.useMutation({
      onSuccess: () => {
        reset();
        utils.user.getBankAccount.refetch();
        closeShowPopup();
      },
      onError: (e) => {
        console.log(e);
      },
    });

  const {
    mutate: createDestinationBankAcct,
    isLoading: isLoadingCreateDestinationBankAcct,
  } = trpc.user.setUpBank.useMutation({
    onSuccess: () => {
      reset();
      utils.user.getBankAccount.refetch();
      setShowAccPopup(false);
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const handleRadioChangeValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedValue(event.target.value as CurrencyTypes);
    setValue("currency", event.target.value as CurrencyTypes);
  };

  const methods = useForm<AddDestinationBankSchema>({
    resolver: zodResolver(addDestinationBankSchema),
    defaultValues: {
      accountNumber: "",
      iban: "",
      accountName: "",
      sortCode: "",
      currency: undefined,
      mainBeneficiary: true,
    },
  });

  const {
    register,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (showEditAccPopup && userAccount) {
      const accountData = userAccount.find((item) => item.id == id);
      // Populate form fields with fetched data
      if (accountData) {
        setValue("accountNumber", accountData.accountNumber || "");
        setValue("iban", accountData.iban || "");
        setValue("accountName", accountData.accountName || "");
        setValue("sortCode", accountData.sortCode || "");
        setValue("currency", accountData.currency as "GBP" | "EUR");
        setSelectedValue(accountData?.currency as "GBP" | "EUR");
      }
    }
  }, [showEditAccPopup, userAccount, setValue]);

  const [expandedCard, setExpandedCard] = useState<null|number>(null);

  const toggleCard = (card: number) => {
    setExpandedCard(expandedCard === card ? null : card);
  };

  function closeShowPopup() {
    if (showAccPopup === true) {
      setShowAccPopup(false);
    } else {
      setShowEditAccPopup(false);
    }
    reset();
  }

  const onSetUpBankAccount = async (data: AddDestinationBankSchema) => {
    try {
      const response = await getSession({});
      if (response.sessionIdHasExpired) {
        await triggerOtp({});
        showModal();
      } else {
        if (showEditAccPopup && id) {
          updateUpBank({ input: { ...data, id } });
        } else {
          createDestinationBankAcct({ input: data });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="mt-6 grid gap-6">
      <div>
        <h2 className="mb-4 text-lg font-semibold">
          Saved settlement bank accounts
        </h2>
        <p className="mb-4 text-sm text-gray-500">
          Bank account where your portal wallet are sent to these saved local
          bank account
        </p>
        {isLoadingUserAcct && (
          <span className="loading loading-spinner loading-sm"></span>
        )}

        {!isLoadingUserAcct &&
          userAccount?.map(
            ({
              accountName,
              accountNumber,
              currency,
              iban,
              sortCode,
              useAsDefault,
              id,
            }) => (
              <div
                key={id}
                className="mb-4 rounded-lg bg-white"
              >
                <div
                  className="flex cursor-pointer items-center justify-between p-4"
                  onClick={() => toggleCard(id)}
                >
                  <div className="flex items-center">
                    {currency === "EUR" ? (
                      <span
                        className="w-18 mr-2"
                        dangerouslySetInnerHTML={{
                          __html: eurosSVG,
                        }}
                      />
                    ) : (
                      <span
                        className="w-18 mr-2"
                        dangerouslySetInnerHTML={{
                          __html: poundsSVG,
                        }}
                      />
                    )}
                    <span className="font-semibold">
                      {currency} bank details
                    </span>
                    {useAsDefault && (
                      <span className="ml-2 rounded-full  px-2 py-1 text-xs font-semibold text-htext-main">
                        (DEFAULT)
                      </span>
                    )}
                  </div>
                  {id === expandedCard ? (
                    <CircleMinus className="text-gray-400" />
                  ) : (
                    <CirclePlus className="text-gray-400" />
                  )}
                </div>
                {expandedCard === id && (
                  <div className="p-4">
                    <div className="accountDetails border-b border-gray-200  flex w-full flex-col justify-center px-2 py-5">
                      <div className="flex w-full items-center justify-between py-2.5">
                        <p className="text-[13.5px] font-normal text-htext-main">
                          Currency
                        </p>

                        <div className="flex items-center">
                          <p className="text-[14.5px] font-semibold text-htext-main">
                            {currency.toUpperCase()}
                          </p>
                          <span
                            className="ml-2"
                            dangerouslySetInnerHTML={{
                              __html: currencyIcons[currency.toLowerCase()],
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex w-full items-center justify-between py-2.5">
                        <p className="text-[13.5px] font-normal text-htext-main">
                          Account name
                        </p>
                        <p className="text-[14.5px] font-semibold text-htext-main">
                          {accountName || `${accountName} `}
                        </p>
                      </div>

                      {currency !== "EUR" && (
                        <div className="flex w-full items-center justify-between py-2.5">
                          <p className="text-[13.5px] font-normal text-htext-main">
                            Account number
                          </p>
                          <p className="text-[14.5px] font-semibold text-htext-main">
                            {accountNumber}
                          </p>
                        </div>
                      )}

                      <div className="flex w-full items-center justify-between py-2.5">
                        <p className="text-[13.5px] font-normal text-htext-main">
                          {iban ? "IBAN" : "Sort Code"}
                        </p>
                        <p className="text-[14.5px] font-semibold text-htext-main">
                          {iban ? iban : sortCode}
                        </p>
                      </div>
                    </div>

                    <div className="mt-4 flex items-center justify-between">
                      <label className="inline-flex cursor-pointer items-center">
                        <input
                          type="checkbox"
                          className="peer sr-only"
                          checked={useAsDefault}
                          onChange={async () => {
                            setLoadingBankId(id);
                            const response = await getSession({});
                            if (response.sessionIdHasExpired) {
                              await triggerOtp({});
                              showModal();
                            } else {
                              setCustomerSetBankDefault({ bankId: id });
                            }
                          }}
                        />
                        <div className="peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700"></div>
                        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                          Set as default settlement account
                        </span>
                      </label>
                      {(loadingBankId === id || isLoadingGettingSession) && (
                        <Loader2 className="h-4 w-4 animate-spin text-blue-500" />
                      )}
                      <button
                        onClick={() => {
                          setShowEditAccPopup(true);
                          setId(id);
                        }}
                        className="text-sm text-blue-500"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )
          )}

        <button
          onClick={() => {
            setShowAccPopup(true);
            setValue("currency", "EUR");
          }}
          className="flex items-center text-blue-500"
        >
          <span className="mr-2">+</span> Add new account
        </button>
      </div>

      {/* POPUP */}
      {(showAccPopup || showEditAccPopup) && (
        <SidePopup
          className="signup-popup"
          popClass="pt-[1.3rem] pb-[1.5rem] w-[38rem]"
        >
          <div className="popup-content-bg flex h-full flex-col items-center justify-between">
            <div className="content-top w-full">
              <div className="content-nav flex w-full items-start justify-between px-[1.5rem]">
                <div>
                  <h3 className="text-[18px] font-semibold text-htext-main">
                    {showEditAccPopup
                      ? "Edit your bank account"
                      : "Add your bank account"}
                  </h3>
                  <p className="mt-1.5 text-[12.5px] font-normal text-htext-subdued">
                    This is your local currency account
                  </p>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => closeShowPopup()}
                >
                  <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: closeBtnSVG }}
                  />
                </div>
              </div>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSetUpBankAccount)}>
                  <fieldset>
                    <div className="form-div ">
                      <div className={`mt-9 h-[76vh] w-full`}>
                        <div className="px-[1.5rem]">
                          <div className="flex items-center ">
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>
                            <p className="text-[13px] font-medium  text-htext-label">
                              Select currency
                            </p>
                          </div>
                          <div className="radio-btns mt-2 flex w-full items-center justify-between">
                            <div className="btn-1 flex h-[2.5rem] w-[31.3%] items-center justify-between rounded-lg border border-hgray-200 bg-hgray-25 py-7 pl-3 pr-4">
                              <p className="flex items-center text-[14.5px] font-semibold text-htext-main">
                                <span
                                  className="w-18 mr-2"
                                  dangerouslySetInnerHTML={{ __html: eurosSVG }}
                                />
                                Euros
                              </p>
                              <RadioButton
                                name="currency"
                                value="EUR"
                                checked={selectedValue === "EUR"}
                                onChange={handleRadioChangeValue}
                                className="accs-radio w-auto"
                              />
                            </div>

                            <div className="btn-2 flex h-[2.5rem] w-[31.3%] items-center justify-between rounded-lg border border-hgray-200 bg-hgray-25 py-7 pl-3 pr-4">
                              <p className="flex items-center text-[14.5px] font-semibold text-htext-main">
                                <span
                                  className="w-18 mr-2"
                                  dangerouslySetInnerHTML={{
                                    __html: poundsSVG,
                                  }}
                                />
                                Pounds
                              </p>
                              <RadioButton
                                name="currency"
                                value="GBP"
                                checked={selectedValue === "GBP"}
                                onChange={handleRadioChangeValue}
                                className="accs-radio curren w-auto"
                              />
                            </div>

                            <div
                              className={`btn-3 flex h-[2.5rem] w-[31.3%] items-center justify-between rounded-lg border border-hgray-200 ${"bg-hgray-100"}  py-7 pl-3 pr-4`}
                            >
                              <p className="flex items-center text-[14.5px] font-semibold text-htext-main">
                                <span
                                  className="w-18 mr-2"
                                  dangerouslySetInnerHTML={{
                                    __html: dollarsSVG,
                                  }}
                                />
                                Dollars
                              </p>
                              <RadioButton
                                name="currency"
                                value="DOLLARS"
                                disabled={true}
                                onChange={handleRadioChangeValue}
                                className={`accs-radio w-auto ${
                                  true && "cursor-not-allowed"
                                }`}
                              />
                            </div>
                          </div>

                          <div className="mt-10 flex w-full items-start rounded-[12px] border border-hbrand-300 bg-hbrand-50 py-3.5 pl-4 pr-16 shadow-s-light">
                            <span
                              className="mr-4"
                              dangerouslySetInnerHTML={{
                                __html: warnToolTipBlueSVG,
                              }}
                            />
                            <p className="text-[13.5px] font-semibold text-htext-label">
                              To successfully add your bank account, your bank
                              account name must match the business name you
                              submitted on Shiga
                            </p>
                          </div>
                        </div>

                        <div className="flex h-full w-full flex-col items-center justify-between">
                          {true && (
                            <div className="mt-5 w-full px-[1.5rem]">
                              <div className="mx-auto flex w-full flex-col pb-6  pt-1">
                                <label
                                  htmlFor="accountName"
                                  className="relative mt-[1.30rem] w-full"
                                >
                                  <div className="mb-1 flex items-center text-[13px] font-medium">
                                    <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                      *
                                    </p>

                                    <p className="text-htext-label">
                                      Account name
                                    </p>
                                  </div>
                                  <Inputs
                                    type="text"
                                    placeholder="Enter here"
                                    register={register("accountName")}
                                    inputClass={true}
                                    error={errors.accountName}
                                  />
                                  {errors.accountName && (
                                    <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                      {errors.accountName.message}!
                                    </p>
                                  )}
                                </label>

                                <label
                                  htmlFor="accountNumber"
                                  className="relative mt-[1.30rem] w-full"
                                >
                                  <div className="mb-1 flex items-center text-[13px] font-medium">
                                    <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                      *
                                    </p>

                                    <p className="text-htext-label">
                                      Account number
                                    </p>
                                  </div>
                                  <Inputs
                                    type="text"
                                    placeholder="Enter here"
                                    register={register("accountNumber")}
                                    inputClass={true}
                                    error={errors.accountNumber}
                                  />
                                  {errors.accountNumber && (
                                    <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                      {errors.accountNumber.message}!
                                    </p>
                                  )}
                                </label>

                                {selectedValue === "EUR" && (
                                  <label
                                    htmlFor="iban"
                                    className="relative mt-[1.30rem] w-full"
                                  >
                                    <div className="mb-1 flex items-center text-[13px] font-medium">
                                      <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                        *
                                      </p>

                                      <p className="text-htext-label">IBAN</p>
                                    </div>
                                    <Inputs
                                      type="text"
                                      placeholder="Enter here"
                                      register={register("iban")}
                                      inputClass={true}
                                      error={errors.iban}
                                    />
                                    {errors.iban && (
                                      <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                        {errors.iban.message}!
                                      </p>
                                    )}
                                  </label>
                                )}
                                {selectedValue === "GBP" && (
                                  <label
                                    htmlFor="sortCode"
                                    className="relative mt-[1.30rem] w-full"
                                  >
                                    <div className="mb-1 flex items-center text-[13px] font-medium">
                                      <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                        *
                                      </p>

                                      <p className="text-htext-label">
                                        Sort code
                                      </p>
                                    </div>
                                    <Inputs
                                      type="text"
                                      placeholder="Enter here"
                                      register={register("sortCode")}
                                      inputClass={true}
                                      error={errors.sortCode}
                                    />
                                    {/* {errors.sortCode && ( */}
                                    <p
                                      className={`font-ibm-plex absolute mt-1.5 text-[11.5px] ${
                                        errors.sortCode
                                          ? "font-medium text-red-600"
                                          : "font-normal text-htext-subdued"
                                      }`}
                                    >
                                      {errors.sortCode
                                        ? errors.sortCode.message + "!"
                                        : " Enter the 6-digit sort code"}
                                    </p>
                                    {/* )} */}
                                  </label>
                                )}
                              </div>
                            </div>
                          )}

                          {selectedValue && (
                            <div className="pop-btns flex h-fit w-full items-center justify-end border-t border-hgray-200 px-[1.5rem] pb-5 pt-7">
                              <button
                                type="button"
                                onClick={() => closeShowPopup()}
                                className="shadow-s-light] mr-[5px] rounded-lg border border-hgray-300 bg-white px-3.5 py-2.5 text-[15px] font-semibold text-hgray-700 hover:opacity-70"
                              >
                                Close
                              </button>
                              <button
                                type="submit"
                                // disabled={!isFormData}
                                //  onClick={() => handleNavigate("/login")}
                                className="ml-[5px] rounded-lg border border-hbrand-600 bg-hbrand-600 px-12 py-2.5 text-[15px] font-semibold text-white shadow-s-light hover:opacity-80"
                              >
                                {isLoadingCreateDestinationBankAcct ||
                                isUpdateUpBank ||
                                isLoadingGettingSession ? (
                                  <span className="loading loading-spinner loading-sm"></span>
                                ) : (
                                  "Save account details"
                                )}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </FormProvider>
            </div>
          </div>
        </SidePopup>
      )}
    </div>
  );
}
