import AccountSettings from "../../components/Dashboard/account-settings";

function AccountSettingsPage() {
    return (
            <div>
                <AccountSettings />
            </div>
    )
}

export default AccountSettingsPage;