import OwnershipInfoForm from "../../../../components/Dashboard/get-started/onboarding-checklist/OwnershipInfoForm";

function DashOnboardingOwnershipInfo() {
    return (
            <div>
                <OwnershipInfoForm />
            </div>
    )
}

export default DashOnboardingOwnershipInfo;