import { parsePhoneNumberFromString } from "libphonenumber-js";

export function getCountryCodeAndNumber(phone: string) {
  // Parse the phone number string
  const phoneNumber = parsePhoneNumberFromString(phone);

  if (phoneNumber && phoneNumber.isValid()) {
    // Extract the country code and national number
    const countryCode = phoneNumber.countryCallingCode; // e.g., '234'
    const nationalNumber = phoneNumber.nationalNumber; // e.g., '8114890462'

    return { countryCode: `+${countryCode}`, nationalNumber };
  } else {
    return { countryCode: "", nationalNumber: phone };
  }
}
