import { FC } from "react";
import { useNavigate } from "react-router-dom";
import WalletsSetup from "./index";

const HowItWorks: FC = () => {
  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    navigate(route); // Replace '/new-route' with your desired route
  };

  return (
    <WalletsSetup currentStep={1} complete={false}>
      <div className="howItWorks mt-12 w-full h-full overflow-y-scroll  hide-scrollbar ">
        <div className="works-content flex flex-col h-full items-center justify-between pt-3 ">
          <div className="works-top w-[795px] pb-5">
            <div className="works-top pb-10">

            <div className="top-banner bg-hgradient-color-89 shadow-s-semi-light flex items-center justify-between rounded-2xl p-5">
              <div className="w-[49.5%]">
                <h3 className="text-[22px] font-semibold leading-[31px] text-htext-main">
                  Swap between your local fiat currencies and stablecoins{" "}
                </h3>
                <p className="mt-[9px] text-[14px] font-normal text-htext-label">
                  Easily exchange USD, EUR and GBP for stablecoins (USDT, USDC)
                  and vice versa. Transfer stablecoins to receive Euros or
                  Pounds in your bank account, or send USD, EUR or GBP to
                  receive stablecoins.
                </p>
              </div>

              <div className="w-[49.5%]">
                <img
                  className="m-auto"
                  src="/assets/works-frame.svg"
                  alt="shiga currencies illustration"
                />
              </div>
            </div>

            <div className="top-steps mt-10 flex w-full items-center justify-between">
              <div className="step step1 relative shadow-s-semi-light w-[47.5%] rounded-2xl bg-hgray-50 p-5">
                <div className="step-top relative z-10">
                  <span className="px-2.5 py-[0.16rem] rounded-2xl border border-hgray-200 bg-hgray-50 text-[12.5px] text-hgray-700 font-medium text-center">
                  STEP 1
                  </span>
                  <h3 className="mt-2.5 text-htext-main text-[22px] font-semibold" >Add your bank account</h3>
                  <p className="mt-1.5 text-htext-subdued text-sm font-normal w-[320px]">Add your bank account information for receiving USD/EUR/GBP when you want to cash out stablecoins. You will receive USD/GBP/EUR from your named virtual accounts.</p>
                </div>
                

                <div className="step-bottom mt-8 relative flex items-center justify-between z-10">
                  <img src="/assets/works-currencies.svg" alt="Currencies logo" />
                  <img src="/assets/payments-logo.svg" alt="Payments logo" />
                </div>
              </div>

              <div className="step step2 relative shadow-s-semi-light w-[47.5%] rounded-2xl bg-hgray-50 p-5">
                <div className="step-top relative z-10">
                  <span className="px-2.5 py-[0.16rem] rounded-2xl border border-hgray-200 bg-hgray-50 text-[12.5px] text-hgray-700 font-medium text-center">
                  STEP 2
                  </span>
                  <h3 className="mt-2.5 text-htext-main text-[22px] font-semibold" >Add your stablecoin wallets</h3>
                  <p className="mt-1.5 text-htext-subdued text-sm font-normal w-[320px]">To receive stablecoins when you send USD/GBP/EUR to your named virtual account. You will receive stablecoins once you send currency to your named virtual account.</p>
                </div>
                

                <div className="step-bottom mt-8 relative flex items-center justify-between z-10">
                  <img src="/assets/works-coins.svg" alt="Coins logo" />
                </div>
              </div>
            </div>
            </div>
          </div>

          <div className="form-btns flex h-fit w-full items-center justify-between border-t border-t-hgray-200 px-14 pb-1 pt-6">
            <button
              className="w-[150px] rounded-lg border  border-solid border-hgray-300 bg-white px-[10px] py-[13px] text-[15px] font-semibold text-hgray-700 shadow-s-light hover:bg-hgray-50"
              type="button"
              onClick={() => navigate('/dashboard/get-started')}
            >
              Go Back
            </button>

            <button
              className="w-[250px] rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[13px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
              type="button"
              onClick={() => handleNavigate("/get-started/onboarding-checklist/wallets-setup/setup-accounts")}
            >
              Next: Setup accounts
            </button>
        </div>
        </div>
      </div>
    </WalletsSetup>
  );
};

export default HowItWorks;
