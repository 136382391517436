import { Controller, useFormContext } from "react-hook-form";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

interface PhoneInputsProps {
  name: string;
  inputClass?: boolean;
  placeholder: string;
  error?: any;
}

const PhoneInputComponent = ({
  name,
  error,
  inputClass,
  placeholder,
}: PhoneInputsProps) => {
  const {  control } = useFormContext();


  return (
    <div className="relative">
      <div
        className={`mt-[4px] flex items-center rounded-[8px] border border-solid bg-white pr-[16px] shadow-s-light focus:outline-double focus:outline-2 ${
          error
            ? "border-red-500 shadow-s-light-red ring-r-width ring-r-color-red focus:outline-red-400"
            : "border-gray-300"
        }`}
      >
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <PhoneInput
            inputStyle={{border:"none"}}
              className={`${
                inputClass ? "h-[39px]" : "h-[36px]"
              } disabled:shadow-none ml-[0.05rem] w-96 rounded-[8px] border-0 border-none py-[3px] text-sm font-normal focus:outline-none disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500`}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              defaultCountry="TH"
            />
          )}
        />
      </div>
      {error && <p className="text-red-500">{error.message}</p>}
    </div>
  );
};

export default PhoneInputComponent;
