import PortalOutFiat from "../../../../components/Dashboard/portal-accounts/portal-out/portal-out-to-fiat";

function PortalOutAccounts() {
    return (
            <div>
                <PortalOutFiat />
            </div>
    )
}

export default PortalOutAccounts;