export default function Roles() {
  return (
    <div className="shadow-sm mx-auto rounded-lg border border-gray-200 bg-white py-6">
      <h1 className="mb-4 px-6 text-xl font-semibold">Roles</h1>

      <div className="border-t  border-gray-200 space-y-2">
        <div className="border-b bg-hgray-200 border-gray-200 py-3 px-6">
          <h2 className="text-sm font-semibold">Types</h2>
        </div>
        <div className="border-b border-gray-200 px-6 pb-6">
          <h2 className="mb-1 text-sm font-semibold">Viewer</h2>
          <p className="text-sm text-gray-500">
            For people who can only view transactions and cannot make any edit
          </p>
        </div>
        <div className="border-b border-gray-200 px-6 pb-6">
          <h2 className="mb-1 text-sm font-semibold">Admin</h2>
          <p className="text-sm text-gray-500">
            For people who need to edit payroll account, view all transactions
            and edit other team members (viewers)
          </p>
        </div>
        <div className="px-6">
          <h2 className="mb-1 text-sm font-semibold">Owner</h2>
          <p className="text-sm text-gray-500">
            For business owners who need access to view, edit and manage
            everything.
          </p>
        </div>
      </div>
    </div>
  );
}
