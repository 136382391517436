import HowItWorks from "../../../../../components/Dashboard/get-started/onboarding-checklist/walletsSetup/HowItWorks";


  
function WalletsSetupHowItWorks() {
    return (
            <div>
                <HowItWorks />
            </div>
    )
}

export default WalletsSetupHowItWorks;