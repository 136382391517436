import { FC, useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { EmailSVG, HidePassSVG, ShowPassSVG } from "../Common/svg";
import Inputs from "../Common/inputs";
import { LoginSchema, loginSchema } from "../../utils/validationSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { trpc } from '../../lib/trpc';

const Login: FC = () => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");

  const handleNavigate = (route: string) => {
    navigate(route); // Replace '/new-route' with your desired route
  };

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginSchema>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const {mutate: logIn, isLoading } = trpc.user.login.useMutation({
    onSuccess: (res) => {
        toast.success("Logging in....") 
         handleNavigate(`/login-verification/${userEmail}`) 
         console.log(res, "res");
     reset();
    },
    onError: (err) => {
      console.log(err, "error");
      toast.error(err.message)
    }
 })

 const onSubmit =  useCallback(
   async (data: LoginSchema) => {
    setUserEmail(data.email);
    logIn({ email: data.email, password: data.password})
 }, [logIn]);

  return (
    <div className="login bg-login-bg relative h-screen  w-screen bg-cover">
      <div className="bg-hgradient-gray-800/[0.8] absolute inset-0"></div>
      <div className="login-content relative flex h-full w-full flex-col items-start overflow-hidden">
        <section className="content-top w-full pb-10 pl-14 pt-14">
          <div className="w-[120px]">
            <img
              className="h-full w-full"
              src="/cus-logo.svg"
              alt="Shiga Logo"
            />
          </div>
        </section>

        <section className="content-right m-auto mt-[80px] self-center justify-self-center bg-white rounded-lg px-[30px] py-[25px]">
          <div className="right-top mb-[12px] w-[330px]">
            <div className="font-ibm-plex w-full font-normal text-htext-main antialiased">
              <h1 className=" text-center text-[28px] font-semibold tracking-[-0.72px]">
                Log in
              </h1>
            </div>
          </div>
          <form className="w-[330px]" onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
              <div className="mt-[18px]">
                <label
                  htmlFor="email"
                  className="font-ibm-plex text-htext-label text-[14px] font-bold antialiased"
                >
                  Email
                  <Inputs
                    type="email"
                    placeholder="johndoe@example.com"
                    icon={EmailSVG}
                    register={register("email")}
                    error={errors.email}
                  />
                  {errors.email && (
                      <p className="font-ibm-plex mt-1.5 text-[13px] font-medium text-red-600">
                        {errors.email.message}!
                      </p>
                    )}
                </label>
              </div>

              <div className="mt-[22px]">
                <label
                  htmlFor="password"
                  className="font-ibm-plex text-htext-label text-[14px] font-bold antialiased"
                >
                  Password
                  <Inputs
                    type="password"
                    placeholder="Enter password"
                    showIcon={ShowPassSVG}
                    hideIcon={HidePassSVG}
                    register={register("password")}
                    error={errors.password}
                  />
                    {errors.password && (
                      <p className="font-ibm-plex mt-1.5 text-[13px] font-medium text-red-600">
                        {errors.password.message}!
                      </p>
                    )}
                </label>
              </div>
            </fieldset>
          <div className="font-ibm-plex mt-[5px] w-full font-normal antialiased">
            <Link
              to="/reset-password-link"
              className="float-right cursor-pointer text-[13px] font-semibold text-hbrand-700 mt-2"
            >
              Forgot Password?
            </Link>
            <button
              className="bg-brand-600 hover:bg-hbrand-700 border-brand-600 mb-[16px] mt-[50px] w-full rounded-lg border border-solid px-[10px] py-[11px] text-[15px] font-bold text-white"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? <span className="loading loading-spinner loading-sm"></span> : "Log in"}
            </button>
            <p
              className="text-[12.5px] text-htext-main font-normal text-center cursor-none my-2.5"
            >
              Need to create an account? <b className="text-hbrand-700 cursor-pointer"><Link to="/signup">Sign up</Link></b>
            </p>
          </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default Login;
