import { useState } from "react";
import { Section } from ".";
import FAQItem from "./faqItem";

const FAQSection = ({ section,data }: { section: Section, data: any[] }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const sortedFaq =
    section == "all" ? data : data?.filter((e) => e.section == section);

  return (
    <div className="col-span-2">
      <h1 className="font-ibm-plex mb-5 text-2xl font-bold text-hgray-900">
        Frequently asked questions
      </h1>
      {sortedFaq?.map(({ question, answer }, index) => (
        <FAQItem
          key={index}
          question={question}
          answer={answer}
          isOpen={openIndex === index}
          onClick={() => setOpenIndex(openIndex === index ? null : index)}
        />
      ))}
    </div>
  );
};

export default FAQSection;
