import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DropDownWithIcon from "../../../../../components/Common/dropdown/DropDownWithIcon";
import MainLoader from "../../../../../components/Common/loader/mainLoader";
import { chainOptions, tokenOptions } from "../../../../../constant/options";
import { useModal } from "../../../../../context/modalProvider";
import { trpc } from "../../../../../lib/trpc";
import RadioButton from "../../../../Common/buttons/radioBtn";
import Inputs from "../../../../Common/inputs";
import SidePopup from "../../../../Common/popup/sidepopup";
import {
  closeBtnSVG,
  dollarsSVG,
  editIconSVG,
  eurosSVG,
  poundsSVG,
  warnToolTipBlueSVG,
} from "../../../../Common/svg";
import {
  AddDestinationBankSchema,
  SetupWalletSchema,
  addDestinationBankSchema,
  setupWalletSchema,
} from "../schema";
import WalletsSetup from "./index";
import { Chain } from "./types";

type CurrencyTypes = "EUR" | "GBP";

const ConfirmSetupDetails: FC = () => {
  const navigate = useNavigate();
  const utils = trpc.useUtils();
  const { showModal } = useModal();

  const [showEditAccPopup, setShowEditAccPopup] = useState(false);
  const [showEditWalletPopup, setShowEditWalletPopup] = useState(false);
  const [isDisabled] = useState(true);
  const [selectedAccValue, setSelectedAccValue] = useState<CurrencyTypes>();
  const [id, setId] = useState<number | null>(null);

  // Accounts
  const { data: bankAccounts, isLoading: isLoadingAccountsList } =
    trpc.user.getBankAccount.useQuery({});

  const { mutateAsync: getSession, isLoading: isLoadingGettingSession } =
    trpc.user.isSessionIdExpired.useMutation({
      onError: (e) => {
        toast.error(e.message);
      },
    });

  const { mutateAsync: triggerOtp } = trpc.user.triggerOtp.useMutation({
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const { mutate: UpdateWallet, isLoading: isUpdatingWallet } =
    trpc.user.UpdateWallet.useMutation({
      onSuccess: () => {
        reset();
        utils.user.getWallet.refetch();
        closeShowPopup();
      },
      onError: (e) => {
        console.log(e);
        toast.error(e.message);
      },
    });

  const accMethods = useForm<AddDestinationBankSchema>({
    resolver: zodResolver(addDestinationBankSchema),
    defaultValues: {
      accountNumber: "",
      iban: "",
      accountName: "",
      sortCode: "",
      currency: "EUR",
      mainBeneficiary: true,
    },
  });

  // Wallets

  const { data: wallets, isLoading: isLoadingWalletsList } =
    trpc.user.getWallet.useQuery({});

  const { mutate: updateUpBank, isLoading: isUpdateUpBank } =
    trpc.user.updateUpBank.useMutation({
      onSuccess: () => {
        reset();
        utils.user.getBankAccount.refetch();
        closeShowPopup();
      },
      onError: (e) => {
        console.log(e);
      },
    });

  const onSetUpWallet = async (data: SetupWalletSchema) => {
    try {
      const response = await getSession({});
      if (response.sessionIdHasExpired) {
        await triggerOtp({});
        showModal();
      } else {
        UpdateWallet({ ...data, currency: "GBP" });

      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSetUpBankAccount = async (data: AddDestinationBankSchema) => {
    try {
      const response = await getSession({});
      if (response.sessionIdHasExpired) {
        await triggerOtp({});
        showModal();
      } else {
        if (id) {
          updateUpBank({ input: { ...data, id } });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { mutate: confirmWalletSetUp, isLoading } =
    trpc.user.confirmWalletSetUp.useMutation({
      onSuccess: () => {
        navigate("/dashboard/get-started"); // Replace '/new-route' with your desired route
      },
      onError: (e) => {
        console.log(e);
      },
    });

  const walletMethods = useForm<SetupWalletSchema>({
    resolver: zodResolver(setupWalletSchema),
    defaultValues: {
      chain: undefined,
      token: "",
      walletAddress: "",
      confirmWalletAddress: "",
    },
  });

  const handleNavigate = () => {
    confirmWalletSetUp({}); // Replace '/new-route' with your desired route
  };

  const {
    register,
    setValue,
    reset,
    formState: { errors },
  } = accMethods;

  const {
    control,
    register: register2,
    setValue: setValue2,
    reset: reset2,
    formState: { errors: errors2 },
  } = walletMethods;

  useEffect(() => {
    const walletData = wallets?.[0];
    // Populate form fields with fetched data
    if (showEditWalletPopup && walletData) {
      setValue2("chain", walletData?.chain as Chain);
      setValue2("token", walletData.token || "");
      setValue2("walletAddress", walletData.walletAddress || "");
      setValue2("confirmWalletAddress", walletData.walletAddress || "");
    }

    const editableBankAccounts = bankAccounts?.find((item) => item.id == id);
    if (showEditAccPopup && editableBankAccounts) {
      setValue("iban", editableBankAccounts?.iban as string);
      setValue("accountName", editableBankAccounts?.accountName as string);
      setValue("currency", editableBankAccounts?.currency as "GBP" | "EUR");
      setValue("accountNumber", editableBankAccounts?.accountNumber as string);
      setValue("sortCode", editableBankAccounts.sortCode as string);
      setSelectedAccValue(editableBankAccounts?.currency as "GBP" | "EUR");
    }
  }, [showEditWalletPopup, wallets, bankAccounts, setValue, showEditAccPopup]);

  function closeShowPopup() {
    if (showEditAccPopup === true) {
      setShowEditAccPopup(false);
    } else {
      setShowEditWalletPopup(false);
    }
    reset();
    reset2();
  }

  const handleRadioChangeValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedAccValue(event.target.value as CurrencyTypes);
    setValue("currency", event.target.value as CurrencyTypes);
  };

  const selectedToken = useWatch({ control, name: "token" });
  const [filteredChainOptions, setFilteredChainOptions] = useState(chainOptions);


  // Filter/sort chain options based on selected token
  useEffect(() => {
    if (selectedToken === "usdc") {
      setFilteredChainOptions(chainOptions.filter(option => !['tron','celo'].includes(option.value) )); // Example
    } else if (selectedToken === "usdt") {
      setFilteredChainOptions(chainOptions.filter(option => !['base','celo'].includes(option.value) )); // Example
    }
  }, [selectedToken]);


  if (isLoadingAccountsList || isLoadingWalletsList) {
    return <MainLoader />;
  }

  return (
    <WalletsSetup currentStep={4} complete={true}>
      <div className="setupAccounts hide-scrollbar mt-12 h-full w-full overflow-y-scroll">
        <div className="accounts-top flex h-full flex-col items-center justify-between pt-3">
          <div className="w-[795px]">
            <div className="top-banner flex w-full items-center justify-between rounded-t-2xl bg-hgray-50 p-6 shadow-s-light">
              <div className="w-[80.5%]">
                <h3 className="text-[22px] font-semibold text-htext-main">
                  Confirm details
                </h3>
                <p className="mt-2 text-sm font-normal text-htext-subdued">
                  Look through the information you provided and confirm details.
                  You can always edit now or later in your account’s page
                </p>
              </div>
            </div>
          </div>

          <FormProvider {...accMethods}>
            <form className="form-content h-full w-full rounded-b-[16px] bg-white">
              <fieldset className="h-full">
                <div className="flex h-full w-full flex-col items-center justify-between">
                  <div className="mx-auto w-[795px] rounded-b-2xl bg-hgray-50 px-6 pb-6 pt-1">
                    <div className="border-t border-t-hgray-200">
                      <div className="mt-6 flex w-full items-center justify-between text-[13px] font-semibold">
                        <p className="text-htext-label">ACCOUNTS ADDED</p>
                      </div>

                      {bankAccounts?.map(
                        ({
                          currency,
                          id,
                          accountNumber,
                          accountName,
                          sortCode,
                          iban,
                        }) => (
                          <>
                            <div
                              key={id}
                              className="mt-6 flex w-full flex-col items-end text-[13px] font-semibold"
                            >
                              <button
                                type="button"
                                className="flex items-center text-hbrand-700 hover:text-hbrand-600"
                                onClick={() => {
                                  setShowEditAccPopup(true);
                                  setId(id);
                                  setSelectedAccValue(
                                    currency as CurrencyTypes
                                  );
                                }}
                              >
                                <span
                                  className="mr-2 w-4"
                                  dangerouslySetInnerHTML={{
                                    __html: editIconSVG,
                                  }}
                                />
                                Edit
                              </button>
                            </div>
                            <div className="mt-3.5 flex w-full flex-wrap items-center gap-y-6 rounded-lg bg-hgray-100 p-[17px]">
                              <div className="w-1/3 text-[13px] text-htext-main">
                                <p className="mb-1 font-normal">Currency</p>
                                <h5 className="flex items-center font-semibold">
                                  <span
                                    className="mr-6 w-4"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        currency == "EUR"
                                          ? eurosSVG
                                          : poundsSVG,
                                    }}
                                  />
                                  {currency}
                                </h5>
                              </div>

                              <div className="w-1/3 text-[13px] text-htext-main">
                                <p className="mb-1 font-normal">Account name</p>
                                <h5 className="flex items-center font-semibold">
                                  {accountName}{" "}
                                </h5>
                              </div>

                              <div className="w-1/3 text-[13px] text-htext-main">
                                <p className="mb-1 font-normal">
                                  Account number
                                </p>
                                <h5 className="flex items-center font-semibold">
                                  {accountNumber}
                                </h5>
                              </div>

                              {currency == "GBP" && (
                                <div className="w-1/3 text-[13px] text-htext-main">
                                  <p className="mb-1 font-normal">Sort code</p>
                                  <h5 className="flex items-center font-semibold">
                                    {sortCode}
                                  </h5>
                                </div>
                              )}
                              {currency == "EUR" && (
                                <div className="w-1/3 text-[13px] text-htext-main">
                                  <p className="mb-1 font-normal">IBAN</p>
                                  <h5 className="flex items-center font-semibold">
                                    {iban}
                                  </h5>
                                </div>
                              )}
                              {/* 
                            <div className="w-1/3 text-[13px] text-htext-main">
                              <p className="mb-1 font-normal">Bank</p>
                              <h5 className="flex items-center font-semibold">
                                N/a
                              </h5>
                            </div> */}
                            </div>
                          </>
                        )
                      )}
                    </div>

                    <div className="mt-9">
                      <div className="mt-6 flex w-full items-center justify-between text-[13px] font-semibold">
                        <p className="text-htext-label">WALLETS ADDED</p>
                        <button
                          type="button"
                          className="flex items-center text-hbrand-700 hover:text-hbrand-600"
                          onClick={() => setShowEditWalletPopup(true)}
                        >
                          <span
                            className="mr-2 w-4"
                            dangerouslySetInnerHTML={{ __html: editIconSVG }}
                          />
                          Edit
                        </button>
                      </div>
                      {wallets?.map(({ chain, id, token, walletAddress }) => (
                        <div
                          key={id}
                          className="mt-3.5 flex w-full flex-wrap items-center gap-y-7 rounded-lg bg-hgray-100 p-[17px]"
                        >
                          <div className="w-1/2 text-[13px] text-htext-main">
                            <p className="mb-1.5 font-normal">Currency</p>
                            <h5 className="flex items-center font-semibold">
                              {
                                tokenOptions?.find(
                                  ({ value }) => value == token
                                )?.icon
                              }

                              {token.toUpperCase()}
                            </h5>
                          </div>

                          <div className="w-1/2 text-[13px] text-htext-main">
                            <p className="mb-1.5 font-normal">Smartchain</p>
                            <h5 className="flex items-center font-semibold">
                              {
                                chainOptions?.find(
                                  ({ value }) => value == chain
                                )?.icon
                              }
                              {chain.charAt(0).toUpperCase() + chain.slice(1)}
                            </h5>
                          </div>

                          <div className="w-1/2 text-[13px] text-htext-main">
                            <p className="mb-1.5 font-normal">Wallet address</p>
                            <h5 className="flex items-center font-semibold">
                              {walletAddress}
                            </h5>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="form-btns flex h-fit w-full items-center justify-between border-t border-t-hgray-200 px-14 pb-1 pt-6">
                    <button
                      className="w-[150px] rounded-lg border  border-solid border-hgray-300 bg-white px-[10px] py-[13px] text-[15px] font-semibold text-hgray-700 shadow-s-light hover:bg-hgray-50"
                      type="button"
                      onClick={() =>
                        navigate(
                          "/get-started/onboarding-checklist/wallets-setup/setup-wallets"
                        )
                      }
                    >
                      Go Back
                    </button>

                    <button
                      className="w-[250px] rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[13px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
                      type="button"
                      onClick={handleNavigate}
                    >
                      {isLoading || isLoadingGettingSession ? (
                        <span className="loading loading-spinner loading-sm"></span>
                      ) : (
                        "Save wallet details"
                      )}{" "}
                    </button>
                  </div>
                </div>
              </fieldset>
            </form>
          </FormProvider>
        </div>
      </div>
      {showEditAccPopup && (
        <SidePopup
          className="signup-popup"
          popClass="pt-[1.3rem] pb-[1.5rem] w-[38rem]"
        >
          <div className="popup-content-bg flex h-full flex-col items-center justify-between">
            <div className="content-top w-full">
              <div className="content-nav flex w-full items-start justify-between px-[1.5rem]">
                <div>
                  <h3 className="text-[18px] font-semibold text-htext-main">
                    {showEditAccPopup
                      ? "Edit your bank account"
                      : "Add your bank account"}
                  </h3>
                  <p className="mt-1.5 text-[12.5px] font-normal text-htext-subdued">
                    This is your local currency account
                  </p>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => closeShowPopup()}
                >
                  <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: closeBtnSVG }}
                  />
                </div>
              </div>
              <FormProvider {...accMethods}>
                <form onSubmit={accMethods.handleSubmit(onSetUpBankAccount)}>
                  <fieldset>
                    <div className="form-div ">
                      <div className={`mt-9 h-[76vh] w-full`}>
                        <div className="px-[1.5rem]">
                          <div className="flex items-center ">
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>
                            <p className="text-[13px] font-medium  text-htext-label">
                              Select currency
                            </p>
                          </div>
                          <div className="radio-btns mt-2 flex w-full items-center justify-between">
                            <div className="btn-1 flex h-[2.5rem] w-[31.3%] items-center justify-between rounded-lg border border-hgray-200 bg-hgray-25 py-7 pl-3 pr-4">
                              <p className="flex items-center text-[14.5px] font-semibold text-htext-main">
                                <span
                                  className="w-18 mr-2"
                                  dangerouslySetInnerHTML={{ __html: eurosSVG }}
                                />
                                Euros
                              </p>
                              <RadioButton
                                name="currency"
                                value="EUR"
                                checked={selectedAccValue === "EUR"}
                                onChange={handleRadioChangeValue}
                                className="accs-radio w-auto"
                              />
                            </div>

                            <div className="btn-2 flex h-[2.5rem] w-[31.3%] items-center justify-between rounded-lg border border-hgray-200 bg-hgray-25 py-7 pl-3 pr-4">
                              <p className="flex items-center text-[14.5px] font-semibold text-htext-main">
                                <span
                                  className="w-18 mr-2"
                                  dangerouslySetInnerHTML={{
                                    __html: poundsSVG,
                                  }}
                                />
                                Pounds
                              </p>
                              <RadioButton
                                name="currency"
                                value="GBP"
                                checked={selectedAccValue === "GBP"}
                                onChange={handleRadioChangeValue}
                                className="accs-radio curren w-auto"
                              />
                            </div>

                            <div
                              className={`btn-3 flex h-[2.5rem] w-[31.3%] items-center justify-between rounded-lg border border-hgray-200 ${
                                isDisabled ? "bg-hgray-100" : "bg-hgray-25"
                              }  py-7 pl-3 pr-4`}
                            >
                              <p className="flex items-center text-[14.5px] font-semibold text-htext-main">
                                <span
                                  className="w-18 mr-2"
                                  dangerouslySetInnerHTML={{
                                    __html: dollarsSVG,
                                  }}
                                />
                                Dollars
                              </p>
                              <RadioButton
                                name="currency"
                                value="DOLLARS"
                                disabled={isDisabled}
                                onChange={handleRadioChangeValue}
                                className={`accs-radio w-auto ${
                                  isDisabled ? "cursor-not-allowed" : ""
                                }`}
                              />
                            </div>
                          </div>

                          <div className="mt-10 flex w-full items-start rounded-[12px] border border-hbrand-300 bg-hbrand-50 py-3.5 pl-4 pr-16 shadow-s-light">
                            <span
                              className="mr-4"
                              dangerouslySetInnerHTML={{
                                __html: warnToolTipBlueSVG,
                              }}
                            />
                            <p className="text-[13.5px] font-semibold text-htext-label">
                              To successfully add your bank account, your bank
                              account name must match the business name you
                              submitted on Shiga
                            </p>
                          </div>
                        </div>

                        <div className="flex h-full w-full flex-col items-center justify-between">
                          {selectedAccValue && (
                            <div className="mt-5 w-full px-[1.5rem]">
                              <div className="mx-auto flex w-full flex-col pb-6  pt-1">
                                {/* <label
                                htmlFor="bankName"
                                className="mt-[1.30rem] w-full"
                              >
                                <div className="mb-1 flex items-center text-[13px] font-medium">
                                  {errors &&
                                    Object.entries(errors).length > 0 && (
                                      <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                        *
                                      </p>
                                    )}
                                  <p className="text-htext-label">Bank name</p>
                                </div>
                                <Inputs
                                  type="text"
                                  placeholder="Enter here"
                                  register={register("bankName")}
                                  inputClass={true}
                                  //    error={errors.password}
                                />
                              </label> */}
                                <label
                                  htmlFor="accountName"
                                  className="relative mt-[1.30rem] w-full"
                                >
                                  <div className="mb-1 flex items-center text-[13px] font-medium">
                                    <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                      *
                                    </p>

                                    <p className="text-htext-label">
                                      Account name
                                    </p>
                                  </div>
                                  <Inputs
                                    type="text"
                                    placeholder="Enter here"
                                    register={register("accountName")}
                                    inputClass={true}
                                    error={errors.accountName}
                                  />
                                  {errors.accountName && (
                                    <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                      {errors.accountName.message}!
                                    </p>
                                  )}
                                </label>

                                <label
                                  htmlFor="accountNumber"
                                  className="relative mt-[1.30rem] w-full"
                                >
                                  <div className="mb-1 flex items-center text-[13px] font-medium">
                                    <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                      *
                                    </p>

                                    <p className="text-htext-label">
                                      Account number
                                    </p>
                                  </div>
                                  <Inputs
                                    type="text"
                                    placeholder="Enter here"
                                    register={register("accountNumber")}
                                    inputClass={true}
                                    error={errors.accountNumber}
                                  />
                                  {errors.accountNumber && (
                                    <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                      {errors.accountNumber.message}!
                                    </p>
                                  )}
                                </label>

                                {selectedAccValue === "EUR" && (
                                  <label
                                    htmlFor="iban"
                                    className="relative mt-[1.30rem] w-full"
                                  >
                                    <div className="mb-1 flex items-center text-[13px] font-medium">
                                      <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                        *
                                      </p>

                                      <p className="text-htext-label">IBAN</p>
                                    </div>
                                    <Inputs
                                      type="text"
                                      placeholder="Enter here"
                                      register={register("iban")}
                                      inputClass={true}
                                      error={errors.iban}
                                    />
                                    {errors.iban && (
                                      <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                        {errors.iban.message}!
                                      </p>
                                    )}
                                  </label>
                                )}

                                <label
                                  htmlFor="sortCode"
                                  className="relative mt-[1.30rem] w-full"
                                >
                                  <div className="mb-1 flex items-center text-[13px] font-medium">
                                    <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                      *
                                    </p>

                                    <p className="text-htext-label">
                                      Sort code
                                    </p>
                                  </div>
                                  <Inputs
                                    type="text"
                                    placeholder="Enter here"
                                    register={register("sortCode")}
                                    inputClass={true}
                                    error={errors.sortCode}
                                  />
                                  {/* {errors.sortCode && ( */}
                                  <p
                                    className={`font-ibm-plex absolute mt-1.5 text-[11.5px] ${
                                      errors.sortCode
                                        ? "font-medium text-red-600"
                                        : "font-normal text-htext-subdued"
                                    }`}
                                  >
                                    {errors.sortCode
                                      ? errors.sortCode.message + "!"
                                      : " Enter the 6-digit sort code"}
                                  </p>
                                  {/* )} */}
                                </label>
                              </div>
                            </div>
                          )}

                          {selectedAccValue && (
                            <div className="pop-btns flex h-fit w-full items-center justify-end border-t border-hgray-200 px-[1.5rem] pb-5 pt-7">
                              <button
                                type="button"
                                onClick={() => closeShowPopup()}
                                className="shadow-s-light] mr-[5px] rounded-lg border border-hgray-300 bg-white px-3.5 py-2.5 text-[15px] font-semibold text-hgray-700 hover:opacity-70"
                              >
                                Close
                              </button>
                              <button
                                type="submit"
                                // disabled={!isFormData}
                                //  onClick={() => handleNavigate("/login")}
                                className="ml-[5px] rounded-lg border border-hbrand-600 bg-hbrand-600 px-12 py-2.5 text-[15px] font-semibold text-white shadow-s-light hover:opacity-80"
                              >
                                {isUpdateUpBank ? (
                                  <span className="loading loading-spinner loading-sm"></span>
                                ) : (
                                  "Save account details"
                                )}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </FormProvider>
            </div>
          </div>
        </SidePopup>
      )}
      {showEditWalletPopup && (
        <SidePopup
          className="signup-popup"
          popClass="pt-[1.3rem] pb-[1.5rem] w-[38rem]"
        >
          <div className="popup-content-bg flex h-full flex-col items-center justify-between">
            <div className="content-top w-full">
              <div className="content-nav flex w-full items-start justify-between px-[1.5rem]">
                <div>
                  <h3 className="text-[18px] font-semibold text-htext-main">
                    Add your stablecoin wallet
                  </h3>
                  <p className="mt-1.5 text-[12.5px] font-normal text-htext-subdued">
                    This is your stablecoin wallet
                  </p>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => closeShowPopup()}
                >
                  <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: closeBtnSVG }}
                  />
                </div>
              </div>

              <FormProvider {...walletMethods}>
                <form
                  className="h-[92vh]"
                  onSubmit={walletMethods.handleSubmit(onSetUpWallet)}
                >
                  <fieldset className="h-full">
                    <div className="form-div flex h-full w-full flex-col items-center justify-between">
                      <div className="mt-8 w-full px-[1.5rem] pb-2">
                        <div
                          className={`flex w-full items-start justify-between ${
                            errors2?.token || errors2.chain
                              ? "mt-[.80rem] h-24"
                              : "mt-[1.30rem]"
                          }`}
                        >
                          <label
                            htmlFor="stablecoin"
                            className="relative h-full w-[48.5%]"
                          >
                            <div className="mb-1.5 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>
                              <p className="text-htext-label">Stablecoin</p>
                            </div>
                            <DropDownWithIcon
                              register={register}
                              name="token"
                              options={tokenOptions}
                              error={errors2.token}
                            />
                            {errors2.token && (
                              <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                {errors2.token.message}!
                              </p>
                            )}
                          </label>

                          <label
                            htmlFor="smartchain"
                            className="relative h-full w-[48.5%]"
                          >
                            <div className="mb-1.5 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>

                              <p className="text-htext-label">Smartchain</p>
                            </div>

                            <DropDownWithIcon
                              register={register}
                              name="chain"
                              options={filteredChainOptions}
                              error={errors2.chain}
                            />
                            {errors2.chain && (
                              <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                {errors2.chain.message}!
                              </p>
                            )}
                          </label>
                        </div>

                        <div
                          className={`mx-auto flex w-full flex-col pb-6  pt-1 ${
                            errors2.walletAddress ||
                            errors2.confirmWalletAddress
                              ? "h-48 justify-start"
                              : ""
                          }`}
                        >
                          <label
                            htmlFor="walletAddress"
                            className={`${
                              errors2.walletAddress
                                ? "mt-[.50rem]"
                                : "mt-[1.30rem]"
                            } w-full"`}
                          >
                            <div className="mb-1 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>

                              <p className="text-htext-label">Wallet address</p>
                            </div>
                            <Inputs
                              type="text"
                              placeholder="Enter here"
                              register={register2("walletAddress")}
                              inputClass={true}
                              error={errors2.walletAddress}
                            />
                            {errors2.walletAddress && (
                              <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                {errors2.walletAddress.message}!
                              </p>
                            )}
                          </label>

                          <label
                            htmlFor="confirmWalletAddress"
                            className={`${
                              errors2.confirmWalletAddress
                                ? "mt-[2.3rem]"
                                : "mt-[1.30rem]"
                            } w-full"`}
                          >
                            <div className="mb-1 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>

                              <p className="text-htext-label">
                                Confirm wallet address
                              </p>
                            </div>
                            <Inputs
                              type="text"
                              placeholder="Enter here"
                              register={register2("confirmWalletAddress")}
                              inputClass={true}
                              error={errors2.confirmWalletAddress}
                            />
                            {errors2.confirmWalletAddress && (
                              <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                {errors2.confirmWalletAddress.message}!
                              </p>
                            )}
                          </label>
                        </div>
                      </div>

                      <div className="pop-btns flex h-fit w-full items-center justify-end border-t border-hgray-200 px-[1.5rem] pb-5 pt-7">
                        <button
                          type="button"
                          onClick={() => closeShowPopup()}
                          className="shadow-s-light] mr-[5px] rounded-lg border border-hgray-300 bg-white px-3.5 py-2.5 text-[15px] font-semibold text-hgray-700 hover:opacity-70"
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          // disabled={!isFormData}
                          //  onClick={() => handleNavigate("/login")}
                          className="ml-[5px] rounded-lg border border-hbrand-600 bg-hbrand-600 px-12 py-2.5 text-[15px] font-semibold text-white shadow-s-light hover:opacity-80"
                        >
                          {isUpdatingWallet || isLoadingGettingSession ? (
                            <span className="loading loading-spinner loading-sm"></span>
                          ) : (
                            "Save wallet details"
                          )}
                        </button>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </FormProvider>
            </div>
          </div>
        </SidePopup>
      )}
    </WalletsSetup>
  );
};

export default ConfirmSetupDetails;
