import CompleteProfileForm from "../../../../components/Dashboard/get-started/onboarding-checklist/CompleteProfileForm";

function DashOnboardingCompleteProfile() {
    return (
            <div>
                <CompleteProfileForm />
            </div>
    )
}

export default DashOnboardingCompleteProfile;