import React, { createContext, ReactNode, useContext, useState } from "react";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import SidePopup from "../components/Common/popup/sidepopup";
import { trpc } from "../lib/trpc";

// Modal context for global visibility management
const ModalContext = createContext<{
  showModal: () => void;
  hideModal: () => void;
  isVisible: boolean;
  sendKycLink: boolean
  setKyc: (value:boolean) => void;
}>({
  showModal: () => {},
  hideModal: () => {},
  isVisible: false,
  sendKycLink: false,
  setKyc: () => {}
});

export const useModal = () => useContext(ModalContext);

// Modal Provider component to wrap your application
export const ModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [sendKycLink, setKycLink] = useState(false);

  const setKyc = (value: boolean) => setKycLink(value);
  const showModal = () => setIsVisible(true);
  const hideModal = () => setIsVisible(false);
  console.log({ isVisible });

  return (
    <ModalContext.Provider value={{ showModal, hideModal, isVisible, sendKycLink,setKyc }}>
      {children}
    </ModalContext.Provider>
  );
};

export const ModalProviders = () => {
  const navigate = useNavigate();
  const { data } = trpc.user.getAccountType.useQuery({});
  const { mutate: OtpForSessionAndTriggerKycLink, isLoading: IsSettingSessionAndTriggerKycLink } =
    trpc.user.OtpForSessionAndTriggerKycLink.useMutation({
      onSuccess: () => {
        hideModal(),
        navigate(`/get-started/onboarding-checklist/verify-identity`);
      },
    });

  const { hideModal, isVisible , sendKycLink} = useModal();

  const [otp, setOtp] = useState("");
  const [isOtpFocused] = useState(false);
  const [formErrors, setFormErrors] = useState("");

  const { mutate: onSetOtp, isLoading: isSettingOtp } =
    trpc.user.exchangeOtpForSessionId.useMutation({
      onSuccess: () => hideModal(),
      onError: (error: any) => {
        setFormErrors(error.message);
      },
    });

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (sendKycLink) {
      OtpForSessionAndTriggerKycLink({ otp });
    } else {
      onSetOtp({ otp });
    }
  };

  return (
    <>
      {isVisible && (
        <SidePopup
          className="signup-popup z-[999]"
          popClass="pt-[1.3rem] pb-[1.5rem] w-[38rem]"
        >
          <div className="popup-content-bg flex h-full flex-col items-center justify-between">
            <div className="content-top w-full">
              <div className="content-nav flex w-full items-start justify-between px-[1.5rem]">
                <section className="content-right mt-[8px] justify-center self-center justify-self-center rounded-lg bg-white px-[30px] pb-[25px] pt-[5px]">
                  <div className="right-top mt-[35px] w-[560px]">
                    <div className="font-ibm-plex w-full font-normal text-htext-main antialiased">
                      <h1 className="text-left text-[29px] font-semibold tracking-[-0.72px]">
                        Enter your verification code
                      </h1>
                      <p className="font-ibm-plex mt-2 text-[13px] text-htext-subdued">
                        To log in, kindly enter the code sent to your email
                        address
                      </p>
                    </div>
                  </div>
                  <form className="h-[90vh]" onSubmit={onSubmit}>
                    <fieldset className="mt-[30px] h-[77%] w-[560px] rounded-[14px] bg-hgray-50 px-4 py-8">
                      <div>
                        <p className="text-center text-[15px] font-normal text-htext-subdued">
                          Enter the code sent to{" "}
                          <span className="dotted-noti ml-1 rounded-lg border border-solid border-hbrand-200 bg-hbrand-50 py-1 pl-5 pr-2.5 text-[13px] font-medium text-hbrand-700">
                            {data?.email}
                          </span>
                        </p>
                      </div>
                      <div
                        className={`${
                          formErrors ? "otp-input-error" : "otp-input-container"
                        } mt-6 flex items-center justify-center`}
                      >
                        <OTPInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={6}
                          inputType="tel"
                          placeholder="------"
                          inputStyle={{
                            width: "3.5rem",
                            color: formErrors ? "#B42318" : "#175CD3",
                            height: "3.5rem",
                            margin: "0 0.5rem",
                            fontSize: "1.8rem",
                            borderRadius: 8,
                            border:
                              otp && !formErrors
                                ? "1px solid #175CD3"
                                : formErrors
                                  ? "2px solid #B42318"
                                  : "1px solid #D0D5DD",
                            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                            ...(isOtpFocused && {
                              borderColor: formErrors ? "#B42318" : "#175CD3",
                              borderWidth: "2px",
                              boxShadow: formErrors
                                ? "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(180, 35, 24, 0.24)"
                                : "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(21, 112, 239, 0.24)",
                            }),
                          }}
                          shouldAutoFocus
                          renderInput={(props) => <input {...props} />}
                        />
                      </div>
                      {formErrors && (
                        <p className="font-ibm-plex mt-4 text-center text-[14px] font-medium text-red-600">
                          {!otp && formErrors
                            ? "The 6-digit code sent to your email is required"
                            : formErrors === "CODE_MISMATCH"
                              ? "Code is invalid or has expired"
                              : ""}
                        </p>
                      )}
                    </fieldset>
                    <div className="pop-btns flex h-fit w-full items-center justify-end border-t border-hgray-200 px-[1.5rem] pb-5 pt-7">
                      <button
                        type="button"
                        onClick={hideModal}
                        className="mr-[5px] rounded-lg border border-hgray-300 bg-white px-3.5 py-2.5 text-[15px] font-semibold text-hgray-700 shadow-s-light hover:opacity-70"
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        className="ml-[5px] rounded-lg border border-hbrand-600 bg-hbrand-600 px-12 py-2.5 text-[15px] font-semibold text-white shadow-s-light hover:opacity-80"
                      >
                        {isSettingOtp||IsSettingSessionAndTriggerKycLink ? (
                          <span className="loading loading-spinner loading-sm"></span>
                        ) : (
                          "Authorize"
                        )}
                      </button>
                    </div>
                  </form>
                </section>
              </div>
            </div>
          </div>
        </SidePopup>
      )}
    </>
  );
};
