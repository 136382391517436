import VerifyIdentity from "../../../../components/Dashboard/get-started/onboarding-checklist/VerifyIdentity";

function DashOnboardingVerifyIdentity() {
    return (
            <div>
                <VerifyIdentity />
            </div>
    )
}

export default DashOnboardingVerifyIdentity;