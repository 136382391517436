import DashTransaction from "../../components/Dashboard/transaction";
import { trpc } from "../../lib/trpc";


export type AccountypeUnion = "INDIVIDUAL" | "CORPORATE";
export type UserAccountStatus = {
  id: number;
  userId: number;
  corporateId: number | null;
  email: string;
  acountType: AccountypeUnion;
  isAccountActivated: boolean;
  kycStatusStep: string;
  businessFormStep: string | null;
  shigaKycStatus: string;
  personalProfileStep: string;
  setupWalletStep: string;
  uboInfoStep: string;
  businessInformationStep: string;
  verifyIdentityStep: string;
};

function Transactions() {
    const { data: userProfile } = trpc.user.getAccountType.useQuery<UserAccountStatus>({});
    return (
        <div>
        <DashTransaction userData={{
          acountType: userProfile?.acountType as AccountypeUnion,
          corporateId: userProfile?.corporateId as number,
          userId: userProfile?.userId as number,
        }} />
      </div>
    )
}

export default Transactions;