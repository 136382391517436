import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink, TRPCLink } from "@trpc/client";
import { observable } from "@trpc/server/observable";
import { CustomerAppRouter } from "api";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./components/Common/isAuth";
import { ModalProvider, ModalProviders } from "./context/modalProvider";
import { trpc } from "./lib/trpc";
import AppRoutes from "./route";

function App() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: { staleTime: 5000, refetchOnWindowFocus: false },
        },
      })
  );

  const { logout } = useAuth();
  const navigate = useNavigate();

  const [trpcClient] = useState(() => {
    const customLink: TRPCLink<CustomerAppRouter> = () => {
      // here we just got initialized in the app - this happens once per app
      return ({ next, op }) => {
        // this is when passing the result to the next link
        // each link needs to return an observable which propagates results
        return observable((observer) => {
          console.log("performing operation:", op);
          const unsubscribe = next(op).subscribe({
            next(value) {
              observer.next(value);
            },
            error(err) {
              observer.error(err);
              if (err?.data?.code === "UNAUTHORIZED") {
                logout();
                navigate("/login");
                queryClient.clear();
              }
              new QueryClient({
                defaultOptions: {
                  queries: {
                    refetchOnWindowFocus: false,
                  },
                },
              });
            },
            complete() {
              observer.complete();
            },
          });
          return unsubscribe;
        });
      };
    };

    return trpc.createClient({
      links: [
        customLink,
        httpBatchLink({
          url: import.meta.env.VITE_BACKEND_BASEURL,
          headers: () => {
            const token = localStorage.getItem("token");
            return {
              Authorization: token ? `Bearer ${token}` : undefined,
            };
          },
        }),
      ],
    });
  });

  return (
    <trpc.Provider queryClient={queryClient} client={trpcClient}>
      <QueryClientProvider client={queryClient}>
        <ModalProvider>
          <ModalProviders/>
          <AppRoutes />
          <ToastContainer
            position="top-right"
            autoClose={8000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
            style={{ fontSize: "14px", zIndex: 1000 }}
            
          />
        </ModalProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}

export default App;
