import { FC } from "react";
import { warnToolTipSVG } from "../svg";

interface ToolTipProps {
    icon?: string;
    tipClass?: string;
    tipHeader?: string;
    tipBody?: string;
  }

const Tooltip: FC<ToolTipProps> = ({icon, tipClass, tipHeader, tipBody }) => {
    return (
        <div className={`ml-1 tipppy ${tipClass}`}>
                        <span
                          className="tippy-warn cursor-pointer"
                          dangerouslySetInnerHTML={{ __html: icon || warnToolTipSVG }}
                        />
                        <div className="tippy-deck">
                            <p className="text-htext-main font-semibold">{tipHeader}</p>
                            <p className="mt-1">{tipBody}</p>
                        </div>
                        </div>
    )
}

export default Tooltip;