import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { trpc } from "../../../lib/trpc";
import {
  resetLinkSchema,
  ResetLinkSchema,
} from "../../../utils/validationSchema";
import Inputs from "../../Common/inputs";
import { BackArrowSVG, EmailSVG } from "../../Common/svg";

const ResetPasswordLink: FC = () => {
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    navigate(route); // Replace '/new-route' with your desired route
  };

  // const handleGoBack = () => {
  //   navigate(-1);
  // };

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(resetLinkSchema),
    defaultValues: {
      email: "",
    },
  });

  const { mutate: forgotPassword, isLoading } =
    trpc.user.forgotPassword.useMutation({
      onSuccess: (res) => {
        console.log(res, "resss");
        toast.success("Email, sent successfully!");
        handleNavigate(`/reset-email-sent/${userEmail}`);
        reset();
      },
      onError: (err) => {
        console.log(err, "errrrrr");
        toast.error(err.message);
      },
    });

  const onSubmit = useCallback(
    async (data: ResetLinkSchema) => {
      setUserEmail(data.email);
      forgotPassword({ email: data.email });
    },
    [forgotPassword]
  );

  return (
    <div className="login relative h-screen w-screen bg-white">
      <div className="login-content flex h-full w-full flex-col items-start overflow-hidden">
        <section className="content-top flex w-full items-center justify-between px-16 py-10">
          <div className="w-[120px]">
            <img className="h-full w-full" src="/logo.jpeg" alt="Shiga Logo" />
          </div>

          <div className="navi-text font-ibm-plex flex items-center justify-end space-x-3 text-sm">
            <p className="font-normal text-htext-main hover:text-gray-700">
              Don’t have an account yet?
            </p>
            <button
              className="w-[110px] cursor-pointer rounded-lg border border-solid border-gray-300 px-[12px] py-[7px] font-semibold text-gray-700 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] transition duration-300 ease-in-out hover:bg-gray-300/[0.2]"
              type="button"
              onClick={() => handleNavigate("/signup")}
            >
              Sign up
            </button>
          </div>
        </section>

        <section className="content-right mt-[8px] justify-center self-center justify-self-center rounded-lg bg-white px-[30px] pb-[25px] pt-[5px]">
          <div className="font-ibm-plex w-[560px] border-b-[1.5px] border-solid border-b-hgray-200 py-3 font-semibold text-gray-600 antialiased">
            <div
              className="flex w-[135px] cursor-pointer items-center justify-start rounded border-solid border-gray-200 px-1.5 py-1.5 hover:border"
              onClick={() => handleNavigate("/login")}
            >
              <span
                className="mr-2"
                dangerouslySetInnerHTML={{ __html: BackArrowSVG }}
              />
              <p className="text-[15px]">Back to log in</p>
            </div>
          </div>
          <div className="right-top mt-[35px] w-[560px]">
            <div className="font-ibm-plex w-full font-normal text-htext-main antialiased">
              <h1 className=" text-left text-[26px] font-semibold tracking-[-0.72px]">
                Reset password
              </h1>
              <p className="mt-2 text-[15px] text-htext-subdued ">
                Please enter your email and we’ll send you a link to reset your
                password
              </p>
            </div>
          </div>
              <form className="w-[560px]" onSubmit={handleSubmit(onSubmit)}>
                <fieldset className="mt-[30px] w-full rounded-[14px] bg-hgray-50 px-4 py-6">
                  <div className="">
                    <label
                      htmlFor="email"
                      className="font-ibm-plex text-[14px] font-bold text-htext-label antialiased"
                    >
                      Email
                      <Inputs
                        type="email"
                        placeholder="johndoe@example.com"
                        icon={EmailSVG}
                        register={register("email")}
                        error={errors.email}
                      />
                      {errors.email && (
                        <p className="font-ibm-plex mt-1.5 text-[13px] font-medium text-red-600">
                          {errors.email.message}!
                        </p>
                      )}
                    </label>
                  </div>
                </fieldset>
                <div className="font-ibm-plex mt-[5px] w-full font-normal antialiased">
                  <button
                    className="mb-[16px] mt-[50px] w-full rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[11px] text-[15px] font-bold text-white hover:bg-hbrand-700"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? <span className="loading loading-spinner loading-sm"></span> : "Send reset link"}
                  </button>
                </div>
              </form>
        </section>
      </div>
    </div>
  );
};

export default ResetPasswordLink;
