import React, { FC } from 'react';

interface RadioButtonProps {
  name: string;
  value: string;
  checked?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  className?: string;
  disabled?: boolean
}

const RadioButton: FC<RadioButtonProps> = ({
  name,
  value,
  checked = false,
  onChange,
  label,
  className = '',
  disabled = false
}) => {
  return (
    <label className={`relative flex items-center cursor-pointer ${className} mr-[-8px]`}>
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        className={`absolute opacity-0 h-0 w-0`}
      />
      <span className={`${name === "pep" ? "squared-radio" : "custom-radio"}  flex-shrink-0`}></span>
      <span className="ml-2">{label}</span>
    </label>
  );
};

export default RadioButton;
