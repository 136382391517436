import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../styles/custom-datePicker.css"; // Custom styles

interface DateDropProps {
  name?: string;
  startDate: Date | null;
  endDate: Date | null;
  onSelect?: (item: [Date | null, Date | null]) => void;
}

const EasyDateDrop = ({ startDate, endDate, onSelect }: DateDropProps) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    startDate,
    endDate,
  ]);

  useEffect(() => {
    setDateRange([startDate, endDate]);
}, [startDate, endDate]);
  
  const handleChange = (update: [Date | null, Date | null]): void => {
    setDateRange(update);
    onSelect?.(update);
  };

  return (
    <div className="relative inline-block w-full bg-white pl-[12px] pr-[10px] outline-none ">
      <DatePicker
        selectsRange={true}
        startDate={dateRange[0] || null}
        endDate={dateRange[1] || null}
        onChange={(update) => { handleChange(update as [Date | null, Date | null]); }}
        placeholderText="Dates"
        calendarClassName="custom-calendar"
        monthsShown={2}
        maxDate={new Date()}
        className="w-full text-ellipsis border-0 border-transparent bg-transparent py-[1.5px] pl-5 text-[13.5px] text-gray-900 focus:outline-none"
      />

      {/* Add a calendar icon for visual */}
      <svg
        className="absolute w-4 h-4 -mt-2 text-gray-400 pointer-events-none top-1/2"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="18"
        viewBox="0 0 16 18"
        fill="none"
      >
        <path
          opacity="0.4"
          d="M0.332031 6.5H15.332V14C15.332 15.8409 13.8396 17.3333 11.9987 17.3333H3.66536C1.82442 17.3333 0.332031 15.8409 0.332031 14V6.5Z"
          fill="#475467"
        />
        <path
          d="M11.9987 1.91675H3.66536C1.82442 1.91675 0.332031 3.40913 0.332031 5.25008V6.50008H15.332V5.25008C15.332 3.40913 13.8396 1.91675 11.9987 1.91675Z"
          fill="#475467"
        />
        <path
          opacity="0.4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 0.041748C4.84518 0.041748 5.125 0.32157 5.125 0.666748V3.16675C5.125 3.51193 4.84518 3.79175 4.5 3.79175C4.15482 3.79175 3.875 3.51193 3.875 3.16675V0.666748C3.875 0.32157 4.15482 0.041748 4.5 0.041748ZM11.1667 0.041748C11.5118 0.041748 11.7917 0.32157 11.7917 0.666748V3.16675C11.7917 3.51193 11.5118 3.79175 11.1667 3.79175C10.8215 3.79175 10.5417 3.51193 10.5417 3.16675V0.666748C10.5417 0.32157 10.8215 0.041748 11.1667 0.041748Z"
          fill="#475467"
        />
      </svg>
    </div>
  );
};

export default EasyDateDrop;
