import React from 'react';
import '../../../styles/stepper.css';


const Stepper = ({
  steps,
  currentStep,
  complete,
}: {
  steps: string[];
  currentStep: number;
  complete: boolean;
  setCurrentStep?: React.Dispatch<React.SetStateAction<number>>;
  setComplete?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <>
      <div className="flex items-center justify-center mt-14">
        {steps?.map((step, i) => (
          <div
            key={i}
            className={`stepp-item ${currentStep === i + 1 && 'active'} ${
              (i + 1 < currentStep || complete) && 'complete'
            } `}
          >
            <div className="stepp">
              {i + 1 < currentStep || complete ? (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Content">
                <rect x="0.75" y="0.75" width="22.5" height="22.5" rx="11.25" fill="#1570EF"/>
                <rect x="0.75" y="0.75" width="22.5" height="22.5" rx="11.25" stroke="#1570EF" strokeWidth="1.5"/>
                <path id="Tick" fillRule="evenodd" clipRule="evenodd" d="M17.098 7.39004L9.93797 14.3L8.03797 12.27C7.68797 11.94 7.13797 11.92 6.73797 12.2C6.34797 12.49 6.23797 13 6.47797 13.41L8.72797 17.07C8.94797 17.41 9.32797 17.62 9.75797 17.62C10.168 17.62 10.558 17.41 10.778 17.07C11.138 16.6 18.008 8.41004 18.008 8.41004C18.908 7.49004 17.818 6.68004 17.098 7.38004V7.39004Z" fill="white"/>
                </g>
                </svg>
              ) : (
                <div className="inner w-2 h-2 bg-hgray-300 rounded-[50%]"></div>
              )}
            </div>
            <p className="text-htext-label text-[13px] font-semibold mt-2">
              {step}
            </p>
          </div>
        ))}
      </div>
    </>
  );
};

export default Stepper;
