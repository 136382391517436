import ResetPasswordLink from "../../components/Auth/ResetPassword/Link";

function AuthResetPasswordLink() {
    return (
            <div>
                <ResetPasswordLink />
            </div>
    )
}

export default AuthResetPasswordLink;