import EmailVerification from "../../components/Auth/EmailVerification";

function AuthEmailVerification() {
    return (
            <div>
                <EmailVerification />
            </div>
    )
}

export default AuthEmailVerification;