import React from 'react';
import { warnQuestion } from '../svg';

type TransactionDetailProps = {
  type: string;
  fee?: string;
  amount: string;
  rate: string;
};

export const TransactionDetail: React.FC<TransactionDetailProps> = ({ type, amount, rate }) => {
  return (
    <div className="w-full pt-3">
      <div className="flex-1">
        <div className="flex items-center justify-between mb-3">
            <div className='flex items-center'>
                <div className='flex flex-col justify-center items-center'>
                <div className="-mt-6 h-6 w-1 border-l-2 border-dashed border-hgray-300"></div>
                 <span className="-ml-[1px] w-[7px] h-[7px] bg-hgray-300 rounded-full"></span>
                </div>
              <span className="ml-3 text-[12.5px] font-normal text-htext-subdued">Type</span>
            </div>
          <span className="text-[12.5px] font-normal text-htext-subdued flex items-center">
            {type}
            <button className="ml-1.5">
             <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: warnQuestion }}
                  />
            </button>
          </span>
        </div>

        {/* <div className="flex items-center justify-between mb-3">
            <div className='flex items-center'>
            <div className='flex flex-col justify-center items-center'>
                <div className="-mt-7 h-6 w-1 border-l-2 border-dashed border-hgray-300"></div>
                 <span className="-ml-[1px] mt-0.5 w-[7px] h-[7px] bg-hgray-300 rounded-full"></span>
                </div>
          <span className="ml-3 text-[12.5px] font-normal text-htext-subdued">Fee</span>
            </div>
          <span className="text-[12.5px] font-normal text-htext-subdued">{fee}</span>
        </div> */}

        <div className="flex items-center justify-between mb-3">
            <div className='flex items-center'>
            <div className='flex flex-col justify-center items-center'>
                <div className="-mt-[26px] h-6 w-1 border-l-2 border-dashed border-hgray-300"></div>
                 <span className="-ml-[1px] mt-0.5 w-[7px] h-[7px] bg-hgray-300 rounded-full"></span>
                </div>
          <span className="ml-3 text-[12.5px] font-normal text-htext-subdued">Amount to portal in</span>
            </div>
          <span className="text-[12.5px] font-normal text-htext-subdued">{amount}</span>
        </div>

        <div className="flex items-center justify-between">
            <div className='flex items-center'>
            <div className='flex flex-col justify-center items-center'>
                <div className="-mt-7 h-6 w-1 border-l-2 border-dashed border-hgray-300"></div>
                 <span className="-ml-[1px] mt-0.5 w-[7px] h-[7px] bg-hgray-300 rounded-full"></span>
                </div>

               <span className="ml-3 text-[12.5px] font-normal text-htext-subdued">Rate</span>
            </div>
          <span className="text-[12.5px] font-normal text-htext-subdued">{rate}</span>
        </div>

        <div className="flex items-start ">
        <div className='flex flex-col justify-center items-center'>
                <div className="-mt-1 ml-[0.8px] h-4 w-1 border-l-2 border-dashed border-hgray-300"></div>
                </div>
        </div>
      </div>
    </div>
  );
};
