import { createTRPCReact, httpBatchLink } from '@trpc/react-query';
import type { CustomerAppRouter } from "api";

export const trpc = createTRPCReact<CustomerAppRouter>();

function geTtoken() {
  return localStorage.getItem("token");
}


export const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      url: import.meta.env.VITE_BACKEND_BASEURL,
      headers() {
        return {
          Authorization: `Bearer ${geTtoken()}`,
        };
      },
    }),
  ],
});