import { zodResolver } from "@hookform/resolvers/zod";
import { Country, State } from "country-state-city";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DateDropdown from "../../../../components/Common/dropdown/DateDrop";
import MainLoader from "../../../../components/Common/loader/mainLoader";
import { trpc } from "../../../../lib/trpc";
import Inputs from "../../../Common/inputs";
import Select from "../../../Common/inputs/select";
import { arrowRight } from "../../../Common/svg/index";
import { BusinessInfoFormData, businessInfoFormSaveSchema } from "./schema";

const BusinessInfoForm: FC = () => {
  const navigate = useNavigate();
  const [isBusinessInfoComplete, setBusinessInfoComplete] = useState(false);

  const { mutate, isLoading } = trpc.user.createCorporateProfile.useMutation({
    onSuccess: () => {
      navigate("/get-started/onboarding-checklist/business-documents");
    },
  });

  const {
    mutate: updateCorporateProfile,
    isLoading: isLoadingupdateCorporateProfile,
  } = trpc.user.updateCorporateProfile.useMutation({
    onSuccess: () => {
      navigate("/get-started/onboarding-checklist/business-documents");
    },
  });

  const { data: businessInfo, isLoading: isLoadingBusinessInfo } =
    trpc.user.getCompany.useQuery({});

  const handleGoBack = () => {
    navigate("/dashboard/get-started");
  };

  const methods = useForm({
    resolver: zodResolver(businessInfoFormSaveSchema),
    defaultValues: {
      legalName: "",
      registrationNumber: "",
      type: "",
      dateOfEstablishment: "",
      sourcesOfIncome: "",
      state: "",
      addressLine: "",
      postalCode: "",
      country: "",
      city: "",
    },
  });

  const {
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (businessInfo?.id) {
      setBusinessInfoComplete(true);
      reset({
        legalName: businessInfo.legalName || "",
        registrationNumber: businessInfo.registrationNumber || "",
        type: businessInfo.type || "",
        dateOfEstablishment: businessInfo?.dateOfEstablishment,
        sourcesOfIncome: businessInfo.sourcesOfIncome || "",
        state: businessInfo.state || "",
        addressLine: businessInfo.addressLine1 || "",
        postalCode: businessInfo.postcode || "",
        country: businessInfo.country || "",
        city: businessInfo.city || "",
      });
    }
  }, [businessInfo, reset]);

  const sof = [
    "COMPANY_CAPITAL",
    "INVESTORS_FUNDS",
    "LOANS",
    "RETAINED_PROFITS",
  ];

  const companyType = [
    "LIMITED_LIABILITY",
    "SOLE_TRADER",
    "PARTNERSHIP",
    "PUBLIC_LIMITED_COMPANY",
    "JOINT_STOCK_COMPANY",
    "CHARITY",
    "DECENTRALISED_AUTONOMOUS_ORGANISATION",
  ];

  const businessInfoFormSave = (data: BusinessInfoFormData) => {
    if (businessInfo?.id) {
      updateCorporateProfile(data);
    } else {
      mutate(data);
    }
  };

  if (isLoadingBusinessInfo) {
    return <MainLoader />;
  }

  return (
    <div className="pform h-screen w-screen bg-white pb-2 pt-4">
      <div className="pform-content h-full w-full overflow-hidden">
        <div className="content-nav mt-5 flex items-center px-40">
          <p className="text-[13px] font-medium text-htext-label">
            Onboarding checklist
          </p>
          <span
            className="mx-4"
            dangerouslySetInnerHTML={{ __html: arrowRight }}
          />
          <p className="text-[13px] font-semibold text-hbrand-700">
            Provide your business information
          </p>
        </div>

        <div className="content-form mx-auto h-full w-full pb-40 ">
          <div className="form-header mx-auto mt-14 w-[800px] pb-8">
            <div>
              <h2 className="text-[28px] font-semibold tracking-[-0.72px] text-htext-main">
                Your business details
              </h2>
            </div>
          </div>
          <div className="hide-scrollbar h-full w-full overflow-y-scroll pb-20">
            <div className="header-header mx-auto w-[800px] rounded-t-[12px] border border-b-0 border-hgray-100 bg-hgray-50 px-5 pb-3 pt-5">
              <h4 className="text-[18px] font-semibold text-htext-main">
                Business profile
              </h4>
              <p className="mt-2.5 text-[13px] font-normal text-htext-subdued">
                Enter your business details as it appears on your business
                documents
              </p>
            </div>
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(businessInfoFormSave)}
                className="form-content h-full w-full rounded-b-[16px]  bg-white"
              >
                <fieldset>
                  <div className="mx-auto w-[800px] rounded-b-[12px] border border-t-0 border-hgray-100 pb-6  pt-1">
                    <div className="px-5">
                      <div className={`mt-6 flex w-full items-start justify-between ${
                          errors.legalName || errors.legalName ? "h-24" : ""
                        }`}>
                        <label htmlFor="businessName" className="w-full h-full relative">
                          <div className="mb-1 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>
                            <p className="text-htext-label">
                              Legal business name
                            </p>
                          </div>
                          <Inputs
                            type="text"
                            placeholder=""
                            register={register("legalName")}
                            inputClass={true}
                            error={errors.legalName}
                          />
                           {errors.legalName && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.legalName.message}!
                            </p>
                          )}
                        </label>
                      </div>

                      <div  className={`flex w-full items-start justify-between ${
                          errors.registrationNumber || errors.registrationNumber
                            ? "mt-[.80rem] h-24"
                            : "mt-[1.30rem]"
                        }`}>
                        <label
                          htmlFor="registrationNumber"
                          className="w-[48.5%] relative h-full"
                        >
                          <div className="mb-1.5 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>

                            <p className="text-htext-label">
                              Business registration number
                            </p>
                          </div>
                          <Inputs
                            type="text"
                            placeholder=""
                            register={register("registrationNumber")}
                            inputClass={true}
                            error={errors.registrationNumber}
                          />
                           {errors.registrationNumber && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.registrationNumber.message}!
                            </p>
                          )}
                        </label>

                        <label htmlFor="type" className="w-[48.5%] relative h-full">
                          <div className="mb-1.5 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>
                            <p className="text-htext-label">Business type</p>
                          </div>
                          <Select
                            id="type"
                            register={register("type")}
                            inputClass={true}
                            optionsItem={companyType}
                            error={errors.type}
                          />
                           {errors.type && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.type.message}!
                            </p>
                          )}
                        </label>
                      </div>
                      {!errors.type && (
                        <p className="float-right mb-3.5 w-[48.5%] text-left text-[12px] font-normal text-hgray-600">
                          E.g sole proprietorship, LLC etc
                        </p>
                      )}

                      <div className={`flex w-full items-start justify-between ${
                          errors.dateOfEstablishment || errors.sourcesOfIncome
                            ? "mt-[.80rem] h-24"
                            : "mt-[1.30rem]"
                        }`}>
                        <label
                          htmlFor="dateOfEstablishment"
                          className="w-[48.5%] relative h-full"
                        >
                          <div className="mb-1.5 flex items-center text-[13px] font-medium">
                            {/* {errors && Object.entries(errors).length > 0 && ( */}
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>
                            {/* )} */}
                            <p className="text-htext-label">
                              Date of formation
                            </p>
                          </div>
                          <DateDropdown name="dateOfEstablishment" error={errors.dateOfEstablishment} />
                          {errors.dateOfEstablishment && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.dateOfEstablishment.message}!
                            </p>
                          )}
                        </label>

                        <label htmlFor="sourcesOfIncome" className="w-[48.5%] relative h-full">
                          <div className="mb-1.5 flex items-center text-[13px] font-medium">
                            {/* {errors && Object.entries(errors).length > 0 && ( */}
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>
                            {/* )} */}
                            <p className="text-htext-label">Source of Income</p>
                          </div>
                          <Select
                            id="sourcesOfIncome"
                            register={register("sourcesOfIncome")}
                            inputClass={true}
                            optionsItem={sof}
                            error={errors?.sourcesOfIncome}
                          />
                           {errors.sourcesOfIncome && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.sourcesOfIncome.message}!
                            </p>
                          )}
                        </label>
                      </div>
                    </div>

                    <div className="mt-14 w-full bg-hgray-50 px-6 py-4">
                      <h3 className="text-[18px] font-semibold text-htext-main">
                        Address of incorporation
                      </h3>
                    </div>

                    <div className="px-5">
                      <div className={`mt-5 flex w-full items-start justify-between ${
                          errors.addressLine ? "h-24" : ""
                        }`}>
                        <label htmlFor="addressLine" className="w-full relative h-full">
                          <div className="mb-1.5 flex items-center text-[13px] font-medium">
                            {/* {errors && Object.entries(errors).length > 0 && ( */}
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>
                            {/* )} */}
                            <p className="text-htext-label">Address Line</p>
                          </div>
                          <Inputs
                            type="text"
                            placeholder=""
                            register={register("addressLine")}
                            inputClass={true}
                            error={errors?.addressLine}
                          />
                           {errors.addressLine && (
                            <p className="absolute font-ibm-plex mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.addressLine.message}!
                            </p>
                          )}
                        </label>
                      </div>

                      <div className={`flex w-full items-start justify-between ${
                          errors.country || errors.city
                            ? "mt-[.80rem] h-24"
                            : "mt-[1.30rem]"
                        }`}>
                        <label htmlFor="country" className="w-[48.5%] h-full relative">
                          <div className="mb-1.5 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>
                            <p className="text-htext-label">Country</p>
                          </div>
                          <Select
                            register={register("country")}
                            inputClass={true}
                            optionsItem={Country.getAllCountries().map(
                              ({ name, isoCode }) => ({
                                value: isoCode,
                                label: name,
                              })
                            )}
                            error={errors?.country}
                          />
                          {errors.country && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.country.message}!
                            </p>
                          )}
                        </label>

                        <label htmlFor="city" className="w-[48.5%] h-full relative">
                          <div className="mb-1.5 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>
                            <p className="text-htext-label">City/Town</p>
                          </div>
                          {/* <Select
                            id="city"
                            register={register("city")}
                            inputClass={true}
                            optionsItem={cities}
                            error={errors?.city?.message}
                          /> */}
                          <Inputs
                            type="text"
                            placeholder=""
                            register={register("city")}
                            inputClass={true}
                            error={errors?.city}
                          />
                           {errors.city && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.city.message}!
                            </p>
                          )}
                        </label>
                      </div>

                      <div  className={`flex w-full items-start justify-between ${
                          errors.postalCode || errors.city
                            ? "mt-[.80rem] h-24"
                            : "mt-[1.30rem]"
                        }`}>
                        <label htmlFor="state" className="w-[48.5%] h-full relative">
                          <div className="mb-1.5 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>
                            <p className="text-htext-label">
                              State/Region/Province
                            </p>
                          </div>
                          <Select
                            id="state"
                            register={register("state")}
                            inputClass={true}
                            optionsItem={State.getStatesOfCountry(
                              watch("country")
                            ).map(({ name }) => ({
                              value: name,
                              label: name,
                            }))}
                            error={errors?.city}
                          />
                           {errors.city && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.city.message}!
                            </p>
                          )}
                        </label>

                        <label htmlFor="postalCode" className="w-[48.5%] h-full relative">
                          <div className="mb-1.5 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>
                            <p className="text-htext-label">Zip/Post code</p>
                          </div>
                          <Inputs
                            type="text"
                            placeholder=""
                            register={register("postalCode")}
                            inputClass={true}
                            error={errors.postalCode}
                          />
                           {errors.postalCode && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.postalCode.message}!
                            </p>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-btns mt-28 flex h-full w-full items-center justify-between border-t border-t-hgray-200 px-14 pb-7 pt-6">
                    <button
                      className="w-[150px] cursor-pointer rounded-lg  border border-solid border-hgray-300 bg-white px-[10px] py-[13px] text-[15px] font-semibold text-hgray-700 shadow-s-light hover:bg-hgray-50"
                      type="button"
                      onClick={() => handleGoBack()}
                    >
                      Go Back
                    </button>

                    <button
                      className="w-[250px] cursor-pointer rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[13px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
                      type="submit"
                    >
                      {isLoading || isLoadingupdateCorporateProfile ? (
                        <span className="loading loading-spinner loading-sm"></span>
                      ) : isBusinessInfoComplete ? (
                        "Update and continue"
                      ) : (
                        "Save and continue"
                      )}
                    </button>
                  </div>
                </fieldset>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessInfoForm;
