import { useState } from "react";

export interface allInputsProps {
  id?: string;
  register: any;
  error?: any;
  icon?: string; // SVG icon passed as a string
  inputClass?: boolean;
  optionsItem: Array<string | { value: string; label: string }>;
}

const Select = ({ id, icon, register, inputClass, error, optionsItem }: allInputsProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div
      className={`bg-white border-solid shadow-s-light mt-[4px] flex items-center rounded-[8px] border px-[16px] focus:outline-double focus:outline-2 ${
        error
        ? "border-red-500 ring-r-width ring-r-color-red shadow-s-light-red focus:outline-red-400"
        : isFocused
        ? "ring-r-width ring-r-color focus:outline-hbrand-300 border-gray-300"
        : "border-gray-300"}`}
    >
      <select
        id={id}
        className={`${inputClass ? "h-[42px]" : "h-[36px]"} w-full rounded-[8px] border-0 py-[4px] text-sm font-normal bg-transparent focus:bg-transparent focus:outline-none cursor-pointer ${selectedValue === '' ? 'text-htext-placeholder' : 'text-htext-main'}`}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        {...register}
      >
        <option value="" disabled className="text-htext-placeholder">Select here</option>
        {optionsItem.map((item, index) => {
          if (typeof item === 'string') {
            return (
              <option value={item} key={index}>
                {item.replaceAll('_', " ")}
              </option>
            );
          } else {
            return (
              <option value={item.value} key={index}>
                {item.label.replaceAll('_', " ")}
              </option>
            );
          }
        })}
      </select>

      <span
        className="ml-1.5 cursor-pointer"
        dangerouslySetInnerHTML={{ __html: icon || '' }}
      />
    </div>
  );
};

export default Select;
