import BusinessDocsForm from "../../../../components/Dashboard/get-started/onboarding-checklist/BusinessDocsForm";

function DashOnboardingBusinessDocs() {
    return (
            <div>
                <BusinessDocsForm />
            </div>
    )
}

export default DashOnboardingBusinessDocs;