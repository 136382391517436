import { FC } from "react";
import { useNavigate } from "react-router-dom";
import PortalInSetup from "./index";
import { poundseuroDisk, dollarDisk } from "../../../Common/svg";

const PortalInActivateAccount: FC = () => {
  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  return (
    <PortalInSetup currentStep={3} complete={false}>
      <div className="portal-type hide-scrollbar mt-12 h-full w-full overflow-y-scroll">
      <div className="type-content flex flex-col h-full items-center justify-between pt-3 ">
          <div className="type-top w-[750px] pb-5">
            <div className="type-top pb-10">

            <div className="relative bg-hgray-50 shadow-s-semi-light flex items-start justify-between rounded-2xl px-5 pt-6 pb-4">
              <div className="w-[62.5%] z-10">
                <h3 className="text-[22px] font-semibold leading-[31px] text-htext-main">
                Activate EUR & GBP portal bank account
                </h3>
                <p className="mt-[5px] text-[13px] font-normal text-htext-main leading-[20px]">
                Any euro & pound amount you send into this portal bank account is swapped into stablecoin and sent to your settlement wallet
                </p>

                <button
                className="w-[240px] mt-7 rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[10px] text-[13px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
                  type="button"
                  onClick={() => handleNavigate('/dashboard/portal-accounts/portal-in-bank-acccounts')}

                >
                Activate EUR & GBP portal account
                </button>
              </div>

              <div className="w-[20.5%] z-10">
                <span 
                 className="block w-fit float-right -mt-[1.5rem] -mr-[1rem]"
                 dangerouslySetInnerHTML={{ __html: poundseuroDisk }}
                />
              </div>
            </div>

            <div className="relative mt-7 bg-hgray-50 shadow-s-semi-light flex items-start justify-between rounded-2xl px-5 pt-6 pb-4">
              <div className="w-[62.5%] z-10">
                <h3 className="text-[22px] font-semibold leading-[31px] text-htext-main">
                Activate USD portal bank account
                </h3>
                <p className="mt-[5px] text-[13px] font-normal text-htext-main leading-[20px]">
                Any dollar amount you send into this portal bank account is swapped into stablecoin and sent to your settlement wallet
                </p>

                <button
                className="w-[240px] mt-7 rounded-lg border border-solid border-hgray-200 bg-hgray-100 px-[10px] py-[10px] text-[13px] font-semibold text-hgray-400 shadow-s-light cursor-not-allowed"
              type="button"
              disabled
                >
                Activate USD portal account
                </button>
              </div>

              <div className="w-[20.5%] z-10">
                <span 
                 className="block w-fit float-right -mt-[1.5rem] -mr-[1rem]"
                 dangerouslySetInnerHTML={{ __html: dollarDisk }}
                />
              </div>
            </div>
            </div>
          </div>

          <div className="form-btns flex h-fit w-full items-center justify-between border-t border-t-hgray-200 px-14 pb-1 pt-6">
            <button
              className="w-[150px] rounded-lg border  border-solid border-hgray-300 bg-white px-[10px] py-[13px] text-[15px] font-semibold text-hgray-700 shadow-s-light hover:bg-hgray-50"
              type="button"
              onClick={() => navigate('/dashboard/portal-accounts/portal-in-settlement-information')}
            >
              Go Back
            </button>

            <button
              className="w-[250px] rounded-lg border border-solid border-hgray-200 bg-hgray-100 px-[10px] py-[13px] text-[15px] font-semibold text-hgray-400 shadow-s-light cursor-not-allowed"
              type="button"
              disabled
              onClick={() => handleNavigate("/dashboard/portal-accounts/portal-in-settlement-information")}
            >
              View rates
            </button>
        </div>
        </div>
      </div>
    </PortalInSetup>
  );
};

export default PortalInActivateAccount;
