import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { chainOptions } from "../../../../constant/options";
import { trpc } from "../../../../lib/trpc";
import { TransactionDetail } from "../../../Common/calDetails";
import SidePopup from "../../../Common/popup/sidepopup";
import {
  BlueThinArrowSVG,
  calculator,
  circleMinus,
  circlePlus,
  closeBtnSVG,
  CopyBlueSVG,
  euroDarkerSmall,
  shareIcon,
  uSDCSVG,
  UsdtSvg,
} from "../../../Common/svg";
import PortalOutSetup from "./index";

type AccordionItem = {
  id: number;
  title: string;
  walletAddress?: string;
};

const items1: AccordionItem[] = [
  { id: 1, title: "Celo",
    walletAddress: "0x0cdd9c8bf232d08a522cc6a803fa85f9a19cea",
   },
  { id: 2, title: "mainnet" },
  { id: 3, title: "Optimism" },
  {
    id: 4,
    title: "Polygon",
    walletAddress: "0x0cdd9c8bf232d08a522cc6a803fa85f9a19cea",
  },
  { id: 5, title: "Solana" },
];

const items2: AccordionItem[] = [
  { id: 1, title: "Arbitrum",
    walletAddress: "0x0cdd9c8bf232d08a522cc6a803fa85f9a19cea",
   },
  {
    id: 2,
    title: "Tron",
    walletAddress: "0x0cdd9c8bf232d08a522cc6a803fa85f9a19cea",
  },
  { id: 3, title: "Solana" },
];

const dropFiatOptions = [
  {
    value: "USD",
    type: "fiat",
  },
  {
    value: "EUR",
    type: "fiat",
  },
  {
    value: "GBP",
    type: "fiat",
  },
];

const dropStableOptions = [
  {
    value: "USDT",
    type: "stablecoin",
  },
  {
    value: "USDC",
    type: "stablecoin",
  },
];

const PortalOutWalletAccount: FC = () => {
  const navigate = useNavigate();
  const stableDropdownRef = useRef<HTMLDivElement>(null);
  const fiatDropdownRef = useRef<HTMLDivElement>(null);

  const [showPopup, setShowPopup] = useState(false);
  const [calRate, setCalRate] = useState("0");
  const [calBase, setCalBase] = useState(`${dropStableOptions[0]?.value}`);
  const [calTarget, setCalTarget] = useState(`${dropFiatOptions[0]?.value}`);
  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const [isUsdt, setIsUsdt] = useState(true);
  const [isUsdc, setIsUsdc] = useState(false);

  const [calculatedValue, setCalculatedValue] = useState("");
  const [amount, setAmount] = useState("");
  const [showDropDownFiat, setShowDropDownFiat] = useState(false);
  const [showDropDownStable, setShowDropDownStable] = useState(false);

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  const toggleItem = (id: number) => {
    setOpenItemId(openItemId === id ? null : id);
  };

  function closeShowPopup() {
    if (showPopup === true) {
      setShowPopup(false);
    }
  }

  // Handle clicks outside of Stablecoin dropdown
  useEffect(() => {
    const handleClickOutsideStable = (event: any) => {
      if (
        stableDropdownRef.current &&
        !stableDropdownRef?.current?.contains(event.target)
      ) {
        setShowDropDownStable(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideStable);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideStable);
    };
  }, []);

  // Handle clicks outside of Fiat dropdown
  useEffect(() => {
    const handleClickOutsideFiat = (event: any) => {
      if (
        fiatDropdownRef.current &&
        !fiatDropdownRef.current.contains(event.target)
      ) {
        setShowDropDownFiat(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideFiat);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideFiat);
    };
  }, []);

  const { data: tr } = trpc.user.getCalculatedRate.useQuery({
    base: calBase,
    target: calTarget,
  });

  useEffect(() => {
    if (amount === "" || isNaN(parseFloat(amount))) {
      setCalculatedValue("0");
    } else if (tr) {
      const rate = tr.exchange_rate; // Adjust based on the actual response structure
      const roundedUpRates = rate.toFixed(2);
      setCalRate(roundedUpRates);
      const targetValue = (parseFloat(amount) * rate).toFixed(2);
      setCalculatedValue(targetValue);
    }
  }, [tr, amount]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value);
    }
  };

  return (
    <PortalOutSetup currentStep={3} complete={false}>
      <div className="portal-type hide-scrollbar mt-12 h-full w-full overflow-y-scroll">
        <div className="type-content flex h-full flex-col items-center justify-between pt-3 ">
          <div className="type-top w-[750px] pb-5">
            <div className="type-text flex w-full items-center justify-between">
              <div className="text-content">
                <h3 className="text-[22px] font-semibold leading-[31px] text-htext-main">
                  Your portal stablecoin wallet
                </h3>
                <p className="mt-[5px] w-[400px] text-[13px] font-normal leading-[18px] text-htext-main">
                  Send stablecoin to this Shiga generated virtual wallets below
                  and it converts into local currency in your fiat settlement
                  account.
                </p>
              </div>
              <div className="text-btns">
                <button
                  className="flex w-[210px] items-center justify-center gap-x-2 rounded-lg border border-solid border-hbrand-600 bg-white px-[10px] py-[9px] text-[13px] font-semibold text-hbrand-700 shadow-s-light hover:border-hbrand-700 hover:bg-hgray-50 hover:text-hbrand-600"
                  type="button"
                  onClick={() => setShowPopup(true)}
                >
                  <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: calculator }}
                  />
                  <p>Calculate rate conversion</p>
                </button>
              </div>
            </div>

            <div className="type-top pb-10 pt-9">
              <div className="space-y-4">
              <div className="dash-toggle w-full flex items-center border border-hgray-200 rounded-[10px] p-[5px] bg-hgray-50 shadow-s-light">
                  <div className={`toggle-usdt w-[50%] flex items-center justify-center ${isUsdt ? "bg-white shadow-s-light rounded-[6px]" : "bg-transparent"} cursor-pointer px-[12px] py-[4.5px]`}
                  onClick={() => { setIsUsdc(false); setIsUsdt(true); }}
                  >
                    <p className="text-htext-main font-semibold text-[12px]">USDT</p>
                    <span
                    className="ml-1.5"
                    dangerouslySetInnerHTML={{ __html: UsdtSvg }}
                  />
                  </div>

                    <div className={`toggle-usdc w-[50%] flex items-center justify-center ${isUsdc ? "bg-white shadow-s-light rounded-[6px]" : "bg-transparent"} cursor-pointer px-[12px] py-[4.5px]`}
                    onClick={() => { setIsUsdt(false); setIsUsdc(true); }}
                    >
                    <p className="text-htext-main font-semibold text-[12px]">USDC</p>
                    <span
                    className="ml-1.5"
                    dangerouslySetInnerHTML={{ __html: uSDCSVG }}
                  />
                  </div>
              </div>

                {(isUsdt ? items1 : items2).map((item) => (
                  <div key={item.id}>
                    <button
                      onClick={() => toggleItem(item.id)}
                      className="flex w-full items-center justify-between rounded-lg bg-hgray-50 px-6 py-3 shadow-s-light focus:outline-none"
                    >
                      <div className="flex items-center">
                        {
                          chainOptions?.find(
                            ({ value }) =>
                              value.toLocaleLowerCase() ==
                              item.title.toLocaleLowerCase()
                          )?.icon
                        }
                        <span className="text-[13.5px] font-semibold text-htext-main">
                          {item.title == "mainnet" ? "Ethereum" : item.title}
                        </span>
                      </div>
                      {openItemId === item.id ? (
                        <span
                          className=""
                          dangerouslySetInnerHTML={{ __html: circleMinus }}
                        />
                      ) : (
                        <span
                          className=""
                          dangerouslySetInnerHTML={{ __html: circlePlus }}
                        />
                      )}
                    </button>
                    {openItemId === item.id && item.walletAddress && (
                      <div className="flex items-center justify-between rounded-lg bg-gray-50 px-[18px] py-3.5">
                        <div>
                          <p className="text-[11.5px] font-normal text-htext-main">
                            Wallet address
                          </p>
                          <p className="mt-0.5 text-[13.5px] font-semibold text-htext-main">
                            {item.walletAddress}
                          </p>
                        </div>

                        <div className="cursor-pointer">
                          <span
                            className=""
                            dangerouslySetInnerHTML={{ __html: CopyBlueSVG }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="form-btns flex h-fit w-full items-center justify-between border-t border-t-hgray-200 px-14 pb-1 pt-6">
            <button
              className="w-[150px] rounded-lg border  border-solid border-hgray-300 bg-white px-[10px] py-[13px] text-[15px] font-semibold text-hgray-700 shadow-s-light hover:bg-hgray-50"
              type="button"
              onClick={() =>
                navigate(
                  "/dashboard/portal-accounts/portal-in-activate-accounts"
                )
              }
            >
              Go Back
            </button>

            <button
              className="w-[250px] rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[13px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
              type="button"
              onClick={() => handleNavigate("/dashboard/portal-accounts")}
            >
              Go to accounts
            </button>
          </div>
        </div>
      </div>

      {showPopup && (
        <SidePopup
          className="signup-popup"
          popClass="pt-[1.3rem] pb-[1.5rem] w-[33rem]"
        >
          <div className="popup-content-bg flex h-full flex-col items-center justify-between px-7">
            <div className=" flex w-full flex-col items-center pb-7">
              <div className="content-top w-full">
                <div className="content-nav -px-[1.5rem] flex w-full items-start justify-between">
                  <div>
                    <h3 className="text-[18px] font-semibold text-htext-main">
                      Rate calculator
                    </h3>
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => closeShowPopup()}
                  >
                    <span
                      className=""
                      dangerouslySetInnerHTML={{ __html: closeBtnSVG }}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4 w-full">
                <div className="content-cal w-full rounded-lg bg-hgray-50 px-5 py-6">
                  <div className="from-card flex w-full items-center justify-between rounded-lg border border-hgray-300 bg-white px-5 py-4 shadow-s-light">
                    <div className="w-[160px]">
                      <p className="text-left text-[14px] font-medium text-htext-main">
                        From
                      </p>
                      <input
                        type="text"
                        className="mt-2.5 bg-transparent text-left text-[18px] font-normal text-htext-main outline-none"
                        placeholder={`${
                          calBase === "EUR"
                            ? "€"
                            : calBase === "GBP"
                              ? "£"
                              : calBase === "USD"
                                ? "$"
                                : "0"
                        }`}
                        value={amount}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="from-drop">
                      <p className="text-right text-[12px] font-normal capitalize text-htext-subdued">
                        Stablecoin
                      </p>
                      <div
                        className="mt-2.5 cursor-pointer rounded-[500px] border-0 bg-hgray-200 py-1 pl-2 pr-2.5 text-right text-[13px] font-medium text-htext-label outline-none hover:bg-hgray-300"
                        onClick={() =>
                          setShowDropDownStable(!showDropDownStable)
                        }
                        ref={stableDropdownRef}
                      >
                        <div className="flex items-center">
                          <img
                            className="w-[14px]"
                            src={`/assets/${
                              calBase === "USDC" ? "usdcSmall" : "usdtSmall"
                            }.svg`}
                            alt={calBase}
                          />
                          <p className="ml-1 mr-2.5">{calBase}</p>
                          <span
                            className="cursor-pointer"
                            dangerouslySetInnerHTML={{
                              __html: BlueThinArrowSVG || "",
                            }}
                          />
                        </div>
                        {showDropDownStable && (
                          <ul className="absolute z-50 -ml-10 mt-3 w-[120px] rounded-lg border border-solid border-hgray-200 bg-hgray-100 py-3">
                            {dropStableOptions.map((option) => (
                              <li
                                key={option.value}
                                className="cursor-pointer px-4 py-1 text-left text-[13px] hover:bg-hgray-300"
                                onClick={() => {
                                  setCalBase(option.value);
                                  setShowDropDownStable(false);
                                }}
                              >
                                <p>{option.value}</p>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="middleCont flex w-full flex-col justify-center pl-16 pr-1 pt-1.5">
                    <TransactionDetail
                      type="Portal out of stablecoin"
                      fee="- €4"
                      amount={amount || "0"}
                      rate={`x ${calRate}`}
                    />
                  </div>

                  <div className="to-card flex w-full items-center justify-between rounded-lg border border-hgray-300 bg-white px-5 py-4 shadow-s-light">
                    <div className="w-[160px]">
                      <p className="text-left text-[14px] font-medium text-htext-main">
                        To
                      </p>
                      <input
                        type="text"
                        className="mt-2.5 bg-transparent text-left text-[18px] font-normal text-htext-main outline-none"
                        placeholder={`${
                          calTarget === "EUR"
                            ? "€"
                            : calTarget === "GBP"
                              ? "£"
                              : calTarget === "USD"
                                ? "$"
                                : "0"
                        }`}
                        readOnly
                        value={calculatedValue}
                      />
                    </div>

                    <div className="to-drop">
                      <p className="text-right text-[12px] font-normal capitalize text-htext-subdued">
                        Fiat
                      </p>
                      <div
                        className="mt-2.5 cursor-pointer rounded-[500px] border-0 bg-hgray-200 py-1 pl-2 pr-2.5 text-right text-[13px] font-medium text-htext-label outline-none hover:bg-hgray-300"
                        onClick={() => setShowDropDownFiat(!showDropDownFiat)}
                        ref={fiatDropdownRef}
                      >
                        <div className="flex items-center">
                          <img
                            className="w-[14px]"
                            src={`/assets/${
                              calTarget === "USD"
                                ? "dollarSmall"
                                : calTarget === "EUR"
                                  ? "euroSmall"
                                  : "poundsSmall"
                            }.svg`}
                            alt={calTarget}
                          />
                          <p className="ml-1 mr-2.5">{calTarget}</p>
                          <span
                            className="cursor-pointer"
                            dangerouslySetInnerHTML={{
                              __html: BlueThinArrowSVG || "",
                            }}
                          />
                        </div>
                        {showDropDownFiat && (
                          <ul className="absolute z-50 -ml-10 mt-3 w-[120px] rounded-lg border border-solid border-hgray-200 bg-hgray-100 py-3">
                            {dropFiatOptions.map((option) => (
                              <li
                                key={option.value}
                                className="cursor-pointer px-4 py-1 text-left text-[13px] hover:bg-hgray-300"
                                onClick={() => {
                                  setCalTarget(option.value);
                                  setShowDropDownFiat(false);
                                }}
                              >
                                <p>{option.value}</p>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full items-center justify-center pb-2 pt-7">
                    <div className="flex w-fit cursor-pointer items-center justify-center text-hbrand-700 hover:border-b  hover:border-hbrand-600 hover:text-hbrand-600">
                      <span
                        className=""
                        dangerouslySetInnerHTML={{ __html: shareIcon }}
                      />
                      <p className="ml-2.5 text-[14px] font-semibold">
                        Share payment request
                      </p>
                    </div>
                  </div>
                </div>

                <div className="content-info mt-10 w-full border-t border-t-hgray-200 py-5">
                  <div className="info-text">
                    <h5 className="text-[13px] font-medium text-htext-main">
                      Settlement information
                    </h5>
                    <p className="mt-1 w-[450px] text-[13px] font-normal text-htext-subdued">
                      Stablecoin sent to your portal wallet are sent to your
                      saved local bank account
                    </p>
                  </div>

                  <div className="info-content mt-4 flex flex-col justify-center gap-y-3 rounded-lg bg-hgray-50 p-6">
                    <div className="flex items-center">
                      <span
                        className=""
                        dangerouslySetInnerHTML={{
                          __html: euroDarkerSmall || "",
                        }}
                      />
                      <p className="ml-2 text-[13px] font-semibold text-htext-main">
                        EUR bank details
                      </p>
                    </div>

                    <div className="mt-3 flex w-full items-center justify-between ">
                      <p className="text-[12px] font-normal text-htext-main">
                        Currency
                      </p>

                      <div className="flex items-center">
                        <p className="text-[12px] font-semibold text-htext-main">
                          Euros
                        </p>
                        <span
                          className="ml-1.5"
                          dangerouslySetInnerHTML={{
                            __html: euroDarkerSmall || "",
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex w-full items-center justify-between">
                      <p className="text-[12px] font-normal text-htext-main">
                        Account name
                      </p>

                      <p className="text-[12px] font-semibold text-htext-main">
                        Olivia Edu
                      </p>
                    </div>

                    <div className="flex w-full items-center justify-between">
                      <p className="text-[12px] font-normal text-htext-main">
                        Account type
                      </p>

                      <p className="text-[12px] font-semibold text-htext-main">
                        Checking
                      </p>
                    </div>

                    <div className="flex w-full items-center justify-between">
                      <p className="text-[12px] font-normal text-htext-main">
                        Account number
                      </p>

                      <p className="text-[12px] font-semibold text-htext-main">
                        8398039419
                      </p>
                    </div>

                    <div className="flex w-full items-center justify-between">
                      <p className="text-[12px] font-normal text-htext-main">
                        IBAN
                      </p>

                      <p className="text-[12px] font-semibold text-htext-main">
                        PT45456IGUR69
                      </p>
                    </div>

                    <div className="flex w-full items-center justify-between">
                      <p className="text-[12px] font-normal text-htext-main">
                        Bank name
                      </p>

                      <p className="text-[12px] font-semibold text-htext-main">
                        Lloyds Bank
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="close-btn w-full border-t border-hgray-200 py-5">
              <button
                className="w-full rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[11px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
                type="button"
                onClick={() => closeShowPopup()}
              >
                Close
              </button>
            </div>
          </div>
        </SidePopup>
      )}
    </PortalOutSetup>
  );
};

export default PortalOutWalletAccount;
