import DashHome from "../../components/Dashboard/home";

function Home() {
    return (
            <div>
                <DashHome />
            </div>
    )
}

export default Home;