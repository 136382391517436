import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useModal } from "../../../../context/modalProvider";
import { trpc } from "../../../../lib/trpc";
import RadioButton from "../../../Common/buttons/radioBtn";
import Inputs from "../../../Common/inputs";
import MainLoader from "../../../Common/loader/mainLoader";
import SidePopup from "../../../Common/popup/sidepopup";
import {
  bankIconSVG,
  closeBtnSVG,
  dollarsSVG,
  eurosSVG,
  poundsSVG,
  warnToolTipBlueSVG,
} from "../../../Common/svg";
import {
  AddDestinationBankSchema,
  addDestinationBankSchema,
} from "../../get-started/onboarding-checklist/schema";
import PortalOutSetup from "./index";

type CurrencyTypes = "EUR" | "GBP";

const PortalOutSettlementInfo: FC = () => {
  const utils = trpc.useUtils();
  // const { data, isLoading } =
  //   trpc.user.listUserDestinationBankAccounts.useQuery({});
  const navigate = useNavigate();

  const { mutateAsync: getSession, isLoading: isLoadingGettingSession } =
    trpc.user.isSessionIdExpired.useMutation({
      onError: (e) => {
        toast.error(e.message);
      },
    });

  const { mutateAsync: triggerOtp } = trpc.user.triggerOtp.useMutation({
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const { mutate, isLoading: isLoadingCreateDestinationBankAcct } =
    trpc.user.setUpBank.useMutation({
      onSuccess: () => {
        reset();
        utils.user.getBankAccount.refetch();
        setShowAccPopup(false);
      },
      onError: (e) => {
        toast.error(e.message);
      },
    });

  const { mutate: updateUpBank, isLoading: isUpdateUpBank } =
    trpc.user.updateUpBank.useMutation({
      onSuccess: () => {
        reset();
        utils.user.getBankAccount.refetch();
        closeShowPopup();
      },
      onError: (e) => {
        console.log(e);
      },
    });

  const { data, isLoading } = trpc.user.getBankAccount.useQuery({});

  const [showAccPopup, setShowAccPopup] = useState(false);
  const [showEditAccPopup, setShowEditAccPopup] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const [isDisabled] = useState(true);
  const [isFormData] = useState(false);
  const { showModal } = useModal();

  const [selectedValue, setSelectedValue] = useState<CurrencyTypes>();

  const handleNavigate = (route: string) => {
    navigate(route); // Replace '/new-route' with your desired route
  };

  const methods = useForm<AddDestinationBankSchema>({
    resolver: zodResolver(addDestinationBankSchema),
    defaultValues: {
      accountNumber: "",
      iban: "",
      accountName: "",
      sortCode: "",
      currency: undefined,
      mainBeneficiary: true,
    },
  });

  const {
    register,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const handleRadioChangeValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedValue(event.target.value as CurrencyTypes);
    setValue("currency", event.target.value as CurrencyTypes);
  };

  function closeShowPopup() {
    if (showAccPopup === true) {
      setShowAccPopup(false);
    } else {
      setShowEditAccPopup(false);
    }
    reset();
  }

  const onSetUpBankAccount = async (data: AddDestinationBankSchema) => {
    try {
      const response = await getSession({});
      if (response.sessionIdHasExpired) {
        await triggerOtp({});
        showModal();
      } else {
        if (showEditAccPopup && id) {
          updateUpBank({ input: { ...data, id } });
        } else {
          mutate({ input: data });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (showEditAccPopup && data) {
      const accountData = data.find((item) => item.id == id);
      // Populate form fields with fetched data
      if (accountData) {
        setValue("accountNumber", accountData.accountNumber || "");
        setValue("iban", accountData.iban || "");
        setValue("accountName", accountData.accountName || "");
        setValue("sortCode", accountData.sortCode || "");
        setValue("currency", accountData.currency as "GBP" | "EUR");
        setSelectedValue(accountData?.currency as "GBP" | "EUR");
      }
    }
  }, [showEditAccPopup, data, setValue]);

  if (isLoading) {
    return <MainLoader />;
  }
  return (
    <PortalOutSetup currentStep={2} complete={false}>
      <div className="setupAccounts hide-scrollbar mt-12 h-full w-full overflow-y-scroll">
        <div className="accounts-top flex h-full flex-col items-center justify-between pt-3">
          <div className="w-[795px] pb-5">
            <div className="top-banner flex w-full items-center justify-between rounded-t-2xl bg-hgray-50 p-6 shadow-s-light">
              <div className="w-[53.5%]">
                <span className="rounded-2xl border border-hgray-200 bg-hgray-50 px-2.5 py-[0.16rem] text-center text-[12.5px] font-medium text-hgray-700">
                  STEP 1
                </span>
                <h3 className="mt-3.5 text-[22px] font-semibold text-htext-main">
                  Add your bank account
                </h3>
                <p className="mt-2 text-sm font-normal text-htext-subdued">
                  Add your bank account information for receiving USD/EUR/GBP
                  when you want to cash out stablecoins.
                </p>
                {!isFormData && (
                  <img
                    className="mt-3.5"
                    src="/assets/payments-logo.svg"
                    alt="Payments logo"
                  />
                )}
              </div>

              {!isFormData && (
                <div>
                  <button
                    className="flex w-[170px] items-center justify-center rounded-lg border border-solid border-hbrand-600 bg-white px-[10px] py-[9.3px] text-[15px] font-semibold text-hbrand-700 shadow-s-light hover:bg-hbrand-600 hover:text-white"
                    type="button"
                    onClick={() => {
                      setShowAccPopup(true);
                      setValue("currency", "EUR");
                    }}
                  >
                    <span
                      className="w-18 mr-2"
                      dangerouslySetInnerHTML={{ __html: bankIconSVG }}
                    />
                    Add bank
                  </button>
                </div>
              )}
            </div>

            <form className="form-content rounded-b-[16px] bg-white">
              <fieldset>
                <div className="">
                  {!!data?.length && (
                    <div className="mx-auto w-[795px] rounded-b-2xl bg-hgray-50 px-6 pb-6 pt-1">
                      <div className="border-t border-t-hgray-200">
                        <div className="mt-6 flex w-full items-center justify-between text-[13px] font-semibold">
                          <p className="text-htext-label">ACCOUNTS ADDED</p>
                        </div>

                        {data?.map(
                          ({
                            id,
                            currency,
                            accountNumber,
                            accountName,
                            sortCode,
                            iban,
                          }) => (
                            <>
                              <div className="mt-3.5 flex w-full flex-col items-center gap-y-6 rounded-lg bg-hgray-100 p-[17px]">
                                <div className="flex w-full flex-wrap items-start gap-y-6 pt-[8px]">
                                  <div className="w-1/3 text-[13px] text-htext-main">
                                    <p className="mb-1 font-normal">Currency</p>
                                    <h5 className="flex items-center font-semibold">
                                      <span
                                        className="mr-6 w-4"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            currency == "EUR"
                                              ? eurosSVG
                                              : poundsSVG,
                                        }}
                                      />
                                      {currency}
                                    </h5>
                                  </div>
                                  <div className="w-1/3 text-[13px] text-htext-main">
                                    <p className="mb-1 font-normal">
                                      Account name
                                    </p>
                                    <h5 className="flex items-center font-semibold">
                                      {accountName}
                                    </h5>
                                  </div>

                                  <div className="w-1/3 text-[13px] text-htext-main">
                                    <p className="mb-1 font-normal">
                                      Account number
                                    </p>
                                    <h5 className="flex items-center font-semibold">
                                      {accountNumber}
                                    </h5>
                                  </div>

                                  {currency == "GBP" && (
                                    <div className="w-1/3 text-[13px] text-htext-main">
                                      <p className="mb-1 font-normal">
                                        Sort code
                                      </p>
                                      <h5 className="flex items-center font-semibold">
                                        {sortCode}
                                      </h5>
                                    </div>
                                  )}
                                  {currency == "EUR" && (
                                    <div className="w-1/3 text-[13px] text-htext-main">
                                      <p className="mb-1 font-normal">IBAN</p>
                                      <h5 className="flex items-center font-semibold">
                                        {iban}
                                      </h5>
                                    </div>
                                  )}
                                </div>

                                <div className="mt-2 flex w-full items-center justify-between border-t border-t-hgray-200 pt-3">
                                  <div className="toggle-cont flex items-center gap-x-2">
                                    <div className="relative inline-block h-[18px] w-8 select-none align-middle transition duration-200 ease-in">
                                      <input
                                        type="checkbox"
                                        name="toggle"
                                        id="toggle"
                                        className="toggle-checkbox absolute ml-[1.5px] mt-[2px] block h-[14px] w-[14px] cursor-pointer appearance-none rounded-full border-4 border-white bg-white"
                                      />

                                      <label
                                        htmlFor="toggle"
                                        className="toggle-label block h-[18px] cursor-pointer overflow-hidden rounded-full bg-hbrand-700"
                                      ></label>
                                    </div>
                                    <p className="text-[13.5px] font-normal text-htext-subdued">
                                      Set as default settlement account
                                    </p>
                                  </div>

                                  <button
                                    type="button"
                                    className="flex items-center text-[13.5px] font-semibold text-hbrand-700 hover:text-hbrand-600"
                                    onClick={() => {
                                      setShowEditAccPopup(true);
                                      setId(id);
                                    }}
                                  >
                                    Edit
                                  </button>
                                </div>
                              </div>
                            </>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </fieldset>
            </form>
          </div>

          <div
            className={`flex h-fit w-full items-center justify-between border-t border-t-hgray-200 px-14 pb-1 pt-6`}
          >
            <button
              className="w-[150px] rounded-lg border  border-solid border-hgray-300 bg-white px-[10px] py-[13px] text-[15px] font-semibold text-hgray-700 shadow-s-light hover:bg-hgray-50"
              type="button"
              onClick={() =>
                navigate("/dashboard/portal-accounts/portal-out-to-fiat")
              }
            >
              Go Back
            </button>

            <button
              className="w-[250px] rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[13px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
              type="button"
              onClick={() =>
                handleNavigate(
                  "/dashboard/portal-accounts/portal-out-activate-wallets"
                )
              }
            >
              View portal information
            </button>
          </div>
        </div>
      </div>
      {(showAccPopup || showEditAccPopup) && (
        <SidePopup
          className="signup-popup"
          popClass="pt-[1.3rem] pb-[1.5rem] w-[38rem]"
        >
          <div className="popup-content-bg flex h-full flex-col items-center justify-between">
            <div className="content-top w-full">
              <div className="content-nav flex w-full items-start justify-between px-[1.5rem]">
                <div>
                  <h3 className="text-[18px] font-semibold text-htext-main">
                    {showEditAccPopup
                      ? "Edit your bank account"
                      : "Add your bank account"}
                  </h3>
                  <p className="mt-1.5 text-[12.5px] font-normal text-htext-subdued">
                    This is your local currency account
                  </p>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => closeShowPopup()}
                >
                  <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: closeBtnSVG }}
                  />
                </div>
              </div>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSetUpBankAccount)}>
                  <fieldset>
                    <div className="form-div ">
                      <div className={`mt-9 h-[76vh] w-full`}>
                        <div className="px-[1.5rem]">
                          <div className="flex items-center ">
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>
                            <p className="text-[13px] font-medium  text-htext-label">
                              Select currency
                            </p>
                          </div>
                          <div className="radio-btns mt-2 flex w-full items-center justify-between">
                            <div className="btn-1 flex h-[2.5rem] w-[31.3%] items-center justify-between rounded-lg border border-hgray-200 bg-hgray-25 py-7 pl-3 pr-4">
                              <p className="flex items-center text-[14.5px] font-semibold text-htext-main">
                                <span
                                  className="w-18 mr-2"
                                  dangerouslySetInnerHTML={{ __html: eurosSVG }}
                                />
                                Euros
                              </p>
                              <RadioButton
                                name="currency"
                                value="EUR"
                                checked={selectedValue === "EUR"}
                                onChange={handleRadioChangeValue}
                                className="accs-radio w-auto"
                              />
                            </div>

                            <div className="btn-2 flex h-[2.5rem] w-[31.3%] items-center justify-between rounded-lg border border-hgray-200 bg-hgray-25 py-7 pl-3 pr-4">
                              <p className="flex items-center text-[14.5px] font-semibold text-htext-main">
                                <span
                                  className="w-18 mr-2"
                                  dangerouslySetInnerHTML={{
                                    __html: poundsSVG,
                                  }}
                                />
                                Pounds
                              </p>
                              <RadioButton
                                name="currency"
                                value="GBP"
                                checked={selectedValue === "GBP"}
                                onChange={handleRadioChangeValue}
                                className="accs-radio curren w-auto"
                              />
                            </div>

                            <div
                              className={`btn-3 flex h-[2.5rem] w-[31.3%] items-center justify-between rounded-lg border border-hgray-200 ${
                                isDisabled ? "bg-hgray-100" : "bg-hgray-25"
                              }  py-7 pl-3 pr-4`}
                            >
                              <p className="flex items-center text-[14.5px] font-semibold text-htext-main">
                                <span
                                  className="w-18 mr-2"
                                  dangerouslySetInnerHTML={{
                                    __html: dollarsSVG,
                                  }}
                                />
                                Dollars
                              </p>
                              <RadioButton
                                name="currency"
                                value="DOLLARS"
                                disabled={isDisabled}
                                onChange={handleRadioChangeValue}
                                className={`accs-radio w-auto ${
                                  isDisabled ? "cursor-not-allowed" : ""
                                }`}
                              />
                            </div>
                          </div>

                          <div className="mt-10 flex w-full items-start rounded-[12px] border border-hbrand-300 bg-hbrand-50 py-3.5 pl-4 pr-16 shadow-s-light">
                            <span
                              className="mr-4"
                              dangerouslySetInnerHTML={{
                                __html: warnToolTipBlueSVG,
                              }}
                            />
                            <p className="text-[13.5px] font-semibold text-htext-label">
                              To successfully add your bank account, your bank
                              account name must match the business name you
                              submitted on Shiga
                            </p>
                          </div>
                        </div>

                        <div className="flex h-full w-full flex-col items-center justify-between">
                          {selectedValue && (
                            <div className="mt-5 w-full px-[1.5rem]">
                              <div className="mx-auto flex w-full flex-col pb-6  pt-1">
                                {/* <label
                                htmlFor="bankName"
                                className="mt-[1.30rem] w-full"
                              >
                                <div className="mb-1 flex items-center text-[13px] font-medium">
                                  {errors &&
                                    Object.entries(errors).length > 0 && (
                                      <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                        *
                                      </p>
                                    )}
                                  <p className="text-htext-label">Bank name</p>
                                </div>
                                <Inputs
                                  type="text"
                                  placeholder="Enter here"
                                  register={register("bankName")}
                                  inputClass={true}
                                  //    error={errors.password}
                                />
                              </label> */}
                                <label
                                  htmlFor="accountName"
                                  className="relative mt-[1.30rem] w-full"
                                >
                                  <div className="mb-1 flex items-center text-[13px] font-medium">
                                    <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                      *
                                    </p>

                                    <p className="text-htext-label">
                                      Account name
                                    </p>
                                  </div>
                                  <Inputs
                                    type="text"
                                    placeholder="Enter here"
                                    register={register("accountName")}
                                    inputClass={true}
                                    error={errors.accountName}
                                  />
                                  {errors.accountName && (
                                    <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                      {errors.accountName.message}!
                                    </p>
                                  )}
                                </label>

                                <label
                                  htmlFor="accountNumber"
                                  className="relative mt-[1.30rem] w-full"
                                >
                                  <div className="mb-1 flex items-center text-[13px] font-medium">
                                    <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                      *
                                    </p>

                                    <p className="text-htext-label">
                                      Account number
                                    </p>
                                  </div>
                                  <Inputs
                                    type="text"
                                    placeholder="Enter here"
                                    register={register("accountNumber")}
                                    inputClass={true}
                                    error={errors.accountNumber}
                                  />
                                  {errors.accountNumber && (
                                    <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                      {errors.accountNumber.message}!
                                    </p>
                                  )}
                                </label>

                                {selectedValue === "EUR" && (
                                  <label
                                    htmlFor="iban"
                                    className="relative mt-[1.30rem] w-full"
                                  >
                                    <div className="mb-1 flex items-center text-[13px] font-medium">
                                      <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                        *
                                      </p>

                                      <p className="text-htext-label">IBAN</p>
                                    </div>
                                    <Inputs
                                      type="text"
                                      placeholder="Enter here"
                                      register={register("iban")}
                                      inputClass={true}
                                      error={errors.iban}
                                    />
                                    {errors.iban && (
                                      <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                        {errors.iban.message}!
                                      </p>
                                    )}
                                  </label>
                                )}
                                {selectedValue === "GBP" && (
                                  <label
                                    htmlFor="sortCode"
                                    className="relative mt-[1.30rem] w-full"
                                  >
                                    <div className="mb-1 flex items-center text-[13px] font-medium">
                                      <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                        *
                                      </p>

                                      <p className="text-htext-label">
                                        Sort code
                                      </p>
                                    </div>
                                    <Inputs
                                      type="text"
                                      placeholder="Enter here"
                                      register={register("sortCode")}
                                      inputClass={true}
                                      error={errors.sortCode}
                                    />
                                    {/* {errors.sortCode && ( */}
                                    <p
                                      className={`font-ibm-plex absolute mt-1.5 text-[11.5px] ${
                                        errors.sortCode
                                          ? "font-medium text-red-600"
                                          : "font-normal text-htext-subdued"
                                      }`}
                                    >
                                      {errors.sortCode
                                        ? errors.sortCode.message + "!"
                                        : " Enter the 6-digit sort code"}
                                    </p>
                                    {/* )} */}
                                  </label>
                                )}
                              </div>
                            </div>
                          )}

                          {selectedValue && (
                            <div className="pop-btns flex h-fit w-full items-center justify-end border-t border-hgray-200 px-[1.5rem] pb-5 pt-7">
                              <button
                                type="button"
                                onClick={() => closeShowPopup()}
                                className="shadow-s-light] mr-[5px] rounded-lg border border-hgray-300 bg-white px-3.5 py-2.5 text-[15px] font-semibold text-hgray-700 hover:opacity-70"
                              >
                                Close
                              </button>
                              <button
                                type="submit"
                                // disabled={!isFormData}
                                //  onClick={() => handleNavigate("/login")}
                                className="ml-[5px] rounded-lg border border-hbrand-600 bg-hbrand-600 px-12 py-2.5 text-[15px] font-semibold text-white shadow-s-light hover:opacity-80"
                              >
                                {isLoadingCreateDestinationBankAcct ||
                                isUpdateUpBank ||
                                isLoadingGettingSession ? (
                                  <span className="loading loading-spinner loading-sm"></span>
                                ) : (
                                  "Save account details"
                                )}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </FormProvider>
            </div>
          </div>
        </SidePopup>
      )}
    </PortalOutSetup>
  );
};

export default PortalOutSettlementInfo;
