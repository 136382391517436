import { formatDistanceToNow } from "date-fns";

export function formatDate(dateString: string): string {
    const date = new Date(dateString);
    
    const day = date.getDate().toString().padStart(2, '0'); // Get day with leading zero
    const month = date.toLocaleString('en-US', { month: 'long' }); // Get short month
    const year = String(date.getFullYear()); // Get last two digits of the year
    const hours = date.getHours() % 12 || 12; // Convert 24-hour time to 12-hour time
    const hoursFormatted = hours.toString().padStart(2, '0'); // Format to 2 digits
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Get minutes with leading zero
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM'; // Get AM/PM

    return `${day} ${month} ${year} ${hoursFormatted}:${minutes} ${ampm}`;
}

export function formatDate2(dateString: string): string {
  const date = new Date(dateString);
  
  const day = date.getDate().toString().padStart(2, '0'); // Get day with leading zero
  const month = date.toLocaleString('en-US', { month: 'short' }); // Get short month
  const year = String(date.getFullYear()); // Get last two digits of the year
  const hours = date.getHours() % 12 || 12; // Convert 24-hour time to 12-hour time
  const minutes = date.getMinutes().toString().padStart(2, '0'); // Get minutes with leading zero
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM'; // Get AM/PM

  return `${month} ${day}, ${year} ${hours}:${minutes} ${ampm}`;
}

export function isTime(value: string) {
    const timeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9]:[0-5][0-9] (AM|PM)$/i;
    return timeRegex.test(value);
}

enum Account {
  Individual = "INDIVIDUAL",
  Corporate = "CORPORATE",
}

export type IUserRole = "INDIVIDUAL" | "CORPORATE";

export function getUserRoleLabel(role: IUserRole) {
  let label = "";
  if (role === Account.Individual) return (label = "Individual");
  if (role === Account.Corporate) return (label = "Business");

  return label;
}

export const formatTimestamp = (timestamp: string): string => {
  const date = new Date(timestamp);
  return formatDistanceToNow(date, { addSuffix: true });
};


export function formatToTwoDecimalPlaces(value: number) {
  if(value == 0) return 0;
  if (value < 0) {
    throw new Error("Value must be greater than zero");
  }

  // Round up to the nearest two decimal places
  const multiplier = 100; // Since we're dealing with two decimal places
  const roundedUp = Math.ceil(value * multiplier) / multiplier;

  // Format to two decimal points
  return roundedUp.toFixed(2);
}