import AccountType from "../../../components/Dashboard/get-started/Account-type";

function DashAccountType() {
    return (
            <div>
                <AccountType />
            </div>
    )
}

export default DashAccountType;