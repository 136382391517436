import { FC, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { trpc } from "../../lib/trpc";

import { useAuth } from "../Common/isAuth";
import { BackArrowSVG } from "../Common/svg";

const EmailVerification: FC = () => {
  const { login } = useAuth();

  const [otp, setOtp] = useState("");
  const [isOtpFocused] = useState(false);
  const [formErrors, setFormErrors] = useState("");

  const params = useParams<{ email: string }>() as { email: string };
  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    navigate(route); // Replace '/new-route' with your desired route
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const { mutate: verifyOtp, isLoading: isVerify } =
    trpc.user.verifyOTP.useMutation({
      onSuccess: (res) => {
        const token = res.token;
        login(token);
        handleNavigate(`/get-started/account-type`);
        toast.success("Email verified, successfully!");
      },
      onError: (err) => {
        console.log(err, "errrrrr2");
        setFormErrors(err.message);
        // toast.error(err.message);
      },
    });

  const handleChange = (value: string) => {
    setOtp(value);
    if (formErrors) {
      setFormErrors(""); // Clear errors when the user starts typing
    }
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      verifyOtp({ useCase: "SIGNUP", otp });
    } catch (error) {
      console.error("Error submitting OTP:", error);
    }
  };

  const { mutate: resendOtp, isLoading: isResend } =
    trpc.user.resendSignUpOTP.useMutation({
      onSuccess: (res) => {
        console.log(res, "resss2");
        toast.success("OTP resent, successfully!");
      },
      onError: (err) => {
        console.log(err, "errr");
        toast.error(err.message);
      },
    });

  const onResend = async (event: any) => {
    event.preventDefault();

    try {
      resendOtp({ useCase: "SIGNUP", email: params?.email });
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  if (!params?.email && params.email === undefined) {
    return null;
  }

  return (
    <div className="login relative h-screen w-screen bg-white">
      <div className="login-content flex h-full w-full flex-col items-start overflow-hidden">
        <section className="content-top flex w-full items-center justify-between px-16 py-10">
          <div className="w-[120px]">
            <img className="h-full w-full" src="/logo.jpeg" alt="Shiga Logo" />
          </div>

          <div className="navi-text font-ibm-plex flex items-center justify-end space-x-3 text-sm">
            <p className="font-normal text-htext-main hover:text-gray-700">
              Already have an account?
            </p>
            <button
              className="w-[110px] cursor-pointer rounded-lg border border-solid border-gray-300 px-[12px] py-[7px] font-semibold text-gray-700 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] transition duration-300 ease-in-out hover:bg-gray-300/[0.2]"
              type="button"
              onClick={() => handleNavigate("/login")}
            >
              Log in
            </button>
          </div>
        </section>

        <section className="content-right mt-[8px] justify-center self-center justify-self-center rounded-lg bg-white px-[30px] pb-[25px] pt-[5px]">
          <div className="font-ibm-plex w-[560px] border-b-[1.5px] border-solid border-b-hgray-200 py-3 font-semibold text-gray-600 antialiased">
            <div
              className="flex w-[135px] cursor-pointer items-center justify-start rounded border-solid border-gray-200 px-1.5 py-1.5 hover:border"
              onClick={handleGoBack}
            >
              <span
                className="mr-2"
                dangerouslySetInnerHTML={{ __html: BackArrowSVG }}
              />
              <p className="text-[15px]">Change email</p>
            </div>
          </div>
          <div className="right-top mt-[35px] w-[560px]">
            <div className="font-ibm-plex w-full font-normal text-htext-main antialiased">
              <h1 className=" text-left text-[29px] font-semibold tracking-[-0.72px]">
                We sent a code to your email
              </h1>
              <div className="flex items-center">
                <div className="mr-2 mt-1.5 h-4 w-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      opacity="0.4"
                      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z"
                      fill="#475467"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 5.25C10.4142 5.25 10.75 5.58579 10.75 6V7C10.75 7.41421 10.4142 7.75 10 7.75C9.58579 7.75 9.25 7.41421 9.25 7V6C9.25 5.58579 9.58579 5.25 10 5.25ZM10 8.75C10.4142 8.75 10.75 9.08579 10.75 9.5V14C10.75 14.4142 10.4142 14.75 10 14.75C9.58579 14.75 9.25 14.4142 9.25 14V9.5C9.25 9.08579 9.58579 8.75 10 8.75Z"
                      fill="#475467"
                    />
                  </svg>
                </div>
                <p className="font-ibm-plex  mt-2 text-[13px] text-htext-subdued ">
                  Check your spam or promotions folder too
                </p>
              </div>
            </div>
          </div>
          <form onSubmit={onSubmit}>
            <fieldset className="mt-[30px] w-[560px] rounded-[14px] bg-hgray-50 px-4 py-8">
              <div className="">
                <p className="text-center text-[15px] font-normal text-htext-subdued">
                  Enter the code sent to{" "}
                  <span className="dotted-noti ml-1 rounded-lg border border-solid border-hbrand-200 bg-hbrand-50 py-1 pl-5 pr-2.5 text-[13px] font-medium text-hbrand-700">
                    {" "}
                    {params?.email}
                  </span>
                </p>
              </div>
              <div
                className={`${
                  formErrors ? "otp-input-error" : "otp-input-container"
                } mt-6 flex items-center justify-center`}
              >
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  inputType="tel"
                  placeholder="------"
                  inputStyle={{
                    width: "3.5rem",
                    color: formErrors ? "#B42318" : "#175CD3",
                    height: "3.5rem",
                    margin: "0 0.5rem",
                    fontSize: "1.8rem",
                    borderRadius: 8,
                    border: `${
                      otp && !formErrors
                        ? "1px solid #175CD3"
                        : (otp && formErrors) || (!otp && formErrors)
                          ? "2px solid #B42318"
                          : "1px solid #D0D5DD"
                    }`,
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                    ...(isOtpFocused && {
                      borderColor: formErrors ? "#B42318" : "#175CD3",
                      borderWidth: "2px",
                      boxShadow: formErrors
                        ? "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(180, 35, 24, 0.24)"
                        : "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(21, 112, 239, 0.24)",
                    }), // Example focus border color
                  }}
                  shouldAutoFocus
                  // onFocus={() => setIsOtpFocused(true)}
                  // onBlur={() => setIsOtpFocused(false)}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
              {formErrors && (
                <p className="font-ibm-plex mt-4 text-center text-[14px] font-medium text-red-600">
                  {!otp && formErrors
                    ? "The 6-digit code sent to your email is required"
                    : formErrors === "Invalid OTP"
                      ? "Code is invalid or has expired"
                      : ""}
                </p>
              )}
            </fieldset>
            <div className="font-ibm-plex mt-[5px] w-[560px] font-normal antialiased">
              <button
                type="submit"
                disabled={isVerify}
                className="mb-[16px] mt-[50px] w-full rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[11px] text-[15px] font-bold text-white hover:bg-hbrand-700"
              >
                {isVerify ? (
                  <span className="loading loading-spinner loading-sm"></span>
                ) : (
                  "Verify"
                )}
              </button>

              <p className="mt-2 text-center text-[15px] font-medium text-htext-main">
                Didn’t get the code?{" "}
                <b
                  className={`font-semibold text-hbrand-700 ${
                    isResend ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                  onClick={onResend}
                >
                  {isResend ? "Resending..." : "Resend"}
                </b>
              </p>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default EmailVerification;
