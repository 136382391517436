import React from "react";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

    
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}



interface TableProps {
  children: React.ReactNode;
}

interface TableHeaderProps {
  children: React.ReactNode;
}

interface TableBodyProps {
  children: React.ReactNode;
}

interface TableRowProps {
  children: React.ReactNode;
}

interface TableHeadProps {
  children: React.ReactNode;
  className?: string;
}

interface TableCellProps {
  children: React.ReactNode;
  className?: string;
}

interface Member {
  name: string;
  email: string;
  role: string;
  lastLogin: string;
}

// Basic Button component
const Button: React.FC<ButtonProps> = ({ children, className, ...props }) => (
  <button className={`rounded px-4 py-2 font-semibold ${className}`} {...props}>
    {children}
  </button>
);

// Basic Table components
const Table: React.FC<TableProps> = ({ children }) => (
  <table className="min-w-full divide-y divide-gray-200">{children}</table>
);
const TableHeader: React.FC<TableHeaderProps> = ({ children }) => (
  <thead>{children}</thead>
);
const TableBody: React.FC<TableBodyProps> = ({ children }) => (
  <tbody className="divide-y divide-gray-200">{children}</tbody>
);
const TableRow: React.FC<TableRowProps> = ({ children }) => <tr>{children}</tr>;
const TableHead: React.FC<TableHeadProps> = ({ children, className }) => (
  <th
    className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 ${className}`}
  >
    {children}
  </th>
);
const TableCell: React.FC<TableCellProps> = ({ children, className }) => (
  <td className={`whitespace-nowrap px-6 py-4 ${className}`}>{children}</td>
);
    // Basic Input component
    const Input: React.FC<InputProps> = ({ className, ...props }) => (
      <input
        className={`focus:ring-2 focus:ring-blue-500 rounded border border-gray-300 px-4 py-2 outline-none focus:border-blue-500 ${className}`}
        {...props}
      />
    );
    // Icons
    const SearchIcon: React.FC = () => (
      <svg
        className="h-5 w-5 text-gray-400"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
      </svg>
    );
    


const PencilIcon: React.FC = () => (
  <svg
    className="h-4 w-4"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>
  </svg>
);

const TrashIcon: React.FC = () => (
  <svg
    className="h-4 w-4"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
  </svg>
);

export default function Team() {
  const members: Member[] = [
    {
      name: "Olivia Edu (You)",
      email: "oliviaedu@eduframes.com",
      role: "Owner",
      lastLogin: "Mon Jun 20, 2023 9:38 PM",
    },
    {
      name: "Peter Audu",
      email: "peteraudu@eduframes.com",
      role: "Admin",
      lastLogin: "Mon Jun 20, 2023 5:21 PM",
    },
    {
      name: "Rachael Ndidi",
      email: "rachaelndidi@eduframes.com",
      role: "Viewer",
      lastLogin: "Mon Jun 18, 2023 12:18 AM",
    },
    {
      name: "Rachael Ndidi (Pending invitation)",
      email: "rachaelndidi@eduframes.com",
      role: "Viewer",
      lastLogin: "",
    },
  ];

  return (
    <div className="shadow border border-gray-200 rounded-lg bg-white">
    <div className="border-b border-gray-200 p-4">
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-semibold">
          Members <span className="ml-1 text-sm text-gray-500">3</span>
        </h2>
        <div className="relative">
          <Input className="pl-10 pr-4" placeholder="Name, email" />
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <SearchIcon />
          </div>
        </div>
      </div>
    </div>
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-1/3">Name</TableHead>
          <TableHead className="w-1/3">Email</TableHead>
          <TableHead className="w-1/6">Roles</TableHead>
          <TableHead className="w-1/6">Last log in</TableHead>
          <TableHead>&nbsp;</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {members.map((member, index) => (
          <TableRow key={index}>
            <TableCell className="font-medium">{member.name}</TableCell>
            <TableCell>{member.email}</TableCell>
            <TableCell>{member.role}</TableCell>
            <TableCell>{member.lastLogin}</TableCell>
            <TableCell className="text-right">
              {index === 0 ? (
                <div className="flex  justify-end space-x-2">
                  <Button className="flex items-center rounded-lg border-2 border-hgray-300 text-gray-600 hover:text-gray-900">
                    <PencilIcon />
                    <span className="ml-1">Change owner</span>
                  </Button>
                </div>
              ) : (
                <div className="flex  justify-end space-x-2">
                  <Button className="flex items-center rounded-lg border-2 border-hgray-300 text-gray-600 hover:text-gray-900">
                    <PencilIcon />
                    <span className="ml-1">Change role</span>
                  </Button>
                  <Button className="hgray-300 flex items-center rounded-lg border-2 text-red-600 hover:text-red-800">
                    <TrashIcon />
                    <span className="ml-1">Remove</span>
                  </Button>
                </div>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </div>

  );
}
