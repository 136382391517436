import { Navigate, Route, Routes } from "react-router-dom";
import ResetLinkError from "../components/Common/errors/resetLinkError";
import Dashboard from "../components/Dashboard";
import AuthEmailVerification from "./auth/emailVerification";
import AuthLogin from "./auth/login";
import AuthLoginOtp from "./auth/loginOtp";
import AuthResetEmailSent from "./auth/resetEmailSent";
import AuthResetPassword from "./auth/resetPassword";
import AuthResetPasswordLink from "./auth/resetPasswordLink";
import AuthSignup from "./auth/signup";
import GetStarted from "./dashboard/get-started";
import DashAccountType from "./dashboard/get-started/account-type";
import HelpandFaq from "./dashboard/helpandfaq";
import Home from "./dashboard/home";
import { default as Accounts, default as PortalAccounts } from "./dashboard/portal-accounts";
// import Home from "./dashboard/";
import Kyc from "../components/kyc";
import AccountSettings from "./dashboard/account-settings";
import DashOnboardingBusinessDocs from "./dashboard/get-started/onboardingChecklist/business-docs-form";
import DashOnboardingBusinessInfo from "./dashboard/get-started/onboardingChecklist/business-info-form";
import DashOnboardingCompleteProfile from "./dashboard/get-started/onboardingChecklist/complete-profile-form";
import DashOnboardingCompleteUBOInfo from "./dashboard/get-started/onboardingChecklist/complete-ubo-info";
import DashOnboardingOwnershipInfo from "./dashboard/get-started/onboardingChecklist/ownershipInfo";
import DashOnboardingVerifyIdentity from "./dashboard/get-started/onboardingChecklist/verify-identity";
import WalletsConfimrSetupDetails from "./dashboard/get-started/onboardingChecklist/walletSetup/confirm-setup-setails";
import WalletsSetupHowItWorks from "./dashboard/get-started/onboardingChecklist/walletSetup/how-it-works";
import WalletsSetupAccounts from "./dashboard/get-started/onboardingChecklist/walletSetup/setup-accounts";
import WalletsSetupWallets from "./dashboard/get-started/onboardingChecklist/walletSetup/setupWallets";
import PortalInActivateAccounts from "./dashboard/portal-accounts/portal-in/portal-in-activate-account";
import PortalInBankAccounts from "./dashboard/portal-accounts/portal-in/portal-in-bank-details";
import PortalInSettlementInformation from "./dashboard/portal-accounts/portal-in/portal-in-settlement-info";
import PortalInAccounts from "./dashboard/portal-accounts/portal-in/portal-into-stablecoin";
import PortalOutSettlementInformation from "./dashboard/portal-accounts/portal-out/portal-out-settlemen-info";
import PortalOutAccounts from "./dashboard/portal-accounts/portal-out/portal-out-to-fiat";
import PortalOutWalletAccounts from "./dashboard/portal-accounts/portal-out/portal-out-wallet-account";
import PortalOutActivateWallets from "./dashboard/portal-accounts/portal-out/portal-out-wallet-activation";
import Teams from "./dashboard/teams";
import Transactions from "./dashboard/transactions";
import Wallets from "./dashboard/wallets";
import { ProtectedRoute, UnprotectedRoute } from "./isAuth";
import PortalOutDetailsCont from "./dashboard/portal-accounts/portal-out/portal-out-details";
import PortalInDetailsCont from "./dashboard/portal-accounts/portal-in/portal-in-details";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="login" replace />} />
      <Route
        path="/signup"
        element={
          <UnprotectedRoute>
            <AuthSignup />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/email-verification/:email"
        element={
          <UnprotectedRoute>
            <AuthEmailVerification />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/login"
        element={
          <UnprotectedRoute>
            <AuthLogin />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/login-verification/:email"
        element={
          <UnprotectedRoute>
            <AuthLoginOtp />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/reset-password-link"
        element={
          <UnprotectedRoute>
            <AuthResetPasswordLink />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/reset-email-sent/:email"
        element={
          <UnprotectedRoute>
            <AuthResetEmailSent />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/reset-link-error"
        element={
          <UnprotectedRoute>
            <ResetLinkError />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/reset-password"
        element={
          <UnprotectedRoute>
            <AuthResetPassword />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/get-started/account-type"
        element={
          <ProtectedRoute>
            <DashAccountType />
          </ProtectedRoute>
        }
      />
      <Route
        path="kyc/:userId"
        element={
          <UnprotectedRoute>
            <Kyc />
          </UnprotectedRoute>
        }
      />
      <Route
        path="kyc-link/:userId"
        element={
          <ProtectedRoute>
            <Kyc />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      >
        <Route path="" element={<Navigate to="get-started" replace />} />
        <Route path="get-started/*" element={<GetStarted />} />
        <Route path="home/*" element={<Home />} />
        <Route path="wallets/*" element={<Wallets />} />
        <Route path="portal-accounts/*" element={<Accounts />} />
        <Route path="portal-accounts/*" element={<PortalAccounts />} />
        <Route path="account-settings/*" element={<AccountSettings />} />
        <Route path="transactions/*" element={<Transactions />} />
        <Route path="teams/*" element={<Teams />} />
        <Route path="help-and-faq/*" element={<HelpandFaq />} />
      </Route>

          {/* Onboarding routes */}
      <Route
        path="/get-started/onboarding-checklist/complete-profile"
        element={
          <ProtectedRoute>
            <DashOnboardingCompleteProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/get-started/onboarding-checklist/business-information"
        element={
          <ProtectedRoute>
            <DashOnboardingBusinessInfo />
          </ProtectedRoute>
        }
      />
      <Route
        path="/get-started/onboarding-checklist/business-documents"
        element={
          <ProtectedRoute>
            <DashOnboardingBusinessDocs />
          </ProtectedRoute>
        }
      />

      <Route
        path="/get-started/onboarding-checklist/ownership-informations"
        element={
          <ProtectedRoute>
            <DashOnboardingOwnershipInfo />
          </ProtectedRoute>
        }
      />

      <Route
        path="/get-started/onboarding-checklist/verify-identity"
        element={
          <ProtectedRoute>
            <DashOnboardingVerifyIdentity />
          </ProtectedRoute>
        }
      />

<Route
        path="/get-started/onboarding-checklist/complete-ubo-information"
        element={
          <ProtectedRoute>
            <DashOnboardingCompleteUBOInfo />
          </ProtectedRoute>
        }
      />

      <Route
        path="/get-started/onboarding-checklist/wallets-setup/how-it-works"
        element={
          <ProtectedRoute>
            <WalletsSetupHowItWorks />
          </ProtectedRoute>
        }
      />

      <Route
        path="/get-started/onboarding-checklist/wallets-setup/setup-accounts"
        element={
          <ProtectedRoute>
            <WalletsSetupAccounts />
          </ProtectedRoute>
        }
      />

      <Route
        path="/get-started/onboarding-checklist/wallets-setup/setup-wallets"
        element={
          <ProtectedRoute>
            <WalletsSetupWallets />
          </ProtectedRoute>
        }
      />

      <Route
        path="/get-started/onboarding-checklist/wallets-setup/confirm-details"
        element={
          <ProtectedRoute>
            <WalletsConfimrSetupDetails />
          </ProtectedRoute>
        }
      />

      {/* Portal Accounts Route */}
      <Route path="/dashboard/portal-accounts/portal-into-stablecoin" element={<PortalInAccounts />}/>
      <Route path="/dashboard/portal-accounts/portal-out-to-fiat" element={<PortalOutAccounts />}/>
      <Route path="/dashboard/portal-accounts/portal-in-settlement-information" element={<PortalInSettlementInformation />}/>
      <Route path="/dashboard/portal-accounts/portal-out-settlement-information" element={<PortalOutSettlementInformation />}/>
      <Route path="/dashboard/portal-accounts/portal-in-activate-accounts" element={<PortalInActivateAccounts />}/>
      <Route path="/dashboard/portal-accounts/portal-out-activate-wallets" element={<PortalOutActivateWallets />}/>
      <Route path="/dashboard/portal-accounts/portal-in-bank-acccounts" element={<PortalInBankAccounts />}/>
      <Route path="/dashboard/portal-accounts/portal-out-wallet-acccounts" element={<PortalOutWalletAccounts />}/>
      <Route path="/dashboard/portal-accounts/portal-in-details" element={<PortalInDetailsCont />}/>
      <Route path="/dashboard/portal-accounts/portal-out-details" element={<PortalOutDetailsCont />}/>

    </Routes>
  );
};

export default AppRoutes;
