import {
  ArbitrumIcon,
  BaseIcon,
  CeloIcon,
  dollarMidSVG,
  dollarsSVG,
  EthereumIcon,
  euroDarker,
  euroDarkerMid,
  eurosSmallSVG,
  EuroToStable,
  GbpToStable,
  OptimismIcon,
  PolygonIcon,
  poundsMidSVG,
  poundsSmallSVG,
  poundsSVG,
  SolanaIcon,
  TronIcon,
  uSDCSVG,
  UsdToStable,
  UsdtSvg
} from "../components/Common/svg";



export const fiatSwapIcons = [
  {
    value: "EUR",
    label: "EUR",
    icon: (
      <div
        className="mr-1"
        dangerouslySetInnerHTML={{ __html: EuroToStable }}
      />
    ),
   },
   {
    value: "GBP",
    label: "GBP",
    icon: (
      <div
        className="mr-1"
        dangerouslySetInnerHTML={{ __html: GbpToStable }}
      />
    ),
   },  {
    value: "USD",
    label: "USD",
    icon: (
      <div
        className="mr-1"
        dangerouslySetInnerHTML={{ __html: UsdToStable }}
      />
    ),
   }
  ]


export const fiatOptions = [
  {
    value: "EUR",
    label: "EUR",
    icon: (
      <span
        className="mr-1 w-6"
        dangerouslySetInnerHTML={{ __html: euroDarker }}
      />
    ),
    iconSmall: (
      <span
        className="mr-1 w-4"
        dangerouslySetInnerHTML={{ __html: euroDarkerMid }}
      />
    ),
  },
  {
    value: "GBP",
    label: "GBP",
    icon: (
      <span
        className="mr-1 w-6"
        dangerouslySetInnerHTML={{ __html: poundsSVG }}
      />
    ),
    iconSmall: (
      <span
        className="mr-1 w-4"
        dangerouslySetInnerHTML={{ __html: poundsMidSVG }}
      />
    ),
  },
  {
    value: "USD",
    label: "USD",
    icon: (
      <span
        className="mr-1 w-6"
        dangerouslySetInnerHTML={{ __html: dollarsSVG }}
      />
    ),
    iconSmall: (
      <span
        className="mr-1 w-4"
        dangerouslySetInnerHTML={{ __html: dollarMidSVG }}
      />
    ),
  },
];


export const currencyOptions = [
  {
    value: "EUR",
    label: "EUR",
    icon: (
      <span
        className="mr-1 w-6"
        dangerouslySetInnerHTML={{ __html: eurosSmallSVG }}
      />
    ),
  },
  {
    value: "GBP",
    label: "GBP",
    icon: (
      <span
        className="mr-1 w-6"
        dangerouslySetInnerHTML={{ __html: poundsSmallSVG }}
      />
    ),
  },
];


export const tokenOptions = [
    {
      value: "usdc",
      label: "USDC",
      icon: (
        <span
          className="mr-1 w-6"
          dangerouslySetInnerHTML={{ __html: uSDCSVG }}
        />
      ),
    },
    {
      value: "usdt",
      label: "USDT",
      icon: (
        <span
          className="mr-1 w-6"
          dangerouslySetInnerHTML={{ __html: UsdtSvg }}
        />
      ),
    },
  ];

   export const chainOptions = [
    {
      value: "arbitrum",
      label: "Arbitrum",
      icon: (
        <span
          className="mr-1 w-6"
          dangerouslySetInnerHTML={{ __html: ArbitrumIcon }}
        />
      ),
    },
    {
      value: "base",
      label: "Base",
      icon: (
        <span
          className="mr-1 w-6"
          dangerouslySetInnerHTML={{ __html: BaseIcon }}
        />
      ),
    },
    {
      value: "celo",
      label: "Celo",
      icon: (
        <span
          className="mr-1 w-6"
          dangerouslySetInnerHTML={{ __html: CeloIcon }}
        />
      ),
    },
    {
      value: "mainnet",
      label: "Ethereum",
      icon: (
        <span
          className="mr-1 w-6"
          dangerouslySetInnerHTML={{ __html: EthereumIcon }}
        />
      ),
    },
    {
      value: "optimism",
      label: "Optimism",
      icon: (
        <span
          className="mr-1 w-6"
          dangerouslySetInnerHTML={{ __html: OptimismIcon }}
        />
      ),
    },
    {
      value: "polygon",
      label: "Polygon",
      icon: (
        <span
          className="mr-1 w-6"
          dangerouslySetInnerHTML={{ __html: PolygonIcon }}
        />
      ),
    },
    {
      value: "solana",
      label: "Solana",
      icon: (
        <span
          className="mr-1 w-6"
          dangerouslySetInnerHTML={{ __html: SolanaIcon }}
        />
      ),
    },
    {
      value: "tron",
      label: "Tron",
      icon: (
        <span
          className="mr-1 w-6"
          dangerouslySetInnerHTML={{ __html: TronIcon }}
        />
      ),
    },
  ];