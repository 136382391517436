import SetupAccounts from "../../../../../components/Dashboard/get-started/onboarding-checklist/walletsSetup/SetupAccounts";


  
function WalletsSetupAccounts() {
    return (
            <div>
                <SetupAccounts />
            </div>
    )
}

export default WalletsSetupAccounts;