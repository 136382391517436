import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { trpc } from "../../../../lib/trpc";
import DropDownWithIcon from "../../../Common/dropdown/DropDownWithIcon";
import Inputs from "../../../Common/inputs";
import MainLoader from "../../../Common/loader/mainLoader";
import SidePopup from "../../../Common/popup/sidepopup";
import { closeBtnSVG, walletsIconSVG } from "../../../Common/svg";

import { chainOptions, tokenOptions } from "../../../../constant/options";
import { useModal } from "../../../../context/modalProvider";
import {
  SetupWalletSchema,
  setupWalletSchema,
} from "../../get-started/onboarding-checklist/schema";
import { Chain } from "../../get-started/onboarding-checklist/walletsSetup/types";
import PortalInSetup from "./index";

const PortalInSettlementInfo: FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showEditWalletPopup, setShowEditWalletPopup] = useState(false);
  const { showModal } = useModal();

  const navigate = useNavigate();
  const utils = trpc.useUtils();

  const { mutate: setUpWallet, isLoading: isSettingUpWallet } =
    trpc.user.setUpWallet.useMutation({
      onSuccess: () => {
        reset();
        utils.user.getWallet.refetch();
        setShowPopup(false);
      },
      onError: (e) => {
        console.log(e);
        toast.error(e.message);
      },
    });

  const { mutateAsync: getSession, isLoading: isLoadingGettingSession } =
    trpc.user.isSessionIdExpired.useMutation({
      onError: (e) => {
        toast.error(e.message);
      },
    });

  const { mutateAsync: triggerOtp } = trpc.user.triggerOtp.useMutation({
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const { mutate: UpdateWallet, isLoading: isUpdatingWallet } =
    trpc.user.UpdateWallet.useMutation({
      onSuccess: () => {
        reset();
        utils.user.getWallet.refetch();
        closeShowPopup();
      },
      onError: (e) => {
        console.log(e);
        toast.error(e.message);
      },
    });

  const { data, isLoading } = trpc.user.getWallet.useQuery({});

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  const methods = useForm<SetupWalletSchema>({
    resolver: zodResolver(setupWalletSchema),
    defaultValues: {
      chain: undefined,
      token: "",
      walletAddress: "",
      confirmWalletAddress: "",
    },
  });

  const {
    control,
    register,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  function closeShowPopup() {
    if (showPopup === true) {
      setShowPopup(false);
    } else {
      setShowEditWalletPopup(false);
    }
    reset();
  }

  const onSetUpWallet = async (data: SetupWalletSchema) => {
    try {
      const response = await getSession({});
      if (response.sessionIdHasExpired) {
        await triggerOtp({});
        showModal();
      } else {
        if (showEditWalletPopup) {
          UpdateWallet({ ...data, currency: "GBP" });
        } else {
          setUpWallet({ ...data, currency: "GBP" });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (showEditWalletPopup && data) {
      const walletData = data[0];
      // Populate form fields with fetched data
      if (walletData) {
        setValue("chain", walletData?.chain as Chain);
        setValue("token", walletData.token || "");
        setValue("walletAddress", walletData.walletAddress || "");
        setValue("confirmWalletAddress", walletData.walletAddress || "");
      }
    }
  }, [showEditWalletPopup, data, setValue]);

  const selectedToken = useWatch({ control, name: "token" });
  const [filteredChainOptions, setFilteredChainOptions] =
    useState(chainOptions);

  // Filter/sort chain options based on selected token
  useEffect(() => {
    if (selectedToken === "usdc") {
      setFilteredChainOptions(
        chainOptions.filter(
          (option) => !["tron", "celo"].includes(option.value)
        )
      ); // Example
    } else if (selectedToken === "usdt") {
      setFilteredChainOptions(
        chainOptions.filter(
          (option) => !["base", "celo"].includes(option.value)
        )
      ); // Example
    }
  }, [selectedToken]);

  if (isLoading) {
    return <MainLoader />;
  }

  return (
    <PortalInSetup currentStep={2} complete={false}>
      <div className="setupAccounts hide-scrollbar mt-12 h-full w-full overflow-y-scroll">
        <div className="accounts-top flex h-full flex-col items-center justify-between pt-3">
          <div className="w-[795px] pb-5">
            <div className="top-banner flex w-full items-center justify-between rounded-t-2xl bg-hgray-50 p-6 shadow-s-light">
              <div className="w-[70.5%]">
                <h3 className="text-[22px] font-semibold text-htext-main">
                  Add your stablecoin wallets
                </h3>
                <p className="mt-2 text-sm font-normal text-htext-subdued">
                  Add your stablecoin wallet address (Ethereum or Solana) where
                  you want to recieve to receive stablecoins when you send
                  USD/GBP/EUR to your named virtual account.
                </p>
                <div className="mt-6 flex items-center">
                  <p className="text-[10.5px] font-normal text-htext-subdued">
                    SMARTCHAINS SUPPORTED:
                  </p>
                  <img
                    className="ml-1.5"
                    src="/assets/stablecoins-icon.svg"
                    alt="Stablecoin logos"
                  />
                </div>
              </div>

              <div>
                <button
                  className="flex w-[170px] items-center justify-center rounded-lg border border-solid border-hbrand-600 bg-white px-[10px] py-[9.3px] text-[15px] font-semibold text-hbrand-700 shadow-s-light hover:bg-hbrand-600 hover:text-white"
                  type="button"
                  onClick={() => setShowPopup(true)}
                >
                  <span
                    className="w-18 mr-2"
                    dangerouslySetInnerHTML={{ __html: walletsIconSVG }}
                  />
                  Add wallet
                </button>
              </div>
            </div>

            <div className="form-content rounded-b-[16px] bg-white">
              <fieldset>
                <div className="flex h-full w-full flex-col items-center justify-between">
                  {data?.map(({ chain, id, token, walletAddress }) => (
                    <div
                      key={id}
                      className="mx-auto w-[795px] rounded-b-2xl bg-hgray-50 px-6 pb-6 pt-1"
                    >
                      <div className="border-t border-t-hgray-200">
                        <div className="mt-6 flex w-full items-center justify-between text-[13px] font-semibold">
                          <p className="text-htext-label">WALLETS ADDED</p>
                        </div>

                        <div className="mt-3.5 flex w-full flex-col items-center gap-y-7 rounded-lg bg-hgray-100 p-[17px]">
                          <div className="flex w-full items-start pt-[8px]">
                            <div className="w-1/3 text-[13px] text-htext-main">
                              <p className="mb-1.5 font-normal">Currency</p>
                              <h5 className="flex items-center font-semibold">
                                {
                                  tokenOptions?.find(
                                    ({ value }) => value == token
                                  )?.icon
                                }
                                {token.toUpperCase()}
                              </h5>
                            </div>

                            <div className="w-1/3 text-[13px] text-htext-main">
                              <p className="mb-1.5 font-normal">Smartchain</p>
                              <h5 className="flex items-center font-semibold">
                                {
                                  chainOptions?.find(
                                    ({ value }) => value == chain
                                  )?.icon
                                }
                                {chain.charAt(0).toUpperCase() + chain.slice(1)}
                              </h5>
                            </div>

                            <div className="w-1/3 text-[13px] text-htext-main">
                              <p className="mb-1.5 font-normal">
                                Wallet address
                              </p>
                              <h5 className="flex items-center break-all font-semibold">
                                {walletAddress}
                              </h5>
                            </div>
                          </div>

                          <div className="mt-2 flex w-full items-center justify-between border-t border-t-hgray-200 pt-3">
                            <div className="toggle-cont flex items-center gap-x-2">
                              <div className="relative inline-block h-[18px] w-8 select-none align-middle transition duration-200 ease-in">
                                <input
                                  type="checkbox"
                                  name="toggle"
                                  id="toggle"
                                  className="toggle-checkbox absolute ml-[1.5px] mt-[2px] block h-[14px] w-[14px] cursor-pointer appearance-none rounded-full border-4 border-white bg-white"
                                />
                                <label
                                  htmlFor="toggle"
                                  className="toggle-label block h-[18px] cursor-pointer overflow-hidden rounded-full bg-hbrand-700"
                                ></label>
                              </div>
                              <p className="text-[13.5px] font-normal text-htext-subdued">
                                Set as default settlement wallet
                              </p>
                            </div>

                            <button
                              type="button"
                              className="flex items-center text-[13.5px] font-semibold text-hbrand-700 hover:text-hbrand-600"
                              onClick={() => setShowEditWalletPopup(true)}
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>
          </div>

          <div
            className={`form-btns flex h-fit w-full items-center justify-between border-t border-t-hgray-200 px-14 pb-1 pt-6`}
          >
            <button
              className="w-[150px] rounded-lg border  border-solid border-hgray-300 bg-white px-[10px] py-[13px] text-[15px] font-semibold text-hgray-700 shadow-s-light hover:bg-hgray-50"
              onClick={() =>
                navigate("/dashboard/portal-accounts/portal-into-stablecoin")
              }
            >
              Go Back
            </button>

            <button
              className="w-[250px] rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[13px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
              type="button"
              onClick={() =>
                handleNavigate(
                  "/dashboard/portal-accounts/portal-in-activate-accounts"
                )
              }
            >
              View portal information
            </button>
          </div>
        </div>
      </div>
      {(showPopup || showEditWalletPopup) && (
        <SidePopup
          className="signup-popup"
          popClass="pt-[1.3rem] pb-[1.5rem] w-[38rem]"
        >
          <div className="popup-content-bg flex h-full flex-col items-center justify-between">
            <div className="content-top w-full">
              <div className="content-nav flex w-full items-start justify-between px-[1.5rem]">
                <div>
                  <h3 className="text-[18px] font-semibold text-htext-main">
                    Add your stablecoin wallet
                  </h3>
                  <p className="mt-1.5 text-[12.5px] font-normal text-htext-subdued">
                    This is your stablecoin wallet
                  </p>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => closeShowPopup()}
                >
                  <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: closeBtnSVG }}
                  />
                </div>
              </div>

              <FormProvider {...methods}>
                <form
                  className="h-[92vh]"
                  onSubmit={methods.handleSubmit(onSetUpWallet)}
                >
                  <fieldset className="h-full">
                    <div className="form-div flex h-full w-full flex-col items-center justify-between">
                      <div className="mt-8 w-full px-[1.5rem] pb-2">
                        <div
                          className={`flex w-full items-start justify-between ${
                            errors.token || errors.chain
                              ? "mt-[.80rem] h-24"
                              : "mt-[1.30rem]"
                          }`}
                        >
                          <label
                            htmlFor="stablecoin"
                            className="relative h-full w-[48.5%]"
                          >
                            <div className="mb-1.5 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>
                              <p className="text-htext-label">Stablecoin</p>
                            </div>
                            <DropDownWithIcon
                              register={register}
                              name="token"
                              options={tokenOptions}
                              error={errors.token}
                            />
                            {errors.token && (
                              <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                {errors.token.message}!
                              </p>
                            )}
                          </label>

                          <label
                            htmlFor="smartchain"
                            className="relative h-full w-[48.5%]"
                          >
                            <div className="mb-1.5 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>

                              <p className="text-htext-label">Smartchain</p>
                            </div>

                            <DropDownWithIcon
                              register={register}
                              name="chain"
                              options={filteredChainOptions}
                              error={errors.chain}
                            />
                            {errors.chain && (
                              <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                {errors.chain.message}!
                              </p>
                            )}
                          </label>
                        </div>

                        <div
                          className={`mx-auto flex w-full flex-col pb-6  pt-1 ${
                            errors.walletAddress || errors.confirmWalletAddress
                              ? "h-48 justify-start"
                              : ""
                          }`}
                        >
                          <label
                            htmlFor="walletAddress"
                            className={`${
                              errors.walletAddress
                                ? "mt-[.50rem]"
                                : "mt-[1.30rem]"
                            } w-full"`}
                          >
                            <div className="mb-1 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>

                              <p className="text-htext-label">Wallet address</p>
                            </div>
                            <Inputs
                              type="text"
                              placeholder="Enter here"
                              register={register("walletAddress")}
                              inputClass={true}
                              error={errors.walletAddress}
                            />
                            {errors.walletAddress && (
                              <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                {errors.walletAddress.message}!
                              </p>
                            )}
                          </label>

                          <label
                            htmlFor="confirmWalletAddress"
                            className={`${
                              errors.confirmWalletAddress
                                ? "mt-[2.3rem]"
                                : "mt-[1.30rem]"
                            } w-full"`}
                          >
                            <div className="mb-1 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>

                              <p className="text-htext-label">
                                Confirm wallet address
                              </p>
                            </div>
                            <Inputs
                              type="text"
                              placeholder="Enter here"
                              register={register("confirmWalletAddress")}
                              inputClass={true}
                              error={errors.confirmWalletAddress}
                            />
                            {errors.confirmWalletAddress && (
                              <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                {errors.confirmWalletAddress.message}!
                              </p>
                            )}
                          </label>
                        </div>
                      </div>

                      <div className="pop-btns flex h-fit w-full items-center justify-end border-t border-hgray-200 px-[1.5rem] pb-5 pt-7">
                        <button
                          type="button"
                          onClick={closeShowPopup}
                          className="shadow-s-light] mr-[5px] rounded-lg border border-hgray-300 bg-white px-3.5 py-2.5 text-[15px] font-semibold text-hgray-700 hover:opacity-70"
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          // disabled={!isFormData}
                          //  onClick={() => handleNavigate("/login")}
                          className="ml-[5px] rounded-lg border border-hbrand-600 bg-hbrand-600 px-12 py-2.5 text-[15px] font-semibold text-white shadow-s-light hover:opacity-80"
                        >
                          {isSettingUpWallet ||
                          isUpdatingWallet ||
                          isLoadingGettingSession ? (
                            <span className="loading loading-spinner loading-sm"></span>
                          ) : (
                            "Save wallet details"
                          )}
                        </button>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </FormProvider>
            </div>
          </div>
        </SidePopup>
      )}
    </PortalInSetup>
  );
};

export default PortalInSettlementInfo;
