import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";
import { trpc } from "../../../lib/trpc";
import { UserAccountStatus } from "../../../route/dashboard/transactions";
import { formatDate2 } from "../../../utils";
import CommonDropdown from "../../Common/dropdown/CommonDrop";
import EasyDateDrop from "../../Common/dropdown/EasyDateDrop";
import FileUpload from "../../Common/inputs/fileUpload";
import Select from "../../Common/inputs/select";
import Pagination from "../../Common/pagination";
import SidePopup from "../../Common/popup/sidepopup";
import {
  BlueThinArrowSVG,
  closeBtnSmallSVG,
  closeBtnSVG,
  LeftDropArrowSVG,
  SearchSVG,
} from "../../Common/svg";
import { Table } from "../../Common/table";
import ViewTransaction from "./viewTransaction";

const columns = [
  {
    label: "Date",
    key: "createdAt",
    format: (date: string) => formatDate2(date),
  },
  { label: "Status", key: "status" },
  {
    label: "Type",
    key: "direction",
    format: (direction: string) =>
      direction === "cryptoToFiat" ? "Portal Out" : "Portal In",
  },
  {
    label: "Conversion",
    key: { currencyCrypto: "currencyCrypto", currencyFiat: "currencyFiat" },
    format: (
      value: { currencyCrypto: string; currencyFiat: string },
      row: any
    ) => {
      if (!value.currencyCrypto || !value.currencyFiat) {
        return 'Processing';
      }
    
      const cryptoCurrency = value.currencyCrypto.toUpperCase();
      const fiatCurrency = value.currencyFiat.toUpperCase();
    
      return row.direction === "cryptoToFiat"
        ? `${cryptoCurrency} to ${fiatCurrency}`
        : `${fiatCurrency} to ${cryptoCurrency}`;
    },
  },
  {
    label: "Input amount",
    key: "amountCrypto", // Depending on the conversion type, this could be either crypto or fiat
    format: (_: string, row: any) =>
      row.direction === "cryptoToFiat" ? row.amountCrypto : row.amountFiat,
  },
  {
    label: "Output amount",
    key: "amountFiat",
    format: (_: string, row: any) =>
      row.direction === "cryptoToFiat" ? row.amountFiat : row.amountCrypto,
  }, // Added amountSent
];

export type queryType = "user" | "company";

type queryObjectTypes = {
  page: number;
  limit: number;
  id: number;
  search: string;
  currency: string;
  direction: string;
  dateFrom: string;
  dateTo: string;
  ype: queryType;
};

const currency = ["GBP", "USD", "EUR"];
const portalType = ["cryptoToFiat", "fiatToCrypto"];

const sof = ["Transaction issue", "Product inquiry", "Complaints and feedback"];

const contactUsSchema = z.object({
  issue: z.string().min(1, "Issue type is required"),
  message: z.string().min(1, "Description type is required"),
  uploadImage: z.string(),
});

export type ContactUsSchema = z.infer<typeof contactUsSchema>;

const DashTransaction = ({ userData }: { userData: Pick<UserAccountStatus, "acountType" | "corporateId" | "userId"> }) => {
  const id = userData?.corporateId ? userData?.corporateId : userData?.userId;
  const userType = userData?.corporateId ? "company" : "user"

  const [searchValue, setSearchValue] = useState("");

  const [showTransactionPopup, setShowTransactionPopup] = useState(false);
  const [showContactusPopup, setShowContactusPopup] = useState(false);

  const [selectedTransactionId, setSelectedTransactionId] = useState<
    string | null
  >(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [queryObject, setQueryObject] = useState({
    page: currentPage,
    limit: 10,
    id: id as number, //userId if the person is not a ubo and corporateid if ubo
    search: "",
    currency: "",
    direction: "",
    dateFrom: "",
    dateTo: "",
    type: userType as queryType,
  });

  // direction: 'cryptoToFiat' || 'fiatToCrypto',

  const isEmpty = (data: any) => Array.isArray(data) && data.length === 0;

  const {
    data: userTransactions,
    isLoading,
    isFetching,
  } = trpc.transaction.transaction.useQuery(queryObject)

  const { reset, watch, setValue } = useForm({
    defaultValues: {
      search: "",
      currency: "",
      portalType: "",
      dateFrom: "",
      dateTo: "",
    },
  });

  const updateQueryParams = (params: Partial<queryObjectTypes>) => {
    setQueryObject((prev) => ({ ...prev, ...params }));
  };

  function closeShowPopup() {
    setShowTransactionPopup(false);
    setShowContactusPopup(false);
    setSelectedTransactionId(null);
  }

  function contactUs() {
    if (showTransactionPopup) {
      setShowTransactionPopup(false);
    }
    setShowContactusPopup(true);
  }

  function handleShowTransactionPopup(show: boolean, id?: string) {
    setSelectedTransactionId(id ?? null); // Store the ID
    setShowTransactionPopup(show);
  }

  function handlePageChange(page: number) {
    setCurrentPage(page);
    updateQueryParams({ page: page });
  }

  const handleSearch = (value: string) => {
    setSearchValue(value);
    updateQueryParams({ search: value });
  };

  const handleClearFilter = () => {
    reset(); // This resets the form to its default values
    setSearchValue("");
    updateQueryParams({
      search: "",
      currency: "",
      direction: "",
      dateFrom: "",
      dateTo: "",
      page: 1,
      limit: 10,
    });
  };

  const { mutate: sendContactUsMsg, isLoading: isSendingMsg } =
    trpc.user.customerContactUs.useMutation({
      onSuccess: () => {
        setShowContactusPopup(false);
      },
    });

  const onSave = (data: ContactUsSchema) => {
    sendContactUsMsg(data);
  };

  const methods = useForm({
    resolver: zodResolver(contactUsSchema),
    defaultValues: {
      issue: "",
      message: "",
      uploadImage: "",
    },
  });

  const {
    register: registerIssue,
    handleSubmit: handleIssueSubmit,
    formState: { errors },
  } = methods;

  return (
    <div className="dashTransaction">
      <section className="dashTransaction_transactions">
        <form
          className="transactions-filter relative mb-[20px] flex w-full items-center justify-between rounded-[12px] bg-[#F9FAFB] px-4 py-3"
        >
          <div className="flex items-center space-x-[15px]">
            <div className="search flex w-[180px] items-center rounded-lg border border-solid border-hgray-300 bg-white px-2 shadow-s-light">
              <span
                className="mr-1.5"
                dangerouslySetInnerHTML={{ __html: SearchSVG }}
              />
              <input
                type="text"
                placeholder="amount, address"
                value={searchValue}
                onChange={(e) => handleSearch(e.target.value)}
                className="text-ellipsis rounded-lg border-0 py-[10.5px] text-[13px] font-normal placeholder:text-htext-placeholder focus:outline-none"
              />
            </div>
            <div className="currency w-[125px] rounded-lg border border-solid border-hgray-300 bg-white px-[14px] py-[7.5px] shadow-s-light">
              <CommonDropdown
                dropClass="currency"
                dropdownItems={currency}
                disabledClass="Currency"
                defaultSelectedItem={watch("currency")} // Watch the currency value
                onSelect={(item) => {
                  setValue("currency", item);
                  updateQueryParams({ currency: item });
                }}
                svgIcon={BlueThinArrowSVG}
              />
            </div>

            <div className="portalType w-[145px] rounded-lg border border-solid border-hgray-300 bg-white py-[7.5px] pl-[14px] pr-[12px] shadow-s-light">
              <CommonDropdown
                dropClass="portalType"
                dropdownItems={portalType}
                disabledClass="Portal Type"
                defaultSelectedItem={watch("portalType")} // Watch the portalType value
                onSelect={(item) => {
                  setValue("portalType", item);
                  updateQueryParams({ direction: item });
                }}
                svgIcon={BlueThinArrowSVG}
              />
            </div>

            <div className="date w-[160px] rounded-lg border border-solid border-hgray-300 bg-white py-[7.5px] shadow-s-light">
              <EasyDateDrop
                startDate={watch("dateFrom") ? new Date(watch("dateFrom")) : null}
                endDate={watch("dateTo") ? new Date(watch("dateTo")) : null}
                
                onSelect={(item) => {
                  const startDate = item[0] ? format(item[0], "yyyy-MM-dd") : "";
                  const endDate = item[1] ? format(item[1], "yyyy-MM-dd") : "";
              
                  // Only update query params if both startDate and endDate are selected
                  if (startDate && endDate) {
                    setValue("dateFrom", startDate);
                    setValue("dateTo", endDate);
                    updateQueryParams({
                      dateFrom: startDate,
                      dateTo: endDate,
                    });
                  }
                }}
              />
            </div>
          </div>

          <button type="button" onClick={() => handleClearFilter()}>
          <div
            className="clear-filter flex cursor-pointer items-center rounded px-3 py-1 hover:bg-hgray-100"
          >
              
            <span
              className="mr-2.5"
              dangerouslySetInnerHTML={{ __html: closeBtnSmallSVG }}
              />
            <p className="text-[13px] font-medium text-hgray-400">
              Clear filter
            </p>
          </div>
              </button>
        </form>
      </section>

      <section>
        <div className="relative mb-5">
          {isLoading || isFetching ? (
            <div className="absolute inset-0 z-10 flex items-center justify-center bg-white bg-opacity-50">
              <div className="loader"></div> {/* Add your loader here */}
            </div>
          ) : null}

          <div
            className={`relative ${
              isLoading || isFetching
                ? "opacity-50"
                : "rounded-[12px] border border-solid border-hgray-200 shadow-s-semi-light"
            }`}
          >
            {isEmpty(userTransactions?.data.data) ? (
              <div className="flex flex-col items-center py-32">
                <img src="/recent-t.svg" alt="empty recent transactions" />
                <h5 className="mt-4 text-center text-[15px] font-semibold">
                  No transaction history
                </h5>
                <p className="mt-1 w-[250px] text-center text-[12px] font-normal text-htext-subdued">
                  Once you start making payments, you can keep track of your
                  transactions here
                </p>
              </div>
            ) : (
              <>
                <Table
                  columns={columns}
                  data={userTransactions?.data.data || []}
                  tableClass={true}
                  setShowTransactionPopup={handleShowTransactionPopup}
                />
                <Pagination
                  currentPage={currentPage}
                  totalPages={userTransactions?.data.meta.totalPages || 1} // Update this based on your data structure
                  onPageChange={handlePageChange}
                />
              </>
            )}
          </div>
        </div>
      </section>
      {showTransactionPopup && selectedTransactionId && (
        <div className="absolute z-50 ">
          <SidePopup
            className="dashhome-side-popup"
            popClass="pt-[1.3rem]  px-8 w-[37rem]"
          >
            <ViewTransaction
              closeShowPopup={closeShowPopup}
              selectedTransactionId={selectedTransactionId}
              customerId={id}
              userType={userType}
              contactUs={contactUs}
            />
          </SidePopup>
        </div>
      )}

      {showContactusPopup && (
        <div className="absolute z-50">
          <SidePopup
            className="dashhome-side-popup"
            popClass="pt-[1.3rem]  px-8 w-[34.5rem]"
          >
            <div className="popup-content-bg flex h-full w-full flex-col items-center justify-between text-htext-main">
              <div className="content-bg-wrapper w-full">
                <div className="content-nav flex w-full items-center justify-between">
                  <div className="flex cursor-pointer items-center gap-x-3 text-hbrand-700 hover:text-hbrand-500">
                    <span
                      className="text-[#475467] hover:text-hbrand-500"
                      dangerouslySetInnerHTML={{ __html: LeftDropArrowSVG }}
                    />
                    <h5 className="text-[14px] font-semibold">Go back</h5>
                  </div>
                  <div
                    className="relative h-[25px] w-[25px] cursor-pointer rounded-full hover:bg-hgray-50"
                    onClick={() => closeShowPopup()}
                  >
                    <span
                      className="absolute -ml-[10.5px] -mt-[10px]"
                      dangerouslySetInnerHTML={{ __html: closeBtnSVG }}
                    />
                  </div>
                </div>

                <div className="content-content mt-9 w-full h-full">
                  <div className="con-top">
                    <h5 className="text-[18.5px] font-semibold text-htext-main">
                      Get in touch
                    </h5>
                    <p className="mt-2 text-[13.3px] font-normal text-htext-subdued">
                      We typically respond within 24 hrs
                    </p>
                  </div>

                  <FormProvider {...methods}>
                    <form
                      className="mt-11 h-full w-full"
                      onSubmit={handleIssueSubmit(onSave)}
                    >
                      <fieldset className="flex flex-col h-full w-full items-center justify-between">
                        <div className="w-full">
                          <div>
                        <label htmlFor="issue"
                          className="relative h-full w-full"
                        >
                          <div className="mb-1.5 flex items-center text-[13.5px] font-medium">
                            <p className="text-hgray-700">Issue Category</p>
                          </div>
                          <Select
                            id="issue"
                            register={registerIssue("issue")}
                            inputClass={true}
                            optionsItem={sof}
                            error={errors?.issue}
                          />
                        </label>
                          {errors?.issue && (
                            <p className="font-ibm-plex absolute mt-1 text-[11.5px] font-medium text-red-600">
                              {errors?.issue.message}!
                            </p>
                          )}
                          </div>

                        <div className="mt-5">
                          <label
                            htmlFor="message"
                            className="relative h-full w-full"
                          >
                            <div className="mb-1.5 flex items-center text-[13.5px] font-medium">
                              <p className="text-hgray-700">Description</p>
                            </div>
                            <textarea
                              id="message"
                              maxLength={1200}
                              rows={5.7}
                              className={`w-full rounded-lg border-solid px-4 py-3 text-[14.5px] font-normal text-hgray-500 shadow-s-light ${errors?.message ? "border-[3px] border-herror-600" : "border border-hgray-300"}`}
                              style={{ resize: "none" }}
                              placeholder="Enter a description..."
                              {...registerIssue("message")}
                            />
                          </label>

                            {errors.message && (
                              <p className="font-ibm-plex absolute mt-1 text-[11.5px] font-medium text-red-600">
                                {errors.message.message}!
                              </p>
                            )}
                        </div>

                        <div className="mt-5">
                          <label htmlFor="uploadImage" className="w-full">
                            <div className="mb-1 flex items-center text-[13.5px] font-medium">
                              <p className="text-hgray-700">
                                Upload attachment
                              </p>
                            </div>
                            <FileUpload
                              name="uploadImage"
                              error={errors?.["uploadImage"]}
                            />
                            {errors?.["uploadImage"] && (
                              <p className="font-ibm-plex absolute mt-1 text-[13px] font-medium text-red-600">
                                {errors?.["uploadImage"]?.message as string}
                              </p>
                            )}
                            {errors.uploadImage && (
                              <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                {errors.uploadImage.message}!
                              </p>
                            )}
                          </label>
                        </div>
                        </div>

                          <div className="content-footer -mt-2 flex w-full items-center justify-end gap-x-3 border-t border-t-hgray-200 pb-6 pt-6">
                            <button
                              type="button"
                              onClick={() => closeShowPopup()}
                              className="rounded-lg border border-hgray-300 bg-white px-5 py-2.5 text-[15px] font-semibold text-hgray-700 shadow-s-light  hover:bg-hgray-100"
                            >
                              Close
                            </button>
                            <button
                              type="submit"
                              className="rounded-lg border border-hbrand-600 bg-hbrand-600 px-24 py-2.5 text-[15px] font-semibold text-white shadow-s-light hover:border-hbrand-700 hover:bg-hbrand-700"
                            >
                              {isSendingMsg ? (
                                <span className="loading loading-spinner loading-sm"></span>
                              ) : (
                                "Send"
                              )}
                            </button>
                          </div>
                      </fieldset>
                    </form>
                  </FormProvider>
                </div>
              </div>
            </div>
          </SidePopup>
        </div>
      )}
    </div>
  );
}

export default DashTransaction;