import React, { FC } from "react";

interface SidePopupContentProps {
  className?: string;
  popClass?: string;
  children?: React.ReactNode;
  closePopup?: () => void;
}

const SidePopup: FC<SidePopupContentProps> = ({ className, popClass, children, closePopup }) => {
  return (
    <div
      className={`popupContainer fixed left-0 top-0 z-[11] h-screen w-screen overflow-hidden bg-hgray-900/[0.8] backdrop-blur-[0.4px] ${className}`}
    >
      <div
        className="scrollContainer h-full w-full"
        onClick={closePopup ? closePopup : undefined}
      >
        <div className={`popupContent hide-scrollbar absolute top-0 bottom-0 right-0 overflow-y-scroll h-full bg-white ${popClass}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default SidePopup;
