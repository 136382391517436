import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import MainLoader from "../../../../components/Common/loader/mainLoader";
import { trpc } from "../../../../lib/trpc";
import {
  arrowRight,
  resendSVG,
  warnAvatarSVG,
} from "../../../Common/svg/index";

const VerifyIdentity: FC = () => {
  const { data, isLoading } = trpc.user.getUserById.useQuery({});
  const { mutate } = trpc.user.verifyIdentity.useMutation({});

  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    navigate(route); // Replace '/new-route' with your desired route
  };

  const handleGoBack = () => {
    navigate("/dashboard/get-started");
  };

  const methods = useForm({
    defaultValues: {},
  });

  if (isLoading) {
    return <MainLoader />;
  }

  return (
    <div className="pform h-screen w-screen bg-white pb-2 pt-4">
      <div className="pform-content h-full w-full overflow-hidden">
        <div className="content-nav mt-5 flex items-center px-40">
          <p className="text-[13px] font-medium text-htext-label">
            Onboarding checklist
          </p>
          <span
            className="mx-4"
            dangerouslySetInnerHTML={{ __html: arrowRight }}
          />
          <p className="text-[13px] font-semibold text-hbrand-700">
            Verify your identity
          </p>
        </div>

        <div className="content-form mx-auto h-full w-full pb-40 ">
          <div className="form-header mx-auto mt-14 w-[800px] pb-8">
            <div>
              <h2 className="text-[28px] font-semibold tracking-[-0.72px] text-htext-main">
                Verify your identity
              </h2>
            </div>
          </div>
          <div className="hide-scrollbar overflow-none h-full w-full pb-2">
            <div className="header-header mx-auto w-[800px] rounded-t-[12px] border border-b-0 border-hgray-100 bg-hgray-50 px-5 pb-4 pt-5">
              <h4 className="text-[18px] font-semibold text-htext-main">
                We send a verification link to your email
              </h4>
              <p className="mt-2 text-[13px] font-normal text-htext-subdued">
                Check your spam or promotions folder too
              </p>
            </div>

            <FormProvider {...methods}>
              <form className="form-content h-full w-full rounded-b-[16px] bg-white">
                <fieldset>
                  <div className="mx-auto w-[800px] rounded-b-[12px] border border-t-0 border-hgray-100 px-6 pb-1 pt-1">
                    <div className="mt-6">
                      <div className="mb-6 flex items-center justify-between rounded-lg bg-hgray-50 px-6 py-6">
                        <div className="flex items-start text-hwarning-600">
                          <span
                            className="w-18"
                            dangerouslySetInnerHTML={{ __html: warnAvatarSVG }}
                          />
                          <div className="ml-3.5 flex flex-col items-start justify-center">
                            <p className="text-[14px] font-medium text-htext-main">{`${data?.firstName} ${data?.lastName}`}</p>
                            <p className="my-1.5 flex items-center text-[14px] font-normal text-htext-main">
                              {data?.phoneNumber}{" "}
                              <b className="mx-1 mt-[-6px] h-auto w-auto text-[15px] font-bold">
                                .
                              </b>{" "}
                              {data?.email}
                            </p>
                            <p className="text-[12px] font-normal text-htext-subdued">
                              Link sent 2 mins ago
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center space-x-2">
                          <div className="rounded-[6px] border border-hwarning-200 bg-hwarning-50 px-1.5 py-[0.05rem]">
                            <p className="text-[12px] font-medium capitalize text-hwarning-700">
                              {data?.kycstatus?.replaceAll("_", " ")}
                            </p>
                          </div>
                          {data?.kycstatus !== "FULL_USER" && <button
                            className="hover:text-hwarning-800 flex items-center text-[12px] font-medium text-hwarning-700"
                            onClick={() => mutate({})}
                          >
                            <span
                              className="mr-1 h-5 w-5"
                              dangerouslySetInnerHTML={{ __html: resendSVG }}
                            />
                            Resend
                          </button>}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-btns mt-[16.3rem] flex h-full w-full items-center justify-between border-t border-t-hgray-200 px-14 pb-7 pt-6">
                    <button
                      className="w-[150px] rounded-lg border  border-solid border-hgray-300 bg-white px-[10px] py-[13px] text-[15px] font-semibold text-hgray-700 shadow-s-light hover:bg-hgray-50"
                      type="button"
                      onClick={() => handleGoBack()}
                    >
                      Go Back
                    </button>

                    <button
                      className="w-[220px] rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[13px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
                      type="button"
                      onClick={() => handleNavigate("/dashboard/get-started")}
                    >
                      Okay
                    </button>
                  </div>
                </fieldset>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyIdentity;
