import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { trpc } from "../../lib/trpc";
import { signupSchema, SignupSchema } from "../../utils/validationSchema";
import Inputs from "../Common/inputs";
import Popup from "../Common/popup";
import {
  EmailSVG,
  errorColonSVG,
  HidePassSVG,
  ShowPassSVG,
  successTickSVG,
} from "../Common/svg";

const Signup: FC = () => {
  const navigate = useNavigate();

  const [userEmail, setUserEmail] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showExistsPopup, setShowExistsPopup] = useState(false);
  const [isUboInvite] = useState(false);

  const handleNavigate = (route: string) => {
    navigate(route); // Replace '/new-route' with your desired route
  };

  function closeExistsPopup() {
    if (showExistsPopup === true) {
      setShowExistsPopup(false);
    }
  }

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, dirtyFields, isSubmitted },
    watch,
  } = useForm({
    resolver: zodResolver(signupSchema),
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  const { mutate: signUp, isLoading } = trpc.user.signup.useMutation({
    onSuccess: (res) => {
      console.log(res, "resss");
      handleNavigate(`/email-verification/${userEmail}`);
      toast.success("Signed up, successfully!");
      reset();
    },
    onError: (err) => {
      console.log(err, "errrrrr");
      if (err.message.includes("email already exist")) {
        setShowExistsPopup(true);
      } else {
        toast.error(err.message);
      }
    },
  });

  const onSubmit = useCallback(
    async (data: SignupSchema) => {
      setFormSubmitted(true);
      setUserEmail(data.email);
      signUp({ email: data.email, password: data.password });
    },
    [signUp]
  );

  useEffect(() => {
    if (formSubmitted) {
      setFormSubmitted(false);
    }
  }, [formSubmitted]);

  const passwordValue = watch("password");
  const confirmPasswordValue = watch("confirmPassword");

  return (
    <div className="login relative h-screen w-screen">
      <div className="login-content flex items-center overflow-hidden">
        <section className="content-left h-screen w-5/12 overflow-hidden bg-[#101828] bg-auth-bg bg-cover bg-center max-2xl:w-[45.666667%] flex flex-col justify-between">
          <div className="ml-8 mt-11 w-28 lg:ml-16 xl:ml-14">
            <img
              className="h-full w-full"
              src="/cus-logo.svg"
              alt="Shiga Logo"
            />
          </div>
          <div className="mb-10 w-4/5 self-center">
            <img 
             className="h-full w-full"
             src="/backed-by.svg"
             alt="Shiga backers"
             />
          </div>
        </section>

        <section className="content-right flex h-screen w-10/12 flex-col items-center bg-white pt-[34px]">
          <div className=" flex w-full flex-col items-center px-[85px]">
            <div className="right-top mb-[15px] w-full">
              <div className="navi-text font-ibm-plex flex h-16 items-center justify-end space-x-3 text-sm">
                <p className="font-normal text-htext-main hover:text-gray-700">
                  Already have an account?
                </p>
                <button
                  className="w-[110px] cursor-pointer rounded-lg border border-solid border-gray-300 px-[12px] py-[7px] font-semibold text-gray-700 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] transition duration-300 ease-in-out hover:bg-gray-300/[0.2]"
                  type="button"
                  onClick={() => handleNavigate("/login")}
                >
                  Log in
                </button>
              </div>
            </div>
            <div className="font-ibm-plex mt-8 w-[410px] font-normal text-htext-main">
              <h1 className="mb-[7px] text-3xl font-semibold">
                Welcome to Shiga 👋🏽
              </h1>
              <p className={`text-sm text-htext-subdued ${isUboInvite ? "w-96" : ""}`}>
                {isUboInvite ? "You’ve been invited to join Shiga as an Ultimate Beneficial Owner (UBO) for [Business Name]. Sign up to get started." : "Sign up to get started"}
              </p>
            </div>
            <form className="w-[410px]" onSubmit={handleSubmit(onSubmit)}>
              <fieldset>
                <div className="mt-[27px]">
                  <label
                    htmlFor="email"
                    className="font-ibm-plex text-[14px] font-semibold text-htext-label"
                  >
                    Email
                    <Inputs
                      type="email"
                      id="email"
                      placeholder="johndoe@example.com"
                      icon={EmailSVG}
                      register={register("email")}
                      error={errors.email}
                    />
                    {errors.email && (
                      <p className="font-ibm-plex mt-1.5 text-[13px] font-medium text-red-600">
                        {errors.email.message}!
                      </p>
                    )}
                  </label>
                </div>
                <div className="mt-[16px]">
                  <label
                    htmlFor="password"
                    className="font-ibm-plex text-[14px] font-semibold text-htext-label"
                  >
                    Password
                    <Inputs
                      type="password"
                      id="password"
                      placeholder="Create a password"
                      showIcon={ShowPassSVG}
                      hideIcon={HidePassSVG}
                      register={register("password")}
                      error={errors.password}
                    />
                  </label>
                </div>
                <div className="mt-[16px]">
                  <label
                    htmlFor="confirmPassword"
                    className="font-ibm-plex text-[14px] font-semibold text-htext-label"
                  >
                    Confirm Password
                    <Inputs
                      type="password"
                      id="confirmPassword"
                      placeholder="Confirm your password"
                      showIcon={ShowPassSVG}
                      hideIcon={HidePassSVG}
                      register={register("confirmPassword")}
                      error={errors.confirmPassword}
                    />
                  </label>
                  <div className="passError items-bottom font-ibm-plex mt-4 flex flex-wrap justify-start text-[12.5px] font-normal text-htext-subdued">
                    <p className="my-2 flex w-44 items-center">
                      {dirtyFields.password || isSubmitted ? (
                        passwordValue && !/[A-Z]/.test(passwordValue) ? (
                          <span
                            className="mr-2"
                            dangerouslySetInnerHTML={{
                              __html: errorColonSVG || "",
                            }}
                          />
                        ) : (
                          <span
                            className="mr-2"
                            dangerouslySetInnerHTML={{
                              __html: successTickSVG || "",
                            }}
                          />
                        )
                      ) : (
                        <span className="mr-2 block h-3.5 w-3.5 rounded-full bg-hgray-500/[0.4]"></span>
                      )}
                      At least one uppercase
                    </p>

                    <p className="my-2 flex w-44 items-center">
                      {dirtyFields.confirmPassword || isSubmitted ? (
                        confirmPasswordValue !== passwordValue ? (
                          <span
                            className="mr-2"
                            dangerouslySetInnerHTML={{
                              __html: errorColonSVG || "",
                            }}
                          />
                        ) : (
                          <span
                            className="mr-2"
                            dangerouslySetInnerHTML={{
                              __html: successTickSVG || "",
                            }}
                          />
                        )
                      ) : (
                        <span className="mr-2 block h-3.5 w-3.5 rounded-full bg-hgray-500/[0.4]"></span>
                      )}
                      Confirm password
                    </p>

                    <p className="my-2 flex w-44 items-center">
                      {dirtyFields.password || isSubmitted ? (
                        passwordValue && !/[0-9]/.test(passwordValue) ? (
                          <span
                            className="mr-2"
                            dangerouslySetInnerHTML={{
                              __html: errorColonSVG || "",
                            }}
                          />
                        ) : (
                          <span
                            className="mr-2"
                            dangerouslySetInnerHTML={{
                              __html: successTickSVG || "",
                            }}
                          />
                        )
                      ) : (
                        <span className="mr-2 block h-3.5 w-3.5 rounded-full bg-hgray-500/[0.4]"></span>
                      )}
                      At least one number
                    </p>

                    <p className="my-2 flex w-44 items-center">
                      {dirtyFields.password || isSubmitted ? (
                        passwordValue && passwordValue.length < 8 ? (
                          <span
                            className="mr-2"
                            dangerouslySetInnerHTML={{
                              __html: errorColonSVG || "",
                            }}
                          />
                        ) : (
                          <span
                            className="mr-2"
                            dangerouslySetInnerHTML={{
                              __html: successTickSVG || "",
                            }}
                          />
                        )
                      ) : (
                        <span className="mr-2 block h-3.5 w-3.5 rounded-full bg-hgray-500/[0.4]"></span>
                      )}
                      8 characters minimum
                    </p>

                    <p className="my-2 flex w-48 items-center">
                      {dirtyFields.password || isSubmitted ? (
                        passwordValue && !/[^A-Za-z0-9]/.test(passwordValue) ? (
                          <span
                            className="mr-2"
                            dangerouslySetInnerHTML={{
                              __html: errorColonSVG || "",
                            }}
                          />
                        ) : (
                          <span
                            className="mr-2"
                            dangerouslySetInnerHTML={{
                              __html: successTickSVG || "",
                            }}
                          />
                        )
                      ) : (
                        <span className="mr-2 block h-3.5 w-3.5 rounded-full bg-hgray-500/[0.4]"></span>
                      )}
                      At least one special character
                    </p>
                  </div>
                </div>
              </fieldset>
              <div className="font-ibm-plex mt-[5px] w-[410px] font-normal">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="mb-[16px] mt-[35px] w-full rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[12px] text-[15px] font-bold text-white hover:bg-hbrand-700"
                >
                  {isLoading ? (
                    <span className="loading loading-spinner loading-sm"></span>
                  ) : (
                    "Sign up for free"
                  )}
                </button>
              </div>
            </form>
          </div>
          <div className="sign-footer font-ibm-plex bottom relative mt-auto w-full border-t border-[#EAECF0] pb-6 pt-5 text-center font-normal">
            <Link to="#" className="text-[12.5px] text-htext-subdued">
              By creating an account, you agree to Shiga’s{" "}
              <b className="text-hbrand-700">Terms of Service</b> and{" "}
              <b className="text-hbrand-700">Privacy Policy</b>
            </Link>
          </div>
        </section>
      </div>
      {showExistsPopup && (
        <Popup
          className="signup-popup"
          popClass="px-[1.5rem] pt-[2rem] pb-[1.5rem] w-[40.5rem] top-[-.5rem]"
        >
          <div className="popup-content-bg flex flex-col items-center justify-center">
            <div className="pop-svg">
              <img src="/assets/existing-pop.svg" alt="Already existing svg" />
            </div>

            <div className="pop-text mt-[22px] w-[410px] text-center">
              <h4 className="text-[30px] font-semibold tracking-[-0.72px] text-htext-main">
                Your profile already exists
              </h4>
              <p className="my-2 text-base font-normal leading-6 text-htext-subdued">
                It appears that the details you provided exist on Shiga. Please
                sign in to continue
              </p>
            </div>

            <div className="pop-btns mt-9 flex w-full items-center justify-end border-t border-hgray-200 pt-8">
              <button
                type="button"
                onClick={closeExistsPopup}
                className="border-hgray-300 shadow-s-light] text-hgray-700 mr-[5px] rounded-lg border bg-white px-3.5 py-2 text-[15px] font-semibold hover:opacity-70"
              >
                Close
              </button>
              <button
                type="button"
                onClick={() => handleNavigate("/login")}
                className="border-hbrand-600 bg-hbrand-600 ml-[5px] rounded-lg border px-16 py-2 text-[15px] font-semibold text-white shadow-s-light hover:opacity-80"
              >
                Go to sign in
              </button>
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
};

export default Signup;
