import Teams from "../../components/Dashboard/team";

function TeamsPage() {
    return (
            <div>
            <Teams />
            </div>
    )
}

export default TeamsPage;