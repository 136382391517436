import { queryType } from ".";
import { trpc } from "../../../lib/trpc";
import { formatDate, formatToTwoDecimalPlaces } from "../../../utils";
import {
  closeBtnSVG,
  eurosSmallSVG,
  fasterPaymentSVG,
  poundsSmallSVG,
  uSDCSVG,
  UsdtSvg,
} from "../../Common/svg";
import Tooltip from "../../Common/tooltip";
import TransactionInfo from "../../Common/transactionInfo";

function ViewTransaction({
  userType,
  closeShowPopup,
  selectedTransactionId,
  customerId,
  contactUs,
}: {
  userType: string;
  closeShowPopup: () => void;
  selectedTransactionId: string | null;
  customerId: number | null;
  contactUs: () => void;
}) {
  const { data: userTrDetail, isLoading: popupLoading } =
    trpc.transaction.transactionDetail.useQuery(
      {
        customerId: customerId as number,
        id: Number(selectedTransactionId), // Use 0 if no ID is selected
        type: userType as queryType,
      },
      {
        enabled: !!selectedTransactionId, // Only fetch if ID is available
      }
    );

  const imageSrc = (details: any) => {
    const direction = details?.direction;
    const currencyPair = `${(direction === "cryptoToFiat"
      ? (details?.currencyCrypto as string)
      : (details?.currencyFiat as string)
    )?.toUpperCase()} to ${(direction === "cryptoToFiat"
      ? (details?.currencyFiat as string)
      : (details?.currencyCrypto as string)
    )?.toUpperCase()}`;

    switch (currencyPair) {
      case "USDT to GBP":
        return "/assets/USDT-to-GBP.svg";
      case "USDT to EUR":
        return "/assets/USDT-to-EUR.svg";
      case "USDC to EUR":
        return "/assets/USDC-to-EUR.svg";
      case "USDC to GBP":
        return "/assets/USDC-to-GBP.svg";
      case "EUR to USDT":
        return "/assets/EUR-to-USDT.svg";
      case "GBP to USDT":
        return "/assets/GBP-to-USDT.svg";
      case "EUR to USDC":
        return "/assets/euro-to-USDC.svg";
      case "GBP to USDC":
        return "/assets/GBP-to-USDC.svg";
      case "EUR to GBP":
        return "/assets/EUR-to-GBP.svg";
      case "GBP to EUR":
        return "/assets/GBP-to-EUR.svg";
      default:
        return "/assets/coin.svg";
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-between w-full h-full popup-content-bg text-htext-main">
        <div className="w-full content-bg-wrapper">
          <div className="flex items-center justify-between w-full content-nav">
            <h3 className="text-[18px] font-semibold">Transaction details</h3>
            <div
              className="relative h-[25px] w-[25px] cursor-pointer rounded-full hover:bg-hgray-50"
              onClick={() => closeShowPopup()}
            >
              <span
                className="absolute -ml-[10.5px] -mt-[10px]"
                dangerouslySetInnerHTML={{ __html: closeBtnSVG }}
              />
            </div>
          </div>
          {popupLoading ? (
            <div className="loader-wrapper">
              <div className="loader"></div> {/* Gray ring loader */}
            </div>
          ) : (
            <div className="w-full mt-10 content-content">
              <div className="flex items-center justify-between content-top">
                <div className="flex items-center top-left">
                  <img
                    className="w-[39px]"
                    src={imageSrc(userTrDetail)}
                    alt="Conversions pair icon"
                  />
                  <div className="ml-3 left-text">
                    <h4 className="text-[20px] font-semibold">
                      {userTrDetail?.direction === "cryptoToFiat"
                        ? `${userTrDetail?.amountCrypto} ${(
                            userTrDetail?.currencyCrypto || ""
                          ).toUpperCase()}`
                        : `${userTrDetail?.amountFiat} ${(
                            userTrDetail?.currencyFiat || ""
                          ).toUpperCase()}`}
                    </h4>
                    <p className="text-[13.5px] font-normal text-htext-subdued">
                      {userTrDetail?.amountFiat && userTrDetail?.amountCrypto && 'swapped to'}{" "}
                      {userTrDetail?.direction === "cryptoToFiat"
                        ? `${userTrDetail?.amountFiat||''} ${(
                            userTrDetail?.currencyFiat || ""
                          ).toUpperCase()}`
                        : `${userTrDetail?.amountCrypto||''} ${(
                            userTrDetail?.currencyCrypto || ""
                          ).toUpperCase()}`}
                    </p>
                  </div>
                </div>

                <div className="text-right top-right">
                  <p className="text-[13px] font-medium">
                    {formatDate(userTrDetail?.createdAt || "")
                      .split(" ")
                      .slice(0, -2)
                      .join(" ")}
                  </p>
                  <p className="text-[13px] font-normal text-htext-subdued">
                    {formatDate(userTrDetail?.createdAt || "")
                      .split(" ")
                      .slice(-2)
                      .join(" ")}
                  </p>
                </div>
              </div>

              <div className="flex flex-col w-full mt-10 content-middle-upper">
                <div className="flex items-center justify-between py-4 border-b middle-content border-b-hgray-200">
                  <p className="text-[14.5px] font-normal text-htext-subdued">
                    Status
                  </p>
                  <div className="px-2 border rounded-2xl border-hsuccess-200 bg-hsuccess-50">
                    <p className="text-[12.5px] font-medium text-hsuccess-700">
                      {userTrDetail?.status === "SUCCESS"
                        ? "SUCCESSFUL"
                        : userTrDetail?.status?.replace('_'," ")}
                    </p>
                  </div>
                </div>

                <div className="flex items-center justify-between py-4 border-b middle-content border-b-hgray-200">
                  <p className="text-[14.5px] font-normal text-htext-subdued">
                    Transaction type
                  </p>
                  <div className="flex items-center">
                    <p className="text-[13.5px] font-medium">
                      {userTrDetail?.direction === "cryptoToFiat"
                        ? "Portal out of stablecoin"
                        : "Portal in to stablecoin"}
                    </p>
                    <Tooltip
                      tipClass="portal-out-tip"
                      tipHeader={
                        userTrDetail?.direction === "cryptoToFiat"
                          ? "Portal out of stablecoin"
                          : "Portal in to stablecoin"
                      }
                      tipBody={
                        userTrDetail?.direction === "cryptoToFiat"
                          ? "Swap STABLECOIN to FIAT by using your stablecoin wallet to receive G7 currencies in your bank account"
                          : "Swap FIAT to STABLECOIN by buying stablecoin's on EVM and Solana without logging into your crypto app or swapping with someone P2P"
                      }
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between py-4 border-b middle-content border-b-hgray-200">
                  <p className="text-[14.5px] font-normal text-htext-subdued">
                    Target amount
                  </p>
                  <div className="flex items-center">
                    <p className="text-[13.5px] font-medium">
                      {userTrDetail?.direction === "cryptoToFiat"
                        ? `${userTrDetail?.amountCrypto} ${(
                            userTrDetail?.currencyCrypto as string
                          )?.toUpperCase()}`
                        : `${userTrDetail?.amountFiat} ${(
                            userTrDetail?.currencyFiat as string
                          )?.toUpperCase()}`}
                    </p>
                    <span
                      className="ml-2"
                      dangerouslySetInnerHTML={{
                        __html:
                          userTrDetail?.direction === "cryptoToFiat" &&
                          (
                            userTrDetail?.currencyCrypto as string
                          )?.toUpperCase() === "USDT"
                            ? UsdtSvg
                            : userTrDetail?.direction === "cryptoToFiat" &&
                                (
                                  userTrDetail?.currencyCrypto as string
                                )?.toUpperCase() === "USDC"
                              ? uSDCSVG
                              : userTrDetail?.direction === "fiatToCrypto" &&
                                  (
                                    userTrDetail?.currencyFiat as string
                                  )?.toUpperCase() === "GBP"
                                ? poundsSmallSVG
                                : userTrDetail?.direction === "fiatToCrypto" &&
                                    (
                                      userTrDetail?.currencyFiat as string
                                    )?.toUpperCase() === "EUR"
                                  ? eurosSmallSVG
                                  : "",
                      }}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between py-4 border-b middle-content border-b-hgray-200">
                  <p className="text-[14.5px] font-normal text-htext-subdued">
                    Settlement amount
                  </p>
                  <div className="flex items-center">
                    <p className="text-[13.5px] font-medium">
                      {userTrDetail?.amountCrypto && userTrDetail.amountFiat ? userTrDetail?.direction === "cryptoToFiat"
                        ? `${userTrDetail?.amountFiat} ${(
                            userTrDetail?.currencyFiat || ""
                          ).toUpperCase()}`
                        : `${userTrDetail?.amountCrypto} ${(
                            userTrDetail?.currencyCrypto || ""
                          ).toUpperCase()}`:'Processing'}
                    </p>
                    <span
                      className="ml-2"
                      dangerouslySetInnerHTML={{
                        __html:
                          userTrDetail?.direction === "cryptoToFiat" &&
                          (
                            userTrDetail?.currencyFiat as string
                          )?.toUpperCase() === "GBP"
                            ? poundsSmallSVG
                            : userTrDetail?.direction === "cryptoToFiat" &&
                                (
                                  userTrDetail?.currencyFiat as string
                                )?.toUpperCase() === "EUR"
                              ? eurosSmallSVG
                              : userTrDetail?.direction === "fiatToCrypto" &&
                                  (
                                    userTrDetail?.currencyCrypto as string
                                  )?.toUpperCase() === "USDT"
                                ? UsdtSvg
                                : userTrDetail?.direction === "fiatToCrypto" &&
                                    (
                                      userTrDetail?.currencyCrypto as string
                                    )?.toUpperCase() === "USDC"
                                  ? uSDCSVG
                                  : "",
                      }}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between py-4 border-b middle-content border-b-hgray-200">
                  <p className="text-[14.5px] font-normal text-htext-subdued">
                    Rate
                  </p>
                  <p className="text-[13.5px] font-medium">
                    {formatToTwoDecimalPlaces(
                      Number(userTrDetail?.shigaExchangeRate)
                    )}
                  </p>
                </div>

                <div className="flex items-center justify-between py-4 middle-content">
                  <p className="text-[14.5px] font-normal text-htext-subdued">
                    Payment method
                  </p>
                  <div className="flex items-center">
                    <p className="text-[13.5px] font-medium">Faster payments</p>
                    <span
                      className="ml-2.5"
                      dangerouslySetInnerHTML={{
                        __html: fasterPaymentSVG,
                      }}
                    />
                  </div>
                </div>
              </div>

              <TransactionInfo
                      targetCurrency={userTrDetail?.targetCurrency || ""}
                      settlementCurrency={
                        userTrDetail?.settlementCurrency || ""
                      }
                      sendingAddress={userTrDetail?.sendingAddress || ""}
                      targetNetwork={userTrDetail?.targetNetwork || ""}
                      walletAddress={userTrDetail?.walletAddress || ""}
                      accountNumber={userTrDetail?.accountnumber || ""}
                      sortCode={userTrDetail?.sortCode || ""}
                      iban={userTrDetail?.iban || ""}
                      direction={
                        userTrDetail?.direction as
                          | "cryptoToFiat"
                          | "fiatToCrypto"
                      }
                    />
            </div>
          )}
        </div>

        <div className="flex items-center justify-between w-full pt-6 pb-6 mt-16 border-t content-footer border-t-hgray-200">
          <p className="text-[13px] font-normal text-htext-subdued">
            Issues with this transaction?{" "}
            <b
              className="font-semibold cursor-pointer text-hbrand-700 hover:text-hbrand-600"
              onClick={() => contactUs()}
            >
              Contact us
            </b>
          </p>
          <button
            type="button"
            onClick={() => closeShowPopup()}
            className="rounded-lg border border-hbrand-600 bg-hbrand-600 px-24 py-2.5 text-[15px] font-semibold text-white shadow-s-light hover:border-hbrand-700 hover:bg-hbrand-700"
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
}

export default ViewTransaction;
