import PortalOutActivateWallet from "../../../../components/Dashboard/portal-accounts/portal-out/portal-out-wallet-activation";

function PortalOutActivateWallets() {
    return (
            <div>
                <PortalOutActivateWallet />
            </div>
    )
}

export default PortalOutActivateWallets;