import CompleteUBOInfo from "../../../../components/Dashboard/get-started/onboarding-checklist/CompleteUBOInfo";

function DashOnboardingCompleteUBOInfo() {
    return (
            <div>
                <CompleteUBOInfo />
            </div>
    )
}

export default DashOnboardingCompleteUBOInfo;