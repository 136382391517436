import { Trash } from "lucide-react";
import { trpc } from "../../../lib/trpc";
import { getCountryCodeAndNumber } from "../../../utils/countryCodeAndNumber";

export default function Profile() {
  const user = trpc.user.getUserById.useQuery({});
  const { data: auth } = trpc.user.getAccountType.useQuery({});


  return (
    <div className="ibm-plex flex flex-wrap gap-4 bg-gray-50 p-8">
      {/* Business Information Section */}
      {auth?.acountType === "CORPORATE" && (
        <div className="shadow-md  basis-2/5 rounded-md bg-white p-6">
          {" "}
          <h2 className="mb-4 text-lg font-medium text-gray-800">
            Business information
          </h2>
          <div className="mb-3 flex justify-between">
            <label className="text-medium mb-1 block text-gray-500">
              Organisation
            </label>
            <p className="text-base font-medium text-gray-800">Edu Frames</p>
          </div>
          <hr className="my-4 h-px border-0 bg-gray-200" />
          <div className="mb-3 flex justify-between">
            <label className="text-medium mb-1 block text-gray-500">
              Your role
            </label>
            <p className="text-base font-medium text-gray-800">Admin</p>
          </div>
          <hr className="my-4 h-px border-0 bg-gray-200" />
          <div className="mb-3 flex justify-between">
            <label className="text-medium mb-1 block text-gray-500">
              Business Logo
            </label>
            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-300 text-xl font-medium text-gray-600">
              E
            </div>{" "}
          </div>
          <hr className="my-4 h-px border-0 bg-gray-200" />
          <div className="justify-end-end mt-4 flex ">
            <div className="flex gap-2 text-end">
              <button className="flex cursor-pointer items-center justify-center rounded border-none bg-transparent px-3 py-1 text-sm text-red-500 hover:text-red-600">
                <Trash size={16} className="mr-2 text-red-700" />
                Remove
              </button>
              <button className="flex cursor-pointer items-center justify-center rounded border border-blue-500 bg-transparent px-3 py-1 text-sm text-blue-500 hover:bg-blue-50">
                <svg
                  className="mr-1 h-4 w-4"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                Update
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Personal Information Section */}
      <div className="shadow-md basis-2/5 rounded-md bg-white p-6">
        {" "}
        <h2 className="mb-4 text-lg font-medium text-gray-800">
          Personal information
        </h2>
        <div className="mb-3 flex justify-between">
          <label className="text-medium mb-1 block text-gray-500">Name</label>
          <p className="text-base font-medium text-gray-800">{`${user.data?.firstName} ${user.data?.lastName}`}</p>
        </div>
        <hr className="my-4 h-px border-0 bg-gray-200" />
        <div className="mb-3 flex justify-between">
          <label className="text-medium mb-1 block text-gray-500">
            Phone number
          </label>
          <p className="text-base font-medium text-gray-800">
            <span className="inline-flex items-center">
              <svg
                className="mr-1 h-4 w-4 text-green-600"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 1a9 9 0 110 18A9 9 0 0110 1zm0 2a7 7 0 100 14A7 7 0 0010 3zm0 11a1 1 0 110 2 1 1 0 010-2zm1-7V7a1 1 0 00-2 0v3a1 1 0 00.293.707l2 2A1 1 0 0012 12a1 1 0 00-1-1H9V9h2z"></path>
              </svg>
              ({" "}
              {
                getCountryCodeAndNumber(user.data?.phoneNumber || "")
                  .countryCode
              }
              ){" "}
              {
                getCountryCodeAndNumber(user.data?.phoneNumber || "")
                  .nationalNumber
              }
            </span>
          </p>
        </div>
        <hr className="my-4 h-px border-0 bg-gray-200" />
        <div className="mb-3 flex justify-between">
          <label className="mb-1 block text-sm text-gray-500">Email</label>
          <p className="text-base font-medium text-gray-800">
            {user.data?.email || ""}{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
