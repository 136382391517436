import PortalInDetails from "../../../../components/Dashboard/portal-accounts/portal-in/portal-in-details";

function PortalInDetailsCont() {
    return (
            <div>
                <PortalInDetails />
            </div>
    )
}

export default PortalInDetailsCont;