import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import MainLoader from "../../../../components/Common/loader/mainLoader";
import { trpc } from "../../../../lib/trpc";
import FileUpload from "../../../Common/inputs/fileUpload";
import { arrowRight } from "../../../Common/svg/index";
import { businessDocs, BusinessDocsData } from "./schema";

const fileData = [
  {
    fileHeader: "Business registration",
    fileSubHeader:
      "A copy of your company’s official registration document issued by the relevant government authority.",
    fileName: "businessRegistrationUpload",
  },
  {
    fileHeader: "Ownership information",
    fileSubHeader:
      "Provide a list of all shareholders with names and ownership percentages, plus a signed ownership structure chart showing your company’s ownership, subsidiaries, and affiliates.",
    fileName: "ownershipInformationUpload",
  },
  {
    fileHeader: "Financial information",
    fileSubHeader:
      "A recent bank statement (no more than three months old) confirming your company’s bank account details.",
    fileName: "financialStatementsUpload",
  },
  {
    fileHeader: "Company governance",
    fileSubHeader:
      "A copy of your company’s bylaws or articles of association, which outline the internal rules and regulations.",
    fileName: "corporateGovernanceUpload",
  },
  {
    fileHeader: "Licenses and permits",
    fileSubHeader:
      "If your business activities require them, please provide any necessary licenses, permits, or special registrations issued by regulatory authorities.",
    fileName: "licenseUpload",
  },
  {
    fileHeader: "AML/CFT Policy",
    fileSubHeader:
      "While optional, providing your company’s Anti-Money Laundering (AML) and Combating the Financing of Terrorism (CFT) status can help us process your application faster.''",
    fileName: "amlPolicyUpload",
  },
];

const BusinessDocsForm: FC = () => {
  const navigate = useNavigate();
  const [isUploaded, setIsUploaded] = useState(false);

  const { mutate, isLoading } = trpc.user.uploadCorporateDoc.useMutation({
    onSuccess: () => {
      navigate("/get-started/onboarding-checklist/ownership-informations");
    },
  });
  const { data: corporateDocuments, isLoading: isFetchCorporateDoc } =
    trpc.user.fetchCorporateDoc.useQuery({});
  const { data: businessInfo, isLoading: isLoadingBusinessInfo } =
    trpc.user.getCompany.useQuery({});

  useEffect(() => {
    if (corporateDocuments?.amlPolicyUpload) {
      setIsUploaded(true);
    }
  });

  const businessDocFormSave = (data: BusinessDocsData) => {
    if (isUploaded) {
      navigate("/get-started/onboarding-checklist/ownership-informations");
    } else {
      mutate(data);
    }
  };

  const handleGoBack = () => {
    navigate('/get-started/onboarding-checklist/business-information');
  };

  const methods = useForm<any>({
    resolver: zodResolver(businessDocs),
    defaultValues: {
      businessRegistrationUpload: "",
      ownershipInformationUpload: "",
      financialStatementsUpload: "",
      corporateGovernanceUpload: "",
      licenseUpload: "",
      amlPolicyUpload: "",
    },
  });

  const {
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    reset(corporateDocuments);
  }, [corporateDocuments]);

  if (isFetchCorporateDoc) {
    return <MainLoader />;
  }

  return (
    <div className="pform h-screen w-screen bg-white pb-2 pt-4">
      <div className="pform-content h-full w-full overflow-hidden">
        <div className="content-nav mt-5 flex items-center px-40">
          <p className="text-[13px] font-medium text-htext-label">
            Onboarding checklist
          </p>
          <span
            className="mx-4"
            dangerouslySetInnerHTML={{ __html: arrowRight }}
          />
          <p className="text-[13px] font-semibold text-hbrand-700">
            Provide your business documents
          </p>
        </div>

        <div className="content-form mx-auto h-full w-full pb-40 ">
          <div className="form-header mx-auto mt-14 w-[800px] pb-8">
            <div>
              <h2 className="text-[28px] font-semibold tracking-[-0.72px] text-htext-main">
                Your business documents
              </h2>
            </div>
          </div>
          <div className="hide-scrollbar h-full w-full overflow-y-scroll pb-20">
            <div className="header-header mx-auto w-[800px] rounded-t-[12px] border border-b-0 border-hgray-100 px-5 pb-3 pt-5">
              <h4 className="text-[18px] font-semibold text-htext-main">
                Required documents for{" "}
                {isLoadingBusinessInfo ? (
                  <span className="loading loading-spinner loading-sm"></span>
                ) : (
                  <span className="m-0 ml-1 rounded-[16px] border border-hgray-200 bg-hgray-50 px-2.5 py-[0.15rem] text-center text-[12px] font-medium text-hgray-700">
                    {businessInfo?.type.replaceAll('_', ' ')}
                  </span>
                )}
              </h4>
              <p className="mt-2.5 border-b border-hgray-100 pb-5 text-[13px] font-normal text-htext-subdued">
                Please ensure all documents uploaded are clear, complete, and
                up-to-date to avoid delays. If your original documents are in a
                language other than English, providing an official translation
                will expedite the signup process. Please note that we are
                normally unable to accept documents in non-Latin script.
              </p>
            </div>

            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(businessDocFormSave)}
                className="form-content h-full w-full rounded-b-[16px] bg-white"
              >
                <fieldset>
                  <div className="mx-auto w-[800px] rounded-b-[12px] border border-t-0 border-hgray-100 px-6 pb-6 pt-1">
                    {fileData.map((file, index) => (
                      <div
                        className={`${
                          file.fileName === "busReg" ? "mt-2" : "mt-6"
                        } w-full"`}
                        key={index}
                      >
                        <label htmlFor={file.fileName} className="w-full relative">
                          <div className="mb-1 flex items-center text-[13px] font-medium">
                            {/* {errors && Object.entries(errors).length > 0 && ( */}
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>
                            {/* )} */}
                            <p className="text-htext-label">
                              {file.fileHeader}
                            </p>
                          </div>
                          <p className="mb-2 mt-[-2px] text-[13px] font-normal text-htext-subdued">
                            {file.fileSubHeader}
                          </p>
                          <FileUpload
                            name={file.fileName}
                            error={errors?.[file.fileName]}
                          />
                           {errors?.[file.fileName] && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[13px] font-medium text-red-600">
                              {errors?.[file.fileName]?.message as string}
                            </p>
                          )}
                        </label>
                      </div>
                    ))}
                  </div>

                  <div className="form-btns mt-28 flex h-full w-full items-center justify-between border-t border-t-hgray-200 px-14 pb-7 pt-6">
                    <button
                      className="w-[150px] rounded-lg border  border-solid border-hgray-300 bg-white px-[10px] py-[13px] text-[15px] font-semibold text-hgray-700 shadow-s-light hover:bg-hgray-50"
                      type="button"
                      onClick={() => handleGoBack()}
                    >
                      Go Back
                    </button>

                    <button
                      className="w-[250px] rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[13px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
                      type="submit"
                    >
                      {isLoading ? (
                        <span className="loading loading-spinner loading-sm"></span>
                      ) : isUploaded ? (
                        "Update and continue"
                      ) : (
                        "Save and continue"
                      )}
                    </button>
                  </div>
                </fieldset>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessDocsForm;
