import Signup from "../../components/Auth/Signup";

function AuthSignup() {
    return (
            <div>
                <Signup />
            </div>
    )
}

export default AuthSignup;