import PortalAccounts from "../../../components/Dashboard/portal-accounts";

function Accounts() {
    return (
            <div>
            <PortalAccounts />
            </div>
    )
}

export default Accounts;