import { useState } from "react";
import { FieldError, useFormContext, UseFormRegister } from "react-hook-form";

type Options = {
  value: string;
  icon: JSX.Element;
  label: string;
};

type DropdownProps = {
  options: Options[];
  register: UseFormRegister<any>;
  name: string;
  error?: FieldError; // Optional error object from RHF
};

const Dropdown = ({ options, name, error }: DropdownProps) => {
  const { register, setValue } = useFormContext(); // useFormContext to get the register function

  const [selected, setSelected] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative inline-block w-full">
      <input type="hidden" value={selected} {...register(name)} />
      <div>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={`shadow-sm focus:ring-2 focus:ring-blue-500 w-full rounded-[10px] px-4 py-2 text-left focus:outline-none ${
            error ? "border-2 border-red-500" : "border-gray-300 border"
          }`}
        >
          {selected
            ? options.find((option) => option.value === selected)?.label
            : "Select here"}
          <span className="absolute right-0 inline-flex items-center px-2">
            <svg
              className="h-4 w-4 text-gray-500"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </button>
        {isOpen && (
          <div className="shadow-lg absolute z-10 mt-1 w-full rounded-md border border-gray-300 bg-white">
            {options.map((option) => (
              <div
                key={option.value}
                onClick={() => {
                  setSelected(option.value);
                  setValue(name, option.value);
                  setIsOpen(false);
                }}
                className="flex cursor-pointer items-center px-4 py-2 hover:bg-gray-100"
              >
                {option.icon}
                <span>{option.label}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
