import { zodResolver } from "@hookform/resolvers/zod";
import { CheckIcon } from "lucide-react";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getImageSrc } from "../../../../components/Common/table";
import {
  chainOptions,
  fiatSwapIcons,
  tokenOptions,
} from "../../../../constant/options";
import { useModal } from "../../../../context/modalProvider";
import { trpc } from "../../../../lib/trpc";
import { TransactionDetail } from "../../../Common/calDetails";
import DropDownWithIcon from "../../../Common/dropdown/DropDownWithIcon";
import Inputs from "../../../Common/inputs";
import MainLoader from "../../../Common/loader/mainLoader";
import SidePopup from "../../../Common/popup/sidepopup";
import {
  BackArrowSVG,
  BlueThinArrowSVG,
  calculator,
  circleSmallMinus,
  circleSmallPlus,
  closeBtnSVG,
  CopyBlueSVG,
  CopyWhiteSVG,
  EmailSVG,
  euroDarkerSmall,
  flusteredWarning,
  gbpDarkerSmall,
  slideRight,
  uSDCSVG,
  usdcSVG,
  usdDarkerSmall,
  UsdtSvg,
} from "../../../Common/svg";
import {
  setupWalletWithoutCurrencySchema,
  SetupWalletWithoutCurrencySchema,
  ShareSchema,
  shareSchema,
} from "../../get-started/onboarding-checklist/schema";
import { Chain } from "../../get-started/onboarding-checklist/walletsSetup/types";

type AccordionItem = {
  currency: string;
  account_number: string;
  bank_country: string;
  sort_code: string;
  status: string;
  uuid: string;
};

const items: AccordionItem[] = [
  {
    currency: "usd",
    account_number: "00042476",
    bank_country: "GB",
    sort_code: "040509",
    status: "ACTIVE",
    uuid: "18a408ed-7f45-4c2b-8fbf-26305627e5e",
  },
];
const dropFiatOptions = [
  {
    value: "USD",
    type: "fiat",
  },
  {
    value: "EUR",
    type: "fiat",
  },
  {
    value: "GBP",
    type: "fiat",
  },
];

const dropStableOptions = [
  {
    value: "USDT",
    type: "stablecoin",
  },
  {
    value: "USDC",
    type: "stablecoin",
  },
];
const cryptoCurrencyIcons: any = {
  usdt: UsdtSvg,
  usdc: usdcSVG,
};

export const currencyIcons: any = {
  eur: euroDarkerSmall,
  gbp: gbpDarkerSmall,
  usd: usdDarkerSmall,
};

const PortalInDetails: FC = () => {
  const navigate = useNavigate();
  const stableDropdownRef = useRef<HTMLDivElement>(null);
  const fiatDropdownRef = useRef<HTMLDivElement>(null);

  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const [showCalPopup, setShowCalPopup] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);

  const [calRate, setCalRate] = useState("0");
  const [calBase, setCalBase] = useState(`${dropFiatOptions[0]?.value}`);
  const [calTarget, setCalTarget] = useState(`${dropStableOptions[0]?.value}`);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currency, setCurrency] = useState("");
  const [calculatedValue, setCalculatedValue] = useState("");
  const [amount, setAmount] = useState("");
  const [showDropDownFiat, setShowDropDownFiat] = useState(false);
  const [showDropDownStable, setShowDropDownStable] = useState(false);
  const [showEditWalletPopup, setShowEditWalletPopup] = useState(false);
  const { showModal } = useModal();
  const [copiedId, setCopiedId] = useState<string | null>(null);

  const { data: getTokenPreferences } = trpc.user.getTokenPreferences.useQuery(
    {}
  );

  const copyToClipboard = (details: any) => {
    const formattedDetails = [
      `Currency ${details.currency.toUpperCase()}`,
      `Account name ${
        details.account_name || `${user.data?.firstName} ${user.data?.lastName}`
      }`,
      details.account_number && `Account number ${details.account_number}`,
      `${details.iban ? "IBAN" : "Sort Code"} ${
        details.iban || details.sort_code
      }`,
      details.bic && `Bank Identification Code (BIC) ${details.bic}`,
    ]
      .filter(Boolean)
      .join("\n");

    navigator.clipboard.writeText(formattedDetails).then(() => {
      setCopiedId(details.uuid);
      setTimeout(() => setCopiedId(null), 2000);
    });
  };

  const copyAddressToClipboard = (address: string) => {
    navigator.clipboard.writeText(address.toString()).then(() => {
      setCopiedId(address);
      setTimeout(() => setCopiedId(null), 2000);
    });
  };

  useEffect(() => {
    setSearchParams((prev) => {
      prev.set("amountToSnd", calBase);
      prev.set("amountToRcv", calTarget);
      prev.set("calBase", amount);
      prev.set("calTarget", calculatedValue);
      return prev;
    });
  }, [calculatedValue, amount, calBase, calTarget, setSearchParams]);

  const { mutate: shareLink, isLoading: isSharingLink } =
    trpc.transaction.sharePaymentLink.useMutation({
      onSuccess: () => {
        resetShareLink();
        toast.success("Payment request sent to email");
      },
      onError: () => {},
    });

  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(userWallet?.[0].walletAddress || "");
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const onShareSubmit = (values: ShareSchema) => {
    const amountToSend = `${searchParams.get("calBase")} ${searchParams.get(
      "amountToSnd"
    )}`;
    const settlementAmount = ` ${searchParams.get(
      "calTarget"
    )}${searchParams.get("amountToRcv")}`;

    shareLink({
      walletAddress: userWallet?.[0].walletAddress || "",
      email: values.email,
      amountToSend,
      settlementAmount,
      network: userWallet?.[0].chain || "",
    });
  };

  function closeShowPopup() {
    if (showCalPopup === true) {
      setShowCalPopup(false);
    } else {
      setShowEditWalletPopup(false);
    }
    reset();
  }

  const toggleItem = (id: number) => {
    setOpenItemId(openItemId === id ? null : id);
  };

  //   const handleNavigate = (route: string) => {
  //     navigate(route);
  //   };

  const handleGoBack = () => {
    navigate("/dashboard/portal-accounts");
  };

  // Handle clicks outside of Stablecoin dropdown
  useEffect(() => {
    const handleClickOutsideStable = (event: any) => {
      if (
        stableDropdownRef.current &&
        !stableDropdownRef?.current?.contains(event.target)
      ) {
        setShowDropDownStable(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideStable);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideStable);
    };
  }, []);

  // Handle clicks outside of Fiat dropdown
  useEffect(() => {
    const handleClickOutsideFiat = (event: any) => {
      if (
        fiatDropdownRef.current &&
        !fiatDropdownRef.current.contains(event.target)
      ) {
        setShowDropDownFiat(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideFiat);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideFiat);
    };
  }, []);

  const { data: tr } = trpc.user.getCalculatedRate.useQuery({
    base: calBase,
    target: calTarget,
  });

  const user = trpc.user.getUserById.useQuery({});

  const { data: unblockBankAccts, isLoading: isLoadingUnblockBankAccts } =
    trpc.user.listUnblockBankAccounts.useQuery({});

  const { data: userWallet, isLoading: isLoadingUserWallet } =
    trpc.user.getWallet.useQuery({});

  useEffect(() => {
    if (amount === "" || isNaN(parseFloat(amount))) {
      setCalculatedValue("0");
    } else if (tr) {
      const rate = tr.exchange_rate; // Adjust based on the actual response structure
      const roundedUpRates = rate.toFixed(2);
      setCalRate(roundedUpRates);
      const targetValue = (parseFloat(amount) * rate).toFixed(2);
      setCalculatedValue(targetValue);
    }
  }, [tr, amount]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value);
    }
  };

  //  edit wallet details
  const utils = trpc.useUtils();

  const { mutate: setUpWallet, isLoading: isSettingUpWallet } =
    trpc.user.setUpWallet.useMutation({
      onSuccess: () => {
        reset();
        utils.user.getWallet.refetch();
        setShowCalPopup(false);
        utils.user.getTokenPreferences.invalidate();
      },
      onError: (e) => {
        console.log(e);
        toast.error(e.message);
      },
    });

  const { mutateAsync: getSession, isLoading: isLoadingGettingSession } =
    trpc.user.isSessionIdExpired.useMutation({
      onError: (e) => {
        toast.error(e.message);
      },
    });

  const { mutateAsync: triggerOtp } = trpc.user.triggerOtp.useMutation({
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const { mutate: UpdateWallet, isLoading: isUpdatingWallet } =
    trpc.user.UpdateWallet.useMutation({
      onSuccess: () => {
        reset();
        utils.user.getWallet.refetch();
        utils.user.getTokenPreferences.invalidate();
        chainOptions;
        closeShowPopup();
      },
      onError: (e) => {
        console.log(e);
        toast.error(e.message);
      },
    });

  const methods = useForm<SetupWalletWithoutCurrencySchema>({
    resolver: zodResolver(setupWalletWithoutCurrencySchema),
    defaultValues: {
      chain: undefined,
      token: "",
      walletAddress: "",
      confirmWalletAddress: "",
    },
  });

  const {
    reset: resetShareLink,
    register: registerShareLInk,
    handleSubmit,
    formState: { errors: shareLinkErrors },
  } = useForm<ShareSchema>({
    resolver: zodResolver(shareSchema),
    defaultValues: {
      email: "",
      walletAddress: "",
      amountToSend: "",
      settlementAmount: "",
      network: "",
    },
  });

  const {
    control,
    register,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const onSetUpWallet = async (data: SetupWalletWithoutCurrencySchema) => {
    try {
      const response = await getSession({});
      if (response.sessionIdHasExpired) {
        await triggerOtp({});
        showModal();
      } else {
        if (showEditWalletPopup) {
          UpdateWallet({ ...data, currency });
        } else {
          setUpWallet({ ...data, currency });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (showEditWalletPopup && userWallet) {
      const walletData = userWallet[0];
      // Populate form fields with fetched data
      if (walletData) {
        setValue("chain", walletData?.chain as Chain);
        setValue("token", walletData.token || "");
        setValue("walletAddress", walletData.walletAddress || "");
        setValue("confirmWalletAddress", walletData.walletAddress || "");
      }
    }
  }, [showEditWalletPopup, userWallet, setValue]);

  const selectedToken = useWatch({ control, name: "token" });
  const [filteredChainOptions, setFilteredChainOptions] =
    useState(chainOptions);

  // Filter/sort chain options based on selected token
  useEffect(() => {
    if (selectedToken === "usdc") {
      setFilteredChainOptions(
        chainOptions.filter(
          (option) => !["tron", "celo"].includes(option.value)
        )
      ); // Example
    } else if (selectedToken === "usdt") {
      setFilteredChainOptions(
        chainOptions.filter(
          (option) => !["base", "celo"].includes(option.value)
        )
      ); // Example
    }
  }, [selectedToken]);

  if (isLoadingUserWallet) {
    return <MainLoader />;
  }
  console.log({ error: methods.formState.errors });
  return (
    <div className="portal-in-stable  h-screen w-screen bg-white pb-2 pt-4">
      <div className="portal-in-stable-content h-full w-full overflow-hidden">
        <div className="content-nav mt-5 flex w-[950px] items-center px-60">
          <p
            className="flex cursor-pointer items-center text-[13px] font-semibold text-hbrand-600 hover:text-hbrand-700"
            onClick={() => handleGoBack()}
          >
            <span
              className="mr-2.5"
              dangerouslySetInnerHTML={{ __html: BackArrowSVG }}
            />
            Go back
          </p>
        </div>

        <div className="content-nav hide-scrollbar mx-auto mt-9 h-full w-[950px] overflow-y-scroll pb-52">
          <div className="nav-top">
            <div className="top-type relative flex items-center justify-between rounded-lg bg-hbrand-50 px-5 py-6 shadow-s-light">
              <div className="z-10">
                <p className="text-[12px] font-semibold text-hbrand-600">
                  PORTAL IN TO STABLECOIN
                </p>
                <h3 className="mt-1.5 text-[22px] font-semibold text-htext-main">
                  Swap FIAT to STABLECOIN
                </h3>
              </div>

              <div className="text-btns z-10">
                <button
                  className="flex w-[210px] items-center justify-center gap-x-2 rounded-lg border border-solid border-hbrand-600 bg-white px-[10px] py-[9px] text-[13px] font-semibold text-hbrand-700 shadow-s-light hover:border-hbrand-700 hover:bg-hgray-50 hover:text-hbrand-600"
                  type="button"
                  onClick={() => setShowCalPopup(true)}
                >
                  <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: calculator }}
                  />
                  <p>Calculate rate conversion</p>
                </button>
              </div>
            </div>

            <div className="content-table mt-6 flex w-full items-start py-3">
              <div className="portal-account w-full border-r-[0.5px] border-r-hgray-200 pl-2 pr-8">
                <div className="type-top pb-10 pt-9">
                  <div className="space-y-4">
                    {isLoadingUnblockBankAccts && (
                      <span className="loading loading-spinner loading-sm"></span>
                    )}
                    {!isLoadingUnblockBankAccts &&
                      unblockBankAccts &&
                      [...unblockBankAccts, ...items]?.map(
                        ({
                          bic,
                          currency,
                          iban,
                          uuid,
                          account_number,
                          account_name,
                          sort_code,
                        }: any) => (
                          <div key={uuid}>
                            <button
                              onClick={() => toggleItem(uuid)}
                              className="flex w-full items-center justify-between rounded-lg bg-hgray-50 px-6 py-5 shadow-s-light focus:outline-none"
                            >
                              <div className="flex items-center">
                                {
                                  fiatSwapIcons?.find(
                                    ({ value }) =>
                                      value.toLocaleLowerCase() ==
                                      currency.toLocaleLowerCase()
                                  )?.icon
                                }
                                <p className="ml-3 text-[14px] font-semibold text-htext-main">
                                  {currency.toUpperCase() == "EUR"
                                    ? "Euros"
                                    : currency.toUpperCase() == "GBP"
                                      ? "Pounds"
                                      : "Dollars"}{" "}
                                  to Stablecoin
                                </p>
                              </div>
                              {currency === "usd" ? (
                                <p className="rounded-2xl border border-solid border-hbrand-200 bg-hbrand-50 px-[5px] py-[0.5px] text-[11px] font-medium text-hbrand-700">
                                  Coming soon
                                </p>
                              ) : openItemId === uuid ? (
                                <div className="flex items-center">
                                  <span
                                    className="mr-3"
                                    dangerouslySetInnerHTML={{
                                      __html: circleSmallMinus,
                                    }}
                                  />
                                  <p className="font-ibm-plex text-[14px] font-semibold text-hgray-500">
                                    Collapse
                                  </p>
                                </div>
                              ) : (
                                <div className="flex items-center">
                                  <span
                                    className="mr-3"
                                    dangerouslySetInnerHTML={{
                                      __html: circleSmallPlus,
                                    }}
                                  />
                                  <p className="font-ibm-plex text-[14px] font-semibold text-hgray-500">
                                    Expand
                                  </p>
                                </div>
                              )}
                            </button>
                            {openItemId === uuid && (
                              <div className="flex">
                                <div className="rounded-lg bg-gray-50 px-6 pb-2 shadow-s-light">
                                  <div className="account-nav">
                                    <h3 className="text-[16px] font-semibold leading-[31px] text-htext-label">
                                      1. Send here
                                    </h3>
                                    <p className="mt-[5px] w-[400px] text-[14px] font-normal leading-[19px] text-htext-subdued">
                                      Send {currency.toUpperCase()} to this
                                      Shiga generated virtual account to convert
                                      to stablecoin.
                                    </p>
                                  </div>
                                  <div className="rounded-[12px] p-4 shadow-s-light">
                                    <div className="flex items-center">
                                      <span
                                        className=""
                                        dangerouslySetInnerHTML={{
                                          __html: flusteredWarning,
                                        }}
                                      />
                                      <p className="ml-2.5 text-[12.5px] font-medium text-htext-subdued">
                                        Send only {currency.toUpperCase()} from
                                        accounts in your name to avoid losing
                                        funds. This account doesn't hold{" "}
                                        {currency.toUpperCase()}; funds are
                                        auto-converted and sent to your
                                        stablecoin wallet.{" "}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="accountDetails flex w-full flex-col justify-center px-2 py-5">
                                    <div className="flex w-full items-center justify-between py-2.5">
                                      <p className="text-[13.5px] font-normal text-htext-main">
                                        Currency
                                      </p>

                                      <div className="flex items-center">
                                        <p className="text-[14.5px] font-semibold text-htext-main">
                                          {currency.toUpperCase()}
                                        </p>
                                        <span
                                          className="ml-2"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              currencyIcons[
                                                currency.toLowerCase()
                                              ],
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="flex w-full items-center justify-between py-2.5">
                                      <p className="text-[13.5px] font-normal text-htext-main">
                                        Account name
                                      </p>
                                      <p className="text-[14.5px] font-semibold text-htext-main">
                                        {account_name ||
                                          `${user.data?.firstName} ${user.data?.lastName}`}
                                      </p>
                                    </div>

                                    {currency !== "EUR" && (
                                      <div className="flex w-full items-center justify-between py-2.5">
                                        <p className="text-[13.5px] font-normal text-htext-main">
                                          Account number
                                        </p>
                                        <p className="text-[14.5px] font-semibold text-htext-main">
                                          {account_number}
                                        </p>
                                      </div>
                                    )}

                                    <div className="flex w-full items-center justify-between py-2.5">
                                      <p className="text-[13.5px] font-normal text-htext-main">
                                        {iban ? "IBAN" : "Sort Code"}
                                      </p>
                                      <p className="text-[14.5px] font-semibold text-htext-main">
                                        {iban ? iban : sort_code}
                                      </p>
                                    </div>
                                    {currency == "EUR" && (
                                      <div className="flex w-full items-center justify-between py-2.5">
                                        <p className="text-[13.5px] font-normal text-htext-main">
                                          Bank Identification Code (BIC)
                                        </p>
                                        <p className="text-[14.5px] font-semibold text-htext-main">
                                          {bic}
                                        </p>
                                      </div>
                                    )}
                                  </div>

                                  <div className="w-full px-2">
                                    <button
                                      onClick={() =>
                                        copyToClipboard({
                                          bic,
                                          currency,
                                          iban,
                                          uuid,
                                          account_number,
                                          account_name,
                                          sort_code,
                                        })
                                      }
                                      className="flex w-full items-center justify-center rounded-lg border border-hbrand-600 bg-hbrand-600 px-4 py-2 hover:border-hbrand-700 hover:bg-hbrand-700"
                                    >
                                      {copiedId === uuid ? (
                                        <CheckIcon className="h-5 w-5 text-white" />
                                      ) : (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: CopyWhiteSVG,
                                          }}
                                        />
                                      )}
                                      <p className="ml-2 text-[14.5px] font-semibold text-white">
                                        {copiedId === uuid
                                          ? "Copied!"
                                          : "Copy details"}
                                      </p>
                                    </button>
                                  </div>
                                </div>

                                <div className="portal-settlement w-[50%] border-l-[0.5px] border-l-hgray-200 pl-8 pr-2">
                                  <div className="account-nav">
                                    <h3 className="text-[16px] font-semibold leading-[31px] text-htext-label">
                                      2. Recieve here
                                    </h3>
                                    <p className="mt-[5px] w-[400px] text-[14px] font-normal leading-[19px] text-htext-subdued">
                                      Receive your swapped stablecoin into the
                                      wallet below.{" "}
                                    </p>
                                  </div>
                                  {isLoadingUserWallet && (
                                    <span className="loading loading-spinner loading-sm"></span>
                                  )}
                                  {!isLoadingUserWallet &&
                                    getTokenPreferences
                                      ?.filter(
                                        (item: { currency: string }) =>
                                          item.currency == currency
                                      )
                                      .map(({ token, chain }: any) => (
                                        <div
                                          key={token}
                                          className="info-content mt-5 rounded-lg bg-hgray-50 px-6 pb-5 pt-4"
                                        >
                                          <div className="flex w-full items-start justify-between pb-4">
                                            <div className="lower-left flex w-[48.5%] items-center">
                                              <div className="rounded-full border border-solid border-hgray-200 p-2">
                                                <span
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      token === "usdc"
                                                        ? uSDCSVG
                                                        : UsdtSvg,
                                                  }}
                                                />
                                              </div>

                                              <div className="lower-left-text ml-2 text-htext-main">
                                                <p className="text-[14px] font-semibold">
                                                  {token.toLocaleUpperCase()}
                                                </p>
                                                <p className="text-[10.5px] font-normal">
                                                  ({chain})
                                                </p>
                                              </div>
                                            </div>

                                            <div className="lower-right w-[58.5%] text-htext-main">
                                              <p className="text-[10.5px] font-normal">
                                                WALLET ADDRESS
                                              </p>
                                              <p className="mt-0.5 w-[13.4rem] break-words text-[14px] font-semibold">
                                                {userWallet?.[0].walletAddress}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="mb-4 mt-2 flex w-full items-center justify-end border-b border-t-hgray-200 pb-2">
                                            <button
                                              type="button"
                                              className="flex items-center text-[12.5px] font-semibold text-hbrand-700 hover:text-hbrand-600"
                                              onClick={() => {
                                                setShowEditWalletPopup(true);
                                                setCurrency(currency);
                                              }}
                                            >
                                              Edit
                                            </button>
                                          </div>
                                          <div className="w-full px-2">
                                            <button
                                              onClick={() =>
                                                copyAddressToClipboard(
                                                  userWallet?.[0]
                                                    .walletAddress as string
                                                )
                                              }
                                              className="flex w-full items-center justify-center rounded-lg border border-hbrand-600 bg-hbrand-600 px-4 py-2 hover:border-hbrand-700 hover:bg-hbrand-700"
                                            >
                                              {copiedId === uuid ? (
                                                <CheckIcon className="h-5 w-5 text-white" />
                                              ) : (
                                                <span
                                                  dangerouslySetInnerHTML={{
                                                    __html: CopyWhiteSVG,
                                                  }}
                                                />
                                              )}
                                              <p className="ml-2 text-[14.5px] font-semibold text-white">
                                                {copiedId === uuid
                                                  ? "Copied!"
                                                  : "Copy address"}
                                              </p>
                                            </button>
                                          </div>
                                        </div>
                                      ))}
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showCalPopup && (
        <SidePopup
          className="signup-popup"
          popClass="pt-[1.3rem] pb-[1.5rem] w-[33rem]"
        >
          <div className="popup-content-bg flex h-full flex-col items-center justify-between px-7">
            <div className=" flex w-full flex-col items-center pb-7">
              <div className="content-top w-full">
                <div className="content-nav -px-[1.5rem] flex w-full items-start justify-between">
                  <div>
                    <h3 className="text-[18px] font-semibold text-htext-main">
                      Rate calculator
                    </h3>
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => closeShowPopup()}
                  >
                    <span
                      className=""
                      dangerouslySetInnerHTML={{ __html: closeBtnSVG }}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4 w-full">
                <div className="content-cal w-full rounded-lg bg-hgray-50 px-5 py-6">
                  <div className="from-card flex w-full items-center justify-between rounded-lg border border-hgray-300 bg-white px-5 py-4 shadow-s-light">
                    <div className="w-[160px]">
                      <p className="text-left text-[14px] font-medium text-htext-main">
                        From
                      </p>
                      <input
                        type="text"
                        className="mt-2.5 bg-transparent text-left text-[18px] font-normal text-htext-main outline-none"
                        placeholder={`${
                          calBase === "EUR"
                            ? "€"
                            : calBase === "GBP"
                              ? "£"
                              : calBase === "USD"
                                ? "$"
                                : "0"
                        }`}
                        value={amount}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="from-drop">
                      <p className="text-right text-[12px] font-normal capitalize text-htext-subdued">
                        Fiat
                      </p>
                      <div
                        className="mt-2.5 cursor-pointer rounded-[500px] border-0 bg-hgray-200 py-1 pl-2 pr-2.5 text-right text-[13px] font-medium text-htext-label outline-none hover:bg-hgray-300"
                        onClick={() => setShowDropDownFiat(!showDropDownFiat)}
                        ref={fiatDropdownRef}
                      >
                        <div className="flex items-center">
                          <img
                            className="w-[14px]"
                            src={`/assets/${
                              calBase === "USD"
                                ? "dollarSmall"
                                : calBase === "EUR"
                                  ? "euroSmall"
                                  : "poundsSmall"
                            }.svg`}
                            alt={calBase}
                          />
                          <p className="ml-1 mr-2.5">{calBase}</p>
                          <span
                            className="cursor-pointer"
                            dangerouslySetInnerHTML={{
                              __html: BlueThinArrowSVG || "",
                            }}
                          />
                        </div>
                        {showDropDownFiat && (
                          <ul className="absolute z-50 -ml-10 mt-3 w-[120px] rounded-lg border border-solid border-hgray-200 bg-hgray-100 py-3">
                            {dropFiatOptions.map((option) => (
                              <li
                                key={option.value}
                                className="cursor-pointer px-4 py-1 text-left text-[13px] hover:bg-hgray-300"
                                onClick={() => {
                                  setCalBase(option.value);
                                  setShowDropDownFiat(false);
                                }}
                              >
                                <p>{option.value}</p>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="middleCont flex w-full flex-col justify-center pl-16 pr-1 pt-1.5">
                    <TransactionDetail
                      type="Portal in to stablecoin"
                      fee="- €4"
                      amount={amount || "0"}
                      rate={`x ${calRate}`}
                    />
                  </div>

                  <div className="to-card flex w-full items-center justify-between rounded-lg border border-hgray-300 bg-white px-5 py-4 shadow-s-light">
                    <div className="w-[160px]">
                      <p className="text-left text-[14px] font-medium text-htext-main">
                        To
                      </p>
                      <input
                        type="text"
                        className="mt-2.5 bg-transparent text-left text-[18px] font-normal text-htext-main outline-none"
                        placeholder={`${
                          calTarget === "EUR"
                            ? "€"
                            : calTarget === "GBP"
                              ? "£"
                              : calTarget === "USD"
                                ? "$"
                                : "0"
                        }`}
                        readOnly
                        value={calculatedValue}
                      />
                    </div>

                    <div className="to-drop">
                      <p className="text-right text-[12px] font-normal capitalize text-htext-subdued">
                        Stablecoin
                      </p>
                      <div
                        className="mt-2.5 cursor-pointer rounded-[500px] border-0 bg-hgray-200 py-1 pl-2 pr-2.5 text-right text-[13px] font-medium text-htext-label outline-none hover:bg-hgray-300"
                        onClick={() =>
                          setShowDropDownStable(!showDropDownStable)
                        }
                        ref={stableDropdownRef}
                      >
                        <div className="flex items-center">
                          <img
                            className="w-[14px]"
                            src={`/assets/${
                              calTarget === "USDC" ? "usdcSmall" : "usdtSmall"
                            }.svg`}
                            alt={calTarget}
                          />
                          <p className="ml-1 mr-2.5">{calTarget}</p>
                          <span
                            className="cursor-pointer"
                            dangerouslySetInnerHTML={{
                              __html: BlueThinArrowSVG || "",
                            }}
                          />
                        </div>
                        {showDropDownStable && (
                          <ul className="absolute z-50 -ml-10 mt-3 w-[120px] rounded-lg border border-solid border-hgray-200 bg-hgray-100 py-3">
                            {dropStableOptions.map((option) => (
                              <li
                                key={option.value}
                                className="cursor-pointer px-4 py-1 text-left text-[13px] hover:bg-hgray-300"
                                onClick={() => {
                                  setCalTarget(option.value);
                                  setShowDropDownStable(false);
                                }}
                              >
                                <p>{option.value}</p>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* <div className="flex w-full items-center justify-center pb-2 pt-7">
                    <div
                      onClick={() => {
                        setShowCalPopup(false);
                        setShowSharePopup(true);
                      }}
                      className="flex w-fit cursor-pointer items-center justify-center text-hbrand-700 hover:border-b  hover:border-hbrand-600 hover:text-hbrand-600"
                    >
                      <span
                        className=""
                        dangerouslySetInnerHTML={{ __html: shareIcon }}
                      />
                      <p className="ml-2.5 text-[14px] font-semibold">
                        Share payment request
                      </p>
                    </div>
                  </div> */}
                </div>

                <div className="content-info mt-10 w-full border-t border-t-hgray-200 py-5">
                  <div className="info-text">
                    <h5 className="text-[13px] font-medium text-htext-main">
                      Settlement information
                    </h5>
                    <p className="mt-1 w-[450px] text-[13px] font-normal text-htext-subdued">
                      Local currency sent to your portal account are sent to
                      this stablecoin wallet you selected as default.
                    </p>
                  </div>

                  <div className="info-content mt-4 rounded-lg bg-hgray-50 p-6">
                    <div className="flex w-full items-center justify-between">
                      <div className="lower-left flex w-[48.5%] items-center">
                        <div className="rounded-full border border-solid border-hgray-200 p-2">
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                cryptoCurrencyIcons[
                                  userWallet?.[0]?.token?.toLowerCase() ||
                                    "usdt"
                                ],
                            }}
                          />
                        </div>

                        <div className="lower-left-text ml-2 text-htext-main">
                          <p className="text-[14px] font-semibold">
                            {userWallet?.[0].currency.toUpperCase()}
                          </p>
                          <p className="text-[10.5px] font-normal">
                            ({userWallet?.[0].chain})
                          </p>
                        </div>
                      </div>

                      <div className="lower-right w-[58.5%] text-htext-main">
                        <p className="text-[10.5px] font-normal">
                          WALLET ADDRESS
                        </p>
                        <p className="mt-0.5 w-[13.4rem] break-words text-[14px] font-semibold">
                          {userWallet?.[0].walletAddress}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="close-btn w-full border-t border-hgray-200 py-5">
              <button
                className="w-full rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[11px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
                type="button"
                onClick={() => closeShowPopup()}
              >
                Close
              </button>
            </div>
          </div>
        </SidePopup>
      )}

      {showEditWalletPopup && (
        <SidePopup
          className="signup-popup"
          popClass="pt-[1.3rem] pb-[1.5rem] w-[38rem]"
        >
          <div className="popup-content-bg flex h-full flex-col items-center justify-between">
            <div className="content-top w-full">
              <div className="content-nav flex w-full items-start justify-between px-[1.5rem]">
                <div>
                  <h3 className="text-[18px] font-semibold text-htext-main">
                    Add your stablecoin wallet
                  </h3>
                  <p className="mt-1.5 text-[12.5px] font-normal text-htext-subdued">
                    This is your stablecoin wallet
                  </p>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => closeShowPopup()}
                >
                  <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: closeBtnSVG }}
                  />
                </div>
              </div>

              <FormProvider {...methods}>
                <form
                  className="h-[92vh]"
                  onSubmit={methods.handleSubmit(onSetUpWallet)}
                >
                  <fieldset className="h-full">
                    <div className="form-div flex h-full w-full flex-col items-center justify-between">
                      <div className="mt-8 w-full px-[1.5rem] pb-2">
                        <div
                          className={`flex w-full items-start justify-between ${
                            errors.token || errors.chain
                              ? "mt-[.80rem] h-24"
                              : "mt-[1.30rem]"
                          }`}
                        >
                          <label
                            htmlFor="stablecoin"
                            className="relative h-full w-[48.5%]"
                          >
                            <div className="mb-1.5 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>
                              <p className="text-htext-label">Stablecoin</p>
                            </div>
                            <DropDownWithIcon
                              register={register}
                              name="token"
                              options={tokenOptions}
                              error={errors.token}
                            />
                            {errors.token && (
                              <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                {errors.token.message}!
                              </p>
                            )}
                          </label>

                          <label
                            htmlFor="smartchain"
                            className="relative h-full w-[48.5%]"
                          >
                            <div className="mb-1.5 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>

                              <p className="text-htext-label">Smartchain</p>
                            </div>

                            <DropDownWithIcon
                              register={register}
                              name="chain"
                              options={filteredChainOptions}
                              error={errors.chain}
                            />
                            {errors.chain && (
                              <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                {errors.chain.message}!
                              </p>
                            )}
                          </label>
                        </div>

                        <div
                          className={`mx-auto flex w-full flex-col pb-6  pt-1 ${
                            errors.walletAddress || errors.confirmWalletAddress
                              ? "h-48 justify-start"
                              : ""
                          }`}
                        >
                          <label
                            htmlFor="walletAddress"
                            className={`${
                              errors.walletAddress
                                ? "mt-[.50rem]"
                                : "mt-[1.30rem]"
                            } w-full"`}
                          >
                            <div className="mb-1 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>

                              <p className="text-htext-label">Wallet address</p>
                            </div>
                            <Inputs
                              type="text"
                              placeholder="Enter here"
                              register={register("walletAddress")}
                              error={errors.walletAddress}
                              inputClass={true}
                            />
                            {errors.walletAddress && (
                              <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                {errors.walletAddress.message}!
                              </p>
                            )}
                          </label>

                          <label
                            htmlFor="confirmWalletAddress"
                            className={`${
                              errors.confirmWalletAddress
                                ? "mt-[2.3rem]"
                                : "mt-[1.30rem]"
                            } w-full"`}
                          >
                            <div className="mb-1 flex items-center text-[13px] font-medium">
                              <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                *
                              </p>

                              <p className="text-htext-label">
                                Confirm wallet address
                              </p>
                            </div>
                            <Inputs
                              type="text"
                              placeholder="Enter here"
                              register={register("confirmWalletAddress")}
                              inputClass={true}
                              error={errors.confirmWalletAddress}
                            />
                            {errors.confirmWalletAddress && (
                              <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                {errors.confirmWalletAddress.message}!
                              </p>
                            )}
                          </label>
                        </div>
                      </div>

                      <div className="pop-btns flex h-fit w-full items-center justify-end border-t border-hgray-200 px-[1.5rem] pb-5 pt-7">
                        <button
                          type="button"
                          onClick={closeShowPopup}
                          className="shadow-s-light] mr-[5px] rounded-lg border border-hgray-300 bg-white px-3.5 py-2.5 text-[15px] font-semibold text-hgray-700 hover:opacity-70"
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          // disabled={!isFormData}
                          //  onClick={() => handleNavigate("/login")}
                          className="ml-[5px] rounded-lg border border-hbrand-600 bg-hbrand-600 px-12 py-2.5 text-[15px] font-semibold text-white shadow-s-light hover:opacity-80"
                        >
                          {isSettingUpWallet ||
                          isUpdatingWallet ||
                          isLoadingGettingSession ? (
                            <span className="loading loading-spinner loading-sm"></span>
                          ) : (
                            "Save wallet details"
                          )}
                        </button>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </FormProvider>
            </div>
          </div>
        </SidePopup>
      )}

      {showSharePopup && (
        <SidePopup
          className="signup-popup"
          popClass="pt-[1.3rem] pb-[1.5rem] w-[33rem]"
        >
          <div className="popup-content-bg flex h-full flex-col items-center justify-between px-7">
            <div className=" flex w-full flex-col items-center pb-7">
              <div className="content-top w-full">
                <div className="content-nav -px-[1.5rem] flex w-full items-start justify-between">
                  <p
                    className="flex cursor-pointer items-center text-[13px] font-semibold text-hbrand-600 hover:text-hbrand-700"
                    onClick={() => handleGoBack()}
                  >
                    <span
                      className="mr-2.5"
                      dangerouslySetInnerHTML={{ __html: BackArrowSVG }}
                    />
                    Go back
                  </p>

                  <div
                    className="cursor-pointer"
                    onClick={() => setShowSharePopup(false)}
                  >
                    <span
                      className=""
                      dangerouslySetInnerHTML={{ __html: closeBtnSVG }}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-3 w-full">
                <div className="content-info w-full pb-5">
                  <div className="info-text">
                    <h5 className="text-[18px] font-semibold text-htext-main">
                      Share payment request
                    </h5>
                    <p className="mt-1 w-[450px] text-[13px] font-normal text-htext-subdued">
                      Your wallet address and the amount can be shared to
                      receive your payment faster and more conveniently
                    </p>
                  </div>

                  <div className="info-content mt-4 flex flex-col justify-center gap-y-3 rounded-lg bg-hgray-50 p-6">
                    <div className="info-top flex w-full items-center gap-x-6">
                      <div className="flex items-center">
                        <img
                          src={getImageSrc(
                            `${searchParams.get(
                              "amountToSnd"
                            )} to ${searchParams.get("amountToRcv")}`
                          )}
                          alt="Conversion stuff"
                        />
                        <div className="ml-2.5 flex flex-col items-start justify-center">
                          <p className="text-[12px] font-normal text-htext-subdued">
                            Send
                          </p>
                          <p className="text-[18px] font-semibold text-htext-main">
                            {` ${searchParams.get(
                              "calBase"
                            )} ${searchParams.get("amountToSnd")}`}
                          </p>
                        </div>
                      </div>

                      <span
                        className=""
                        dangerouslySetInnerHTML={{ __html: slideRight }}
                      />
                      <div className="flex flex-col items-start justify-center">
                        <p className="text-[12px] font-normal text-htext-subdued">
                          Receive
                        </p>
                        <p className="text-[18px] font-semibold text-htext-main">
                          {` ${searchParams.get("calTarget")}${searchParams.get(
                            "amountToRcv"
                          )}`}
                        </p>
                      </div>
                    </div>

                    <div className="info-bottom mt-2.5 space-y-4 border-t border-t-hgray-200 py-4">
                      <h6 className="text-[11px] font-normal text-htext-main">
                        WALLET DETAILS
                      </h6>
                      <div className="flex items-start justify-between">
                        <p className="text-[13px] font-normal text-htext-main">
                          Network
                        </p>
                        <div className="flex items-center">
                          <p className="text-[13px] font-semibold text-htext-main">
                            {userWallet?.[0].chain}
                          </p>
                          {
                            chainOptions.find(
                              (chain) => chain.value == userWallet?.[0].chain
                            )?.icon
                          }
                          {/* <span
                            className="ml-2"
                            dangerouslySetInnerHTML={{
                              __html: chainOptions.find(
                                (chain) => chain.value == userWallet?.[0].chain
                              )?.icon as TrustedHTML,
                            }}
                          /> */}
                        </div>
                      </div>

                      <div className="flex items-start justify-between">
                        <p className="text-[13px] font-normal text-htext-main">
                          Wallet
                        </p>

                        <p className="w-[180px] break-words text-right text-[13px] font-semibold text-htext-main">
                          {userWallet?.[0].walletAddress}
                        </p>
                      </div>
                    </div>

                    <div className="rounded-[12px] bg-hwarning-50 p-4 shadow-s-light">
                      <div className="flex items-start">
                        <span
                          className="-mt-1.5"
                          dangerouslySetInnerHTML={{
                            __html: flusteredWarning,
                          }}
                        />
                        <p className="ml-2.5 text-[12.5px] font-medium text-[#4E1D09]">
                          Always verify your wallet address to ensure a safe
                          transaction. Sending funds to an incorrect address may
                          result in a permanent loss of your assets. We cannot
                          recover funds sent to the wrong address.
                        </p>
                      </div>
                    </div>

                    <button
                      className="mt-3.5 flex w-full items-center justify-center rounded-lg border border-solid border-brand-600 bg-white px-[10px] py-[8px] text-[12.5px] font-semibold text-hbrand-700 shadow-s-light hover:bg-hgray-50"
                      type="button"
                      onClick={handleCopy}
                      aria-label={
                        isCopied
                          ? "Wallet address copied"
                          : "Copy wallet address"
                      }
                    >
                      {isCopied ? (
                        <CheckIcon className="h-4 w-4 text-green-500" />
                      ) : (
                        <span
                          className=""
                          dangerouslySetInnerHTML={{ __html: CopyBlueSVG }}
                        />
                      )}
                      <p className="ml-1.5">
                        {isCopied ? "Copied!" : "Copy wallet address"}
                      </p>
                    </button>
                  </div>

                  <form
                    onSubmit={handleSubmit(onShareSubmit)}
                    className="mt-4 pb-10"
                  >
                    <div className="mb-1 flex items-center text-[13px] font-medium">
                      <p className="text-htext-label">Share via email</p>
                    </div>

                    <div className="flex items-center gap-x-3">
                      <label htmlFor="email" className="relative w-full">
                        <Inputs
                          type="email"
                          placeholder="Enter here"
                          icon={EmailSVG}
                          inputClass={true}
                          register={registerShareLInk("email")}
                          error={shareLinkErrors.email}
                        />
                      </label>

                      <button
                        className="w-[100px] rounded-lg border  border-solid border-hbrand-600 bg-white px-[10px] py-[9.8px] text-[13px] font-semibold text-hbrand-700 shadow-s-light hover:bg-hgray-50"
                        type="submit"
                      >
                        {isSharingLink ? (
                          <span className="loading loading-spinner loading-sm"></span>
                        ) : (
                          "Send"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="close-btn flex w-full items-center justify-end border-t border-hgray-200 pb-5 pt-5">
              <button
                className="w-[140px] rounded-lg border  border-solid border-hgray-300 bg-white px-[10px] py-[11px] text-[15px] font-semibold text-hgray-700 shadow-s-light hover:bg-hgray-50"
                type="button"
                onClick={() => navigate("/dashboard/portal-accounts")}
              >
                Go Back
              </button>

              <button
                className="ml-7 w-[280px] rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[11px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
                type="button"
                onClick={() => closeShowPopup()}
              >
                Close
              </button>
            </div>
          </div>
        </SidePopup>
      )}
    </div>
  );
};

export default PortalInDetails;
