import FAQPage from "../../components/Dashboard/faq";

function HelpandFaq() {
  return (
    <div className="get-started px-8 py-6">
      <div className="get-started-content">
          {/* <ComingSoon name="Help & Faq" /> */}
         < FAQPage/>
      </div>
    </div>
  );
}

export default HelpandFaq;
