import ResetPassword from "../../components/Auth/ResetPassword";

function AuthResetPassword() {
    return (
            <div>
                <ResetPassword />
            </div>
    )
}

export default AuthResetPassword;