import classNames from "classnames";

const Sidebar = ({ setSelectedSection, section, toggleDrawer }: any) => (
    <div className="mb-6 flex flex-col space-y-4">
      <a
        onClick={() => setSelectedSection("all")}
        className={classNames(
          "pl-3 py-2 text-start text-sm font-semibold text-gray-700 cursor-pointer",
          {
            "text-hbrand-630 border-l-2 border-l-hbrand-600 text-hbrand-600 ":
              "all" == section,
          }
        )}
      >
        All
      </a>
      <a
        onClick={() => setSelectedSection("aboutShiga")}
        className={classNames(
          "pl-3 py-2 text-start text-sm font-semibold text-gray-700 cursor-pointer",
          {
            "text-hbrand-630 border-l-2 border-l-hbrand-600 text-hbrand-600":
              "aboutShiga" == section,
          }
        )}
      >
        About Shiga
      </a>
      <a
        onClick={() => setSelectedSection("keyBenefits")}
        className={classNames(
          "pl-3 py-2 text-start text-sm font-semibold text-gray-700 cursor-pointer",
          {
            "text-hbrand-630 border-l-2 border-l-hbrand-600 text-hbrand-600":
              "keyBenefits" == section,
          }
        )}
      >
        Key Benefits
      </a>
      <a
        onClick={() => setSelectedSection("howToUseShiga")}
        className={classNames(
          "pl-3 py-2 text-start text-sm font-semibold text-gray-700 cursor-pointer",
          {
            "text-hbrand-630 border-l-2 border-l-hbrand-600 text-hbrand-600":
              "howToUseShiga" == section,
          }
        )}
      >
        How to use Shiga
      </a>
  
      <a
        onClick={() => setSelectedSection("contactSupport")}
        className={classNames(
          "pl-3 py-2 text-start text-sm font-semibold text-gray-700 cursor-pointer",
          {
            "text-hbrand-630 border-l-2 border-l-hbrand-600 text-hbrand-600":
              "contactSupport" == section,
          }
        )}
      >
        Contact & Support
      </a>
      <h1 className="font-ibm-plex mb-5 text-2xl font-bold text-hgray-900">
        How can we help?
      </h1>
      <div className="mt-4 text-gray-600">Send a message to our support team</div>
      <button
        type="button"
        onClick={toggleDrawer}
        className="focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 mb-2 me-2 w-[60%] rounded-lg border border-blue-700 px-5 py-2.5 text-center text-sm font-medium  text-blue-700"
      >
        Send us a messsage{" "}
      </button>
    </div>
  );

  export default Sidebar