import * as z from 'zod';

export const passwordSchema = z.string()
  .min(8, "Password must be at least 8 characters long")
  .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
  .regex(/[0-9]/, "Password must contain at least one number")
  .regex(/[^A-Za-z0-9]/, 'Password must contain at least one special character');


export const signupSchema = z.object({
    email: z.string().email("Invalid email address"),
    password: passwordSchema,
    confirmPassword: z.string()
  }).refine(data => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"], // Path to the error
  });

  export const loginSchema = z.object({
    email: z.string().email("Invalid email address"),
    password: passwordSchema,
  })

  export const resetLinkSchema = z.object({
    email: z.string().email("Invalid email address"),
  })

  export const verifyOtpSchema = z.string()
  .length(6, "The 6-digit code sent to your email is required")
  .regex(/^\d+$/, "OTP must contain only numeric digits");

  export const resetPasswordSchema = z.object({
    password: passwordSchema,
    confirmPassword: z.string()
  }).refine(data => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"], // Path to the error
  });
  
 export type SignupSchema = z.infer<typeof signupSchema>;
 export type LoginSchema = z.infer<typeof loginSchema>;
 export type VerifyOtpSchema = z.infer<typeof verifyOtpSchema>;
 export type ResetLinkSchema = z.infer<typeof resetLinkSchema>;
 export type ResetPasswordSchema = z.infer<typeof resetPasswordSchema>;