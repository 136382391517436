import LoginOtp from "../../components/Auth/LoginOtp";

function AuthLoginOtp() {
    return (
            <div>
                <LoginOtp />
            </div>
    )
}

export default AuthLoginOtp;