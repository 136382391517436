import { FC } from "react";
import { arrowRight } from "../../../../Common/svg/index";
import Stepper from "../../../../Common/stepper/stepper";

interface WalletChildren {
  children: any;
  currentStep: number;
  complete: boolean;
  // onStepChange:(e:any)=>vid
}

const WalletsSetup: FC<WalletChildren> = ({ children, currentStep, complete }) => {
  // const [currentStep, setCurrentStep] = useState(1); // Set default step to the third step
  const steps = [
    "How it works",
    "Setup accounts",
    "Setup wallets",
    "Confirm details",
  ];

  return (
    <div className="pform h-screen w-screen bg-white pb-2 pt-4">
      <div className="pform-content h-full w-full overflow-hidden">
        <div className="content-nav mt-5 flex items-center px-40">
          <p className="text-[13px] font-medium text-htext-label">
            Onboarding checklist
          </p>
          <span
            className="mx-4"
            dangerouslySetInnerHTML={{ __html: arrowRight }}
          />
          <p className="text-[13px] font-semibold text-hbrand-700">
            Setup wallets & virtual accounts
          </p>
        </div>

        <div className="content-nav mx-auto h-full w-full pb-52">
          <Stepper steps={steps} currentStep={currentStep} complete={complete}/>
          {children}
        </div>
      </div>
    </div>
  );
};

export default WalletsSetup;
