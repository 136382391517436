import BusinessInfoForm from "../../../../components/Dashboard/get-started/onboarding-checklist/BusinessInfoForm";

function DashOnboardingBusinessInfo() {
    return (
            <div>
                <BusinessInfoForm />
            </div>
    )
}

export default DashOnboardingBusinessInfo;