import { FC } from "react";

const MiniLoader: FC = () => {
  return (
    <div className="relative h-full w-full overflow-hidden bg-hgray-50">
          <div className="flex items-center justify-center">
            <img
              className="circle w-[90px] p-4 grayscale"
              src="/loaderEllipse.png"
              alt="Loader icon"
            />
          </div>
    </div>
  );
};

export default MiniLoader;