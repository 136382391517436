import PortalInSettlementInfo from "../../../../components/Dashboard/portal-accounts/portal-in/portal-in-settlement-info";

function PortalInSettlementInformation() {
    return (
            <div>
                <PortalInSettlementInfo />
            </div>
    )
}

export default PortalInSettlementInformation;