import React, { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { trpc } from "../../../lib/trpc";
import { boxedCheck, deleteBasket, filePagePDF } from "../svg";

interface FileUploadProps {
  name: string;
  error?: any;

}

const FileUpload: FC<FileUploadProps> = ({ name, error }) => {
  const [file, setFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<
    "idle" | "uploading" | "uploaded"
  >("idle");
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const { data } = trpc.uploads.getUploadSignedUrl.useQuery({
    id: name,
    contentType: "image/jpeg",
  });

  const { register,setValue,watch } = useFormContext(); // useFormContext to get the register function

  const fileValue = watch(name); // Watch the value of the field to display it

  useEffect(() => {
    if (fileValue) {
      setFile(new File([""], fileValue));
      setUploadStatus("uploaded");
    }
  }, [fileValue]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
      setUploadStatus("uploading");
      try {
        setValue(name, data?.key as string);
        await uploadToS3(data?.url as string, selectedFile);
        setUploadStatus("uploaded");
      } catch (error) {
        console.error("Upload failed", error);
        setUploadStatus("idle");
      }
    }
  };


  const uploadToS3 = (url: string, file: File) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", url);
      xhr.setRequestHeader("Content-Type", file.type);
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          setUploadProgress((event.loaded / event.total) * 100);
        }
      };
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(true);
        } else {
          reject(xhr.statusText);
        }
      };
      xhr.onerror = () => reject(xhr.statusText);
      xhr.send(file);
    });
  };

  const handleRemoveFile = () => {
    setFile(null);
    setUploadStatus("idle");
    setUploadProgress(0);
  };


  return (
    <div
      className={`rounded-[8px] ${
        error
          ? "border-red-500 shadow-s-light-red ring-r-width ring-r-color-red focus:outline-red-400"
          : "border-hgray-300 shadow-s-light"
      } border border-solid  ${
        uploadStatus === "idle"
          ? "px-4 py-6 hover:border-2 hover:border-dashed"
          : "p-4"
      }`}
    >
      {uploadStatus === "idle" && (
        <label
          htmlFor={name} // Unique id for each input
          className="flex cursor-pointer flex-col items-center justify-center"
        >
          <div className="flex items-center justify-center">
            <svg
              width="38"
              height="39"
              viewBox="0 0 45 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_1680_3037)">
                <rect
                  x="2.5"
                  y="1"
                  width="40"
                  height="40"
                  rx="8"
                  fill="white"
                  shapeRendering="crispEdges"
                />
                <rect
                  x="3"
                  y="1.5"
                  width="39"
                  height="39"
                  rx="7.5"
                  stroke="#EAECF0"
                  shapeRendering="crispEdges"
                />
                <path
                  opacity="0.4"
                  d="M18.6458 18.8125L26.9792 18.8125C28.8201 18.8125 30.3125 20.3049 30.3125 22.1458L30.3125 25.4792C30.3125 27.3201 28.8201 28.8125 26.9792 28.8125L18.6458 28.8125C16.8049 28.8125 15.3125 27.3201 15.3125 25.4792L15.3125 22.1458C15.3125 20.3049 16.8049 18.8125 18.6458 18.8125Z"
                  fill="#475467"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19.8706 16.7544C19.6265 16.5104 19.6265 16.1146 19.8706 15.8706L22.3706 13.3706C22.6146 13.1265 23.0104 13.1265 23.2544 13.3706L25.7544 15.8706C25.9985 16.1146 25.9985 16.5104 25.7544 16.7544C25.5104 16.9985 25.1146 16.9985 24.8706 16.7544L23.4375 15.3214V23.8125C23.4375 24.1577 23.1577 24.4375 22.8125 24.4375C22.4673 24.4375 22.1875 24.1577 22.1875 23.8125V15.3214L20.7544 16.7544C20.5104 16.9985 20.1146 16.9985 19.8706 16.7544Z"
                  fill="#475467"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_1680_3037"
                  x="0.5"
                  y="0"
                  width="44"
                  height="44"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="1" />
                  <feGaussianBlur stdDeviation="1" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1680_3037"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1680_3037"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
            <span className="ml-2.5 w-48 text-center text-[11px] font-normal text-htext-subdued">
              <b className="text-[13px] font-semibold text-hbrand-700">
                Click to upload
              </b>{" "}
              or drag and drop PDF, PNG, JPG (max. 10mb)
            </span>
          </div>
          <input
            id={name} // Unique id for each input
            type="file"
            className="hidden"
            name={name}
            onChange={handleFileChange}
            ref={register(name).ref}
          />
        </label>
      )}
      {uploadStatus === "uploading" && file && (
        <div className="flex items-center justify-between">
          <div className="flex items-center text-hgray-500">
            {file.type.includes("image") ? (
              <svg
                className="h-6 w-6 text-gray-500"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 4.5C13.3807 4.5 14.5 3.38071 14.5 2C14.5 0.619289 13.3807 -0.5 12 -0.5C10.6193 -0.5 9.5 0.619289 9.5 2C9.5 3.38071 10.6193 4.5 12 4.5Z"
                  fill="currentColor"
                />
                <path d="M4.5 6.5H19.5V7.5H4.5V6.5Z" fill="currentColor" />
                <path
                  d="M4 8.5H20C20.5523 8.5 21 8.94772 21 9.5V20.5C21 21.0523 20.5523 21.5 20 21.5H4C3.44772 21.5 3 21.0523 3 20.5V9.5C3 8.94772 3.44772 8.5 4 8.5Z"
                  fill="currentColor"
                />
                <path
                  d="M9.5 11.5H14.5C15.0523 11.5 15.5 11.9477 15.5 12.5V19.5H8.5V12.5C8.5 11.9477 8.94772 11.5 9.5 11.5Z"
                  fill="currentColor"
                />
              </svg>
            ) : (
              <span
                className=""
                dangerouslySetInnerHTML={{ __html: filePagePDF || "" }}
              />
            )}
            <div className="ml-2.5 overflow-hidden text-ellipsis">
              <p className="text-[12.5px] font-medium text-htext-label">
                {file.name}
              </p>
              <p className="flex items-center text-[13px] font-normal text-htext-subdued">
                {(file.size / 1024).toFixed(2)} KB - {uploadProgress.toFixed(2)}
                % uploaded
              </p>
            </div>
          </div>
        </div>
      )}
      {uploadStatus === "uploaded" && file && (
        <div className="flex items-center justify-between">
          <div className="flex items-center text-hgray-500">
            {file.type.includes("image") ? (
              <svg
                className="h-6 w-6 text-gray-500"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 4.5C13.3807 4.5 14.5 3.38071 14.5 2C14.5 0.619289 13.3807 -0.5 12 -0.5C10.6193 -0.5 9.5 0.619289 9.5 2C9.5 3.38071 10.6193 4.5 12 4.5Z"
                  fill="currentColor"
                />
                <path d="M4.5 6.5H19.5V7.5H4.5V6.5Z" fill="currentColor" />
                <path
                  d="M4 8.5H20C20.5523 8.5 21 8.94772 21 9.5V20.5C21 21.0523 20.5523 21.5 20 21.5H4C3.44772 21.5 3 21.0523 3 20.5V9.5C3 8.94772 3.44772 8.5 4 8.5Z"
                  fill="currentColor"
                />
                <path
                  d="M9.5 11.5H14.5C15.0523 11.5 15.5 11.9477 15.5 12.5V19.5H8.5V12.5C8.5 11.9477 8.94772 11.5 9.5 11.5Z"
                  fill="currentColor"
                />
              </svg>
            ) : (
              <span
                className=""
                dangerouslySetInnerHTML={{ __html: filePagePDF || "" }}
              />
            )}
            <div className="ml-2.5 overflow-hidden text-ellipsis">
              <p className="text-[12.5px] font-medium text-htext-label">
                {file.name}
              </p>
              {error ? (
                <>
                  <p className=" text-[13px] font-normal text-htext-subdued">
                    Upload failed, please try again
                  </p>
                  <p className="text-[13px] font-semibold text-hgray-600">
                    Try again
                  </p>
                </>
              ) : (
                <p className="flex items-center text-[13px] font-normal text-htext-subdued">
                  {(file.size / 1024).toFixed(2)} KB - 100% uploaded{" "}
                  <span
                    className="ml-2"
                    dangerouslySetInnerHTML={{ __html: boxedCheck || "" }}
                  />
                </p>
              )}
            </div>
          </div>
          <button
            onClick={handleRemoveFile}
            className="text-red-600 hover:text-red-800"
          >
            <span
              className=""
              dangerouslySetInnerHTML={{ __html: deleteBasket || "" }}
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
