import BankAccounts from "./accounts";
import Wallet from "./wallets";

export default function Settlement() {


  return (
    <div className="mt-6 grid grid-cols-2 gap-6">
      <BankAccounts />
      <Wallet />
    </div>
  );
}