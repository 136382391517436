import PortalInStable from "../../../../components/Dashboard/portal-accounts/portal-in/portal-into-stablecoin";

function PortalInAccounts() {
    return (
            <div>
                <PortalInStable />
            </div>
    )
}

export default PortalInAccounts;