import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Inputs from "../../../components/Common/inputs";
import { HidePassSVG, ShowPassSVG } from "../../../components/Common/svg";
import { trpc } from "../../../lib/trpc";
import {
  resetPasswordSchema,
  ResetPasswordSchema,
} from "../../../utils/validationSchema";

const ResetPassword: FC = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const resetToken = new URLSearchParams(search).get("resetToken") as string;

  if (!resetToken) {
    navigate("/reset-link-error");
    return;
  }

  const handleNavigate = (route: string) => {
    navigate(route); // Replace '/new-route' with your desired route
  };

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(resetPasswordSchema),
    mode: "onChange",
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const { mutate: resetPassword, isLoading } =
    trpc.user.resetPassword.useMutation({
      onSuccess: (res) => {
        console.log(res, "resss");
        handleNavigate(`/login`);
        toast.success("Password updated, successfully!");
        reset();
      },
      onError: (err) => {
        if (err.message === "Token is invalid or has expired") {
          console.log(err, "errrrrr");
          handleNavigate("/reset-link-error");
          toast.error(err.message);
        } else {
          toast.error(err.message);
        }
      },
    });

  const onSubmit = useCallback(
    async (data: ResetPasswordSchema) => {
      resetPassword({ password: data.password, token: resetToken || "" });
    },
    [resetPassword]
  );

  // const password = watch('password');
  // const confirmPassword = watch('confirmPassword');

  return (
    <div className="login relative h-screen w-screen bg-white">
      <div className="login-content flex h-full w-full flex-col items-start overflow-hidden">
        <section className="content-top flex w-full items-center justify-between px-16 py-10">
          <div className="w-[120px]">
            <Link to="/login">
              <img
                className="h-full w-full"
                src="/logo.jpeg"
                alt="Shiga Logo"
              />
            </Link>
          </div>
        </section>

        <section className="content-right mt-[8px] justify-center self-center justify-self-center rounded-lg bg-white px-[30px] pb-[25px] pt-[5px]">
          <div className="right-top mt-[8px] w-[560px]">
            <div className="font-ibm-plex w-full font-normal text-htext-main antialiased">
              <h1 className=" text-left text-[28px] font-semibold tracking-[-0.72px]">
                Enter a new password
              </h1>
              <p className="mt-2 flex items-center text-[14px] text-htext-subdued ">
                <span className="mr-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      opacity="0.4"
                      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z"
                      fill="#475467"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 5.25C10.4142 5.25 10.75 5.58579 10.75 6V7C10.75 7.41421 10.4142 7.75 10 7.75C9.58579 7.75 9.25 7.41421 9.25 7V6C9.25 5.58579 9.58579 5.25 10 5.25ZM10 8.75C10.4142 8.75 10.75 9.08579 10.75 9.5V14C10.75 14.4142 10.4142 14.75 10 14.75C9.58579 14.75 9.25 14.4142 9.25 14V9.5C9.25 9.08579 9.58579 8.75 10 8.75Z"
                      fill="#475467"
                    />
                  </svg>
                </span>{" "}
                Your new password must be at least 8 characters
              </p>
            </div>
          </div>
          <form className="w-[560px]" onSubmit={handleSubmit(onSubmit)}>
            <fieldset className="mt-[30px] w-full rounded-[10px] bg-hgray-50 px-4 py-7">
              <div className="">
                <label
                  htmlFor="password"
                  className="font-ibm-plex text-[14px] font-bold text-htext-label antialiased"
                >
                  New password
                  <Inputs
                    type="password"
                    placeholder="Enter here"
                    showIcon={ShowPassSVG}
                    hideIcon={HidePassSVG}
                    register={register("password")}
                    error={errors.password}
                  />
                  {errors.password && (
                    <p className="font-ibm-plex mt-1.5 text-[13px] font-medium text-red-600">
                      {errors.password.message}!
                    </p>
                  )}
                </label>
              </div>

              <div className="mt-[22px]">
                <label
                  htmlFor="confirmPassword"
                  className="font-ibm-plex text-[14px] font-bold text-htext-label antialiased"
                >
                  Re-enter new password
                  <Inputs
                    type="password"
                    placeholder="Enter here"
                    showIcon={ShowPassSVG}
                    hideIcon={HidePassSVG}
                    register={register("confirmPassword")}
                    error={errors.confirmPassword}
                  />
                  {errors.confirmPassword && (
                    <p className="font-ibm-plex mt-1.5 text-[13px] font-medium text-red-600">
                      {errors.confirmPassword.message}!
                    </p>
                  )}
                </label>
              </div>
            </fieldset>
            <div className="font-ibm-plex mt-[5px] w-full font-normal antialiased">
              <button
                className="mb-[16px] mt-[50px] w-full rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[11px] text-[15px] font-bold text-white hover:bg-hbrand-700"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <span className="loading loading-spinner loading-sm"></span>
                ) : (
                  "Reset password"
                )}
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default ResetPassword;
