import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { trpc } from "../../../../lib/trpc";
import { formatTimestamp } from "../../../../utils";
import RadioButton from "../../../Common/buttons/radioBtn";
import Inputs from "../../../Common/inputs";
import Select from "../../../Common/inputs/select";
import SidePopup from "../../../Common/popup/sidepopup";
import {
  addOwnSVG,
  arrowRight,
  closeBtnSVG,
  EmailSVG,
  warnAvatarSVG,
  warnToolTipBlueSVG,
  warnToolTipSVG,
} from "../../../Common/svg/index";
import createUBOprofileForOldUserSchema, {
  CreateUBOprofileForOldUserSchema,
} from "./schema";

type OwnerTypes = "YES" | "NO";

const OwnershipInfoForm: FC = () => {
  const navigate = useNavigate();
  const trpcUtils = trpc.useUtils();
  const methods = useForm({
    resolver: zodResolver(createUBOprofileForOldUserSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      jobTitle: "",
      percentageOfOwnership: "",
      email: "",
      phoneNumber: "",
      isPep: false,
      isOwner: false,
    },
  });

  const {
    setValue,
    register,
    reset,
    formState: { errors },
  } = methods;

  const { mutate, isLoading: isCreateCorpUbo } =
    trpc.user.createCorporateUboForExistingUser.useMutation({
      onSuccess: () => {
        reset();
        trpcUtils.user.getCorporateUbo.invalidate();
        setShowForm(false);
      },
    });

  const { mutate: inviteUBO, isLoading: isinvitingUBO } =
    trpc.user.inviteCorporateUBO.useMutation({
      onSuccess: () => {
        reset();
        trpcUtils.user.getCorporateUbo.invalidate();
        setShowForm(false);
      },
    });

  const { data: corporateUbos, isLoading: isFetchingCorporateUbos } =
    trpc.user.getCorporateUbo.useQuery({});

  const [showForm, setShowForm] = useState(false);

  const [selectedValue, setSelectedValue] = useState<OwnerTypes>();
  const [selectedPEP, setSelectedPEP] = useState<OwnerTypes>();

  const handleNavigate = () => {
    navigate("/dashboard/get-started");
  };

  const handleGoBack = () => {
    navigate("/get-started/onboarding-checklist/business-documents");
  };

  const sof = ["DIRECTOR", "CEO", "CFO", "COO", "OTHERS"];

  const handleRadioChangeValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedValue(event.target.value as OwnerTypes);
    setValue("isOwner", event.target.value == "YES");
  };
  const handleRadioChangePEP = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedPEP(value as OwnerTypes);
    setValue("isPep", value == "YES");
  };

  function closeShowPopup() {
    if (showForm === true) {
      setShowForm(false);
      reset();
    }
  }

  const createUBO = (data: CreateUBOprofileForOldUserSchema) => {
    const {
      isOwner,
      isPep,
      jobTitle,
      percentageOfOwnership,
      firstName,
      lastName,
      email,
      phoneNumber,
    } = data;
    if (selectedValue == "YES") {
      mutate({
        isOwner,
        isPep: isPep as boolean,
        jobTitle: jobTitle as string,
        percentageOfOwnership: percentageOfOwnership as string,
      });
    } else {
      inviteUBO({
        firstName: firstName as string,
        lastName: lastName as string,
        email: email as string,
        phoneNumber: phoneNumber as string,
      });
    }
  };
  return (
    <div className="pform h-screen w-screen bg-white pb-2 pt-4">
      <div className="pform-content h-full w-full overflow-scroll">
        <div className="content-nav mt-5 flex items-center px-40">
          <p className="text-[13px] font-medium text-htext-label">
            Onboarding checklist
          </p>
          <span
            className="mx-4"
            dangerouslySetInnerHTML={{ __html: arrowRight }}
          />
          <p className="text-[13px] font-semibold text-hbrand-700">
            Provide your ownership information
          </p>
        </div>

        <div className="content-form mx-auto h-full w-full pb-40 ">
          <div className="form-header mx-auto mt-14 w-[800px] pb-8">
            <div>
              <h2 className="text-[28px] font-semibold tracking-[-0.72px] text-htext-main">
                Ownership information
              </h2>
            </div>
          </div>
          <div
            className={`hide-scrollbar h-full w-full ${
              !corporateUbos?.length ? "overflow-y-none pb-2" : "pb-10"
            }`}
          >
            <div className="header-header mx-auto w-[800px] rounded-t-[12px] border border-b-0 border-hgray-100 bg-hgray-50 px-5 pb-3 pt-5">
              <h4 className="text-[18px] font-semibold text-htext-main">
                Ultimate Beneficial Owner (UBO) Information
              </h4>
              <p className="mt-2.5 text-[13px] font-normal text-htext-subdued">
                {!corporateUbos?.length
                  ? " To comply with KYC regulations, please provide the personal details of your Ultimate Beneficial Owners (UBOs) or Persons with Significant Control (PSCs)."
                  : "Provide details about the individual who ultimately owns or controls the business"}
              </p>
            </div>
            <form className="form-content h-full w-full rounded-b-[16px] bg-white">
              <fieldset>
                <div className="mx-auto w-[800px] rounded-b-[12px] border border-t-0 border-hgray-100 px-6 pb-6 pt-1">
                  {isFetchingCorporateUbos ? (
                    <div className="mx-auto mt-9 flex w-[16rem] flex-col items-center">
                      <span className="loading loading-spinner loading-lg"></span>
                    </div>
                  ) : !corporateUbos?.length ? (
                    <div className="mx-auto mt-9 flex w-[16rem] flex-col items-center">
                      <span
                        className="w-18"
                        dangerouslySetInnerHTML={{ __html: addOwnSVG }}
                      />
                      <p className="text-center text-[12.5px] font-normal text-gray-600">
                        These are the company director(s) as listed in your
                        business documents{" "}
                      </p>
                    </div>
                  ) : (
                    <>
                      {corporateUbos.map(
                        ({
                          firstName,
                          lastName,
                          email,
                          phoneNumber,
                          uboVerificationStatus,
                          createdAt,
                        }) => (
                          <div className="mt-6">
                            <div className="mb-6 flex items-center justify-between rounded-lg bg-hgray-50 px-6 py-6">
                              <div className="flex items-start text-hsuccess-600">
                                <span
                                  className="w-18"
                                  dangerouslySetInnerHTML={{
                                    __html: warnAvatarSVG,
                                  }}
                                />
                                <div className="ml-3.5 flex flex-col items-start justify-center">
                                  <p className="text-[14px] font-medium text-htext-main">
                                    {`${firstName} ${lastName}`}
                                  </p>
                                  <p className="my-1.5 flex items-center text-[14px] font-normal text-htext-main">
                                    {phoneNumber as string}{" "}
                                    <b className="mx-1 mt-[-6px] h-auto w-auto text-[15px] font-bold">
                                      .
                                    </b>{" "}
                                    {email as string}{" "}
                                  </p>
                                  <p className="text-[12px] font-normal text-htext-subdued">
                                    Link sent {formatTimestamp(createdAt)}
                                  </p>
                                </div>
                              </div>
                              <div
                                className={classNames(
                                  "rounded-[6px] border px-1.5 py-[0.05rem]",
                                  {
                                    "border-hsuccess-200 bg-hsuccess-50":
                                      uboVerificationStatus == "COMPLETE",
                                    "text-hwarning-700":
                                      uboVerificationStatus !== "COMPLETE",
                                  }
                                )}
                              >
                                <p
                                  className={classNames(
                                    "text-[12px] font-medium lowercase",
                                    {
                                      "text-hsuccess-700":
                                        uboVerificationStatus == "COMPLETE",
                                    }
                                  )}
                                >
                                  {uboVerificationStatus}
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </>
                  )}

                  <div
                    className={`mt-6 flex w-full items-center pb-1 ${
                      !corporateUbos?.length
                        ? "justify-center"
                        : "justify-start"
                    }`}
                  >
                    <button
                      className={` rounded-lg border border-solid border-brand-600 ${
                        !corporateUbos?.length
                          ? "w-[230px] bg-brand-600 text-white hover:bg-hbrand-700 "
                          : "w-[190px] bg-white text-hbrand-600 hover:bg-hgray-50"
                      } px-[10px] py-[9px] text-[14px] font-semibold shadow-s-light `}
                      type="button"
                      onClick={() => setShowForm(true)}
                    >
                      Add owner’s information
                    </button>
                  </div>

                  {/* 
                  <div className="mt-6 flex w-full items-center justify-center pb-1">
                    <button
                      className="w-[230px] rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[9px] text-[14px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
                      type="button"
                      onClick={() => setShowForm(true)}
                    >
                      Add owner’s information
                    </button>
                  </div> */}
                </div>

                <div className="form-btns mt-[12.4rem] flex h-full w-full items-center justify-between border-t border-t-hgray-200 px-14 pb-7 pt-6">
                  <button
                    className="w-[150px] rounded-lg border  border-solid border-hgray-300 bg-white px-[10px] py-[13px] text-[15px] font-semibold text-hgray-700 shadow-s-light hover:bg-hgray-50"
                    type="button"
                    onClick={() => handleGoBack()}
                  >
                    Go Back
                  </button>

                  <button
                    className="w-[250px] rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[13px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
                    type="button"
                    onClick={() => handleNavigate()}
                  >
                    Continue
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      {showForm && (
        <SidePopup
          className="signup-popup"
          popClass="pt-[1.3rem] pb-[1.5rem] w-[38rem]"
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(createUBO)}>
              <div className="popup-content-bg flex h-full flex-col items-center justify-between">
                <div className="content-top w-full px-[1.5rem]">
                  <div className="content-nav flex w-full items-start justify-between">
                    <div>
                      <h3 className="text-[18px] font-semibold text-htext-main">
                        Add owner’s information
                      </h3>
                      <p className="mt-1.5 text-[12.5px] font-normal text-htext-subdued">
                        These are the company director(s) as listed in your
                        business documents
                      </p>
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => closeShowPopup()}
                    >
                      <span
                        className=""
                        dangerouslySetInnerHTML={{ __html: closeBtnSVG }}
                      />
                    </div>
                  </div>

                  <fieldset>
                    <div className="form-div">
                      <div className="mt-6 w-full">
                        <div className="flex items-center">
                          <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                            *
                          </p>
                          <p className="text-[13px] font-medium  text-htext-label">
                            Are you a UBO/owner?
                          </p>
                        </div>
                        <div className="radio-btns mt-2 flex w-full items-center justify-between">
                          <div className="btn-1 flex h-[2.5rem] w-[48.5%] items-center rounded-lg border border-hgray-200 bg-hgray-25 px-4 py-2">
                            <RadioButton
                              name="ubo"
                              value="YES"
                              checked={selectedValue === "YES"}
                              onChange={handleRadioChangeValue}
                              className="accs-radio w-auto"
                            />
                            <p className="ml-2 text-[13px] font-medium text-hgray-700">
                              Yes
                            </p>
                          </div>

                          <div className="btn-2 flex h-[2.5rem] w-[48.5%] items-center rounded-lg border border-hgray-200 bg-hgray-25 px-4 py-2">
                            <RadioButton
                              name="ubo"
                              value="NO"
                              checked={selectedValue === "NO"}
                              onChange={handleRadioChangeValue}
                              className="accs-radio w-auto"
                            />
                            <p className="ml-2 text-[13px]  font-medium text-hgray-700">
                              No
                            </p>
                          </div>
                        </div>
                        {selectedValue === "YES" && (
                          <div className="mt-10 w-full">
                            <div className="header-header mx-auto w-full rounded-t-[12px] border border-b-0 border-hgray-100 bg-hgray-50 px-5 pb-3 pt-5">
                              <h4 className="text-[18px] font-semibold text-htext-main">
                                UBO’s business information
                              </h4>
                            </div>

                            <div className="mx-auto w-full rounded-b-[12px] border border-t-0 border-hgray-100 px-5 pb-6 pt-1">
                              <div
                                className={`flex w-full items-start justify-between ${
                                  errors.jobTitle ||
                                  errors.percentageOfOwnership
                                    ? "mt-[.80rem] h-24"
                                    : "mt-[1.30rem]"
                                }`}
                              >
                                <label
                                  htmlFor="jobTitle"
                                  className="relative h-full w-[48.5%]"
                                >
                                  <div className="mb-1.5 flex items-center text-[13px] font-medium">
                                    <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                      *
                                    </p>
                                    <p className="text-htext-label">
                                      Job title
                                    </p>
                                  </div>
                                  <Select
                                    id="jobTitle"
                                    register={register("jobTitle")}
                                    optionsItem={sof}
                                    error={errors?.jobTitle}
                                  />
                                  {errors.jobTitle && (
                                    <p className="font-ibm-plex absolute mt-1.5 text-[13px] font-medium text-red-600">
                                      {errors.jobTitle.message}!
                                    </p>
                                  )}
                                </label>

                                <label
                                  htmlFor="percentageOfOwnership"
                                  className="relative h-full w-[48.5%]"
                                >
                                  <div className="mb-1.5 flex items-center text-[13px] font-medium">
                                    <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                      *
                                    </p>
                                    <p className="text-htext-label">
                                      Percentage of ownership
                                    </p>
                                  </div>
                                  <Inputs
                                    type="text"
                                    placeholder="Enter here"
                                    register={register("percentageOfOwnership")}
                                    addedIcon={true}
                                    error={errors?.percentageOfOwnership}
                                  />
                                  {errors.percentageOfOwnership && (
                                    <p className="font-ibm-plex absolute mt-1.5 text-[13px] font-medium text-red-600">
                                      {errors.percentageOfOwnership.message}!
                                    </p>
                                  )}
                                </label>
                              </div>

                              <div className="mt-8">
                                <div className="flex items-center">
                                  <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                    *
                                  </p>
                                  <p className="flex items-center  text-[13px] font-medium text-htext-label">
                                    Is the UBO a political exposed personnel
                                    (PEP)?{" "}
                                    <span
                                      className="ml-1 cursor-pointer"
                                      dangerouslySetInnerHTML={{
                                        __html: warnToolTipSVG,
                                      }}
                                    />
                                  </p>
                                </div>
                                <div className="radio-btns mt-2 flex w-full items-center justify-between">
                                  <div className="btn-1 flex h-[2.5rem] w-[48.5%] items-center rounded-lg border border-hgray-200 bg-hgray-25 px-4 py-2">
                                    <RadioButton
                                      name="pep"
                                      value="YES"
                                      checked={selectedPEP == "YES"}
                                      onChange={handleRadioChangePEP}
                                      className="squared w-auto"
                                    />
                                    <p className="ml-2 text-[13px] font-medium text-hgray-700">
                                      Yes
                                    </p>
                                  </div>

                                  <div className="btn-2 flex h-[2.5rem] w-[48.5%] items-center rounded-lg border border-hgray-200 bg-hgray-25 px-4 py-2">
                                    <RadioButton
                                      name="pep"
                                      value="NO"
                                      checked={selectedPEP == "NO"}
                                      onChange={handleRadioChangePEP}
                                      className="squared w-auto"
                                    />
                                    <p className="ml-2 text-[13px]  font-medium text-hgray-700">
                                      No
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {selectedValue === "NO" && (
                          <div className="mt-10 w-full">
                            <div className="flex w-full items-start rounded-[12px] border border-hbrand-300 bg-hbrand-50 py-4 pl-4 pr-24 shadow-s-light">
                              <span
                                className="mr-4"
                                dangerouslySetInnerHTML={{
                                  __html: warnToolTipBlueSVG,
                                }}
                              />
                              <p className="text-[13px] font-semibold text-htext-label">
                                We will send this owner an email requesting
                                additional information from them
                              </p>
                            </div>
                            <div className="header-header mx-auto mt-10 w-full rounded-t-[12px] border border-b-0 border-hgray-100 bg-hgray-50 px-5 pb-3 pt-5">
                              <h4 className="text-[18px] font-semibold text-htext-main">
                                UBO’s personal information
                              </h4>
                              <p className="mt-2 text-[12px] font-normal text-htext-subdued">
                                Be sure to enter the details as it appears on
                                their government-issued ID
                              </p>
                            </div>

                            <div className="mx-auto w-full rounded-b-[12px] border border-t-0 border-hgray-100 px-5 pb-6 pt-1">
                              <div
                                className={`flex w-full items-start justify-between ${
                                  errors?.firstName || errors?.lastName
                                    ? "mt-[.80rem] h-24"
                                    : "mt-[1.30rem]"
                                }`}
                              >
                                <label
                                  htmlFor="firstName"
                                  className="relative h-full w-[48.5%]"
                                >
                                  <div className="mb-1 flex items-center text-[13px] font-medium">
                                    <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                      *
                                    </p>
                                    <p className="text-htext-label">
                                      Legal first name
                                    </p>
                                  </div>
                                  <Inputs
                                    type="text"
                                    placeholder=""
                                    register={register("firstName")}
                                    inputClass={true}
                                    error={errors?.firstName}
                                  />
                                  {errors.firstName && (
                                    <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                      {errors.firstName.message}!
                                    </p>
                                  )}
                                </label>

                                <label
                                  htmlFor="lastName"
                                  className="relative h-full w-[48.5%]"
                                >
                                  <div className="mb-1 flex items-center text-[13px] font-medium">
                                    <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                      *
                                    </p>
                                    <p className="text-htext-label">
                                      Legal last name
                                    </p>
                                  </div>
                                  <Inputs
                                    type="text"
                                    placeholder=""
                                    register={register("lastName")}
                                    inputClass={true}
                                    error={errors?.lastName}
                                  />
                                  {errors.lastName && (
                                    <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                      {errors.lastName.message}!
                                    </p>
                                  )}
                                </label>
                              </div>

                              <div
                                className={`mt-5 flex w-full items-start justify-between ${
                                  errors?.phoneNumber || errors?.email
                                    ? "h-24"
                                    : ""
                                }`}
                              >
                                <label
                                  htmlFor="phoneNumber"
                                  className="w-[48.5%]"
                                >
                                  <div className="mb-1.5 flex items-center text-[13px] font-medium">
                                    <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                      *
                                    </p>

                                    <p className="text-htext-label">
                                      Phone Number
                                    </p>
                                  </div>
                                  <Inputs
                                    type="text"
                                    placeholder=""
                                    inputClass={true}
                                    register={register("phoneNumber")}
                                    error={errors?.phoneNumber}
                                  />
                                  {errors.phoneNumber && (
                                    <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                      {errors.phoneNumber.message}!
                                    </p>
                                  )}
                                </label>

                                <label htmlFor="email" className="w-[48.5%]">
                                  <div className="mb-1.5 flex items-center text-[13px] font-medium">
                                    <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                      *
                                    </p>
                                    <p className="text-htext-label">Email</p>
                                  </div>
                                  <Inputs
                                    type="email"
                                    icon={EmailSVG}
                                    placeholder="olivia@gmail.com"
                                    register={register("email")}
                                    inputClass={true}
                                    error={errors?.email}
                                  />
                                  {errors.email && (
                                    <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                      {errors.email.message}!
                                    </p>
                                  )}
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div className="pop-btns mt-9 flex w-full items-center justify-end border-t border-hgray-200 px-[1.5rem] pt-8">
                  <button
                    type="button"
                    onClick={() => closeShowPopup()}
                    className="shadow-s-light] mr-[5px] rounded-lg border border-hgray-300 bg-white px-3.5 py-2 text-[15px] font-semibold text-hgray-700 hover:opacity-70"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="ml-[5px] rounded-lg border border-hbrand-600 bg-hbrand-600 px-20 py-2 text-[15px] font-semibold text-white shadow-s-light hover:opacity-80"
                  >
                    {isCreateCorpUbo || isinvitingUBO ? (
                      <span className="loading loading-spinner loading-sm"></span>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </FormProvider>
        </SidePopup>
      )}
    </div>
  );
};

export default OwnershipInfoForm;
