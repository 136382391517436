import DashGetStarted from "../../../components/Dashboard/get-started";

function GetStarted() {
    return (
            <div>
                <DashGetStarted />
            </div>
    )
}

export default GetStarted;