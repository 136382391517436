import React, { useState } from "react";
import Roles from "./roles";
import Team from "./team";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

// Basic Button component
export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  ...props
}) => (
  <button className={`rounded px-4 py-2 font-semibold ${className}`} {...props}>
    {children}
  </button>
);

const Teams: React.FC = () => {
  const [activeTab, setActiveTab] = useState("Members");

  return (
    <div className="container mx-auto p-6">
      <div className="mb-6 flex items-center justify-between">
        <div className="flex space-x-4">
          {/* <button className="text-sm font-semibold text-blue-600 border-b-2 border-blue-600 pb-2">Members</button>
          <button className="text-sm font-semibold text-gray-500 pb-2">Roles</button> */}
          <nav className="-mb-px flex space-x-8">
            {["Members", "Roles"].map((tab) => (
              <button
                key={tab}
                className={`px-1 py-2 ${
                  activeTab === tab
                    ? "border-b-2 border-blue-500 text-blue-600"
                    : "text-gray-500 hover:text-gray-700"
                }`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
            ))}
          </nav>
        </div>
        <Button className="bg-blue-600 text-white hover:bg-blue-700">
          + Invite a member
        </Button>
      </div>

      {activeTab === "Members" && <Team />}
      {activeTab === "Roles" && <Roles />}
    </div>
  );
};

export default Teams;
