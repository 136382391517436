import { useState } from "react";
import Profile from "./profile";
import Security from "./security";
import Settlement from "./settlements";

export default function AccountSettings() {
  const [activeTab, setActiveTab] = useState("Profile");

  const tabs = ["Profile", "Settlement", "Security"];

  return (
    <div className="bg-white p-6 font-sans">
      <h1 className="mb-4 text-2xl font-semibold">Account Settings</h1>
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`px-1 py-2 ${
                activeTab === tab
                  ? "border-b-2 border-blue-500 text-blue-600"
                  : "text-gray-500 hover:text-gray-700"
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </nav>
      </div>
      <div className="p-6">
        {activeTab === "Profile" && <Profile />}
        {activeTab === "Settlement" && <Settlement />}
        {activeTab === "Security" && <Security />}
      </div>
    </div>
  );
}
