import Login from "../../components/Auth/Login";

function AuthLogin() {
    return (
            <div>
                <Login />
            </div>
    )
}

export default AuthLogin;