import ComingSoon from "../../components/Common/comingSoon";

function Wallets() {
    return (
        <div className="get-started px-8 py-6">
        <div className="get-started-content m-auto flex w-[650px] flex-col items-center justify-center">
          <div className="flex items-center">
            <ComingSoon name="Portal Accounts" />
          </div>
        </div>
      </div>
    )
}

export default Wallets;