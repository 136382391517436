import { zodResolver } from "@hookform/resolvers/zod";
import { CheckIcon, Loader2 } from "lucide-react";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getImageSrc } from "../../../../components/Common/table";
import { chainOptions, fiatOptions } from "../../../../constant/options";
import { useModal } from "../../../../context/modalProvider";
import { trpc } from "../../../../lib/trpc";
import { formatNumberWithCommasAndTwoDecimals } from "../../../../utils/formatNumber";
import RadioButton from "../../../Common/buttons/radioBtn";
import { TransactionDetail } from "../../../Common/calDetails";
import Inputs from "../../../Common/inputs";
import MainLoader from "../../../Common/loader/mainLoader";
import SidePopup from "../../../Common/popup/sidepopup";
import {
  BackArrowSVG,
  BlueThinArrowSVG,
  calculator,
  circleSmallMinus,
  circleSmallPlus,
  closeBtnSVG,
  CopyBlueSVG,
  dollarsSVG,
  EmailSVG,
  euroDarkerSmall,
  eurosSVG,
  flusteredWarning,
  gbpDarkerSmall,
  plusSVG,
  poundsSVG,
  shareIcon,
  slideRight,
  step1Warn,
  step2Warn,
  uSDCSVG,
  usdDarkerSmall,
  UsdtSvg,
  warnToolTipBlueSVG,
} from "../../../Common/svg";
import {
  AddDestinationBankSchema,
  addDestinationBankSchema,
  ShareSchema,
  shareSchema,
} from "../../get-started/onboarding-checklist/schema";

type CurrencyTypes = "EUR" | "GBP";

const dropFiatOptions = [
  {
    value: "USD",
    type: "fiat",
  },
  {
    value: "EUR",
    type: "fiat",
  },
  {
    value: "GBP",
    type: "fiat",
  },
];

const dropStableOptions = [
  {
    value: "USDT",
    type: "stablecoin",
  },
  {
    value: "USDC",
    type: "stablecoin",
  },
];

const currencyIcons: any = {
  eur: euroDarkerSmall,
  gbp: gbpDarkerSmall,
  usd: usdDarkerSmall,
};

const PortalOutDetails: FC = () => {
  const navigate = useNavigate();
  const stableDropdownRef = useRef<HTMLDivElement>(null);
  const fiatDropdownRef = useRef<HTMLDivElement>(null);
  const { showModal } = useModal();
  const [openItemId, setOpenItemId] = useState<number | string | null>(null);
  const [openItemId2, setOpenItemId2] = useState<number | string | null>(null);
  const [showCalPopup, setShowCalPopup] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [showAccPopup, setShowAccPopup] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [calRate, setCalRate] = useState("0");
  const [calBase, setCalBase] = useState(`${dropStableOptions[0]?.value}`);
  const [calTarget, setCalTarget] = useState(`${dropFiatOptions[0]?.value}`);
  const [isUsdt, setIsUsdt] = useState(true);
  const [isUsdc, setIsUsdc] = useState(false);
  const [calculatedValue, setCalculatedValue] = useState("");
  const [amount, setAmount] = useState("");
  const [showDropDownFiat, setShowDropDownFiat] = useState(false);
  const [showDropDownStable, setShowDropDownStable] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const [showEditAccPopup, setShowEditAccPopup] = useState(false);
  const [selectedValue, setSelectedValue] = useState<CurrencyTypes>();
  const [isCopied, setIsCopied] = useState(false);
  const [loadingBankId, setLoadingBankId] = useState<number | null>(null);

  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const utils = trpc.useUtils();

  function closeShowPopup() {
    if (showCalPopup === true) {
      setShowCalPopup(false);
    } else {
      setShowEditAccPopup(false);
    }
    reset();
  }

  useEffect(() => {
    setSearchParams((prev) => {
      prev.set("amountToSnd", calBase);
      prev.set("amountToRcv", calTarget);
      prev.set("calBase", amount);
      prev.set("calTarget", calculatedValue);
      return prev;
    });
  }, [calculatedValue, amount, calBase, calTarget, setSearchParams]);

  const {
    mutate: createDestinationBankAcct,
    isLoading: isLoadingCreateDestinationBankAcct,
  } = trpc.user.setUpBank.useMutation({
    onSuccess: () => {
      reset();
      utils.user.getBankAccount.refetch();
      setShowAccPopup(false);
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const { mutate: updateUpBank, isLoading: isUpdateUpBank } =
    trpc.user.updateUpBank.useMutation({
      onSuccess: () => {
        reset();
        utils.user.getBankAccount.refetch();
        closeShowPopup();
      },
      onError: (e) => {
        console.log(e);
      },
    });

  const onSetUpBankAccount = async (data: AddDestinationBankSchema) => {
    try {
      const response = await getSession({});
      if (response.sessionIdHasExpired) {
        await triggerOtp({});
        showModal();
      } else {
        if (showEditAccPopup && id) {
          updateUpBank({ input: { ...data, id } });
        } else {
          createDestinationBankAcct({ input: data });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleItem = (id: number | string) => {
    setOpenItemId(openItemId === id ? null : id);
  };

  const toggleItem2 = (id: number) => {
    setOpenItemId2(openItemId2 === id ? null : id);
  };

  // const handleNavigate = (route: string) => {
  //   navigate(route);
  // };

  const handleGoBack = () => {
    navigate("/dashboard/portal-accounts");
  };

  // Handle clicks outside of Stablecoin dropdown
  useEffect(() => {
    const handleClickOutsideStable = (event: any) => {
      if (
        stableDropdownRef.current &&
        !stableDropdownRef?.current?.contains(event.target)
      ) {
        setShowDropDownStable(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideStable);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideStable);
    };
  }, []);

  // Handle clicks outside of Fiat dropdown
  useEffect(() => {
    const handleClickOutsideFiat = (event: any) => {
      if (
        fiatDropdownRef.current &&
        !fiatDropdownRef.current.contains(event.target)
      ) {
        setShowDropDownFiat(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideFiat);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideFiat);
    };
  }, []);

  const { data: tr } = trpc.user.getCalculatedRate.useQuery({
    base: calBase,
    target: calTarget,
  });

  useEffect(() => {
    if (amount === "" || isNaN(parseFloat(amount))) {
      setCalculatedValue("0");
    } else if (tr) {
      const rate = tr.exchange_rate; // Adjust based on the actual response structure
      const roundedUpRates = rate.toFixed(2);
      setCalRate(roundedUpRates);
      const targetValue = (parseFloat(amount) * rate).toFixed(2);
      setCalculatedValue(targetValue);
    }
  }, [tr, amount]);

  const { mutate: shareLink, isLoading: isSharingLink } =
    trpc.transaction.sharePaymentLink.useMutation({
      onSuccess: () => {
        resetShareLink();
        toast.success("Payment request sent to email");
      },
      onError: () => {},
    });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value);
    }
  };

  //   edit account details
  const { mutateAsync: getSession, isLoading: isLoadingGettingSession } =
    trpc.user.isSessionIdExpired.useMutation({
      onError: (e) => {
        toast.error(e.message);
      },
    });

  const {
    reset: resetShareLink,
    register: registerShareLInk,
    handleSubmit,
    formState: { errors: shareLinkErrors },
  } = useForm<ShareSchema>({
    resolver: zodResolver(shareSchema),
    defaultValues: {
      email: "",
      walletAddress: "",
      amountToSend: "",
      settlementAmount: "",
      network: "",
    },
  });

  const onShareSubmit = (values: ShareSchema) => {
    // Convert the searchParams values to numbers, with a fallback to 0 if they are null or invalid
    const calBase = parseFloat(searchParams.get("calBase") || "0");
    const amountToSnd = searchParams.get("amountToSnd") || "";
    const calTarget = parseFloat(searchParams.get("calTarget") || "0");
    const amountToRcv = searchParams.get("amountToRcv") || "";

    // Format the amounts using the converted numbers
    const amountToSend = `${formatNumberWithCommasAndTwoDecimals(
      calBase
    )} ${amountToSnd}`;
    const settlementAmount = `${formatNumberWithCommasAndTwoDecimals(
      calTarget
    )} ${amountToRcv}`;

    // Call the shareLink function with the formatted values
    shareLink({
      walletAddress:
        unblockWallets?.find((wallet) => wallet.network == "optimism")
          ?.address || "",
      email: values.email,
      amountToSend,
      settlementAmount,
      network: "optimism",
    });
  };

  const { mutateAsync: triggerOtp } = trpc.user.triggerOtp.useMutation({
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const { mutate: setCustomerSetBankDefault } =
    trpc.user.customerSetBankDefault.useMutation({
      onError: (e) => {
        toast.error(e.message);
        utils.user.getBankAccount.invalidate();
      },
      onSuccess: () => {
        utils.user.getBankAccount.refetch();
      },
      onSettled: () => {
        setLoadingBankId(null);
      },
    });

  const methods = useForm<AddDestinationBankSchema>({
    resolver: zodResolver(addDestinationBankSchema),
    defaultValues: {
      accountNumber: "",
      iban: "",
      accountName: "",
      sortCode: "",
      currency: undefined,
      mainBeneficiary: true,
    },
  });

  const {
    register,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const { data: unblockWallets, isLoading: isLoadingUnblockWallets } =
    trpc.user.listUnblockWallets.useQuery({});

  const { data: userAccount, isLoading: isLoadingUserAcct } =
    trpc.user.getBankAccount.useQuery({});

  const handleRadioChangeValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedValue(event.target.value as CurrencyTypes);
    setValue("currency", event.target.value as CurrencyTypes);
  };

  useEffect(() => {
    if (showEditAccPopup && userAccount) {
      console.log({ id });
      const accountData = userAccount.find((item) => item.id == id);
      // Populate form fields with fetched data
      if (accountData) {
        setValue("accountNumber", accountData.accountNumber || "");
        setValue("iban", accountData.iban || "");
        setValue("accountName", accountData.accountName || "");
        setValue("sortCode", accountData.sortCode || "");
        setValue("currency", accountData.currency as "GBP" | "EUR");
        setSelectedValue(accountData?.currency as "GBP" | "EUR");
      }
    }
  }, [showEditAccPopup, userAccount, setValue]);

  if (isLoadingUserAcct) {
    return <MainLoader />;
  }

  return (
    <div className="portal-in-stable  h-screen w-screen bg-white pb-2 pt-4">
      <div className="portal-in-stable-content h-full w-full overflow-hidden">
        <div className="content-nav mt-5 flex w-[950px] items-center px-60">
          <p
            className="flex cursor-pointer items-center text-[13px] font-semibold text-hbrand-600 hover:text-hbrand-700"
            onClick={() => handleGoBack()}
          >
            <span
              className="mr-2.5"
              dangerouslySetInnerHTML={{ __html: BackArrowSVG }}
            />
            Go back
          </p>
        </div>

        <div className="content-nav hide-scrollbar mx-auto mt-9 h-full w-[950px] overflow-y-scroll pb-52">
          <div className="nav-top">
            <div className="top-type relative flex items-center justify-between rounded-lg bg-new-linear px-5 py-6 shadow-s-light">
              <div className="z-10">
                <p className="text-[12px] font-semibold text-hwarning-700">
                  PORTAL OUT OF STABLECOIN
                </p>
                <h3 className="mt-1.5 text-[22px] font-semibold text-htext-main">
                  Swap STABLECOIN to FIAT
                </h3>

                <div className="text-steps mt-5">
                  <p className="text-[12px] font-medium text-htext-subdued">
                    STEPS
                  </p>

                  <div className="mt-3.5 flex items-center">
                    <span
                      className=""
                      dangerouslySetInnerHTML={{ __html: step1Warn }}
                    />
                    <p className="ml-1.5 text-[14px] font-normal text-htext-subdued">
                      Send your fiat currency
                    </p>
                  </div>

                  <div className="mt-2 flex items-center">
                    <span
                      className=""
                      dangerouslySetInnerHTML={{ __html: step2Warn }}
                    />
                    <p className="ml-1.5 text-[14px] font-normal text-htext-subdued">
                      Receive your stablecoin
                    </p>
                  </div>
                </div>
              </div>

              <div className="text-btns z-10">
                <button
                  className="flex w-[210px] items-center justify-center gap-x-2 rounded-lg border border-solid border-hbrand-600 bg-white px-[10px] py-[9px] text-[13px] font-semibold text-hbrand-700 shadow-s-light hover:border-hbrand-700 hover:bg-hgray-50 hover:text-hbrand-600"
                  type="button"
                  onClick={() => setShowCalPopup(true)}
                >
                  <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: calculator }}
                  />
                  <p>Calculate rate conversion</p>
                </button>
              </div>
            </div>

            <div className="content-table mt-6 flex w-full items-start py-3">
              <div className="portal-account w-[50%] border-r-[0.5px] border-r-hgray-200 pl-2 pr-8">
                <div className="account-nav">
                  <h3 className="text-[19px] font-semibold leading-[31px] text-htext-label">
                    Your portal stablecoin wallet
                  </h3>
                  <p className="mt-[5px] w-[400px] text-[13px] font-normal leading-[19px] text-htext-subdued">
                    Send stablecoin to this Shiga generated virtual wallets
                    below and it converts into local currency in your fiat
                    settlement account.
                  </p>
                </div>

                <div className="type-top pb-10 pt-9">
                  <div className="space-y-4">
                    <div className="dash-toggle flex w-full items-center rounded-[10px] border border-hgray-200 bg-hgray-50 p-[5px] shadow-s-light">
                      <div
                        className={`toggle-usdc flex w-[50%] items-center justify-center ${
                          !isUsdc
                            ? "rounded-[6px] border-b-2 border-blue-500 bg-white shadow-s-light" // Dark red border when active
                            : "bg-transparent"
                        } cursor-pointer px-[12px] py-[4.5px]`}
                        onClick={() => {
                          setIsUsdc(false);
                          setIsUsdt(true);
                        }}
                      >
                        <p className="text-[12px] font-semibold text-htext-main">
                          USDT
                        </p>
                        <span
                          className="ml-1.5"
                          dangerouslySetInnerHTML={{ __html: UsdtSvg }}
                        />
                      </div>

                      <div
                        className={`toggle-usdc flex w-[50%] items-center justify-center ${
                          !isUsdt
                            ? "rounded-[6px] border-b-2 border-blue-500 bg-white shadow-s-light" // Dark red border when active
                            : "bg-transparent"
                        } cursor-pointer px-[12px] py-[4.5px]`}
                        onClick={() => {
                          setIsUsdt(false);
                          setIsUsdc(true);
                        }}
                      >
                        <p className="text-[12px] font-semibold text-htext-main">
                          USDC
                        </p>
                        <span
                          className="ml-1.5"
                          dangerouslySetInnerHTML={{ __html: uSDCSVG }}
                        />
                      </div>
                    </div>

                    {isLoadingUnblockWallets && (
                      <span className="loading loading-spinner loading-sm"></span>
                    )}

                    {/* Filter logic based on USDC or USDT */}
                    {unblockWallets
                      ?.filter(({ network }) => {
                        if (isUsdc) {
                          // USDC supported networks
                          return [
                            "mainnet",
                            "polygon",
                            "optimism",
                            "arbitrum",
                            "solana",
                            "base",
                          ].includes(network.toLowerCase());
                        } else if (isUsdt) {
                          // USDT supported networks
                          return [
                            "mainnet",
                            "polygon",
                            "optimism",
                            "arbitrum",
                            "tron",
                            "solana",
                          ].includes(network.toLowerCase());
                        }
                        return false; // no match, nothing will be shown
                      })
                      ?.sort((a, b) => a.network.localeCompare(b.network))
                      .map(({ address, network }) => (
                        <div key={network}>
                          <button
                            onClick={() => toggleItem(network)}
                            className="flex w-full items-center justify-between rounded-lg bg-hgray-50 px-6 py-3 shadow-s-light focus:outline-none"
                          >
                            <div className="flex items-center">
                              {
                                chainOptions?.find(
                                  ({ value }) =>
                                    value.toLocaleLowerCase() ===
                                    network.toLocaleLowerCase()
                                )?.icon
                              }
                              <span className="text-[13.5px] font-semibold capitalize text-htext-main">
                                {network === "mainnet" ? "Ethereum" : network}
                              </span>
                            </div>
                            {openItemId === network ? (
                              <span
                                className=""
                                dangerouslySetInnerHTML={{
                                  __html: circleSmallMinus,
                                }}
                              />
                            ) : (
                              <span
                                className=""
                                dangerouslySetInnerHTML={{
                                  __html: circleSmallPlus,
                                }}
                              />
                            )}
                          </button>

                          {openItemId === network && address && (
                            <div className="flex items-center justify-between rounded-lg bg-gray-50 px-[18px] py-3.5">
                              <div>
                                <p className="text-[11.5px] font-normal text-htext-main">
                                  Wallet address
                                </p>
                                <p className="mt-0.5 text-[13.5px] font-semibold text-htext-main">
                                  {address}
                                </p>
                              </div>

                              <div
                                onClick={() => handleCopy(address)}
                                className="cursor-pointer"
                              >
                                <span
                                  className=""
                                  dangerouslySetInnerHTML={{
                                    __html: CopyBlueSVG,
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="portal-settlement w-[50%] border-l-[0.5px] border-l-hgray-200 pl-8 pr-2">
                <div className="settlement-nav">
                  <h3 className="text-[19px] font-semibold leading-[31px] text-htext-label">
                    Your settlement bank account
                  </h3>
                  <p className="mt-[5px] w-[420px] text-[13px] font-normal leading-[19px] text-htext-subdued">
                    Stablecoin sent to your portal wallet are sent to your saved
                    local bank account.
                  </p>
                </div>

                <div className="type-top pb-7 pt-9">
                  <div className="space-y-4">
                    {isLoadingUserAcct && (
                      <span className="loading loading-spinner loading-sm"></span>
                    )}

                    {!isLoadingUserAcct &&
                      userAccount?.map(
                        ({
                          accountName,
                          accountNumber,
                          currency,
                          iban,
                          sortCode,
                          useAsDefault,
                          id,
                        }) => (
                          <div key={id}>
                            <button
                              onClick={() => toggleItem2(id)}
                              className="flex w-full items-center justify-between rounded-lg bg-hgray-50 px-6 py-5 shadow-s-light focus:outline-none"
                            >
                              <div className="flex items-center">
                                {
                                  fiatOptions?.find(
                                    ({ value }) =>
                                      value.toLocaleLowerCase() ==
                                      currency.toLocaleLowerCase()
                                  )?.iconSmall
                                }
                                <p className="ml-3 text-[13px] font-semibold text-htext-main">
                                  {currency} bank details
                                </p>
                              </div>
                              {currency === "USD" ? (
                                <p className="rounded-2xl border border-solid border-hbrand-200 bg-hbrand-50 px-[5px] py-[0.5px] text-[11px] font-medium text-hbrand-700">
                                  Coming soon
                                </p>
                              ) : openItemId2 === id ? (
                                <span
                                  className=""
                                  dangerouslySetInnerHTML={{
                                    __html: circleSmallMinus,
                                  }}
                                />
                              ) : (
                                <span
                                  className=""
                                  dangerouslySetInnerHTML={{
                                    __html: circleSmallPlus,
                                  }}
                                />
                              )}
                            </button>
                            {openItemId2 === id && (
                              <div className="rounded-lg bg-gray-50 px-6 pb-2 shadow-s-light">
                                <div className="accountDetails flex w-full flex-col justify-center px-2 pb-5 pt-0.5">
                                  <div className="flex w-full items-center justify-between py-2.5">
                                    <p className="text-[13.5px] font-normal text-htext-main">
                                      Currency
                                    </p>

                                    <div className="flex items-center">
                                      <p className="text-[14.5px] font-semibold text-htext-main">
                                        {currency.toUpperCase()}
                                      </p>
                                      <span
                                        className="ml-2"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            currencyIcons[
                                              currency.toLowerCase()
                                            ],
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="flex w-full items-center justify-between py-2.5">
                                    <p className="text-[13.5px] font-normal text-htext-main">
                                      Account name
                                    </p>
                                    <p className="text-[14.5px] font-semibold text-htext-main">
                                      {accountName}
                                    </p>
                                  </div>
                                  {currency !== "EUR" && (
                                    <div className="flex w-full items-center justify-between py-2.5">
                                      <p className="text-[13.5px] font-normal text-htext-main">
                                        Account number
                                      </p>
                                      <p className="text-[14.5px] font-semibold text-htext-main">
                                        {accountNumber}
                                      </p>
                                    </div>
                                  )}

                                  <div className="flex w-full items-center justify-between py-2.5">
                                    <p className="text-[13.5px] font-normal text-htext-main">
                                      {iban ? "IBAN" : "Sort Code"}
                                    </p>
                                    <p className="text-[14.5px] font-semibold text-htext-main">
                                      {iban ? iban : sortCode}
                                    </p>
                                  </div>
                                </div>

                                <div className="mt-2 flex w-full items-center justify-between border-t border-t-hgray-200 pt-5">
                                  <div className="toggle-cont flex items-center gap-x-2">
                                    <div className="relative inline-block h-[18px] w-8 select-none align-middle transition duration-200 ease-in">
                                      <input
                                        type="checkbox"
                                        name="toggle1"
                                        checked={useAsDefault}
                                        onChange={async () => {
                                          setLoadingBankId(id);
                                          const response = await getSession({});
                                          if (response.sessionIdHasExpired) {
                                            await triggerOtp({});
                                            showModal();
                                          } else {
                                            setCustomerSetBankDefault({
                                              bankId: id,
                                            });
                                          }
                                        }}
                                        id="toggle1"
                                        className="toggle-checkbox absolute ml-[1.5px] mt-[2px] block h-[14px] w-[14px] cursor-pointer appearance-none rounded-full border-4 border-white bg-white"
                                      />
                                      <label
                                        htmlFor="toggle1"
                                        className="toggle-label block h-[18px] cursor-pointer overflow-hidden rounded-full bg-hbrand-700"
                                      >
                                        {(loadingBankId === id ||
                                          isLoadingGettingSession) && (
                                          <Loader2 className="h-4 w-4 animate-spin text-blue-500" />
                                        )}
                                      </label>
                                    </div>
                                    <p className="text-[12.5px] font-normal text-htext-subdued">
                                      Set as default settlement wallet
                                    </p>
                                  </div>

                                  <button
                                    type="button"
                                    className="flex items-center text-[12.5px] font-semibold text-hbrand-700 hover:text-hbrand-600"
                                    onClick={() => {
                                      setShowEditAccPopup(true);
                                      setId(id);
                                    }}
                                  >
                                    Edit
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      )}
                  </div>
                </div>

                <div
                  onClick={() => {
                    setShowAccPopup(true);
                    setValue("currency", "EUR");
                  }}
                  className="flex w-full cursor-pointer items-center justify-end hover:text-hbrand-600"
                >
                  <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: plusSVG }}
                  />
                  <p className="ml-2.5 text-[14.5px] font-semibold text-hbrand-700">
                    Add new account
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showCalPopup && (
        <SidePopup
          className="signup-popup"
          popClass="pt-[1.3rem] pb-[1.5rem] w-[33rem]"
        >
          <div className="popup-content-bg flex h-full flex-col items-center justify-between px-7">
            <div className=" flex w-full flex-col items-center pb-7">
              <div className="content-top w-full">
                <div className="content-nav -px-[1.5rem] flex w-full items-start justify-between">
                  <div>
                    <h3 className="text-[18px] font-semibold text-htext-main">
                      Rate calculator
                    </h3>
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => closeShowPopup()}
                  >
                    <span
                      className=""
                      dangerouslySetInnerHTML={{ __html: closeBtnSVG }}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4 w-full">
                <div className="content-cal w-full rounded-lg bg-hgray-50 px-5 py-6">
                  <div className="from-card flex w-full items-center justify-between rounded-lg border border-hgray-300 bg-white px-5 py-4 shadow-s-light">
                    <div className="w-[160px]">
                      <p className="text-left text-[14px] font-medium text-htext-main">
                        From
                      </p>
                      <input
                        type="text"
                        className="mt-2.5 bg-transparent text-left text-[18px] font-normal text-htext-main outline-none"
                        placeholder={`${
                          calBase === "EUR"
                            ? "€"
                            : calBase === "GBP"
                              ? "£"
                              : calBase === "USD"
                                ? "$"
                                : "0"
                        }`}
                        value={amount}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="from-drop">
                      <p className="text-right text-[12px] font-normal capitalize text-htext-subdued">
                        Stablecoin
                      </p>
                      <div
                        className="mt-2.5 cursor-pointer rounded-[500px] border-0 bg-hgray-200 py-1 pl-2 pr-2.5 text-right text-[13px] font-medium text-htext-label outline-none hover:bg-hgray-300"
                        onClick={() =>
                          setShowDropDownStable(!showDropDownStable)
                        }
                        ref={stableDropdownRef}
                      >
                        <div className="flex items-center">
                          <img
                            className="w-[14px]"
                            src={`/assets/${
                              calBase === "USDC" ? "usdcSmall" : "usdtSmall"
                            }.svg`}
                            alt={calBase}
                          />
                          <p className="ml-1 mr-2.5">{calBase}</p>
                          <span
                            className="cursor-pointer"
                            dangerouslySetInnerHTML={{
                              __html: BlueThinArrowSVG || "",
                            }}
                          />
                        </div>
                        {showDropDownStable && (
                          <ul className="absolute z-50 -ml-10 mt-3 w-[120px] rounded-lg border border-solid border-hgray-200 bg-hgray-100 py-3">
                            {dropStableOptions.map((option) => (
                              <li
                                key={option.value}
                                className="cursor-pointer px-4 py-1 text-left text-[13px] hover:bg-hgray-300"
                                onClick={() => {
                                  setCalBase(option.value);
                                  setShowDropDownStable(false);
                                }}
                              >
                                <p>{option.value}</p>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="middleCont flex w-full flex-col justify-center pl-16 pr-1 pt-1.5">
                    <TransactionDetail
                      type="Portal out of stablecoin"
                      fee="- €4"
                      amount={amount || "0"}
                      rate={`x ${calRate}`}
                    />
                  </div>

                  <div className="to-card flex w-full items-center justify-between rounded-lg border border-hgray-300 bg-white px-5 py-4 shadow-s-light">
                    <div className="w-[160px]">
                      <p className="text-left text-[14px] font-medium text-htext-main">
                        To
                      </p>
                      <input
                        type="text"
                        className="mt-2.5 bg-transparent text-left text-[18px] font-normal text-htext-main outline-none"
                        placeholder={`${
                          calTarget === "EUR"
                            ? "€"
                            : calTarget === "GBP"
                              ? "£"
                              : calTarget === "USD"
                                ? "$"
                                : "0"
                        }`}
                        readOnly
                        value={calculatedValue}
                      />
                    </div>

                    <div className="to-drop">
                      <p className="text-right text-[12px] font-normal capitalize text-htext-subdued">
                        Fiat
                      </p>
                      <div
                        className="mt-2.5 cursor-pointer rounded-[500px] border-0 bg-hgray-200 py-1 pl-2 pr-2.5 text-right text-[13px] font-medium text-htext-label outline-none hover:bg-hgray-300"
                        onClick={() => setShowDropDownFiat(!showDropDownFiat)}
                        ref={fiatDropdownRef}
                      >
                        <div className="flex items-center">
                          <img
                            className="w-[14px]"
                            src={`/assets/${
                              calTarget === "USD"
                                ? "dollarSmall"
                                : calTarget === "EUR"
                                  ? "euroSmall"
                                  : "poundsSmall"
                            }.svg`}
                            alt={calTarget}
                          />
                          <p className="ml-1 mr-2.5">{calTarget}</p>
                          <span
                            className="cursor-pointer"
                            dangerouslySetInnerHTML={{
                              __html: BlueThinArrowSVG || "",
                            }}
                          />
                        </div>
                        {showDropDownFiat && (
                          <ul className="absolute z-50 -ml-10 mt-3 w-[120px] rounded-lg border border-solid border-hgray-200 bg-hgray-100 py-3">
                            {dropFiatOptions.map((option) => (
                              <li
                                key={option.value}
                                className="cursor-pointer px-4 py-1 text-left text-[13px] hover:bg-hgray-300"
                                onClick={() => {
                                  setCalTarget(option.value);
                                  setShowDropDownFiat(false);
                                }}
                              >
                                <p>{option.value}</p>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full items-center justify-center pb-2 pt-7">
                    <div
                      onClick={() => {
                        setShowCalPopup(false);
                        setShowSharePopup(true);
                      }}
                      className="flex w-fit cursor-pointer items-center justify-center text-hbrand-700 hover:border-b  hover:border-hbrand-600 hover:text-hbrand-600"
                    >
                      <span
                        className=""
                        dangerouslySetInnerHTML={{ __html: shareIcon }}
                      />
                      <p className="ml-2.5 text-[14px] font-semibold">
                        Share payment request
                      </p>
                    </div>
                  </div>
                </div>
                {userAccount
                  ?.filter(({ currency }) => currency == calTarget)
                  .slice(0, 1)
                  ?.map(
                    ({
                      currency,
                      accountName,
                      accountNumber,
                      sortCode,
                      iban,
                    }) => (
                      <div className="content-info mt-10 w-full border-t border-t-hgray-200 py-5">
                        <div className="info-text">
                          <h5 className="text-[13px] font-medium text-htext-main">
                            Settlement information
                          </h5>
                          <p className="mt-1 w-[450px] text-[13px] font-normal text-htext-subdued">
                            Stablecoin sent to your portal wallet are sent to
                            your saved local bank account
                          </p>
                        </div>

                        <div className="info-content mt-4 flex flex-col justify-center gap-y-3 rounded-lg bg-hgray-50 p-6">
                          <div className="flex items-center">
                            <span
                              className=""
                              dangerouslySetInnerHTML={{
                                __html: currencyIcons[currency.toLowerCase()],
                              }}
                            />
                            <p className="ml-2 text-[13px] font-semibold text-htext-main">
                              {currency.toUpperCase()} bank details
                            </p>
                          </div>

                          <div className="mt-3 flex w-full items-center justify-between ">
                            <p className="text-[12px] font-normal text-htext-main">
                              Currency
                            </p>

                            <div className="flex items-center">
                              <p className="text-[12px] font-semibold text-htext-main">
                                {currency.toUpperCase()}
                              </p>
                              <span
                                className="ml-1.5"
                                dangerouslySetInnerHTML={{
                                  __html: currencyIcons[currency.toLowerCase()],
                                }}
                              />
                            </div>
                          </div>

                          <div className="flex w-full items-center justify-between">
                            <p className="text-[12px] font-normal text-htext-main">
                              Account name
                            </p>

                            <p className="text-[12px] font-semibold text-htext-main">
                              {accountName}{" "}
                            </p>
                          </div>

                          {accountNumber && (
                            <div className="flex w-full items-center justify-between">
                              <p className="text-[12px] font-normal text-htext-main">
                                Account number
                              </p>

                              <p className="text-[12px] font-semibold text-htext-main">
                                {accountNumber}
                              </p>
                            </div>
                          )}
                          <div className="flex w-full items-center justify-between">
                            <p className="text-[12px] font-normal text-htext-main">
                              {iban ? "IBAN" : "Sort Code"}
                            </p>

                            <p className="text-[12px] font-semibold text-htext-main">
                              {iban ? iban : sortCode}
                            </p>
                          </div>

                          {/* <div className="flex w-full items-center justify-between">
                          <p className="text-[12px] font-normal text-htext-main">
                            Bank name
                          </p>

                          <p className="text-[12px] font-semibold text-htext-main">
                            Lloyds Bank
                          </p>
                        </div> */}
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>

            <div className="close-btn w-full border-t border-hgray-200 py-5">
              <button
                className="w-full rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[11px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
                type="button"
                onClick={() => closeShowPopup()}
              >
                Close
              </button>
            </div>
          </div>
        </SidePopup>
      )}

      {/* POPUP */}
      {(showAccPopup || showEditAccPopup) && (
        <SidePopup
          className="signup-popup"
          popClass="pt-[1.3rem] pb-[1.5rem] w-[38rem]"
        >
          <div className="popup-content-bg flex h-full flex-col items-center justify-between">
            <div className="content-top w-full">
              <div className="content-nav flex w-full items-start justify-between px-[1.5rem]">
                <div>
                  <h3 className="text-[18px] font-semibold text-htext-main">
                    {showEditAccPopup
                      ? "Edit your bank account"
                      : "Add your bank account"}
                  </h3>
                  <p className="mt-1.5 text-[12.5px] font-normal text-htext-subdued">
                    This is your local currency account
                  </p>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => closeShowPopup()}
                >
                  <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: closeBtnSVG }}
                  />
                </div>
              </div>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSetUpBankAccount)}>
                  <fieldset>
                    <div className="form-div ">
                      <div className={`mt-9 h-[76vh] w-full`}>
                        <div className="px-[1.5rem]">
                          <div className="flex items-center ">
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>
                            <p className="text-[13px] font-medium  text-htext-label">
                              Select currency
                            </p>
                          </div>
                          <div className="radio-btns mt-2 flex w-full items-center justify-between">
                            <div className="btn-1 flex h-[2.5rem] w-[31.3%] items-center justify-between rounded-lg border border-hgray-200 bg-hgray-25 py-7 pl-3 pr-4">
                              <p className="flex items-center text-[14.5px] font-semibold text-htext-main">
                                <span
                                  className="w-18 mr-2"
                                  dangerouslySetInnerHTML={{ __html: eurosSVG }}
                                />
                                Euros
                              </p>
                              <RadioButton
                                name="currency"
                                value="EUR"
                                checked={selectedValue === "EUR"}
                                onChange={handleRadioChangeValue}
                                className="accs-radio w-auto"
                              />
                            </div>

                            <div className="btn-2 flex h-[2.5rem] w-[31.3%] items-center justify-between rounded-lg border border-hgray-200 bg-hgray-25 py-7 pl-3 pr-4">
                              <p className="flex items-center text-[14.5px] font-semibold text-htext-main">
                                <span
                                  className="w-18 mr-2"
                                  dangerouslySetInnerHTML={{
                                    __html: poundsSVG,
                                  }}
                                />
                                Pounds
                              </p>
                              <RadioButton
                                name="currency"
                                value="GBP"
                                checked={selectedValue === "GBP"}
                                onChange={handleRadioChangeValue}
                                className="accs-radio curren w-auto"
                              />
                            </div>

                            <div
                              className={`btn-3 flex h-[2.5rem] w-[31.3%] items-center justify-between rounded-lg border border-hgray-200 ${"bg-hgray-100"}  py-7 pl-3 pr-4`}
                            >
                              <p className="flex items-center text-[14.5px] font-semibold text-htext-main">
                                <span
                                  className="w-18 mr-2"
                                  dangerouslySetInnerHTML={{
                                    __html: dollarsSVG,
                                  }}
                                />
                                Dollars
                              </p>
                              <RadioButton
                                name="currency"
                                value="DOLLARS"
                                disabled={true}
                                onChange={handleRadioChangeValue}
                                className={`accs-radio w-auto ${
                                  true && "cursor-not-allowed"
                                }`}
                              />
                            </div>
                          </div>

                          <div className="mt-10 flex w-full items-start rounded-[12px] border border-hbrand-300 bg-hbrand-50 py-3.5 pl-4 pr-16 shadow-s-light">
                            <span
                              className="mr-4"
                              dangerouslySetInnerHTML={{
                                __html: warnToolTipBlueSVG,
                              }}
                            />
                            <p className="text-[13.5px] font-semibold text-htext-label">
                              To successfully add your bank account, your bank
                              account name must match the business name you
                              submitted on Shiga
                            </p>
                          </div>
                        </div>

                        <div className="flex h-full w-full flex-col items-center justify-between">
                          {true && (
                            <div className="mt-5 w-full px-[1.5rem]">
                              <div className="mx-auto flex w-full flex-col pb-6  pt-1">
                                <label
                                  htmlFor="accountName"
                                  className="relative mt-[1.30rem] w-full"
                                >
                                  <div className="mb-1 flex items-center text-[13px] font-medium">
                                    <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                      *
                                    </p>

                                    <p className="text-htext-label">
                                      Account name
                                    </p>
                                  </div>
                                  <Inputs
                                    type="text"
                                    placeholder="Enter here"
                                    register={register("accountName")}
                                    inputClass={true}
                                    error={errors.accountName}
                                  />
                                  {errors.accountName && (
                                    <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                      {errors.accountName.message}!
                                    </p>
                                  )}
                                </label>

                                {selectedValue !== "EUR" && (
                                  <label
                                    htmlFor="accountNumber"
                                    className="relative mt-[1.30rem] w-full"
                                  >
                                    <div className="mb-1 flex items-center text-[13px] font-medium">
                                      <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                        *
                                      </p>

                                      <p className="text-htext-label">
                                        Account number
                                      </p>
                                    </div>
                                    <Inputs
                                      type="text"
                                      placeholder="Enter here"
                                      register={register("accountNumber")}
                                      inputClass={true}
                                      error={errors.accountNumber}
                                    />
                                    {errors.accountNumber && (
                                      <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                        {errors.accountNumber.message}!
                                      </p>
                                    )}
                                  </label>
                                )}

                                {selectedValue === "EUR" && (
                                  <label
                                    htmlFor="iban"
                                    className="relative mt-[1.30rem] w-full"
                                  >
                                    <div className="mb-1 flex items-center text-[13px] font-medium">
                                      <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                        *
                                      </p>

                                      <p className="text-htext-label">IBAN</p>
                                    </div>
                                    <Inputs
                                      type="text"
                                      placeholder="Enter here"
                                      register={register("iban")}
                                      inputClass={true}
                                      error={errors.iban}
                                    />
                                    {errors.iban && (
                                      <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                        {errors.iban.message}!
                                      </p>
                                    )}
                                  </label>
                                )}
                                {selectedValue === "GBP" && (
                                  <label
                                    htmlFor="sortCode"
                                    className="relative mt-[1.30rem] w-full"
                                  >
                                    <div className="mb-1 flex items-center text-[13px] font-medium">
                                      <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                        *
                                      </p>

                                      <p className="text-htext-label">
                                        Sort code
                                      </p>
                                    </div>
                                    <Inputs
                                      type="text"
                                      placeholder="Enter here"
                                      register={register("sortCode")}
                                      inputClass={true}
                                      error={errors.sortCode}
                                    />
                                    {/* {errors.sortCode && ( */}
                                    <p
                                      className={`font-ibm-plex absolute mt-1.5 text-[11.5px] ${
                                        errors.sortCode
                                          ? "font-medium text-red-600"
                                          : "font-normal text-htext-subdued"
                                      }`}
                                    >
                                      {errors.sortCode
                                        ? errors.sortCode.message + "!"
                                        : " Enter the 6-digit sort code"}
                                    </p>
                                    {/* )} */}
                                  </label>
                                )}
                              </div>
                            </div>
                          )}

                          {selectedValue && (
                            <div className="pop-btns flex h-fit w-full items-center justify-end border-t border-hgray-200 px-[1.5rem] pb-5 pt-7">
                              <button
                                type="button"
                                onClick={() => {
                                  setShowAccPopup(false);
                                  setShowEditAccPopup(false);
                                }}
                                className="shadow-s-light] mr-[5px] rounded-lg border border-hgray-300 bg-white px-3.5 py-2.5 text-[15px] font-semibold text-hgray-700 hover:opacity-70"
                              >
                                Close
                              </button>
                              <button
                                type="submit"
                                // disabled={!isFormData}
                                //  onClick={() => handleNavigate("/login")}
                                className="ml-[5px] rounded-lg border border-hbrand-600 bg-hbrand-600 px-12 py-2.5 text-[15px] font-semibold text-white shadow-s-light hover:opacity-80"
                              >
                                {isLoadingCreateDestinationBankAcct ||
                                isUpdateUpBank ||
                                isLoadingGettingSession ? (
                                  <span className="loading loading-spinner loading-sm"></span>
                                ) : (
                                  "Save account details"
                                )}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </FormProvider>
            </div>
          </div>
        </SidePopup>
      )}

      {showSharePopup && (
        <SidePopup
          className="signup-popup"
          popClass="pt-[1.3rem] pb-[1.5rem] w-[33rem]"
        >
          <div className="popup-content-bg flex h-full flex-col items-center justify-between px-7">
            <div className=" flex w-full flex-col items-center pb-7">
              <div className="content-top w-full">
                <div className="content-nav -px-[1.5rem] flex w-full items-start justify-between">
                  <p
                    className="flex cursor-pointer items-center text-[13px] font-semibold text-hbrand-600 hover:text-hbrand-700"
                    onClick={() => {
                      setShowCalPopup(true);
                      setShowSharePopup(false);
                    }}
                  >
                    <span
                      className="mr-2.5"
                      dangerouslySetInnerHTML={{ __html: BackArrowSVG }}
                    />
                    Go back
                  </p>

                  <div
                    className="cursor-pointer"
                    onClick={() => setShowSharePopup(false)}
                  >
                    <span
                      className=""
                      dangerouslySetInnerHTML={{ __html: closeBtnSVG }}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-3 w-full">
                <div className="content-info w-full pb-5">
                  <div className="info-text">
                    <h5 className="text-[18px] font-semibold text-htext-main">
                      Share payment request
                    </h5>
                    <p className="mt-1 w-[450px] text-[13px] font-normal text-htext-subdued">
                      Your wallet address and the amount can be shared to
                      receive your payment faster and more conveniently
                    </p>
                  </div>

                  <div className="info-content mt-4 flex flex-col justify-center gap-y-3 rounded-lg bg-hgray-50 p-6">
                    <div className="info-top flex w-full items-center gap-x-6">
                      <div className="flex items-center">
                        <img
                          src={getImageSrc(
                            `${searchParams.get(
                              "amountToSnd"
                            )} to ${searchParams.get("amountToRcv")}`
                          )}
                          alt="Conversion stuff"
                        />
                        <div className="ml-2.5 flex flex-col items-start justify-center">
                          <p className="text-[12px] font-normal text-htext-subdued">
                            Send
                          </p>
                          <p className="text-[18px] font-semibold text-htext-main">
                            {` ${searchParams.get(
                              "calBase"
                            )} ${searchParams.get("amountToSnd")}`}{" "}
                          </p>
                        </div>
                      </div>

                      <span
                        className=""
                        dangerouslySetInnerHTML={{ __html: slideRight }}
                      />
                      <div className="flex flex-col items-start justify-center">
                        <p className="text-[12px] font-normal text-htext-subdued">
                          Receive
                        </p>
                        <p className="text-[18px] font-semibold text-htext-main">
                          {` ${searchParams.get("calTarget")}${searchParams.get(
                            "amountToRcv"
                          )}`}{" "}
                        </p>
                      </div>
                    </div>

                    <div className="info-bottom mt-2.5 space-y-4 border-t border-t-hgray-200 py-4">
                      <h6 className="text-[11px] font-normal text-htext-main">
                        WALLET DETAILS
                      </h6>
                      <div className="flex items-start justify-between">
                        <p className="text-[13px] font-normal text-htext-main">
                          Network
                        </p>
                        <div className="flex items-center">
                          <p className="text-[13px] font-semibold text-htext-main">
                            {
                              unblockWallets?.find(
                                (wallet) => wallet.network == "optimism"
                              )?.network
                            }
                          </p>
                          {
                            chainOptions?.find(
                              ({ value }) =>
                                value.toLocaleLowerCase() == "optimism"
                            )?.icon
                          }
                          {/* <span
                            className="ml-2"
                            dangerouslySetInnerHTML={{ __html: PolygonIcon }}
                          /> */}
                        </div>
                      </div>

                      <div className="flex items-start justify-between">
                        <p className="text-[13px] font-normal text-htext-main">
                          Wallet
                        </p>

                        <p className="w-[180px] break-words text-right text-[13px] font-semibold text-htext-main">
                          {
                            unblockWallets?.find(
                              (wallet) => wallet.network == "optimism"
                            )?.address
                          }
                        </p>
                      </div>
                    </div>

                    <div className="rounded-[12px] bg-hwarning-50 p-4 shadow-s-light">
                      <div className="flex items-start">
                        <span
                          className="-mt-1.5"
                          dangerouslySetInnerHTML={{
                            __html: flusteredWarning,
                          }}
                        />
                        <p className="ml-2.5 text-[12.5px] font-medium text-[#4E1D09]">
                          Always verify your wallet address to ensure a safe
                          transaction. Sending funds to an incorrect address may
                          result in a permanent loss of your assets. We cannot
                          recover funds sent to the wrong address.
                        </p>
                      </div>
                    </div>

                    <button
                      className="mt-3.5 flex w-full items-center justify-center rounded-lg border border-solid border-brand-600 bg-white px-[10px] py-[8px] text-[12.5px] font-semibold text-hbrand-700 shadow-s-light hover:bg-hgray-50"
                      type="button"
                      onClick={() =>
                        handleCopy(
                          unblockWallets?.find(
                            (wallet) => wallet.network == "optimism"
                          )?.address || ""
                        )
                      }
                    >
                      {isCopied ? (
                        <CheckIcon className="h-4 w-4 text-green-500" />
                      ) : (
                        <span
                          className=""
                          dangerouslySetInnerHTML={{ __html: CopyBlueSVG }}
                        />
                      )}
                      <p className="ml-1.5">
                        {isCopied ? "Copied!" : "Copy wallet address"}
                      </p>
                    </button>
                  </div>

                  <form
                    onSubmit={handleSubmit(onShareSubmit)}
                    className="mt-4 pb-10"
                  >
                    <div className="mb-1 flex items-center text-[13px] font-medium">
                      <p className="text-htext-label">Share via email</p>
                    </div>

                    <div className="flex items-center gap-x-3">
                      <label htmlFor="email" className="relative w-full">
                        <Inputs
                          type="email"
                          placeholder="Enter here"
                          icon={EmailSVG}
                          inputClass={true}
                          register={registerShareLInk("email")}
                          error={shareLinkErrors.email}
                        />
                      </label>
                      <button
                        className="w-[100px] rounded-lg border  border-solid border-hbrand-600 bg-white px-[10px] py-[9.8px] text-[13px] font-semibold text-hbrand-700 shadow-s-light hover:bg-hgray-50"
                        type="submit"
                      >
                        {isSharingLink ? (
                          <span className="loading loading-spinner loading-sm"></span>
                        ) : (
                          "Send"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="close-btn flex w-full items-center justify-end border-t border-hgray-200 pb-5 pt-5">
              <button
                className="w-[140px] rounded-lg border  border-solid border-hgray-300 bg-white px-[10px] py-[11px] text-[15px] font-semibold text-hgray-700 shadow-s-light hover:bg-hgray-50"
                type="button"
                onClick={() => {
                  setShowCalPopup(true);
                  setShowSharePopup(false);
                }}
              >
                Go Back
              </button>

              <button
                className="ml-7 w-[280px] rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[11px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
                type="button"
                onClick={() => {
                  setShowCalPopup(false);
                  setShowSharePopup(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </SidePopup>
      )}
    </div>
  );
};

export default PortalOutDetails;
