import SumsubWebSdk from "@sumsub/websdk-react";
import { useParams, useSearchParams } from "react-router-dom";
import { trpc } from "../../lib/trpc";

const Kyc = () => {
  const [searchParams] = useSearchParams();
  const { userId } = useParams();
  const token = searchParams.get("token");
  if (!userId || !token) {
    return <div>Nothing to do here.</div>;
  }
  const { data, isLoading, isError, error } =
    trpc.user.getUserAndUnblockSumSubToken.useQuery({ userId: +userId, token });

  if (isError) {
    console.error(error);
    return <h2>Error...</h2>;
  }
  if (isLoading) {
    return <h2>Loading...</h2>;
  }

  const accessToken = data.sumSubToken;

  if (!accessToken) {
    return <div>Nothing to do here.</div>;
  }

  return (
    <div>
      <SumsubWebSdk
        accessToken={accessToken}
        expirationHandler={console.log}
        config={{
          lang: "en",
          email: data.email,
          phone: data.phoneNumber,
        }}
        options={{
          addViewPortTag: false,
          adaptIframeHeight: true,
        }}
        onMessage={console.log}
        onError={console.error}
      />
    </div>
  );
};

export default Kyc;
