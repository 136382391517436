import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the styles
import { useFormContext } from "react-hook-form";
import { getYear, getMonth } from "date-fns"; // Import getYear and getMonth from date-fns

interface DateDropdownProps {
  name: string;
  error?: any;
}

const DateDropdown = ({ name, error }: DateDropdownProps) => {
  const {
    setValue,
    watch,
  } = useFormContext();
  
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  
  // Range function to create an array of years
  const range = (start: number, end: number, step: number = 1) => {
    return Array.from({ length: (end - start) / step + 1 }, (_, i) => start + i * step);
  };

  const years = range(1900, getYear(new Date()) + 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleFocus = () => {
    setIsFocused(true);
  };

  const fileValue = watch(name); // Watch the value of the field to display it

  useEffect(() => {
    if (fileValue) {
      setSelectedDate(new Date(fileValue)); // Ensure the fileValue is converted to a Date object
    }
  }, [fileValue]);

  return (
    <div className={`relative inline-block w-full rounded-[8px] border border-solid  bg-white px-[16px] shadow-s-light focus:outline-double focus:outline-2 ${
      error
        ? "border-red-500 shadow-s-light-red ring-r-width ring-r-color-red focus:outline-red-400"
        : isFocused
          ? "border-gray-300 ring-r-width ring-r-color focus:outline-hbrand-300"
          : "border-gray-300"
    }`}>
      <DatePicker
        placeholderText=""
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          // decreaseMonth,
          // increaseMonth,
          // prevMonthButtonDisabled,
          // nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // width: "80%",
            }}
          >
            {/* <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button> */}
            <select
            className="w-16 border border-hgray-300 pl-1 py-[0.12rem] rounded-tl-[8px] rounded-br-[8px] "
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(Number(value))}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
            className="ml-1 border border-hgray-300 pl-1 py-[0.12rem] rounded-tl-[8px] rounded-br-[8px]"
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            {/* <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </button> */}
          </div>
        )}
        onChange={(date) => {
          setValue(name, date?.toISOString().trim());
          setSelectedDate(date);
        }}
        onFocus={handleFocus}
        selected={selectedDate}
        dateFormat="dd/MM/yyyy"
        
        className="w-[21rem] border-0 border-transparent bg-transparent py-[9.2px] text-gray-900 focus:outline-none"
      />

      {/* Add a calendar icon for visual */}
      <svg
        className="pointer-events-none absolute right-4 top-1/2 -mt-2 h-4 w-4 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="18"
        viewBox="0 0 16 18"
        fill="none"
      >
        <path
          opacity="0.4"
          d="M0.332031 6.5H15.332V14C15.332 15.8409 13.8396 17.3333 11.9987 17.3333H3.66536C1.82442 17.3333 0.332031 15.8409 0.332031 14V6.5Z"
          fill="#475467"
        />
        <path
          d="M11.9987 1.91675H3.66536C1.82442 1.91675 0.332031 3.40913 0.332031 5.25008V6.50008H15.332V5.25008C15.332 3.40913 13.8396 1.91675 11.9987 1.91675Z"
          fill="#475467"
        />
        <path
          opacity="0.4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 0.041748C4.84518 0.041748 5.125 0.32157 5.125 0.666748V3.16675C5.125 3.51193 4.84518 3.79175 4.5 3.79175C4.15482 3.79175 3.875 3.51193 3.875 3.16675V0.666748C3.875 0.32157 4.15482 0.041748 4.5 0.041748ZM11.1667 0.041748C11.5118 0.041748 11.7917 0.32157 11.7917 0.666748V3.16675C11.7917 3.51193 11.5118 3.79175 11.1667 3.79175C10.8215 3.79175 10.5417 3.51193 10.5417 3.16675V0.666748C10.5417 0.32157 10.8215 0.041748 11.1667 0.041748Z"
          fill="#475467"
        />
      </svg>
    </div>
  );
};

export default DateDropdown;
