import { zodResolver } from "@hookform/resolvers/zod";
import { Country, State } from "country-state-city";
import { format, parseISO } from "date-fns";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DateDropdown from "../../../../components/Common/dropdown/DateDrop";
import MainLoader from "../../../../components/Common/loader/mainLoader";
import { useModal } from "../../../../context/modalProvider";
import { trpc } from "../../../../lib/trpc";
import Inputs from "../../../Common/inputs";
import FileUpload from "../../../Common/inputs/fileUpload";
import PhoneInput from "../../../Common/inputs/phoneInput";
import Select from "../../../Common/inputs/select";
import { arrowRight, EmailSVG } from "../../../Common/svg/index";
import { ProfileFormData, profileFormSchema } from "./schema";

const CompleteProfileForm: FC = () => {
  const navigate = useNavigate();
  const { showModal } = useModal();
  const { mutateAsync: getSession, isLoading: isLoadingGettingSession } =
    trpc.user.isSessionIdExpired.useMutation({
      onError: (e) => {
        toast.error(e.message);
      },
    });
  const { mutateAsync: triggerOtp } = trpc.user.triggerOtp.useMutation({
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const { mutate, isLoading } = trpc.user.createPersonalProfile.useMutation({
    onSuccess: () => {
      navigate("/dashboard/get-started");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const {
    mutate: updatePersonalProfile,
    isLoading: isLoadingupdatePersonalProfile,
  } = trpc.user.updatePersonalProfile.useMutation({
    onSuccess: () => {
      navigate("/dashboard/get-started");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });
  const { data: authUser } = trpc.user.getAccountType.useQuery({});

  const { data: personalProfile, isLoading: isFetchPersonalProfile } =
    trpc.user.fetchPersonalProfile.useQuery({});

  const handleGoBack = (): void => {
    navigate("/dashboard/get-started");
  };

  const methods = useForm({
    resolver: zodResolver(profileFormSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      dob: "",
      sourceOfFunds: "",
      idType: "",
      idNumber: "",
      uploadIdUrl: "",
      addressLine: "",
      zipCode: "",
      country: "",
      city: "",
      addressVerification: "",
      proofOfAddressUrl: "",
    },
  });

  const {
    watch,
    setValue,
    reset,
    register,
    formState: { errors },
  } = methods;

  setValue("email", authUser?.email as string);

  useEffect(() => {
    if (personalProfile) {
      reset({
        firstName: personalProfile.firstName || "",
        lastName: personalProfile.lastName || "",
        email: personalProfile.email || "",
        phoneNumber: personalProfile.phoneNumber || "",
        dob: personalProfile.dob || "",
        sourceOfFunds: personalProfile.sourceOfFunds || "",
        idType: personalProfile.idType || "",
        idNumber: personalProfile.idNumber || "",
        uploadIdUrl: personalProfile.uploadIdUrl || "",
        addressLine: personalProfile.addressLine || "",
        zipCode: personalProfile.zipCode || "",
        country: personalProfile.country || "",
        city: personalProfile.city || "",
        addressVerification: personalProfile.addressVerification || "",
        proofOfAddressUrl: personalProfile.proofOfAddressUrl || "",
      });
    }
  }, [personalProfile, reset]);

  const sof = ["SALARY", "BUSINESS_INCOME", "PENSION", "OTHER"];
  const addressVerificationOptions = [
    "WATER_ELECTRICITY_GAS_TELEPHONE_INTERNET_BILL",
    "CREDIT_CARD_BILL_STATEMENT",
    "BANK_STATEMENT",
    "MORTGAGE_STATEMENT_OR_CONTRACT",
    "LETTER_ISSUED_BY_A_PUBLIC_AUTHORITY",
    "COMPANY_PAYSLIP",
    "CAR_OR_HOME_INSURANCE_POLICY",
    "CAR_REGISTRATION",
    "MUNICIPALITY_BILL_OR_GOVERNMENT_TAX_LETTER",
    "DRIVERS_LICENCE",
    "RESIDENCE_PERMIT",
  ];

  const idTypes = ["Drivers Licence", "International Passport", "National ID"];

  const onProfileFormSaved = async (formData: ProfileFormData) => {
    formData.dob = format(parseISO(formData.dob), "yyyy/MM/dd");
    if (personalProfile?.id) {
      const response = await getSession({});
      if (response.sessionIdHasExpired) {
        await triggerOtp({});
        showModal();
      } else {
        updatePersonalProfile(formData);
      }
    } else {
      mutate(formData);
    }
  };

  if (isFetchPersonalProfile) {
    return <MainLoader />;
  }

  return (
    <div className="pform h-screen w-screen bg-white pb-2 pt-4">
      <div className="pform-content h-full w-full overflow-hidden">
        <div className="content-nav mt-5 flex items-center px-40">
          <p className="text-[13px] font-medium text-htext-label">
            Onboarding checklist
          </p>
          <span
            className="mx-4"
            dangerouslySetInnerHTML={{ __html: arrowRight }}
          />
          <p className="text-[13px] font-semibold text-hbrand-700">
            Complete your personal profile
          </p>
        </div>

        <div className="content-form mx-auto h-full w-full pb-40 ">
          <div className="form-header mx-auto mt-14 w-[800px] pb-8">
            <div>
              <h2 className="text-[28px] font-semibold tracking-[-0.72px] text-htext-main">
                Complete your personal profile
              </h2>
            </div>
          </div>
          <div className="hide-scrollbar h-full w-full overflow-y-scroll pb-20">
            <div className="header-header mx-auto w-[800px] rounded-t-[12px] border border-b-0 border-hgray-100 bg-hgray-50 px-5 pb-3 pt-5">
              <h4 className="text-[18px] font-semibold text-htext-main">
                Personal information
              </h4>
              <p className="mt-2.5 text-[13px] font-normal text-htext-subdued">
                Be sure to enter your name as it appears on your
                government-issued ID
              </p>
            </div>
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onProfileFormSaved)}
                className="form-content h-full w-full rounded-b-[16px] bg-white"
              >
                <fieldset>
                  <div className="mx-auto w-[800px] rounded-b-[12px] border border-t-0 border-hgray-100 pb-6  pt-1">
                    <div className="px-5">
                      <div
                        className={`mt-6 flex w-full items-start justify-between ${
                          errors.firstName || errors.lastName ? "h-24" : ""
                        }`}
                      >
                        <label
                          htmlFor="firstName"
                          className="relative h-full w-[48.5%]"
                        >
                          <div className="mb-1 flex items-center text-[13px] font-medium">
                            {/* {((!dirtyFields.firstName && !errors.firstName) ||
                              (dirtyFields.firstName && errors.firstName)) && ( */}
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>
                            {/* )} */}
                            <p className="text-htext-label">Legal first name</p>
                          </div>
                          <Inputs
                            type="text"
                            placeholder=""
                            register={register("firstName")}
                            inputClass={true}
                            error={errors.firstName}
                          />
                          {errors.firstName && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.firstName.message}!
                            </p>
                          )}
                        </label>

                        <label
                          htmlFor="lastName"
                          className="relative h-full w-[48.5%]"
                        >
                          <div className="mb-1 flex items-center text-[13px] font-medium">
                            {/* {((!dirtyFields.lastName && !errors.lastName) ||
                              (dirtyFields.lastName && errors.lastName)) && ( */}
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>
                            {/* )} */}
                            <p className="text-htext-label">Legal last name</p>
                          </div>
                          <Inputs
                            type="text"
                            placeholder=""
                            register={register("lastName")}
                            inputClass={true}
                            error={errors.lastName}
                          />
                          {errors.lastName && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.lastName.message}!
                            </p>
                          )}
                        </label>
                      </div>

                      <div
                        className={`flex w-full items-start justify-between ${
                          errors.phoneNumber || errors.email
                            ? "mt-[.80rem] h-24"
                            : "mt-[1.30rem]"
                        }`}
                      >
                        <label
                          htmlFor="phoneNumber"
                          className="relative h-full w-[48.5%]"
                        >
                          <div className="mb-1.5 flex items-center text-[13px] font-medium">
                            {/* {((!dirtyFields.phoneNumber &&
                              !errors.phoneNumber) ||
                              (dirtyFields.phoneNumber &&
                                errors.phoneNumber)) && ( */}
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>
                            {/* )} */}
                            <p className="text-htext-label">Phone Number</p>
                          </div>

                          <PhoneInput
                            name="phoneNumber"
                            placeholder="Enter phone number"
                            inputClass={true}
                            error={errors.phoneNumber}
                          />
                          {errors.phoneNumber && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.phoneNumber.message}!
                            </p>
                          )}
                        </label>

                        <label htmlFor="email" className="h-full w-[48.5%]">
                          <div className="mb-1.5 flex items-center text-[13px] font-medium">
                            <p className="text-htext-label">Email</p>
                          </div>
                          <Inputs
                            type="email"
                            icon={EmailSVG}
                            placeholder="olivia@gmail.com"
                            register={register("email")}
                            inputClass={true}
                            disabled
                            error={errors.email?.message}
                          />
                        </label>
                      </div>

                      <div
                        className={`flex w-full items-start justify-between ${
                          errors.dob || errors.sourceOfFunds
                            ? "mt-[.80rem] h-24"
                            : "mt-[1.30rem]"
                        }`}
                      >
                        <label
                          htmlFor="dob"
                          className="relative h-full w-[48.5%]"
                        >
                          <div className="mb-1.5 flex items-center text-[13px] font-medium">
                            {/* {((!dirtyFields.dob && !errors.dob) ||
                              (dirtyFields.dob && errors.dob)) && ( */}
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>
                            {/* )} */}
                            <p className="text-htext-label">Date of birth</p>
                          </div>
                          <DateDropdown name="dob" error={errors.dob} />
                          {errors.dob && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.dob.message}!
                            </p>
                          )}
                        </label>

                        <label
                          htmlFor="sourceOfFunds"
                          className="relative h-full w-[48.5%]"
                        >
                          <div className="mb-1.5 flex items-center text-[13px] font-medium">
                            {/* {((!dirtyFields.sourceOfFunds &&
                              !errors.sourceOfFunds) ||
                              (dirtyFields.sourceOfFunds &&
                                errors.sourceOfFunds)) && ( */}
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>
                            {/* )} */}
                            <p className="text-htext-label">Source of funds</p>
                          </div>
                          <Select
                            id="sourceOfFunds"
                            register={register("sourceOfFunds")}
                            inputClass={true}
                            optionsItem={sof}
                            error={errors.sourceOfFunds}
                          />
                          {errors.sourceOfFunds && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.sourceOfFunds.message}!
                            </p>
                          )}
                        </label>
                      </div>

                      <div
                        className={`flex w-full items-start justify-between ${
                          errors.idType || errors.idNumber
                            ? "mt-[.80rem] h-24"
                            : "mt-[1.30rem]"
                        }`}
                      >
                        <label
                          htmlFor="idType"
                          className="relative h-full w-[48.5%]"
                        >
                          <div className="mb-1.5 flex items-center text-[13px] font-medium">
                            {/* {((!dirtyFields.idType && !errors.idType) ||
                              (dirtyFields.idType && errors.idType)) && ( */}
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>
                            {/* )} */}
                            <p className="text-htext-label">ID type</p>
                          </div>

                          <Select
                            id="idType"
                            register={register("idType")}
                            inputClass={true}
                            optionsItem={idTypes}
                            error={errors.idType}
                          />
                          {errors.idType && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.idType.message}!
                            </p>
                          )}
                        </label>

                        <label
                          htmlFor="idNumber"
                          className="relative h-full w-[48.5%]"
                        >
                          <div className="mb-1.5 flex items-center text-[13px] font-medium">
                            {/* {((!dirtyFields.idNumber && !errors.idNumber) ||
                              (dirtyFields.idNumber && errors.idNumber)) && ( */}
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>
                            {/* )} */}
                            <p className="text-htext-label">ID number</p>
                          </div>
                          <Inputs
                            type="text"
                            placeholder="Enter here"
                            register={register("idNumber")}
                            inputClass={true}
                            error={errors.idNumber}
                          />
                          {errors.idNumber && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.idNumber.message}!
                            </p>
                          )}
                        </label>
                      </div>

                      <div
                        className={`flex w-full items-start justify-between ${
                          errors.uploadIdUrl ? "mt-[.80rem]" : "mt-[1.30rem]"
                        }`}
                      >
                        <label htmlFor="uploadIdUrl" className="w-full">
                          <div className="mb-1 flex items-center text-[13px] font-medium">
                            {/* {((!dirtyFields.uploadIdUrl &&
                              !errors.uploadIdUrl) ||
                              (dirtyFields.uploadIdUrl &&
                                errors.uploadIdUrl)) && ( */}
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>
                            {/* )} */}
                            <p className="text-htext-label">Upload ID</p>
                          </div>
                          <p className="mb-1.5 mt-[-3px] text-[13px] font-normal text-htext-subdued ">
                            Clearly show your biodata on the ID
                          </p>
                          <FileUpload
                            name="uploadIdUrl"
                            error={errors.uploadIdUrl}
                          />
                          {errors.uploadIdUrl && (
                            <p className="font-ibm-plex mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.uploadIdUrl.message}!
                            </p>
                          )}
                        </label>
                      </div>
                    </div>

                    <div className="mt-14 w-full bg-hgray-50 px-5 py-4">
                      <h3 className="text-[18px] font-semibold text-htext-main">
                        Residential address
                      </h3>
                    </div>

                    <div className="px-5">
                      <div
                        className={`mt-5 flex w-full items-start justify-between ${
                          errors.addressLine || errors.zipCode ? "h-24" : ""
                        }`}
                      >
                        <label
                          htmlFor="addressLine"
                          className="relative h-full w-[48.5%]"
                        >
                          <div className="mb-1.5 flex items-center text-[13px] font-medium">
                            {/* {((!dirtyFields.addressLine &&
                              !errors.addressLine) ||
                              (dirtyFields.addressLine &&
                                errors.addressLine)) && ( */}
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>
                            {/* )} */}
                            <p className="text-htext-label">Address</p>
                          </div>
                          <Inputs
                            type="text"
                            placeholder=""
                            register={register("addressLine")}
                            inputClass={true}
                            error={errors.addressLine}
                          />
                          {errors.addressLine && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.addressLine.message}!
                            </p>
                          )}
                        </label>

                        <label
                          htmlFor="zipCode"
                          className="relative h-full w-[48.5%]"
                        >
                          <div className="mb-1.5 flex items-center text-[13px] font-medium">
                            {/* {((!dirtyFields.zipCode && !errors.zipCode) ||
                              (dirtyFields.zipCode && errors.zipCode)) && ( */}
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>
                            {/* )} */}
                            <p className="text-htext-label">Zip/Post code</p>
                          </div>
                          <Inputs
                            type="text"
                            placeholder=""
                            register={register("zipCode")}
                            inputClass={true}
                            error={errors?.zipCode}
                          />
                          {errors.zipCode && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.zipCode.message}!
                            </p>
                          )}
                        </label>
                      </div>

                      <div
                        className={`flex w-full items-start justify-between ${
                          errors.country || errors.city
                            ? "mt-[.80rem] h-24"
                            : "mt-[1.30rem]"
                        }`}
                      >
                        <label
                          htmlFor="country"
                          className="relative h-full w-[48.5%]"
                        >
                          <div className="mb-1.5 flex items-center text-[13px] font-medium">
                            {/* {((!dirtyFields.country && !errors.country) ||
                              (dirtyFields.country && errors.country)) && ( */}
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>
                            {/* )} */}
                            <p className="text-htext-label">Country</p>
                          </div>
                          <Select
                            id="country"
                            register={register("country")}
                            inputClass={true}
                            optionsItem={Country.getAllCountries().map(
                              ({ name, isoCode }) => ({
                                value: isoCode,
                                label: name,
                              })
                            )}
                            error={errors?.country}
                          />
                          {errors.country && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.country.message}!
                            </p>
                          )}
                        </label>

                        <label
                          htmlFor="city"
                          className="relative h-full w-[48.5%]"
                        >
                          <div className="mb-1.5 flex items-center text-[13px] font-medium">
                            {/* {((!dirtyFields.city && !errors.city) ||
                              (dirtyFields.city && errors.city)) && ( */}
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>
                            {/* )} */}
                            <p className="text-htext-label">State</p>
                          </div>
                          <Select
                            id="city"
                            register={register("city")}
                            inputClass={true}
                            optionsItem={State.getStatesOfCountry(
                              watch("country")
                            ).map(({ name, isoCode }) => ({
                              value: isoCode,
                              label: name,
                            }))}
                            error={errors?.city}
                          />
                          {errors.city && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.city.message}!
                            </p>
                          )}
                        </label>
                      </div>

                      <div
                        className={`flex w-full items-start justify-between ${
                          errors.addressVerification
                            ? "mt-[.80rem] h-24"
                            : "mt-[1.30rem]"
                        }`}
                      >
                        <label
                          htmlFor="addressVerification"
                          className="relative h-full w-full"
                        >
                          <div className="mb-1.5 flex items-center text-[13px] font-medium">
                            {/* {((!dirtyFields.addressVerification &&
                              !errors.addressVerification) ||
                              (dirtyFields.addressVerification &&
                                errors.addressVerification)) && ( */}
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>
                            {/* )} */}
                            <p className="text-htext-label">
                              Address verification option
                            </p>
                          </div>

                          <Select
                            id="addressVerification"
                            register={register("addressVerification")}
                            inputClass={true}
                            optionsItem={addressVerificationOptions}
                            error={errors?.addressVerification}
                          />
                          {errors.addressVerification && (
                            <p className="before:font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.addressVerification.message}!
                            </p>
                          )}
                        </label>
                      </div>

                      <div
                        className={`flex w-full items-start justify-between ${
                          errors.proofOfAddressUrl
                            ? "mt-[.80rem]"
                            : "mt-[1.30rem]"
                        }`}
                      >
                        <label
                          htmlFor="proofOfAddressUrl"
                          className="relative w-full"
                        >
                          <div className="mb-1 flex items-center text-[13px] font-medium">
                            {/* {((!dirtyFields.proofOfAddressUrl &&
                              !errors.proofOfAddressUrl) ||
                              (dirtyFields.proofOfAddressUrl &&
                                errors.proofOfAddressUrl)) && ( */}
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>
                            {/* )} */}
                            <p className="text-htext-label">
                              Upload proof of address option selected above
                            </p>
                          </div>
                          <p className="mb-1.5 mt-[-2px] text-[13px] font-normal text-htext-subdued">
                            The proof shouldn’t be more than 3 months old,
                            clearly showing your address
                          </p>
                          <FileUpload
                            name="proofOfAddressUrl"
                            error={errors?.proofOfAddressUrl}
                          />
                          {errors.proofOfAddressUrl && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[13px] font-medium text-red-600">
                              {errors.proofOfAddressUrl.message}!
                            </p>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-btns mt-28 flex h-full w-full items-center justify-between border-t border-t-hgray-200 px-14 pb-7 pt-6">
                    <button
                      className="w-[150px] cursor-pointer rounded-lg  border border-solid border-hgray-300 bg-white px-[10px] py-[13px] text-[15px] font-semibold text-hgray-700 shadow-s-light hover:bg-hgray-50"
                      type="button"
                      onClick={() => handleGoBack()}
                    >
                      Go Back
                    </button>

                    <button
                      className="w-[250px] cursor-pointer rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[13px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
                      type="submit"
                    >
                      {isLoading ||
                      isLoadingupdatePersonalProfile ||
                      isLoadingGettingSession ? (
                        <span className="loading loading-spinner loading-sm"></span>
                      ) : personalProfile?.id ? (
                        "Update and continue"
                      ) : (
                        "Save and continue"
                      )}
                    </button>
                  </div>
                </fieldset>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteProfileForm;
