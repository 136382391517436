import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { DashboardSVG, GetStartedSVG, HelpFaqSVG, PortalAccSVG, TeamsSVG, TransactionsSVG } from "../../components/Common/svg";
import { trpc } from "../../lib/trpc";



interface Item {
	name: string,
	icon: string,
	route: string,
	subRoute: string
}

interface SideItemProps {
	item: Item
}

const SideItem: FC<SideItemProps> = ({ item }) => {
	const location = useLocation();
	const isActive = item?.route === location.pathname || location.pathname.includes(item?.subRoute);


	return (
		<Link to={item?.route} className="">
			<div className={`w-[230px] flex items-center flex-[1_0_0%] mb-3 ${isActive ? "text-hbrand-600 bg-hbrand-50" : "text-htext-label"} hover:bg-hbrand-50 rounded-[6px] py-2 pl-3.5 pr-2`}>
				<div className={`mr-4 transition-transform ${isActive ? "scale-90" : "scale-100"}`}>
				<span dangerouslySetInnerHTML={{ __html: item?.icon }} />
			</div>
		        <h3 className={`icon-name text-sm w-full ${isActive ? "font-semibold" : "font-normal"}`}>{item?.name}</h3>
		    </div>
		</Link>
	)
}

const Sidebar: FC = () => {
	const location = useLocation();
	const { data: user } = trpc.user.getAccountType.useQuery({});
	
   const itemsData: Item[] = [
	{
		name: 'Get Started', 
		icon: GetStartedSVG,
		route: "get-started",
		subRoute: "get-started"
    },
	{
		name: 'Dashboard', 
		icon: DashboardSVG,
		route: "home",
		subRoute: "home"
    },
	{
		name: 'Portal accounts', 
		icon: PortalAccSVG,
		route: "portal-accounts",
		subRoute: "portal-accounts"
    },
	{
		name: 'Transactions', 
		icon: TransactionsSVG,
		route: "transactions",
		subRoute: "transactions"
    },
	{
		name: 'Teams', 
		icon: TeamsSVG,
		route: "teams",
		subRoute: "teams"
    },
	{
		name: 'Help and FAQ', 
		icon: HelpFaqSVG,
		route: "help-and-faq",
		subRoute: "help-and-faq"
    },
   ];

    // Filter out the Teams item if the account type is Individual
	const filteredItemsData = ((user?.acountType || "") as string) === "INDIVIDUAL"
	? itemsData.filter(item => item.name !== 'Teams')
	: itemsData;
  
  const finalItemsData = ((user?.shigaKycStatus || "") as string) === 'ACTIVE' && location.pathname !== "/dashboard/get-started"
	? filteredItemsData.filter(item => item.name !== "Get Started")
	: filteredItemsData;

	return (
		<div className="sidebar fixed w-[16.5rem] h-screen bg-hgray-50 z-20 py-6 flex flex-col">
			<div className="pr-9 pl-8">
		  <div className="w-[100px] pt-2 pb-4 gap-9">
			<img className="w-full" src="/shiga-cus.svg" alt="Shiga.io logo" />
			<p className="text-xs text-htext-subdued font-normal text-center">FOR {(user?.acountType == "CORPORATE" ? "BUSINESSES" : user?.acountType || "").toUpperCase()}</p>
		  </div>
			</div>

			<div className="pr-9 pl-5">
		  <div className="mt-10">
			{finalItemsData.map((item, index) => (
				<SideItem item={item} key={index} />
			))}
		  </div>
			</div>
		</div>
	)
}

export default Sidebar