import PortalOutSettlementInfo from "../../../../components/Dashboard/portal-accounts/portal-out/portal-out-settlement-info";

function PortalOutSettlementInformation() {
    return (
            <div>
                <PortalOutSettlementInfo />
            </div>
    )
}

export default PortalOutSettlementInformation;