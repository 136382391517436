import { Outlet, useLocation } from "react-router-dom";
import { trpc } from "../../lib/trpc";
import Navbar from "../../section/navbar";
import Sidebar from "../../section/sidebar";
import MainLoader from "../Common/loader/mainLoader";

function Dashboard() {
  const location = useLocation();
  const { data, isLoading } = trpc.user.getAccountType.useQuery({});
  const { data: user } = trpc.user.getUserById.useQuery({});

  const currentRoute = location.pathname;
  let navbarTitle = "";
  let navbarSubTitle = "";
  let userData = {
    name: "",
    email: "",
    company: "",
  };

  if (currentRoute.includes("/dashboard/home")) {
    navbarTitle = user?.firstName ? `Welcome ${user?.firstName}` : "";

    if (data?.acountType) {
      navbarSubTitle =
        data?.acountType === "INDIVIDUAL"
          ? ""
          : "You're signed in as an admin in";
    }

    userData = {
      name: "",
      email: data?.email || "",
      company: "",
    };
  } else if (currentRoute.includes("/portal-accounts")) {
    navbarTitle = "Portal accounts";
    userData = {
      name: "",
      email: data?.email || "",
      company: "",
    };
  } else if (currentRoute.includes("/transactions")) {
    navbarTitle = "Transactions";
    userData = {
      name: "",
      email: data?.email || "",
      company: "",
    };
  }

  if (isLoading) {
    return <MainLoader />;
  }

  return (
    <div className="flex h-screen w-screen items-start overflow-y-hidden">
      <Sidebar />
      <div className="dashboard_content relative ml-64 flex h-full w-full flex-col overflow-x-hidden bg-white">
        <Navbar
          navHeader={navbarTitle}
          navSubheader={navbarSubTitle}
          navUser={userData}
        />
        <div
          className={`overflow-y-scroll ${
            currentRoute === "/dashboard/home" ? "pb-8 pt-3.5" : "py-8"
          } pl-14 pr-12`}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
