const ComingSoon = ({name}:{name:string}) => {
  return (
    <div className="text-center">
      <img
        className="mb-4 inline-block"
        src="/assets/coming-soon.svg"
        alt="Already existing svg"
      />
      <h1 className="font-ibm-plex mb-5 text-2xl font-bold text-hgray-900">
        {name} coming soon
      </h1>
      <p className="mb-5 text-base font-normal text-gray-600">
        <span className="block">
          {" "}
          We are hard at work to allow you to manage all your
        </span>{" "}
        <span className="block"> activities seamlessly.</span>
      </p>
      <div className="flex items-center">
        <div className="flex items-center">
          <img
            className="mr-5 inline-block"
            src="/assets/info.svg"
            alt="information"
          />
        </div>{" "}
        <p className="mb-5 flex items-center flex-col text-base font-normal text-gray-600">
          <span className="block">
            {" "}
            In the meantime, stay tuned for important{" "}
          </span>

          <span className="block">
            updates and transaction details via email.
          </span>
        </p>
      </div>
      <hr className="mb-7 mt-5 max-[]:-7 h-px border-0 bg-gray-200 " />
      <p className="mb-2 text-base font-normal text-gray-600">Need help?</p>
      <p className="mb-2 text-base font-normal text-gray-600">Contact us via email and we’ll be in touch</p>
      <div className="flex items-center justify-center ">
        <img className="mr-2 h-5" src="/assets/email.svg" alt="information" />
        <p className="text-base font-semibold text-hbrand-700">
          contact@shiga.io
        </p>
      </div>{" "}
    </div>
  );
};

export default ComingSoon;
