import { zodResolver } from "@hookform/resolvers/zod";
import { CheckCircle } from "lucide-react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as z from "zod";
import Inputs from "../../../components/Common/inputs";
import {
  errorColonSVG,
  HidePassSVG,
  ShowPassSVG,
  successTickSVG,
} from "../../../components/Common/svg";
import { trpc } from "../../../lib/trpc";
import { passwordSchema } from "../../../utils/validationSchema";

export const resetPasswordSchema = z
  .object({
    passwordNew: passwordSchema,
    passwordCurrent: passwordSchema,
    confirmPassword: z.string(),
  })
  .refine((data) => data.passwordNew === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"], // Path to the error
  });
export type ResetPasswordSchema = z.infer<typeof resetPasswordSchema>;

export default function Security() {
  const { mutate: UpdatePassword, isLoading } =
    trpc.user.customerChangePassword.useMutation({
      onSuccess: () => {
        reset();
        toast.success('Password Updated')

      },
      onError:(e)=>{
        if(e.data?.httpStatus||0 > 499){
          toast.error('Please try again later')
          return;
        }
        toast.error(e.message)
      }
    });

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, dirtyFields, isSubmitted },
    watch,
  } = useForm({
    resolver: zodResolver(resetPasswordSchema),
    defaultValues: {
      passwordCurrent: "",
      passwordNew: "",
      confirmPassword: "",
    },
  });

  const onSubmit = async (data: ResetPasswordSchema) => {
    const { passwordCurrent, passwordNew } = data;
    UpdatePassword({ passwordCurrent, passwordNew });
    // setFormSubmitted(true);
    // setUserEmail(data.email);
    // signUp({ email: data.email, password: data.password });
  };

  const passwordValue = watch("passwordNew");
  const confirmPasswordValue = watch("confirmPassword");

  console.log({ coonfirm: confirmPasswordValue, passwordValue, errors });

  return (
    <div className="font-ibm-plex mt-6 grid grid-cols-2 gap-6">
      <div className="rounded-md border border-hgray-200 bg-hgray-50 px-5 py-8">
        <h2 className="mb-4 text-lg font-semibold">Password</h2>
        <p className="font-ibm-plex mb-4 text-sm text-gray-500">
          Please enter your current password to change your password.
        </p>

        <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label
              htmlFor="current-password"
              className="font-ibm-plex mb-1 block text-sm font-medium text-gray-700"
            >
              Current password
            </label>
            <label
              htmlFor="passwordCurrent"
              className="font-ibm-plex text-[14px] font-semibold text-htext-label"
            >
              Password
              <Inputs
                type="password"
                id="passwordCurrent"
                placeholder="current password"
                showIcon={ShowPassSVG}
                hideIcon={HidePassSVG}
                register={register("passwordCurrent")}
                error={errors.passwordCurrent}
              />
            </label>
          </div>

          <div>
            <div className="relative">
              <label
                htmlFor="passwordNew"
                className="font-ibm-plex text-[14px] font-semibold text-htext-label"
              >
                New password
                <Inputs
                  type="password"
                  id="passwordNew"
                  placeholder="Confirm your password"
                  showIcon={ShowPassSVG}
                  hideIcon={HidePassSVG}
                  register={register("passwordNew")}
                  error={errors.passwordNew}
                />
              </label>
            </div>
          </div>

          <div>
            <label
              htmlFor="confirmPassword"
              className="font-ibm-plex text-[14px] font-semibold text-htext-label"
            >
              Confirm New Password
              <Inputs
                type="password"
                id="confirmPassword"
                placeholder="Confirm your password"
                showIcon={ShowPassSVG}
                hideIcon={HidePassSVG}
                register={register("confirmPassword")}
                error={errors.confirmPassword}
              />
            </label>
          </div>

          <div className="passError items-bottom font-ibm-plex mt-4 flex flex-wrap justify-start text-[12.5px] font-normal text-htext-subdued">
            <p className="my-2 flex w-44 items-center">
              {dirtyFields.passwordNew || isSubmitted ? (
                passwordValue && /[A-Z]/.test(passwordValue) ? (
                  <span
                    className="mr-2"
                    dangerouslySetInnerHTML={{
                      __html: successTickSVG || "",
                    }}
                  />
                ) : (
                  <span
                    className="mr-2"
                    dangerouslySetInnerHTML={{
                      __html: errorColonSVG || "",
                    }}
                  />
                )
              ) : (
                <span className="mr-2 block h-3.5 w-3.5 rounded-full bg-hgray-500/[0.4]"></span>
              )}
              At least one uppercase
            </p>

            <p className="my-2 flex w-44 items-center">
              {dirtyFields.confirmPassword || isSubmitted ? (
                confirmPasswordValue === passwordValue ? (
                  <span
                    className="mr-2"
                    dangerouslySetInnerHTML={{
                      __html: successTickSVG || "",
                    }}
                  />
                ) : (
                  <span
                    className="mr-2"
                    dangerouslySetInnerHTML={{
                      __html: errorColonSVG || "",
                    }}
                  />
                )
              ) : (
                <span className="mr-2 block h-3.5 w-3.5 rounded-full bg-hgray-500/[0.4]"></span>
              )}
              Confirm password
            </p>

            <p className="my-2 flex w-44 items-center">
              {dirtyFields.passwordNew || isSubmitted ? (
                passwordValue && /[0-9]/.test(passwordValue) ? (
                  <span
                    className="mr-2"
                    dangerouslySetInnerHTML={{
                      __html: successTickSVG || "",
                    }}
                  />
                ) : (
                  <span
                    className="mr-2"
                    dangerouslySetInnerHTML={{
                      __html: errorColonSVG || "",
                    }}
                  />
                )
              ) : (
                <span className="mr-2 block h-3.5 w-3.5 rounded-full bg-hgray-500/[0.4]"></span>
              )}
              At least one number
            </p>

            <p className="my-2 flex w-44 items-center">
              {dirtyFields.passwordNew || isSubmitted ? (
                passwordValue && passwordValue.length > 8 ? (
                  <span
                    className="mr-2"
                    dangerouslySetInnerHTML={{
                      __html: successTickSVG || "",
                    }}
                  />
                ) : (
                  <span
                    className="mr-2"
                    dangerouslySetInnerHTML={{
                      __html: errorColonSVG || "",
                    }}
                  />
                )
              ) : (
                <span className="mr-2 block h-3.5 w-3.5 rounded-full bg-hgray-500/[0.4]"></span>
              )}
              8 characters minimum
            </p>

            <p className="my-2 flex w-48 items-center">
              {dirtyFields.passwordNew || isSubmitted ? (
                passwordValue && /[^A-Za-z0-9]/.test(passwordValue) ? (
                  <span
                    className="mr-2"
                    dangerouslySetInnerHTML={{
                      __html: successTickSVG || "",
                    }}
                  />
                ) : (
                  <span
                    className="mr-2"
                    dangerouslySetInnerHTML={{
                      __html: errorColonSVG || "",
                    }}
                  />
                )
              ) : (
                <span className="mr-2 block h-3.5 w-3.5 rounded-full bg-hgray-500/[0.4]"></span>
              )}
              At least one special character
            </p>
          </div>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-outline border border-hbrand-300 text-hbrand-400"
            >
           {isLoading ? "Updating Password": 'Update password'}
            </button>
          </div>
        </form>
      </div>

      <div className="h-2/5 rounded-md border border-hgray-200 bg-hgray-50 px-5 py-8">
        <h2 className="font-ibm-plex mb-4 text-lg font-semibold">
          Two-factor authentication (2FA)
        </h2>
        <p className="font-ibm-plex mb-4 text-sm text-gray-500">
          Local currency sent to your portal account are sent to these saved
          stablecoin wallets.
        </p>

        <div className="flex items-start space-x-3 rounded-md border border-blue-200 bg-blue-50 p-4">
          <CheckCircle className="mt-0.5 h-5 w-5 text-blue-500" />
          <div>
            <p className="font-ibm-plex text-sm font-medium text-blue-800">
              2FA is enabled on your account via email verification
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
