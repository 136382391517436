import React, { Fragment } from "react";

interface Option {
  value: string;
  label: string;
}

type ColumnKey = string | { [key: string]: string };

type Column = {
  label: string;
  key: ColumnKey;
  format?: (v1: any, v2?: any) => any;
  meta?: {
    type?: string;
    options?: Option[];
  } | null;
  className?: string;
};

type TableProps = {
  enableActions?: boolean;
  title?: string;
  subtitle?: string;
  tableClass?: boolean;
  columns: Column[];
  data: any[];
  setShowTransactionPopup: (show: boolean, id?: string) => void;
  onRowClick?: (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: any
  ) => void;
  onClickSave?: (row: any) => void;
};

const capitalize = (str: string) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
export const getImageSrc = (formattedValue: any) => {
  switch (formattedValue) {
      case "USDT to GBP":
          return "/assets/USDT-to-GBP.svg";
      case "USDT to EUR":
          return "/assets/USDT-to-EUR.svg";
      case "USDC to EUR":
          return "/assets/USDC-to-EUR.svg";
      case "USDC to GBP":
          return "/assets/USDC-to-GBP.svg";
      case "EUR to USDT":
          return "/assets/EUR-to-USDT.svg";
      case "GBP to USDT":
          return "/assets/GBP-to-USDT.svg";
      case "EUR to USDC":
          return "/assets/euro-to-USDC.svg";
      case "GBP to USDC":
          return "/assets/GBP-to-USDC.svg";
      case "EUR to GBP":
          return "/assets/EUR-to-GBP.svg";
      case "GBP to EUR":
          return "/assets/GBP-to-EUR.svg";
      default:
        return "/assets/coin.svg";
  }
};


export function Table({
  columns = [],
  data = [],
  enableActions,
  tableClass,
  setShowTransactionPopup,
}: TableProps): JSX.Element {
  return (
    <Fragment>
      <div className="overflow-x-auto rounded-t-[12px]">
        <table className="table table-xs">
          <thead className="bg-hgray-50 text-xs font-medium text-htext-subdued">
            <tr>
              {columns.map((column: Column) => (
                <th
                  scope="col"
                  className="px-6 py-[15px] text-[12px] font-medium text-htext-subdued opacity-[0.8]"
                  key={
                    typeof column.key === "string"
                      ? column.key
                      : Object.values(column.key).join()
                  }
                >
                  {column.label}
                </th>
              ))}
              {enableActions && (
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data?.map((row: any, rowIndex: number) => {
              const transactionId = row?.id;
              
              return (
                <tr className="odd:bg-white" key={rowIndex}>
                  {columns.map((column: Column, colIndex: number) => {
                    let value;
                    if (typeof column.key === "string") {
                      value = column.key
                        .split(".")
                        .reduce((obj, key) => obj?.[key], row);
                    } else {
                      value = Object.fromEntries(
                        Object.entries(column.key).map(([key, path]) => [
                          key,
                          path.split(".").reduce((obj, k) => obj?.[k], row),
                        ])
                      );
                    }
                    return (
                      <td
                        className="border-b border-t border-solid border-hgray-200 px-6 py-[18px]"
                        key={colIndex}
                      >
                        {column?.format &&
                        (column.label === "Date" || column.label === "Type") ? (
                          <span
                            className={`${column.className} ${
                              tableClass
                                ? "text-[13px] font-normal text-htext-main"
                                : ""
                            } `}
                          >
                            {tableClass && column.label === "Date" ? (
                              <div className="flex flex-col">
                                <span>
                                  {String(column?.format(value, row) ?? "")
                                    .split(" ")
                                    .slice(0, -2)
                                    .join(" ")}
                                </span>
                                <span className="mt-[3px] text-[11.2px] font-normal text-htext-subdued">
                                  {String(column?.format(value, row) ?? "")
                                    .split(" ")
                                    .slice(-2)
                                    .join(" ")}
                                </span>
                              </div>
                            ) : (
                              <>{column?.format(value, row)}</>
                            )}
                          </span>
                        ) : (
                          <span
                            className={`${column.className} ${
                              tableClass
                                ? "text-[13px] font-normal text-htext-main"
                                : ""
                            } `}
                          >
                            {tableClass &&
                            (value === "FAILED" ||
                              value === "IN_PROGRESS" ||
                              value === "ON_HOLD" ||
                              value === "SUCCESS") ? (
                              <div
                                className={`w-fit rounded-2xl border border-solid px-[9px] py-0.5 ${
                                  value === "FAILED"
                                    ? "border-herror-200 bg-herror-50 text-herror-700"
                                    : value === "IN_PROGRESS"
                                      ? "border-hgray-200 bg-hgray-50 text-hgray-700"
                                      : value === "ON_HOLD"
                                        ? "border-hwarning-200 bg-hwarning-50 text-hwarning-700"
                                        : value === "SUCCESS"
                                          ? "border-hsuccess-200 bg-hsuccess-50 text-hsuccess-700"
                                          : ""
                                }`}
                              >
                                <span className="font-medium">
                                  {capitalize(String(value ?? "")) === "Success"
                                    ? "Completed"
                                    : capitalize(String(value ?? "")) ===
                                        "On_hold"
                                      ? "On hold"
                                      : capitalize(String(value ?? "")) ===
                                          "In_progress"
                                        ? "Processing"
                                        : capitalize(String(value ?? ""))}
                                </span>
                              </div>
                            ) : column.label === "Conversion" ? (
                              <div className="flex items-center">
                                <img
                                  className="w-[34.5px]"
                                  src={getImageSrc(column.format?.(value, row))}
                                  alt="Conversion icons ticket"
                                />
                                <div className="ml-3 flex flex-col">
                                  <span>{column.format?.(value, row)}</span>
                                  <p className="mt-0.5 text-[11px] font-normal text-htext-subdued">
                                    {/* {column.format?.(value, row)} */}
                                  </p>
                                </div>
                              </div>
                            ) : column.label === "Input amount" ? (
                              // <div className="flex items-center justify-between gap-x-7">
                              <span>{column.format?.(value, row)}</span>
                            ) : // </div>
                            column.label === "Output amount" ? (
                              <div className="flex items-center justify-between gap-x-7">
                                <span>{column.format?.(value, row)||'Processing'}</span>
                                <p
                                  className="cursor-pointer font-medium text-hbrand-600 hover:text-hbrand-700"
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent triggering the row onClick
                                    setShowTransactionPopup(
                                      true,
                                      transactionId
                                    ); // Pass the id to the handler
                                  }}
                                >
                                  View
                                </p>
                              </div>
                            ) : (
                              <span>{String(value ?? "")}</span>
                            )}
                          </span>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}
