import PortalOutWalletAccount from "../../../../components/Dashboard/portal-accounts/portal-out/portal-out-wallet-details";

function PortalOutWalletAccounts() {
    return (
            <div>
                <PortalOutWalletAccount />
            </div>
    )
}

export default PortalOutWalletAccounts;