import React, { FC } from "react";

interface PopupContentProps {
  className?: string;
  popClass?: string;
  children?: React.ReactNode;
  closePopup?: () => void;
}

const Popup: FC<PopupContentProps> = ({ className, popClass, children, closePopup }) => {
  return (
    <div
      className={`popupContainer fixed left-0 top-0 z-[11] h-screen w-screen overflow-y-scroll bg-hgray-900/[0.7] backdrop-blur-[1px] ${className}`}
    >
      <div
        className="scrollContainer h-full w-full overflow-y-scroll"
        onClick={closePopup ? closePopup : undefined}
      >
        <div className={`popupContent absolute bottom-0 left-0 right-0 top-0 m-auto max-h-fit rounded-lg bg-white ${popClass}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Popup;
