import ResetEmailSent from "../../components/Auth/ResetPassword/EmailSent";

function AuthResetEmailSent() {
    return (
            <div>
                <ResetEmailSent />
            </div>
    )
}

export default AuthResetEmailSent;