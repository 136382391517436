import PortalInActivateAccount from "../../../../components/Dashboard/portal-accounts/portal-in/portal-in-activate-account";

function PortalInActivateAccounts() {
    return (
            <div>
                <PortalInActivateAccount />
            </div>
    )
}

export default PortalInActivateAccounts;