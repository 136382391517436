import clsx from 'classnames';
import React from 'react';

type DrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  position?: 'left' | 'right' | 'top' | 'bottom';
  width?: string;
  title?: string;
  children: React.ReactNode;
  customClass?: string;
  showCloseButton?: boolean;
};

const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  onClose,
  position = 'right',
  width = 'w-[30%]',
  title = 'Drawer Title',
  children,
  customClass = '',
  showCloseButton = true,
}) => {
  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 z-30 bg-gray-900 bg-opacity-50 transition-opacity"
          onClick={onClose}
        />
      )}

      <div
        className={clsx(
          'fixed top-0 z-40 h-screen p-4 overflow-y-auto transition-transform bg-white',
          {
            'translate-x-full': !isOpen && position === 'right',
            '-translate-x-full': !isOpen && position === 'left',
            'translate-y-full': !isOpen && position === 'bottom',
            '-translate-y-full': !isOpen && position === 'top',
          },
          position === 'right' && 'right-0',
          position === 'left' && 'left-0',
          position === 'top' && 'top-0',
          position === 'bottom' && 'bottom-0',
          width,
          customClass
        )}
        tabIndex={-1}
        aria-labelledby="drawer-label"
      >
        <h5 id="drawer-label" className="inline-flex items-center mb-4 text-base font-bold font-ibm-plex  text-gray-500">
          {title}
        </h5>
        {showCloseButton && (
          <button
            type="button"
            onClick={onClose}
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center"
          >
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
            <span className="sr-only">Close menu</span>
          </button>
        )}
        {children}
      </div>
    </>
  );        

};

export default Drawer;
